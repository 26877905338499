import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { Injectable, inject } from '@angular/core';
import { AccountService } from 'src/app/shared/services/account.service';

@Injectable({
  providedIn: 'root',
})
export class SiteDetailSensorsService {
  constructor() {}

  private accountService = inject(AccountService);

  isSummer() {
    return UtilFunctions.isSummer();
  }

  isWinter() {
    return this.accountService.isWestUser() ? UtilFunctions.isWinter() : false;
  }
}
