<section>
  <div class="m-5">
    <p class="text-4xl py-5">設定済センサー</p>
    <ng-container *ngIf="site$ | async as site">
      <app-sensor-list-table
        [site]="site"
        (selected)="onSelectedSensor($event)"
      ></app-sensor-list-table>
    </ng-container>
  </div>
  <div class="mx-5 my-10 flex gap-2">
    <div>
      <button mat-raised-button color="accent" (click)="onCancel()">
        キャンセル
      </button>
    </div>
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="onWbgtSensor()"
        [disabled]="isVisibleWbgtForm"
      >
        WBGT追加
      </button>
    </div>
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="onWindSensor()"
        [disabled]="isVisibleWindForm"
      >
        風速追加
      </button>
    </div>
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="onNoiseSensor()"
        [disabled]="isVisibleNoiseForm"
      >
        騒音追加
      </button>
    </div>
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="onPhSensor()"
        [disabled]="isVisiblePhForm"
      >
        pH追加
      </button>
    </div>
  </div>
  <div>
    <ng-container *ngIf="isVisibleWbgtForm && (sensor$ | async)">
      <app-sensor-wbgt-form
        [sensor]="sensor$ | async"
        (registered)="onWbgtRegistered()"
        (canceled)="onWbgtCanceled()"
      ></app-sensor-wbgt-form>
    </ng-container>
    <ng-container *ngIf="isVisibleWindForm">
      <app-sensor-wind-form
        [sensor]="sensor$ | async"
        (registered)="onWindRegistered()"
        (canceled)="onWindCanceled()"
      ></app-sensor-wind-form>
    </ng-container>
    <ng-container *ngIf="isVisibleNoiseForm">
      <app-sensor-noise-form
        [sensor]="sensor$ | async"
        (registered)="onNoiseRegistered()"
        (canceled)="onNoiseCanceled()"
      ></app-sensor-noise-form>
    </ng-container>
    <ng-container *ngIf="isVisiblePhForm">
      <app-sensor-ph-form
        [sensor]="sensor$ | async"
        (registered)="onPhRegistered()"
        (canceled)="onPhCanceled()"
      ></app-sensor-ph-form>
    </ng-container>
  </div>
</section>
