<section>
  <mat-card class="site-map-card">
    <mat-card-header>
      <mat-card-title class="site-map-title">
        現場地図表示
        <ng-container *ngIf="!isWinter">
          <button
            mat-raised-button
            color="primary"
            class="site-map-title-button"
            [disabled]="isWbgtMarker"
            (click)="onToggle()"
          >
            WBGT
          </button>
        </ng-container>
        <ng-container *ngIf="isWinter">
          <button
            mat-raised-button
            color="primary"
            class="site-map-title-button"
            [disabled]="isTemperatureMarker"
            (click)="onToggle()"
          >
            温度
          </button>
        </ng-container>
        <button
          mat-raised-button
          color="primary"
          class="site-map-title-button"
          [disabled]="isWindMarker"
          (click)="onToggle()"
        >
          風向風速
        </button>
      </mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="isWbgtMarker">
      <div class="site-map-guide">
        <span>
          WBGT凡例　
          <span class="status-signal-20"> 1 </span>危険：33℃以上
          <span class="status-signal-11"> 1 </span>厳重警戒：30℃から33℃
          <span class="status-signal-10"> 1 </span>警戒：28℃から30℃
          <span class="status-signal-3"> 1 </span>注意：26℃から28℃
          <span class="status-signal-2"> 1 </span>ほぼ安全：25℃から26℃
          <span class="status-signal-1"> 1 </span>ほぼ安全：25℃未満
          <span class="status-signal-99"> 1 </span>
          停止：信号なし又はセンサー未設定
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="isTemperatureMarker">
      <div class="site-map-guide">
        <span>
          温度凡例　要注意（<span class="status-temperature-signal-10"> 1 </span
          >-5℃以下<span class="status-temperature-signal-9 ml-4"> 1 </span
          >0℃以下<span class="status-temperature-signal-8 ml-4"> 1 </span
          >5℃以下）、その他(<span class="status-temperature-signal-7 ml-4">
            1 </span
          >5℃以上<span class="status-temperature-signal-6 ml-4"> 1 </span
          >10℃以上<span class="status-temperature-signal-5 ml-4"> 1 </span
          >15℃以上<span class="status-temperature-signal-4 ml-4"> 1 </span
          >20℃以上<span class="status-temperature-signal-3 ml-4"> 1 </span
          >25℃以上<span class="status-temperature-signal-2 ml-4"> 1 </span
          >30℃以上<span class="status-temperature-signal-1 ml-4"> 1 </span
          >35℃以上)、<span class="status-temperature-signal-99"> 1 </span
          >停止：信号なし又はセンサー未設定</span
        >
      </div>
    </ng-container>
    <ng-container *ngIf="isWindMarker">
      <div class="site-map-guide">
        <span>
          風速凡例　
          <span class="status-wind-signal-20"> 1 </span>警報：10m以上
          <span class="status-wind-signal-10"> 1 </span>注意：8m以上10m未満
          <span class="status-wind-signal-3"> 1 </span>注意：5m以上8m未満
          <span class="status-wind-signal-2"> 1 </span>ほぼ安全：2m以上5m未満
          <span class="status-wind-signal-1"> 1 </span>ほぼ安全：2m未満
          <span class="status-wind-signal-99"> 1 </span>停止：信号なし
        </span>
      </div>
    </ng-container>
    <ng-container>
      <div class="site-map">
        <app-map
          [isWbgtMarker]="isWbgtMarker"
          [isWindMarker]="isWindMarker"
          [isTemperatureMarker]="isTemperatureMarker"
          [clickable]="clickable"
        ></app-map>
      </div>
    </ng-container>
    <mat-card-content></mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>
</section>
