import { Sensor } from 'src/app/shared/interfaces/sensor';
import { SensorService } from 'src/app/shared/services/sensor.service';

import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DocumentData, DocumentReference } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-sensor-noise-standard',
  templateUrl: './sensor-noise-standard.component.html',
  styleUrls: ['./sensor-noise-standard.component.scss'],
})
export class SensorNoiseStandardComponent implements OnInit {
  @Input() sensorRef: DocumentReference<DocumentData> | undefined;
  @Output() onSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  sensorService = inject(SensorService);
  fb = inject(FormBuilder);

  sensor: Sensor | undefined = undefined;

  form = this.fb.group({
    standardNoise: [
      '',
      [Validators.required, Validators.min(0), Validators.max(100)],
    ],
    standardVibration: [
      '',
      [Validators.required, Validators.min(0), Validators.max(100)],
    ],
  });
  get standardNoise() {
    return this.form.get('standardNoise');
  }
  get standardVibration() {
    return this.form.get('standardVibration');
  }

  constructor() {}

  async ngOnInit(): Promise<void> {
    if (this.sensorRef !== undefined) {
      this.sensor = await this.sensorService.getFromReference(this.sensorRef);

      if (this.sensor !== undefined) {
        if (this.sensor.standardNoise !== undefined) {
          this.standardNoise?.setValue(this.sensor.standardNoise?.toString());
        }
        if (this.sensor.standardVibration !== undefined) {
          this.standardVibration?.setValue(
            this.sensor.standardVibration?.toString()
          );
        }
      }
    }
  }

  async onSubmit() {
    try {
      await this.sensorService.updateNoiseStandard(
        this.sensor?.id!,
        Number(this.standardNoise?.value),
        Number(this.standardVibration?.value)
      );
      this.onSaved.emit(true);
    } catch (error) {
      console.error(error);
    }
  }
}
