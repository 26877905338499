<p class="page-title">月次データダウンロード</p>
<mat-card class="data-download-card">
  <mat-card-content>
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let item of items; let i = index">
        <span class="data-download-date">
          {{ item.year }}年{{ item.month }}月
        </span>
        <mat-icon class="data-download-icon" (click)="onClickDownload(i)">
          download
        </mat-icon>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
