<section>
  <div *ngIf="apiLoaded">
    <google-map
      class="google-map-container"
      width="100%"
      height="75vh"
      [options]="mapOptions"
    >
      <map-marker
        *ngFor="let mark of wbgtMarks"
        [position]="mark.position"
        [options]="mark.options!"
        [title]="mark.title!"
        [visible]="mark.visible!"
        (mapClick)="onClickMarker(mark.siteId)"
      ></map-marker>
      <map-marker
        *ngFor="let mark of temperatureMarks"
        [position]="mark.position"
        [options]="mark.options!"
        [title]="mark.title!"
        [visible]="mark.visible!"
        (mapClick)="onClickMarker(mark.siteId)"
      ></map-marker>
      <map-marker
        *ngFor="let mark of windMarks"
        [position]="mark.position"
        [options]="mark.options!"
        [title]="mark.title!"
        [visible]="mark.visible!"
        (mapClick)="onClickMarker(mark.siteId)"
      ></map-marker>
    </google-map>
  </div>
</section>
