import { Subscription } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/shared/components/yes-no-dialog/yes-no-dialog.component';
import { AppConst } from 'src/app/shared/constants/app-const';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { Site } from 'src/app/shared/interfaces/site';
import { YesNoDialogData } from 'src/app/shared/interfaces/yes-no-dialog-data';

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { SensorListTableService } from './sensor-list-table.service';

@Component({
  selector: 'app-sensor-list-table',
  templateUrl: './sensor-list-table.component.html',
  styleUrls: ['./sensor-list-table.component.scss'],
})
export class SensorListTableComponent implements OnInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    private sensorListTableService: SensorListTableService
  ) {}

  @Input() site: Site = {} as Site;
  @Output() selected = new EventEmitter<string>();

  displayedColumns: string[] = [
    'id',
    'categoryName',
    'positionName',
    'actions',
  ];
  dataSource: Sensor[] = [];
  sensors: Sensor[] = [];

  subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(
      this.sensorListTableService
        .fetchSensorsFromSiteId(this.site)
        .subscribe((sensors) => {
          this.dataSource = sensors;
          this.sensors = sensors;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSelect(id: string) {
    this.selected.emit(id);
  }

  onDelete(id: string) {
    const sensor = this.sensors.find((sensor) => sensor.id === id);
    if (sensor === undefined) {
      return;
    }

    const dialogData: YesNoDialogData = {
      title:
        AppConst.YESNO_DIALOG_DELETE_SITE_SENSOR_TITLE +
        `: ${sensor.id}(${sensor.categoryName})`,
      message:
        this.site.name +
        'から' +
        AppConst.YESNO_DIALOG_DELETE_SITE_SENSOR_MESSAGE,
      captionNo: AppConst.YESNO_DIALOG_CAPTION_NO,
      captionYes: AppConst.YESNO_DIALOG_CAPTION_YES,
    };
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      height: AppConst.YES_NO_DIALOG_HEIGHT,
      width: AppConst.YES_NO_DIALOG_WIDTH,
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        return;
      }

      try {
        await this.sensorListTableService.deleteSensor(this.site, sensor);
      } catch (error) {
        console.log('SiteConfigPageComponent.deleteConfig(): ', error);
      }
    });
  }
}
