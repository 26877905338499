import { GeoPoint } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { v4 as uuidV4 } from 'uuid';

import { Component, Input, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { AppConst } from '../../constants/app-const';
import { UrlConst } from '../../constants/url-const';
import { Group } from '../../interfaces/group';
import { SignUpRequest } from '../../interfaces/sign-up-request';
import { Site } from '../../interfaces/site';
import { SiteManagementDepartment } from '../../interfaces/site-management-department';
import { SiteUpdateRequest } from '../../interfaces/site-update-request';
import { AccountService } from '../../services/account.service';
import { GoogleMapApiService } from '../../services/google-map-api.service';
import { GroupService } from '../../services/group.service';
import { LoadingService } from '../../services/loading.service';
import { SiteManagementDepartmentService } from '../../services/site-management-department.service';
import { SiteService } from '../../services/site.service';
import { UtilFunctions } from '../../utils/util-functions';

@Component({
  selector: 'app-site-config-form',
  templateUrl: './site-config-form.component.html',
  styleUrls: ['./site-config-form.component.scss'],
})
export class SiteConfigFormComponent implements OnInit {
  @Input() id: string = '';
  site: Site | null = null;
  departments: SiteManagementDepartment[] = [];
  departments$ = this.siteManagementDepartmentService.getAll();
  errors: string[] = [];
  apiLoaded: boolean = false;

  managementDepartmentId: string = '';
  uuid: string = uuidV4();
  siteUrl: string = '';
  address1: string = '';
  address2: string = '';

  form = this.formBuilder.group({
    id: [
      '',
      [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(9),
        Validators.maxLength(10),
      ],
    ],
    name: ['', Validators.required],
    address1: ['', Validators.required],
    address2: [''],
    department: ['', Validators.required],
    emails: this.formBuilder.array([]),
  });

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private loadingService: LoadingService,
    private siteService: SiteService,
    private accountService: AccountService,
    private groupService: GroupService,
    private siteManagementDepartmentService: SiteManagementDepartmentService,
    private mapApiService: GoogleMapApiService
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.apiLoaded) {
      try {
        this.apiLoaded = await firstValueFrom(this.mapApiService.setApiUrl());
      } catch (error) {
        console.log('ngOnInit', error);
      }
    }

    // this.departments = await this.siteManagementDepartmentService.getAll();
    this.departments = await this.departments$;
    this.siteUrl =
      'https://' + environment.fqdn + UrlConst.SLASH + 'q/' + this.uuid;

    if (this.id) {
      this.site = await this.siteService.get(this.id);

      if (this.site === null) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      }

      this.form.get('id')!.setValue(this.site!.id);
      this.form.get('name')!.setValue(this.site!.name);
      if (this.site!.address1) {
        this.form.get('address1')!.setValue(this.site!.address1);
      } else {
        this.form.get('address1')!.setValue(this.site!.address);
      }
      this.managementDepartmentId = this.site!.managementDepartmentId;
      this.form.get('department')!.setValue(this.site!.managementDepartmentId);

      if (this.site!.address2) {
        this.form.get('address2')!.setValue(this.site!.address2);
      }
      if (this.site!.uuid) {
        this.siteUrl =
          'https://' +
          environment.fqdn +
          UrlConst.SLASH +
          'q/' +
          this.site!.uuid;
      }
    }
  }

  async onClick(next: boolean) {
    const siteManagementDepartment: SiteManagementDepartment | undefined =
      this.departments.find((d) => {
        return d.id === this.form.get('department')?.value;
      });

    if (siteManagementDepartment === null) {
      return;
    }

    const request: SiteUpdateRequest = {
      id: this.form.get('id')?.value,
      name: this.form.get('name')?.value,
      address1: this.form.get('address1')?.value,
      address2: this.form.get('address2')?.value,
      uuid: this.uuid,
      managementDepartmentId: this.form.get('department')?.value,
      managementDepartmentName: siteManagementDepartment!.name,
      updatedAt: UtilFunctions.now(),
    };

    request.address2 = this.form.get('address2')?.value;
    request.address = request.address1 + this.form.get('address2')?.value;

    // 新規もしくは住所が変わった場合、もしくはgeopointがFirestoreに保存されてない時
    if (
      this.site === null ||
      this.site.geopoint === undefined ||
      (this.site && this.site.address !== request.address)
    ) {
      const geo = await this.mapApiService.getGeometry(request.address);
      request.geopoint = new GeoPoint(geo.latitude, geo.longitude);
    }

    if (!this.site) {
      this.create(request, next);
    } else {
      this.update(request);
    }
  }

  onCancel(): void {
    this.site = null;
    this.departments = [];
    this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
  }

  async create(request: SiteUpdateRequest, next: boolean) {
    try {
      this.loadingService.startLoading();
      const organizationId =
        this.accountService.getOrganizationId() ??
        AppConst.DEFAULT_ORGANIZATION_ID;
      request.organizationId = organizationId;
      request.createdAt = UtilFunctions.now();

      await this.siteService.create(request);

      // 作成する現場用の一般ユーザと外部ユーザを作成する
      const generalGroup: Group = await this.groupService.get(
        environment.groupIdGeneral
      );
      const generalRequest: SignUpRequest = {
        organizationId: organizationId,
        user: request.id,
        password: AppConst.SITE_PASSWORD,
        groupId: generalGroup.id,
        groupName: generalGroup.name,
        groupRef: this.groupService.getGroupReference(generalGroup.id),
      };
      await this.accountService.signUp(generalRequest);

      const outerGroup: Group = await this.groupService.get(
        environment.groupIdOuter
      );
      const outerRequest: SignUpRequest = {
        organizationId: organizationId,
        user: request.id + 'p',
        password: AppConst.OUTER_PASSWORD,
        groupId: outerGroup.id,
        groupName: outerGroup.name,
        groupRef: this.groupService.getGroupReference(outerGroup.id),
      };
      await this.accountService.signUp(outerRequest);

      if (next) {
        this.router.navigate([
          UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_SENSOR + '/' + request.id,
        ]);
      } else {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.stopLoading();
    }
  }

  async update(request: SiteUpdateRequest): Promise<void> {
    try {
      this.loadingService.startLoading();
      await this.siteService.update(request);
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.stopLoading();
    }
  }
}
