<section class="sign-up-page-wrapper">
  <div class="sign-up-page">
    <div class="sign-up-title-wrapper">
      <p>ユーザ登録</p>
    </div>
    <app-error-messaging></app-error-messaging>
    <div class="sign-up-criteria-wrapper">
      <form [formGroup]="form">
        <mat-error class="validation-error" *ngIf="error">
          ユーザ登録できませんでした
        </mat-error>

        <div>
          <mat-form-field>
            <mat-label>ユーザ名</mat-label>
            <input
              id="signup-user-account"
              matInput
              type="text"
              formControlName="user"
              placeholder="ユーザ名"
              required
            />
            <mat-error
              class="validation-error"
              *ngIf="user?.invalid && (user?.dirty || user?.touched)"
            >
              必須項目です
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>パスワード</mat-label>
            <input
              id="signup-user-password"
              matInput
              type="password"
              formControlName="password"
              placeholder="パスワード"
              required
            />
            <mat-error
              class="validation-error"
              *ngIf="
                password?.invalid && (password?.dirty || password?.touched)
              "
            >
              必須項目です
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>パスワード（確認）</mat-label>
            <input
              id="signup-user-password"
              matInput
              type="password"
              formControlName="confirm"
              placeholder="パスワード"
              required
            />
            <mat-error
              class="validation-error"
              *ngIf="
                (confirm?.invalid && (confirm?.dirty || confirm?.touched)) ||
                password?.value !== confirm?.value
              "
            >
              必須項目です{{ password?.value }}:{{ confirm?.value }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>グループ</mat-label>
            <mat-select formControlName="groupId" required>
              <mat-option *ngFor="let g of groups$ | async" [value]="g['id']">
                {{ g["name"] }}
              </mat-option>
            </mat-select>
            <mat-error
              class="validation-error"
              *ngIf="
                password?.invalid && (password?.dirty || password?.touched)
              "
            >
              必須項目です
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <button
            mat-flat-button
            color="primary"
            id="sign-up-button"
            class="flat-button"
            type="submit"
            [disabled]="!form.valid"
            (click)="clickSignUpButton()"
          >
            サインアップ
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
