import { UrlConst } from 'src/app/shared/constants/url-const';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { Site, SiteDrawingPosition } from 'src/app/shared/interfaces/site';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SiteConfigDrawingPageService } from './site-config-drawing-page.service';

@Component({
  selector: 'app-site-config-drawing-page',
  templateUrl: './site-config-drawing-page.component.html',
  styleUrls: ['./site-config-drawing-page.component.scss'],
})
export class SiteConfigDrawingPageComponent implements OnInit {
  windEnable = true;
  siteId: string | null = null;
  site: Site | null = null;
  imageSrc: string | ArrayBuffer | null = '';
  imageFile: File | null = null;
  topFloorBoxColor: String = 'rgba(500,0,0,0.8)';
  boardBoxColor: String = 'rgba(500,0,0,0.8)';

  isExistsImage: boolean = false;
  compassAngle: number = 0;

  sensors: Sensor[] = [];

  boardBoxStyle = {
    // [style.top.px]="boxPosition['board']!.top"
    // [style.left.px]="boxPosition['board']!.left"
    'background-color': 'rgba(500, 0, 0, 0.8)',
    'top.px': '',
    'left.px': '',
  };
  topFloorBoxStyle = {
    // [style.top.px]="boxPosition['top_floor']!.top"
    // [style.left.px]="boxPosition['top_floor']!.left"
    'background-color': 'rgba(500, 0, 0, 0.8)',
    'top.px': '',
    'left.px': '',
  };

  toggleBoardBox = false;
  toggleTopFloorBox = false;

  boxPosition: SiteDrawingPosition = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private siteService: SiteService,
    private accountService: AccountService,
    private logService: AccessLogService,
    private siteConfigDrawingPageService: SiteConfigDrawingPageService
  ) {}

  async ngOnInit() {
    await this.logService.addLog(
      UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_DRAWING
    );

    if (
      !(
        this.accountService.isSysAdminGroup() ||
        this.accountService.isGeneralGroup()
      )
    ) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
      return;
    }

    this.siteId = this.route.snapshot.paramMap.get('id');

    if (this.accountService.isGeneralGroup()) {
      const _account = this.accountService.getUser();
      if (_account === null) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      }
      if (this.siteId !== _account.user) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      }
    }

    if (this.siteId === null) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
    } else {
      this.site = await this.siteService.get(this.siteId);

      if (this.site === null || this.site!.deletedAt) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      }

      this.sensors = await this.siteConfigDrawingPageService.getSensorsFromSite(
        this.site!
      );

      if ('drawingImageURL' in this.site!) {
        this.imageSrc = this.site.drawingImageURL!;
        this.isExistsImage = true;
      }

      let data: SiteDrawingPosition = {};
      if ('drawingPosition' in this.site!) {
        data['board'] = this.site!.drawingPosition?.board;
        data['top_floor'] = this.site!.drawingPosition?.top_floor;

        this.boxPosition = data;
        let boxStyle = this.boardBoxStyle;
        boxStyle = {
          ...boxStyle,
          'top.px': `${data['board']?.top}`,
          'left.px': `${data['board']?.left}`,
        };
        this.boardBoxStyle = boxStyle;
        boxStyle = this.topFloorBoxStyle;
        boxStyle = {
          ...boxStyle,
          'top.px': `${data['top_floor']?.top}`,
          'left.px': `${data['top_floor']?.left}`,
        };
        this.topFloorBoxStyle = boxStyle;

        this.toggleBoardBox = true;
        this.toggleTopFloorBox = true;
      } else {
        data['board'] = { top: 0, left: 0 };
        data['top_floor'] = { top: 0, left: 0 };
        this.boxPosition = data;
      }

      // 北の向き
      this.compassAngle = this.site!.compassAngle
        ? this.site!.compassAngle!
        : 0;
    }
  }

  async onChange(event: any) {
    const reader = new FileReader();
    this.imageFile = event.target.files[0];
    const name = this.imageFile?.name;
    // const type = name!.substr(name!.lastIndexOf('.') + 1 - name!.length);

    reader.onload = () => {
      this.imageSrc = reader.result;
      this.isExistsImage = true;
    };
    reader.readAsDataURL(this.imageFile!);
  }

  async onRelease(event: any, position: string) {
    const element = event.source.getRootElement();
    const parent = element.parentNode;
    const parentRect = parent.getBoundingClientRect();
    let rect = element.getBoundingClientRect();

    const top = rect.top - parentRect.top;
    const left = rect.left - parentRect.left;

    const key: keyof SiteDrawingPosition =
      position as keyof SiteDrawingPosition;

    let data: SiteDrawingPosition = this.boxPosition;
    data[key] = { top: top, left: left };

    this.boxPosition = data;
    // if ('board' in data && 'top_floor' in data) {
    //   this.invalid = false;
    // }
  }

  async onClick() {
    try {
      this.loadingService.startLoading();
      if (this.imageFile) {
        await this.siteService.uploadDrawing(this.siteId!, this.imageFile!);
      }
      await this.siteService.setDrawingPosition(this.siteId!, this.boxPosition);
      await this.siteService.setCompassAngle(this.site!, this.compassAngle);

      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.stopLoading();
    }
  }

  clickCancel() {
    this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
  }

  toggleBoard() {
    this.toggleBoardBox = !this.toggleBoardBox;
    if (this.toggleBoardBox === false) {
      delete this.boxPosition['board'];
    } else {
      let data = this.boxPosition;
      data['board'] = { top: 0, left: 0 };
      this.boxPosition = data;
    }
  }

  toggleTopFloor() {
    this.toggleTopFloorBox = !this.toggleTopFloorBox;
    if (this.toggleTopFloorBox === false) {
      delete this.boxPosition['top_floor'];
    } else {
      let data = this.boxPosition;
      data['top_floor'] = { top: 0, left: 0 };
      this.boxPosition = data;
    }
  }

  onChangeCompassAngle(event: number) {
    this.compassAngle = event;
  }
}
