import { environment } from 'src/environments/environment';

import { SensorStatus } from '../interfaces/sensor-status';

export class AppConst {
  static readonly APP_TITLE = '長谷工IoT';
  static readonly SIGN_IN_CARD_TITLE = '長谷工IoTサインイン';
  static readonly SESSION_KEY_USER = 'user';
  static readonly SESSION_KEY_GROUP = 'group';
  static readonly DEFAULT_ORGANIZATION_ID = '10';
  static readonly ORGANIZATION_ID_WEST = '10';
  static readonly ORGANIZATION_ID_EAST = '20';
  static readonly YESNO_DIALOG_SIGNOUT_TITLE = 'サインアウト';
  static readonly YESNO_DIALOG_SIGNOUT_MESSAGE = 'サインアウトしますか?';
  static readonly YESNO_DIALOG_DELETE_SITE_TITLE = '現場削除';
  static readonly YESNO_DIALOG_DELETE_SITE_MESSAGE =
    'センサー、図面等の関連データも合わせて削除します';
  static readonly YESNO_DIALOG_DELETE_SITE_SENSOR_TITLE = 'センサー削除';
  static readonly YESNO_DIALOG_DELETE_SITE_SENSOR_MESSAGE = '削除します';
  static readonly YESNO_DIALOG_CAPTION_YES = 'はい';
  static readonly YESNO_DIALOG_CAPTION_NO = 'いいえ';
  static readonly YES_NO_DIALOG_HEIGHT = '250px';
  static readonly YES_NO_DIALOG_WIDTH = '400px';
  static readonly SITE_LIST_PAGE_FILTER_TIMEOUT = 750;
  static readonly USER_AUTH_DOMAIN = 'haseko-iot.senseway.net';
  static readonly RELOAD_INTERVAL =
    environment.mapReloadIntervalMin * 60 * 1000; // 10min = 10min * (60sec * 1000millisecond)

  // センサーの停止ステータス
  static readonly SENSOR_STATUS_STOPPED = '99';
  static readonly SENSOR_STATUS_STOPPED_LABEL = '停止';
  static readonly SENSOR_STATUS_STOPPED_BGCOLOR = '#757575';
  static readonly SENSOR_STATUS_STOPPED_COLOR = '#FFFFFF';

  // 騒音・振動センサーデフォルト値
  static readonly DEFAULT_NOISE = 86;
  static readonly DEFAULT_VIBRATION = 76;

  // pHセンサーデフォルト値
  static readonly DEFAULT_PH_MIN = 6.0;
  static readonly DEFAULT_PH_MAX = 8.0;

  static readonly SENSOR_STATUS: SensorStatus[] = [
    {
      no: '1',
      label: 'ほぼ安全',
      backgroundColor: '#305496',
      foregroundColor: '#ffffff',
    },
    {
      no: '2',
      label: 'ほぼ安全',
      backgroundColor: '#548235',
      foregroundColor: '#ffffff',
    },
    {
      no: '3',
      label: '注意',
      backgroundColor: '#ffff00',
      foregroundColor: '#777777',
    },
    {
      no: '10',
      label: '警戒',
      backgroundColor: '#ff9900',
      foregroundColor: '#ffffff',
    },
    {
      no: '11',
      label: '厳重警戒',
      backgroundColor: '#ff0000',
      foregroundColor: '#ffffff',
    },
    {
      no: '20',
      label: '危険',
      backgroundColor: '#a50021',
      foregroundColor: '#ffffff',
    },
    {
      no: '99',
      label: '停止',
      backgroundColor: '#757575',
      foregroundColor: '#ffffff',
    },
  ];

  static readonly TEMPERATURE_SENSOR_STATUS: SensorStatus[] = [
    { no: '1', label: 'ー(35℃以上)', backgroundColor: '#B40068' },
    { no: '2', label: 'ー(30℃以上)', backgroundColor: '#FF2800' },
    { no: '3', label: 'ー(25℃以上)', backgroundColor: '#FF9900' },
    { no: '4', label: 'ー(20℃以上)', backgroundColor: '#FAF500' },
    { no: '5', label: 'ー(15℃以上)', backgroundColor: '#FFFF96' },
    { no: '6', label: 'ー(10℃以上)', backgroundColor: '#FFFFF0' },
    { no: '7', label: 'ー(5℃以上)', backgroundColor: '#B9EBFF' },
    { no: '8', label: '要注意(5℃以下)', backgroundColor: '#0096FF' },
    { no: '9', label: '要注意(0℃以下)', backgroundColor: '#0041FF' },
    { no: '10', label: '要注意(-5℃以下)', backgroundColor: '#002080' },
    { no: '99', label: '停止', backgroundColor: '#757575' },
  ];

  static readonly WIND_SENSOR_STATUS: SensorStatus[] = [
    {
      no: '1',
      label: 'ほぼ安全',
      backgroundColor: '#003366',
      foregroundColor: '#ffffff',
    },
    {
      no: '2',
      label: 'ほぼ安全',
      backgroundColor: '#339933',
      foregroundColor: '#ffffff',
    },
    {
      no: '3',
      label: '注意',
      backgroundColor: '#CCFF33',
      foregroundColor: '#777777',
    },
    {
      no: '10',
      label: '注意',
      backgroundColor: '#FFFF00',
      foregroundColor: '#777777',
    },
    {
      no: '20',
      label: '警報',
      backgroundColor: '#FF0000',
      foregroundColor: '#ffffff',
    },
    {
      no: '99',
      label: '停止',
      backgroundColor: '#757575',
      foregroundColor: '#ffffff',
    },
  ];

  static readonly NOISE_SENSOR_STATUS: SensorStatus[] = [
    {
      no: '1',
      label: '基準値未満',
      backgroundColor: '#003366',
      foregroundColor: '#ffffff',
    },
    {
      no: '10',
      label: '要確認',
      backgroundColor: '#FFFF00',
      foregroundColor: '#777777',
    },
    {
      no: '99',
      label: '停止',
      backgroundColor: '#757575',
      foregroundColor: '#ffffff',
    },
  ];

  // 停止 / 要確認(酸性 強）/ 正常 / 要確認(アルカリ 強）
  static readonly PH_SENSOR_STATUS: SensorStatus[] = [
    {
      no: '1',
      label: '正常',
      backgroundColor: '#003366',
      foregroundColor: '#ffffff',
    },
    {
      no: '10',
      label: '要確認(酸性 強)',
      backgroundColor: '#FFFF00',
      foregroundColor: '#777777',
    },
    {
      no: '11',
      label: '要確認(アルカリ 強)',
      backgroundColor: '#FFFF00',
      foregroundColor: '#777777',
    },
    {
      no: '99',
      label: '停止',
      backgroundColor: '#757575',
      foregroundColor: '#ffffff',
    },
  ];

  static readonly GROUP_NAME_ADMINISTRATOR: string = 'Administrators';
  static readonly GROUP_RANK_ADMINISTRATOR: number = 9;
  static readonly GROUP_RANK_SYSADMIN: number = 7;
  static readonly GROUP_RANK_CRITERIA: number = 5;
  static readonly GROUP_RANK_GENERAL: number = 3;
  static readonly GROUP_RANK_MONITOR: number = 2;
  static readonly GROUP_RANK_OUTER: number = 1;

  static readonly GOOGLE_MAP_API_KEY_URL =
    'https://maps.googleapis.com/maps/api/js?key=';
  static readonly GOOGLE_MAP_API_KEY =
    'AIzaSyDVA3lE5fVFqjKW8WDqlzeG1szZVuG5eUY';
  static readonly GOOGLE_MAP_ID = 'd6a6f2713107cfb4';

  static readonly FIREBASE_STORAGE_MONTHLY_SENSOR_CSV_DIR =
    'monthly-sensor-csv';
  static readonly FIREBASE_STORAGE_DAILY_ACCESS_LOG_DIR =
    'daily-access-log-csv';
  static readonly FIREBASE_STORAGE_DRAWING_DIR = 'drawing';

  static readonly WEATHER_NEWS_API_URL =
    'https://wxtech.weathernews.com/api/v1/ss1wx';
  static readonly WEATHER_NEWS_API_KEY =
    'd5Qk1TJ0dpZ4wjVWN3He9mMfy6dpvIY8bWzkg3L8';
  static readonly WEATHER_NEWS_API_ICON_URL =
    'https://tpf.weathernews.jp/wxicon/152/';

  static readonly SITE_DUMMY_USER = '000000001';
  static readonly SITE_PASSWORD = '18081808';
  static readonly OUTER_PASSWORD = 'haseko2022';

  // グラフ関連
  static readonly LINE_CHART_Y_SCALE_MAX = 10;
  static readonly NOISE_LINE_CHART_Y_SCALE_MAX = 100;
  static readonly PH_LINE_CHART_Y_SCALE_MAX = 14;
}
