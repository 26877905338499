import { inject, Injectable } from '@angular/core';

import { AppConst } from '../../constants/app-const';
import { AccountService } from '../../services/account.service';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  accountService = inject(AccountService);

  constructor() {}

  getDefaultCenter() {
    if (
      this.accountService.getOrganizationId() === AppConst.ORGANIZATION_ID_WEST
    ) {
      return { lat: 34.91663711768134, lng: 135.5000950822929 }; // 大阪
    } else if (
      this.accountService.getOrganizationId() === AppConst.ORGANIZATION_ID_EAST
    ) {
      return { lat: 36.06444546562778, lng: 139.88488602538501 }; // 茨城県坂東市
    } else {
      return { lat: 34.91663711768134, lng: 135.5000950822929 }; // 大阪
    }
  }
}
