import { Observable, of, Subscription } from 'rxjs';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { Site } from 'src/app/shared/interfaces/site';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SiteSensorService } from './site-sensor.service';

@Component({
  selector: 'app-site-sensor',
  templateUrl: './site-sensor.component.html',
  styleUrls: ['./site-sensor.component.scss'],
})
export class SiteSensorComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private siteSensorService: SiteSensorService
  ) {}

  siteId: string | null = null;
  site$: Observable<Site | null> = of({} as Site);
  sensor$: Observable<Sensor> = of({} as Sensor);
  isVisibleWbgtForm = false;
  isVisibleWindForm = false;
  isVisibleNoiseForm = false;
  isVisiblePhForm = false;

  subscriptions: Subscription = new Subscription();

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (params) => {
      this.siteId = params.get('id');

      if (this.siteId === null) {
        this.navigateToSiteConfig();
        return;
      }

      const site = await this.siteSensorService.getSiteWithOrganization(
        this.siteId
      );
      if (site === null) {
        this.navigateToSiteConfig();
        return;
      }

      this.site$ = this.siteSensorService.fetchSite(this.siteId);
    });
  }

  navigateToSiteConfig() {
    this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
  }

  onWbgtSensor() {
    this.resetVisibleForm();
    this.isVisibleWbgtForm = true;
  }

  onWindSensor() {
    this.resetVisibleForm();
    this.isVisibleWindForm = true;
  }

  onNoiseSensor() {
    this.resetVisibleForm();
    this.isVisibleNoiseForm = true;
  }

  onPhSensor() {
    this.resetVisibleForm();
    this.isVisiblePhForm = true;
  }

  onWbgtRegistered() {
    this.sensor$ = of({} as Sensor);
    this.resetVisibleForm();
  }

  onWbgtCanceled() {
    this.sensor$ = of({} as Sensor);
    this.resetVisibleForm();
  }

  onWindRegistered() {
    this.sensor$ = of({} as Sensor);
    this.resetVisibleForm();
  }

  onWindCanceled() {
    this.sensor$ = of({} as Sensor);
    this.resetVisibleForm();
  }

  onNoiseRegistered() {
    this.sensor$ = of({} as Sensor);
    this.resetVisibleForm();
  }

  onNoiseCanceled() {
    this.sensor$ = of({} as Sensor);
    this.resetVisibleForm();
  }

  onPhRegistered() {
    this.sensor$ = of({} as Sensor);
    this.resetVisibleForm();
  }

  onPhCanceled() {
    this.sensor$ = of({} as Sensor);
    this.resetVisibleForm();
  }

  resetVisibleForm() {
    this.isVisibleWbgtForm = false;
    this.isVisibleWindForm = false;
    this.isVisibleNoiseForm = false;
    this.isVisiblePhForm = false;
  }

  onSelectedSensor(id: string) {
    this.sensor$ = this.siteSensorService.fetchSensor(id);
    this.sensor$.subscribe((sensor) => {
      if (sensor.categoryName === 'WBGT') {
        this.onWbgtSensor();
      } else if (sensor.categoryName === 'WIND') {
        this.onWindSensor();
      } else if (sensor.categoryName === 'NOISE') {
        this.onNoiseSensor();
      } else if (sensor.categoryName === 'PH') {
        this.onPhSensor();
      }
    });
  }

  onCancel() {
    this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
  }
}
