import {
    QrCodeDialogComponent
} from 'src/app/shared/components/qr-code-dialog/qr-code-dialog.component';
import {
    YesNoDialogComponent
} from 'src/app/shared/components/yes-no-dialog/yes-no-dialog.component';
import { AppConst } from 'src/app/shared/constants/app-const';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { Site } from 'src/app/shared/interfaces/site';
import { YesNoDialogData } from 'src/app/shared/interfaces/yes-no-dialog-data';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-config-page',
  templateUrl: './site-config-page.component.html',
  styleUrls: ['./site-config-page.component.scss'],
})
export class SiteConfigPageComponent implements OnInit {
  displayedColumns: string[] = ['name', 'address', 'action'];
  dataSource: Site[] = [];
  isSysAdmin = true;

  constructor(
    private matDialog: MatDialog,
    private loading: LoadingService,
    private siteService: SiteService,
    private sensorService: SensorService,
    private router: Router,
    private logService: AccessLogService,
    public dialog: MatDialog,
    private accountService: AccountService
  ) {}

  async ngOnInit() {
    await this.logService.addLog(UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG);
    if (this.accountService.isSysAdminGroup()) {
      // console.log(this.accountService.getUser());
      await this.siteService.getAll();
      this.dataSource = this.siteService.sites;
    } else if (this.accountService.isGeneralGroup()) {
      this.isSysAdmin = false;
      // console.log(this.accountService.getUser());
      const _account = this.accountService.getUser();
      const _site = await this.siteService.get(_account?.user!);
      this.dataSource = [_site!];
    } else {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
    }
  }

  clickConfigCreator(siteId: string) {
    this.router.navigate([
      UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_CREATE,
      siteId,
    ]);
  }

  clickConfigSensor(siteId: string) {
    this.router.navigate([
      UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_SENSOR,
      siteId,
    ]);
  }

  clickConfigDrawing(siteId: string) {
    this.router.navigate([
      UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_DRAWING,
      siteId,
    ]);
  }

  clickConfigAlert(siteId: string) {
    this.router.navigate([
      UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_ALERT,
      siteId,
    ]);
  }

  async openQRCodeDialog(siteId: string) {
    const _site = await this.siteService.get(siteId);
    if (_site === null) {
      return;
    }

    this.dialog.open(QrCodeDialogComponent, {
      data: {
        siteId: _site.id!,
        siteUrl:
          'https://' + environment.fqdn + UrlConst.SLASH + 'q/' + _site.uuid,
      },
    });
  }

  async clickConfigDelete(siteId: string) {
    const site = await this.siteService.get(siteId);
    if (site === null) {
      return;
    }

    const dialogData: YesNoDialogData = {
      title: AppConst.YESNO_DIALOG_DELETE_SITE_TITLE + `　(${site.name})`,
      message: AppConst.YESNO_DIALOG_DELETE_SITE_MESSAGE,
      captionNo: AppConst.YESNO_DIALOG_CAPTION_NO,
      captionYes: AppConst.YESNO_DIALOG_CAPTION_YES,
    };

    const dialogRef = this.matDialog.open(YesNoDialogComponent, {
      height: AppConst.YES_NO_DIALOG_HEIGHT,
      width: AppConst.YES_NO_DIALOG_WIDTH,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.deleteConfig(siteId);
      }
    });
  }

  async deleteConfig(siteId: string) {
    this.loading.startLoading();
    try {
      await this.sensorService.deleteSite(siteId);
      await this.siteService.delete(siteId);
      this.dataSource = await this.siteService.getAll();
    } catch (error) {
      console.log('SiteConfigPageComponent.deleteConfig(): ', error);
    } finally {
      this.loading.stopLoading();
    }
  }
}
