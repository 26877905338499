export class UrlConst {
  static readonly SLASH = '/';
  static readonly PATH_SIGN_IN = 'sign-in';
  static readonly PATH_SIGN_UP = 'sign-up';
  static readonly PATH_ACCOUNT_LIST = 'account-list';
  static readonly PATH_SITE_LIST = 'site-list';
  static readonly PATH_SITE_DETAIL_FROM_UUID = 'q';
  static readonly PATH_SITE_DETAIL = 'site-detail';
  static readonly PATH_SITE_MAP = 'site-map';
  static readonly PATH_SITE_CONFIG = 'site-config';
  static readonly PATH_SITE_CONFIG_CREATE = 'site-create';
  static readonly PATH_SITE_CONFIG_UPDATE = 'site-update';
  static readonly PATH_SITE_CONFIG_EDIT = 'site-config-edit';
  static readonly PATH_SITE_CONFIG_DELETE = 'site-config-delete';
  static readonly PATH_SITE_CONFIG_DRAWING = 'site-drawing';
  static readonly PATH_SITE_CONFIG_SENSOR = 'site-sensor';
  static readonly PATH_SITE_CONFIG_ALERT = 'site-config-alert';
  static readonly PATH_EXTRACT_DATA = 'extract-data';
  static readonly PATH_DOWNLOAD_DATA = 'download-data';
  static readonly PATH_DOWNLOAD_ACCESS_LOG = 'download-access-log';
  static readonly PATH_DOWNLOAD_PDF = 'download-pdf';
  static readonly PATH_DIGITAL_SIGNAGE = 'digital-signage';
}
