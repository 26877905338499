import { NoiseLatestSiteData } from 'src/app/shared/interfaces/latest-site-data';

import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { SiteDetailAlertService } from '../site-detail-alert/site-detail-alert.service';

@Component({
  selector: 'app-site-detail-alert-noise',
  templateUrl: './site-detail-alert-noise.component.html',
  styleUrls: ['./site-detail-alert-noise.component.scss'],
})
export class SiteDetailAlertNoiseComponent implements OnChanges {
  @Input() data: NoiseLatestSiteData | null = null;

  isOuter = false; // 外部のユーザかどうか

  siteDetailAlertService = inject(SiteDetailAlertService);

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isOuter = this.siteDetailAlertService.isOuterUser();
  }
}
