import {
  NoiseLatestSiteData,
  PhLatestSiteData,
  WbgtLatestSiteData,
  WindLatestSiteData,
} from 'src/app/shared/interfaces/latest-site-data';

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import { SiteDetailSensorsService } from '../site-detail-sensors/site-detail-sensors.service';

@Component({
  selector: 'app-site-detail-sensor',
  templateUrl: './site-detail-sensor.component.html',
  styleUrls: ['./site-detail-sensor.component.scss'],
})
export class SiteDetailSensorComponent implements OnChanges, OnDestroy {
  constructor(private siteDetailSensorsService: SiteDetailSensorsService) {}

  @Input() wbgtData: WbgtLatestSiteData | null = null;
  @Input() windData: WindLatestSiteData | null = null;
  @Input() noiseData: NoiseLatestSiteData | null = null;
  @Input() phData: PhLatestSiteData | null = null;

  isSummer: boolean = false;
  isWinter: boolean = false;
  isWbgtSensor: boolean = false;
  isWindSensor: boolean = false;
  isNoiseSensor: boolean = false;
  isPhSensor: boolean = false;
  flooredPh: number = 0;
  needleAngle: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.selectSensor();
  }

  selectSensor(): void {
    this.isSummer = this.siteDetailSensorsService.isSummer();
    this.isWinter = this.siteDetailSensorsService.isWinter();

    if (this.wbgtData !== null) {
      this.resetSensorFlags();
      this.isWbgtSensor = true;
    } else if (this.windData !== null) {
      this.resetSensorFlags();
      this.isWindSensor = true;
    } else if (this.noiseData !== null) {
      this.resetSensorFlags();
      this.isNoiseSensor = true;
    } else if (this.phData !== null) {
      this.resetSensorFlags();
      this.isPhSensor = true;
      if (this.phData.ph !== undefined) {
        this.needleAngle = this.phNeedleAngle(this.phData.ph);
        // this.flooredPh = Math.floor(this.phData.ph);
      }
    }
  }

  resetSensorFlags() {
    this.isWbgtSensor = false;
    this.isWindSensor = false;
    this.isNoiseSensor = false;
    this.isPhSensor = false;
  }

  ngOnDestroy(): void {}

  // pH値により針の角度を変更する
  // 例 x === 1
  //            x.0: (30 - (7.5 * 4)) => 0
  //     x.0 - x.25: (30 - (7.5 * 3)) => 7.5
  //           x.25: (30 - (7.5 * 2)) => 15
  //     x.25 - x.5: (30 - (7.5 * 1)) => 22.5
  //            x.5: 30
  //     x.5 - x.75: (30 + (7.5 * 1)) => 37.5
  //           x.75: (30 + (7.5 * 2)) => 45
  // x.75 - (x+1).0: (30 + (7.5 * 3)) => 52.5
  //        (x+1).0: (30 + (7.5 * 4)) => 60
  phNeedleAngle(ph: number): number {
    const x = Math.floor(ph);
    const deg = 15 / 4; // 1単位の角度

    if (ph === x + 0.0) {
      return 30 * x - deg * 4;
    } else if (x + 0.0 < ph && ph < x + 0.25) {
      return 30 * x - deg * 3;
    } else if (ph === x + 0.25) {
      return 30 * x - deg * 2;
    } else if (x + 0.25 < ph && ph < x + 0.5) {
      return 30 * x - deg * 1;
    } else if (ph === x + 0.5) {
      return 30 * x;
    } else if (x + 0.5 < ph && ph < x + 0.75) {
      return 30 * x + deg * 1;
    } else if (ph === x + 0.75) {
      return 30 * x + deg * 2;
    } else if (x + 0.75 < ph && ph < x + 1.0) {
      return 30 * x + deg * 3;
    }

    return 30 * x;
  }
}
