import { UrlConst } from 'src/app/shared/constants/url-const';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { SensorLogService } from 'src/app/shared/services/sensor-log.service';

import { Component, OnInit } from '@angular/core';
import { Storage } from '@angular/fire/storage';

@Component({
  selector: 'app-download-data',
  templateUrl: './download-data.component.html',
  styleUrls: ['./download-data.component.scss'],
})
export class DownloadDataComponent implements OnInit {
  items: { name: string; year: string; month: string; path: string }[] = [];

  constructor(
    private sensorLogService: SensorLogService,
    private logService: AccessLogService
  ) {}

  async ngOnInit() {
    await this.logService.addLog(UrlConst.SLASH + UrlConst.PATH_DOWNLOAD_DATA);

    const logList = await this.sensorLogService.getLogList();
    logList.forEach((log) => {
      const element: string[] = log.id.split('-');
      this.items.push({
        name: log.id,
        year: element[0],
        month: element[1],
        path: log.path,
      });
    });
  }

  async onClickDownload(index: number) {
    try {
      const blob = await this.sensorLogService.getBlob(this.items[index].path);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const newYM = this.items[index].name.replace('-', '');

      link.href = url;
      link.download = 'haseko-wbgt-monthly-' + newYM;
      link.click();
    } catch (error) {
      console.error(error);
    }
  }
}
