<p class="page-title">現場情報登録 / 変更</p>

<mat-card class="">
  <mat-card-content>
    <div class="">
      <div class="mb-6">
        <button
          mat-raised-button
          color="primary"
          routerLink="/site-config/site-create"
        >
          新規登録
        </button>
      </div>
      <div class="">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>現場名</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>住所</th>
            <td mat-cell *matCellDef="let element">{{ element.address }}</td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <div class="flex gap-2 justify-end">
                <button
                  *ngIf="isSysAdmin"
                  mat-raised-button
                  color="primary"
                  (click)="clickConfigCreator(element.id)"
                >
                  現場情報変更
                </button>
                <button
                  *ngIf="isSysAdmin"
                  mat-raised-button
                  color="primary"
                  (click)="clickConfigSensor(element.id)"
                >
                  センサー設定
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  (click)="clickConfigDrawing(element.id)"
                >
                  図面設定
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  (click)="clickConfigAlert(element.id)"
                >
                  通知設定
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  (click)="openQRCodeDialog(element.id)"
                >
                  QR生成
                </button>
                <button
                  *ngIf="isSysAdmin"
                  mat-raised-button
                  color="accent"
                  (click)="clickConfigDelete(element.id)"
                >
                  削除
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
