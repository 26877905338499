<mat-toolbar color="primary" class="header-toolbar">
  <button
    *ngIf="!isOuter"
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="sidenav?.toggle()"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span>ＨＬＳＳ</span>
  <span class="spacer"></span>
  <span *ngIf="!isGeneral && !isOuter" class="site-search">
    <form (ngSubmit)="clickSearch()">
      <mat-form-field>
        <mat-label>現場名で検索</mat-label>
        <input
          matInput
          id="site-search"
          name="site-search"
          type="text"
          class="site-search-field"
          placeholder="現場名"
          [(ngModel)]="searchName"
        />
      </mat-form-field>
      <button
        mat-stroked-button
        class="header-stroked-button"
        (click)="clickSearch()"
      >
        検索
      </button>
    </form>
  </span>
  <!-- <span class="spacer"></span> -->
  <span class="sign-in-user" *ngIf="!isOuter">{{ account?.user }}</span>
  <button
    mat-stroked-button
    class="header-stroked-button"
    (click)="clickSignOut()"
  >
    サインアウト
  </button>
</mat-toolbar>
