import { WbgtLatestSiteData } from 'src/app/shared/interfaces/latest-site-data';
import { SensorHistory } from 'src/app/shared/interfaces/sensor-history';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { AreaChartComponent, LegendPosition } from '@swimlane/ngx-charts';

import {
  LineChartResultDto,
  LineChartResultSeriesDto,
} from '../../charts.interface';
import { ChartsService } from '../../charts.service';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-temperature-line-chart',
  templateUrl: './temperature-line-chart.component.html',
  styleUrls: ['./temperature-line-chart.component.scss'],
})
export class TemperatureLineChartComponent implements OnChanges {
  @Input() data: WbgtLatestSiteData = {} as WbgtLatestSiteData;
  @Input() chartRange: string = 'daytime';
  @ViewChild(AreaChartComponent) chart: AreaChartComponent | null = null;

  private accountService = inject(AccountService);

  isSummer = UtilFunctions.isSummer();
  isWinter = this.accountService.isWestUser()
    ? UtilFunctions.isWinter()
    : false;
  results: LineChartResultDto[] = [];

  // Line chart options
  view: [number, number] = [600, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  legendTitle = '温度 (℃)';
  legendPosition = LegendPosition.Below;
  showXAxisLabel = false;
  xAxisLabel = 'Year';
  showYAxisLabel = false;
  yAxisLabel = 'Population';
  yScaleMin = this.isWinter ? -10 : 10;
  yScaleMax = 40;

  colorScheme = 'cool';
  lineColor = '#FF9800';
  customColors = (value: string) => {
    return this.lineColor;
  };

  timeline = true;

  sensorId = '';
  dataList: LineChartResultSeriesDto[] = [];

  constructor(private chartsService: ChartsService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.sensorId = this.data.id;
    this.setChartWidth();

    const histories = await this.getHistories();
    this.clearAllData();
    this.createDataSets(histories);

    this.results = [];
    this.results.push({
      name: '温度',
      series: this.dataList,
    });

    if (this.chart) {
      this.chart.update();
    }
  }

  onResize(event: UIEvent) {
    this.setChartWidth();
  }

  setChartWidth() {
    this.view[0] = this.chartsService.getChartWidthFromWindowWidth(
      window.innerWidth
    );
  }

  onSelect(event: any) {}

  async getHistories() {
    let histories: SensorHistory[] | null = null;
    if (this.chartRange === '' || this.chartRange === 'daytime') {
      histories = await this.chartsService.getDaytimeSensorHistory(
        this.sensorId
      );
    } else if (this.chartRange === 'day') {
      histories = await this.chartsService.getDaySensorHistory(this.sensorId);
    } else if (this.chartRange === 'week') {
      histories = await this.chartsService.getWeekSensorHistory(this.sensorId);
    } else if (this.chartRange === 'month') {
      histories = await this.chartsService.getMonthSensorHistory(this.sensorId);
    }

    return histories;
  }

  createDataSets(histories: SensorHistory[] | null) {
    if (histories === null) return;

    const result = this.chartsService.createWbgtChartDataSets(
      this.chartRange,
      histories
    );
    if (result !== undefined) {
      this.dataList = result.temperature;
    }
  }

  clearAllData() {
    this.dataList = [];
  }
}
