<div class="w-full flex gap-4">
  <div *ngFor="let latestData of sensorData?.wbgt" class="flex-1">
    <app-site-detail-sensor [wbgtData]="latestData"> </app-site-detail-sensor>
  </div>
  <div *ngFor="let latestData of sensorData?.wind" class="flex-1">
    <app-site-detail-sensor [windData]="latestData"> </app-site-detail-sensor>
  </div>
  <div *ngIf="sensorData?.noise" class="flex-1">
    <app-site-detail-sensor [noiseData]="sensorData!.noise!">
    </app-site-detail-sensor>
  </div>
  <div *ngIf="sensorData?.ph" class="flex-1">
    <app-site-detail-sensor [phData]="sensorData!.ph!">
    </app-site-detail-sensor>
  </div>
</div>
