import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';

import { AppConst } from '../shared/constants/app-const';
import { UrlConst } from '../shared/constants/url-const';
import { AccountService } from '../shared/services/account.service';
import { SiteService } from '../shared/services/site.service';

@Injectable({
  providedIn: 'root',
})
export class AutoSignInGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private siteService: SiteService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const uuid = route.paramMap.get('uuid');

    if (uuid) {
      return this.autoSignIn(uuid);
    } else {
      return this.cantActivate();
    }
  }

  private async autoSignIn(uuid: string) {
    try {
      await this.accountService.signIn({
        user: AppConst.SITE_DUMMY_USER,
        password: AppConst.SITE_PASSWORD,
      });
      const site = await this.siteService.getFromUuid(uuid);
      await this.accountService.signOut();
      await this.accountService.signIn({
        user: site!.id + 'p',
        password: AppConst.OUTER_PASSWORD,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  private cantActivate(): boolean {
    this.router.navigate([UrlConst.PATH_SIGN_IN]);
    return false;
  }
}
