import { UrlConst } from 'src/app/shared/constants/url-const';
import { AccessLogService } from 'src/app/shared/services/access-log.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-site-config-create-page',
  templateUrl: './site-config-create-page.component.html',
  styleUrls: ['./site-config-create-page.component.scss'],
})
export class SiteConfigCreatePageComponent implements OnInit {
  id: string | null = '';

  constructor(
    private route: ActivatedRoute,
    private logService: AccessLogService
  ) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      await this.logService.addLog(
        UrlConst.SLASH +
          UrlConst.PATH_SITE_CONFIG_CREATE +
          UrlConst.SLASH +
          this.id
      );
    } else {
      await this.logService.addLog(
        UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_CREATE
      );
    }
  }
}
