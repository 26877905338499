import { inject, Injectable } from '@angular/core';

import { SiteDetailChartsService } from '../pages/components/site-detail-page/components/site-detail-charts/site-detail-charts.service';
import { SensorHistory } from '../shared/interfaces/sensor-history';
import { SensorService } from '../shared/services/sensor.service';
import { UtilFunctions } from '../shared/utils/util-functions';
import { LineChartResultSeriesDto } from './charts.interface';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  siteDetailChartService = inject(SiteDetailChartsService);
  sensorService = inject(SensorService);

  constructor() {}

  // グラフ本体の横幅を計算する
  // PCサイズの場合、メニューバー(200px)、コンテンツサイズが90%なので、0.9をかける(realWidth)
  // 上記をセンサーの個数で割るだけなら楽なんだけど、そこからcardのpadding(左右16pxづつ)とflexのgap(16px)を引く
  // 最後に4px加えてるのはなぜか足りないから実測で付け足した
  getChartWidthFromWindowWidth(width: number) {
    const number = this.siteDetailChartService.numberOfSensors;

    if (width > 960) {
      const realWidth = (width - 200) * 0.9
      const paddingWidth = 32 * number;
      const gapWidth = 16 * (number - 1);

      return ((realWidth - paddingWidth - gapWidth) / number) - 4
    } else {
      const realWidth = width;
      const paddingWidth = 32 * number;
      const gapWidth = 16 * (number - 1);

      // return ((realWidth - paddingWidth - gapWidth) / number) + 4
      return (realWidth - paddingWidth - gapWidth) / number
    }
  }

  isSummer() {
    return UtilFunctions.isSummer();
  }

  getDaytimeSensorHistory(sensorId: string) {
    return this.sensorService.getHistoryDaytime(sensorId);
  }

  getDaySensorHistory(sensorId: string) {
    return this.sensorService.getHistory24Hours(sensorId);
  }

  async getWeekSensorHistory(sensorId: string) {
    const sensor = await this.sensorService.get(sensorId);
    const histories = await this.sensorService.getHistory7Days(sensorId);

    if (histories === null) {
      return null;
    } else if (sensor?.categoryName === 'WBGT' || sensor?.categoryName === 'NOISE' || sensor?.categoryName === 'PH') {
      const latestHistory = histories.pop();
      const newHistories = this.selectHistoriesByWeek(histories);
      newHistories.push(latestHistory!);

      return newHistories;
    } else if (sensor?.categoryName === 'WIND') {
      return histories;
    }

    return null;
  }

  selectHistoriesByWeek(histories: SensorHistory[]) {
    const result: SensorHistory[] = [];

    for (let history of histories) {
      if (UtilFunctions.isValidDateByHours(history.date, 11)) {
        result.push(history);
      }
      if (UtilFunctions.isValidDateByHours(history.date, 15)) {
        result.push(history);
      }
    }

    return result;
  }

  async getMonthSensorHistory(sensorId: string) {
    const sensor = await this.sensorService.get(sensorId);
    const histories = await this.sensorService.getHistory1Months(sensorId);

    if (histories === null) {
      return null;
    } else if (sensor?.categoryName === 'WBGT' || sensor?.categoryName === 'NOISE' || sensor?.categoryName === 'PH') {
      const latestHistory = histories.pop();
      const newHistories = this.selectHistoriesByMonth(histories);
      newHistories.push(latestHistory!);

      return newHistories;
    } else if (sensor?.categoryName === 'WIND') {
      return histories;
    }

    return null;
  }

  selectHistoriesByMonth(histories: SensorHistory[]) {
    const result: SensorHistory[] = [];

    for (let history of histories) {
      if (UtilFunctions.isValidDateByHours(history.date, 11)) {
        result.push(history);
      }
      // if (UtilFunctions.isValidDateByHours(history.date, 15)) {
      //   result.push(history);
      // }
    }

    return result;
  }

  createWbgtChartDataSets(chartRange: string, histories: SensorHistory[]) {
    if (histories === null) return;

    let wbgtDataList: LineChartResultSeriesDto[] = [];
    let temperatureDataList: LineChartResultSeriesDto[] = [];
    let humidityDataList: LineChartResultSeriesDto[] = [];

    for (let history of histories) {
      if (history === undefined) continue;

      let name = '';
      if (chartRange === 'daytime') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'day') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'week') {
        name = UtilFunctions.formatDateForCharts(history.date, 'MM/dd HH:mm');
      } else if (chartRange === 'month') {
        name = UtilFunctions.formatDateForCharts(history.date, 'MM/dd HH:mm');
      } else {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      }

      wbgtDataList.push({
        name: name,
        value: history.wbgt ? history.wbgt : 0,
      });
      temperatureDataList.push({
        name: name,
        value: history.temperature ? history.temperature : 0,
      });
      humidityDataList.push({
        name: name,
        value: history.humidity ? history.humidity : 0,
      });
    }

    if (wbgtDataList.length === 0) {
      return {
        wbgt: [],
        temperature: [],
        humidity: [],
      };
    }

    return {
      wbgt: wbgtDataList,
      temperature: temperatureDataList,
      humidity: humidityDataList,
    };
  }

  createWindChartDataSets(chartRange: string, histories: SensorHistory[]) {
    if (histories === null) return;

    const speedDataList = [];

    for (let history of histories) {
      if (history === undefined) continue;

      let name = '';
      if (chartRange === 'daytime') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'day') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'week') {
        name = UtilFunctions.formatDateForCharts(history.date, 'dd HH:mm');
      } else if (chartRange === 'month') {
        name = UtilFunctions.formatDateForCharts(history.date, 'MM/dd HH:mm');
      } else {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      }

      speedDataList.push({ name: name, value: history.windSpeed ? history.windSpeed : 0 });
    }

    if (speedDataList.length === 0) {
      speedDataList.push({ name: '', value: 0 });
    }

    return {
      speed: speedDataList,
    };
  }

  createNoiseChartDataSets(chartRange: string, histories: SensorHistory[]) {
    if (histories === null) return;

    const dataList = [];

    for (let history of histories) {
      if (history === undefined) continue;

      let name = '';
      if (chartRange === 'daytime') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'day') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'week') {
        name = UtilFunctions.formatDateForCharts(history.date, 'dd HH:mm');
      } else if (chartRange === 'month') {
        name = UtilFunctions.formatDateForCharts(history.date, 'MM/dd HH:mm');
      } else {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      }

      dataList.push({ name: name, value: history.noiseL5 ? history.noiseL5 : 0 });
    }

    if (dataList.length === 0) {
      dataList.push({ name: '', value: 0 });
    }

    return {
      noise: dataList,
    };
  }

  createVibrationChartDataSets(chartRange: string, histories: SensorHistory[]) {
    if (histories === null) return;

    const dataList = [];

    for (let history of histories) {
      if (history === undefined) continue;

      let name = '';
      if (chartRange === 'daytime') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'day') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'week') {
        name = UtilFunctions.formatDateForCharts(history.date, 'dd HH:mm');
      } else if (chartRange === 'month') {
        name = UtilFunctions.formatDateForCharts(history.date, 'MM/dd HH:mm');
      } else {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      }

      dataList.push({ name: name, value: history.vibrationL10 ? history.vibrationL10 : 0 });
    }

    if (dataList.length === 0) {
      dataList.push({ name: '', value: 0 });
    }

    return {
      vibration: dataList,
    };
  }

  createPhChartDataSets(chartRange: string, histories: SensorHistory[]) {
    if (histories === null) return;

    const dataList = [];

    for (let history of histories) {
      if (history === undefined) continue;

      let name = '';
      if (chartRange === 'daytime') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'day') {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      } else if (chartRange === 'week') {
        name = UtilFunctions.formatDateForCharts(history.date, 'dd HH:mm');
      } else if (chartRange === 'month') {
        name = UtilFunctions.formatDateForCharts(history.date, 'MM/dd HH:mm');
      } else {
        name = UtilFunctions.formatDateForCharts(history.date, 'HH:mm');
      }

      dataList.push({ name: name, value: history.ph! });
    }

    if (dataList.length === 0) {
      dataList.push({ name: '', value: 0 });
    }

    return {
      ph: dataList,
    };
  }
}
