<p class="page-title">現場一覧</p>
<div class="usage-guide">
  <ng-container *ngIf="isWinter; else notWinter">
    <span>
      温度凡例　要注意（<span class="status-temperature-signal-10"> 1 </span
      >-5℃以下<span class="status-temperature-signal-9 ml-4"> 1 </span
      >0℃以下<span class="status-temperature-signal-8 ml-4"> 1 </span
      >5℃以下）、その他(<span class="status-temperature-signal-7 ml-4"> 1 </span
      >5℃以上<span class="status-temperature-signal-6 ml-4"> 1 </span
      >10℃以上<span class="status-temperature-signal-5 ml-4"> 1 </span
      >15℃以上<span class="status-temperature-signal-4 ml-4"> 1 </span
      >20℃以上<span class="status-temperature-signal-3 ml-4"> 1 </span
      >25℃以上<span class="status-temperature-signal-2 ml-4"> 1 </span
      >30℃以上<span class="status-temperature-signal-1 ml-4"> 1 </span
      >35℃以上)、<span class="status-temperature-signal-99"> 1 </span
      >停止：信号なし又はセンサー未設定</span
    >
  </ng-container>
  <ng-template #notWinter>
    <span>
      WBGT凡例　
      <span class="status-signal-20"> 1 </span>危険：33℃以上
      <span class="status-signal-11 ml-4"> 1 </span>厳重警戒：30℃から33℃
      <span class="status-signal-10 ml-4"> 1 </span>警戒：28℃から30℃
      <span class="status-signal-3 ml-4"> 1 </span>注意：26℃から28℃
      <span class="status-signal-2 ml-4"> 1 </span>ほぼ安全：25℃から26℃
      <span class="status-signal-1 ml-4"> 1 </span>ほぼ安全：25℃未満
      <span class="status-signal-99 ml-4"> 1 </span
      >停止：信号なし又はセンサー未設定
    </span>
  </ng-template>
</div>
<ng-container *ngIf="windEnable">
  <div class="usage-guide">
    <span>
      風速凡例　
      <span class="status-wind-signal-20"> 1 </span>警報：10m以上
      <span class="status-wind-signal-10 ml-4"> 1 </span>注意：8m以上10m未満
      <span class="status-wind-signal-3 ml-4"> 1 </span>注意：5m以上8m未満
      <span class="status-wind-signal-2 ml-4"> 1 </span>ほぼ安全：2m以上5m未満
      <span class="status-wind-signal-1 ml-4"> 1 </span>ほぼ安全：2m未満
      <span class="status-wind-signal-99 ml-4"> 1 </span>停止：信号なし
    </span>
  </div>
</ng-container>
<div class="table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- 現場名 Column -->
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="site-list-header-cell"
      >
        現場名
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="toDetail(element.id)"
        [class.site-list-clickable]="clickable"
      >
        {{ element.name }}
      </td>
    </ng-container>

    <!-- 管理部署名 Column -->
    <ng-container matColumnDef="managementDepartmentName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="site-list-header-cell"
      >
        管理部署名
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.managementDepartmentName }}
      </td>
    </ng-container>

    <!-- 現場ステータス Column -->
    <ng-container matColumnDef="siteStatus">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="siteStatusNo"
        class="site-list-header-cell"
      >
        {{ isWinter ? "温度状況" : "WBGT状況" }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span
          class="{{
            isWinter ? 'status-temperature-signal-' : 'status-signal-'
          }}{{
            isWinter ? element.siteTemperatureStatusNo : element.siteStatusNo
          }}"
        >
          {{
            isWinter ? element.siteTemperatureStatusNo : element.siteStatusNo
          }}
        </span>
        {{ isWinter ? element.siteTemperatureStatus : element.siteStatus }}
      </td>
    </ng-container>

    <!-- 現場WBGT Column-->
    <ng-container matColumnDef="siteWbgt">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="site-list-header-cell"
      >
        {{ isWinter ? "温度" : "WBGT" }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          isWinter
            ? element.siteTemperature
              ? (element.siteTemperature | number : ".1")
              : "----"
            : element.siteWbgt
            ? (element.siteWbgt | number : ".1")
            : "----"
        }}
        ℃
      </td>
    </ng-container>

    <!-- 風速ステータス Column -->
    <ng-container matColumnDef="windStatus">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="siteWindStatusNo"
        class="site-list-header-cell"
      >
        風速状況
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="status-wind-signal-{{ element.siteWindStatusNo }}">
          {{ element.siteWindStatusNo }}
        </span>
        {{ element.siteWindStatus }}
      </td>
    </ng-container>

    <!-- 風速 Column -->
    <ng-container matColumnDef="windSpeed" *ngIf="windEnable">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="siteWindSpeed"
        class="site-list-header-cell"
      >
        風速
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element.siteWindSpeed !== undefined
            ? (element.siteWindSpeed | number : ".1")
            : "----"
        }}
        m/s
      </td>
    </ng-container>

    <!-- 風向 Column -->
    <ng-container matColumnDef="windDirectionName" *ngIf="windEnable">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="siteWindDirectionName"
        class="site-list-header-cell"
      >
        風向
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.siteWindDirectionName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="nameFilter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label for="name">現場名</mat-label>
          <input
            id="name"
            matInput
            placeholder="現場名"
            #input
            [(ngModel)]="name"
            (keyup)="applyFilter($event)"
          />
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container matColumnDef="managementDepartmentNameFilter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label for="managementDepartmentName">管理部署名</mat-label>
          <mat-select
            id="managementDepartmentId"
            [(ngModel)]="managementDepartmentId"
            (selectionChange)="applyFilter($event)"
          >
            <mat-option></mat-option>
            <mat-option
              *ngFor="let d of managementDepartmentList | async"
              [value]="d.id"
            >
              {{ d.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container matColumnDef="siteStatusFilter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label for="siteStatusNo">現場ステータス</mat-label>
          <ng-container *ngIf="isWinter; else notWinterStatusSelect">
            <mat-select
              id="siteStatusNo"
              [(ngModel)]="siteTemperatureStatusNo"
              (selectionChange)="applyFilter($event)"
            >
              <mat-option></mat-option>
              <mat-option
                *ngFor="let status of temperatureStatusList"
                [value]="status.no"
              >
                {{ status.label }}
              </mat-option>
            </mat-select>
          </ng-container>
          <ng-template #notWinterStatusSelect>
            <mat-select
              id="siteStatusNo"
              [(ngModel)]="siteStatusNo"
              (selectionChange)="applyFilter($event)"
            >
              <mat-option></mat-option>
              <mat-option *ngFor="let status of statusList" [value]="status.no">
                {{ status.label }}
              </mat-option>
            </mat-select>
          </ng-template>
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container matColumnDef="siteWbgtFilter">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *ngIf="isWinter; else notWinterTemperature">
          <mat-form-field appearance="standard">
            <mat-label for="siteTemperature">温度</mat-label>
            <input
              id="siteTemperature"
              matInput
              placeholder="指定数値以下出力"
              [(ngModel)]="siteTemperature"
              (keyup)="applyFilter($event)"
            />
          </mat-form-field>
        </ng-container>
        <ng-template #notWinterTemperature>
          <mat-form-field appearance="standard">
            <mat-label for="siteWbgt">WBGT</mat-label>
            <input
              id="siteWbgt"
              matInput
              placeholder="指定数値以上出力"
              [(ngModel)]="siteWbgt"
              (keyup)="applyFilter($event)"
            />
          </mat-form-field>
        </ng-template>
      </th>
    </ng-container>
    <ng-container matColumnDef="windStatusFilter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label for="windStatusNo">風速ステータス</mat-label>
          <mat-select
            id="windStatusNo"
            [(ngModel)]="windStatusNo"
            (selectionChange)="applyFilter($event)"
          >
            <mat-option></mat-option>
            <mat-option
              *ngFor="let status of windStatusList"
              [value]="status.no"
            >
              {{ status.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container matColumnDef="windSpeedFilter" *ngIf="windEnable">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label for="windSpeed">風速</mat-label>
          <input
            id="windSpeed"
            matInput
            placeholder="指定数値以上出力"
            [(ngModel)]="windSpeed"
            (keyup)="applyFilter($event)"
          />
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container matColumnDef="windDirectionNameFilter" *ngIf="windEnable">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label for="windDirectionName">風向</mat-label>
          <input
            id="windDirectionName"
            matInput
            [(ngModel)]="windDirectionName"
            (keyup)="applyFilter($event)"
          />
        </mat-form-field>
      </th>
    </ng-container>
    <!-- <ng-container matColumnDef="siteWbgtFilter">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-header-row
      *matHeaderRowDef="
        [
          'nameFilter',
          'managementDepartmentNameFilter',
          'siteStatusFilter',
          'siteWbgtFilter',
          'windStatusFilter',
          'windSpeedFilter',
          'windDirectionNameFilter'
        ];
        sticky: true
      "
      class="filter-column"
    ></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="site-list-row"
    ></tr>
  </table>
</div>
