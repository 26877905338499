import {
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

import { Injectable } from '@angular/core';
import { collection, Firestore } from '@angular/fire/firestore';

import { AppConst } from '../constants/app-const';
import { SiteManagementDepartment } from '../interfaces/site-management-department';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class SiteManagementDepartmentService {
  managementDepartments: SiteManagementDepartment[] = [];

  constructor(
    private firestore: Firestore,
    private accountService: AccountService
  ) {
    this.getAll();
  }

  async getAll() {
    const snapshot = await getDocs(
      collection(this.firestore, 'management_departments')
    );

    let departments: SiteManagementDepartment[] = [];

    for (let doc of snapshot.docs) {
      const _data = doc.data();
      departments.push({
        id: _data['id'],
        name: _data['name'],
        organizationId: _data['organizationId'],
      });
    }

    this.managementDepartments = departments;
    return departments;
  }

  async getAllWithOrganization() {
    const organizationId =
      this.accountService.getOrganizationId() ??
      AppConst.DEFAULT_ORGANIZATION_ID;

    const q = query(
      collection(this.firestore, 'management_departments'),
      where('organizationId', '==', organizationId)
    );
    const snapshot = await getDocs(q);

    let departments: SiteManagementDepartment[] = [];

    for (let doc of snapshot.docs) {
      const _data = doc.data();
      departments.push({
        id: _data['id'],
        name: _data['name'],
        organizationId: _data['organizationId'],
      });
    }

    this.managementDepartments = departments;
    return departments;
  }

  async get(id: string): Promise<SiteManagementDepartment | null> {
    const docRef = doc(this.firestore, 'management_departments', id);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
      const data = snapshot.data();
      return {
        id: data['id'],
        name: data['name'],
        organizationId: data['organizationId'],
      };
    } else {
      return null;
    }
  }
}
