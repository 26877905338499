<mat-card class="status-signal-20">
  <div *ngIf="data?.minPhAlertDate" class="site-detail-alert-container">
    <span class="site-detail-alert-time">{{ data?.minPhAlertDate }}</span>
    にpHステータスが
    <span class="site-detail-alert-status">酸性 強</span>
    になりました。
  </div>
  <div class="h-4"></div>
  <div *ngIf="data?.maxPhAlertDate" class="site-detail-alert-container">
    <span class="site-detail-alert-time">{{ data?.maxPhAlertDate }}</span>
    にpHステータスが
    <span class="site-detail-alert-status">アルカリ 強</span>
    になりました。
  </div>
  <!--
  <div class="mt-8 text-center" *ngIf="!isOuter">
    <div>
      <a
        class="underline-offset-2 underline text-blue-900"
        href="/assets/pdf/WIND2022.pdf"
        target="_blank"
      >
        ◆安全資料のリンク
      </a>
    </div>
  </div>
-->
</mat-card>
