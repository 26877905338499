<section *ngIf="siteFormService.apiLoaded">
  <p class="page-title">現場情報登録 / 変更</p>
  <mat-card class="w-1/3 mx-5">
    <mat-card-content>
      <div>
        <form
          class="w-full flex flex-col"
          [formGroup]="form"
          (keydown.enter)="$event.preventDefault()"
          autocomplete="off"
          *ngIf="departments$ | async"
        >
          <mat-form-field>
            <mat-label>現場ID</mat-label>
            <input
              matInput
              formControlName="id"
              required
              *ngIf="!id"
              placeholder="0123456789"
              maxlength="10"
            />
            <mat-hint align="start" *ngIf="!id"
              ><strong>半角英数字9〜10桁</strong>
            </mat-hint>
            <input matInput disabled value="{{ id }}" *ngIf="id" />
            <!-- <mat-error *ngIf="loginForm.controls.user.invalid">ユーザー名を空白にはできません</mat-error> -->
          </mat-form-field>

          <mat-form-field>
            <mat-label>現場名</mat-label>
            <input matInput formControlName="name" required />
            <!-- <mat-error *ngIf="loginForm.controls.user.invalid">ユーザー名を空白にはできません</mat-error> -->
          </mat-form-field>

          <mat-form-field>
            <mat-label>住所</mat-label>
            <input
              matInput
              formControlName="address1"
              placeholder="東京都港区芝"
              required
            />
            <mat-hint align="start"
              ><strong>丁目、番地を除いた住所</strong>
            </mat-hint>
            <!-- <mat-error *ngIf="loginForm.controls.user.invalid">ユーザー名を空白にはできません</mat-error> -->
          </mat-form-field>

          <mat-form-field>
            <mat-label>住所（丁目、番地等）</mat-label>
            <input
              matInput
              formControlName="address2"
              placeholder="1丁目1番地"
            />
            <!-- <mat-error *ngIf="loginForm.controls.user.invalid">ユーザー名を空白にはできません</mat-error> -->
          </mat-form-field>

          <mat-form-field>
            <mat-label>管理部署</mat-label>
            <mat-select formControlName="departmentId" required>
              <mat-option *ngFor="let d of departments" [value]="d.id">
                {{ d.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </mat-card-content>

    <mat-card-actions class="w-full">
      <div class="flex">
        <button
          type="button"
          mat-raised-button
          color="accent"
          (click)="onCancel()"
        >
          キャンセル
        </button>
        <button
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="form.invalid"
          (click)="onClick(false)"
        >
          登録して一覧に戻る
        </button>
        <button
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="form.invalid"
          (click)="onClick(true)"
          *ngIf="!site"
        >
          登録してセンサー設定画面に進む
        </button>
      </div>
    </mat-card-actions>
    <mat-card-footer>
      <mat-error *ngIf="0 < errors.length">
        <ul>
          <li *ngFor="let error of errors">{{ error }}</li>
        </ul>
      </mat-error>
    </mat-card-footer>
  </mat-card>
</section>
