import { UrlConst } from 'src/app/shared/constants/url-const';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { Component, OnInit, inject } from '@angular/core';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-site-map-page',
  templateUrl: './site-map-page.component.html',
  styleUrls: ['./site-map-page.component.scss'],
})
export class SiteMapPageComponent implements OnInit {
  isWbgtMarker = false;
  isTemperatureMarker = false;
  isWindMarker = false;
  isSummer = false;
  isWinter = false;

  private accountService = inject(AccountService);

  // クリックによる現場遷移を許可するかどうか
  clickable = !this.accountService.isMonitorGroup();

  constructor(private logService: AccessLogService) {}

  async ngOnInit() {
    await this.logService.addLog(UrlConst.SLASH + UrlConst.PATH_SITE_MAP);
    this.isSummer = UtilFunctions.isSummer();
    this.isWinter = this.accountService.isWestUser()
      ? UtilFunctions.isWinter()
      : false;
    if (this.isWinter) {
      this.isWbgtMarker = false;
      this.isTemperatureMarker = true;
      this.isWindMarker = false;
    } else {
      this.isWbgtMarker = true;
      this.isTemperatureMarker = false;
      this.isWindMarker = false;
    }
  }

  onToggle() {
    if (this.isWinter) {
      this.isTemperatureMarker = !this.isTemperatureMarker;
    } else {
      this.isWbgtMarker = !this.isWbgtMarker;
    }
    this.isWindMarker = !this.isWindMarker;
  }
}
