import { QRCodeModule } from 'angularx-qrcode';
import { NgChartsModule } from 'ng2-charts';

import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material/material.module';
import { ErrorMessagingComponent } from './components/error-messaging/error-messaging.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LineChartsComponent } from './components/line-charts/line-charts.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MapComponent } from './components/map/map.component';
import { QrCodeDialogComponent } from './components/qr-code-dialog/qr-code-dialog.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SiteConfigFormComponent } from './components/site-config-form/site-config-form.component';
import { TodayWnDialogComponent } from './components/today-wn-dialog/today-wn-dialog.component';
import {
    TomorrowWnDialogComponent
} from './components/tomorrow-wn-dialog/tomorrow-wn-dialog.component';
import {
    UsageGuideDialogComponent
} from './components/usage-guide-dialog/usage-guide-dialog.component';
import { WindMapComponent } from './components/wind-map/wind-map.component';
import { WnDialogComponent } from './components/wn-dialog/wn-dialog.component';
import { YesNoDialogComponent } from './components/yes-no-dialog/yes-no-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    LoadingComponent,
    ErrorMessagingComponent,
    YesNoDialogComponent,
    MapComponent,
    SiteConfigFormComponent,
    LineChartsComponent,
    WindMapComponent,
    QrCodeDialogComponent,
    WnDialogComponent,
    TodayWnDialogComponent,
    TomorrowWnDialogComponent,
    UsageGuideDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    FormsModule,
    NgChartsModule,
    QRCodeModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    LoadingComponent,
    ErrorMessagingComponent,
    YesNoDialogComponent,
    MapComponent,
    LineChartsComponent,
    SiteConfigFormComponent,
    WindMapComponent,
    QrCodeDialogComponent,
  ],
})
export class SharedModule {}
