// 風速センサーの平均値を出すためだけのクラス
// 20個の値を保持して、平均値を出す

export class WindSensorSpeed {
  private MAX_SPEED_LIST_LENGTH = 20;
  private speedList: number[] = [];

  // 平均値を返す
  average() {
    const len = this.speedList.length;
    let totalSpeed: number = 0;
    for (const speed of this.speedList) {
      totalSpeed += speed;
    }

    // 小数点第一位で四捨五入
    return Math.round((totalSpeed / this.speedList.length) * 10) / 10;
  }

  // 値を追加する
  // 20個以上の値がある場合は、古い値を削除する
  add(speed: number) {
    this.speedList.push(speed);
    if (this.speedList.length > this.MAX_SPEED_LIST_LENGTH) {
      const shifted = this.speedList.shift();
    }
  }

  // 値をリセットする
  reset() {
    this.speedList.length = 0;
  }
}
