<div class="p-4 w-full flex justify-center">
  <svg
    id="svg"
    [attr.width]="svgWidth"
    [attr.height]="svgHeight"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style>
      /* 背景画像を設定する */
      svg {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    </style>
    <g *ngFor="let drawingSensor of drawingSensors">
      <rect
        [attr.x]="drawingSensor.drawing!.x"
        [attr.y]="drawingSensor.drawing!.y"
        [attr.width]="drawingSensor.drawing!.width"
        [attr.height]="drawingSensor.drawing!.height"
        [attr.fill]="drawingSensor.drawing!.bgColor"
        [class.hide]="!isImageLoaded"
        [class.show]="isImageLoaded"
      ></rect>
      <text
        [attr.x]="drawingSensor.drawing!.x + drawingSensor.drawing!.width / 2"
        [attr.y]="drawingSensor.drawing!.y + drawingSensor.drawing!.height / 3"
        text-anchor="middle"
        dominant-baseline="central"
        [attr.fill]="drawingSensor.drawing!.fgColor"
        [class.hide]="!isImageLoaded"
        [class.show]="isImageLoaded"
      >
        {{ drawingSensor.positionName }}
      </text>
      <text
        [attr.x]="drawingSensor.drawing!.x + drawingSensor.drawing!.width / 2"
        [attr.y]="
          drawingSensor.drawing!.y + (drawingSensor.drawing!.height * 2) / 3
        "
        text-anchor="middle"
        dominant-baseline="central"
        [attr.fill]="drawingSensor.drawing!.fgColor"
        [class.hide]="!isImageLoaded"
        [class.show]="isImageLoaded"
      >
        {{
          isWinter
            ? drawingSensor.temperature
              ? drawingSensor.temperature
              : "----"
            : drawingSensor.wbgt
              ? drawingSensor.wbgt
              : "----"
        }}℃
      </text>
    </g>
  </svg>
</div>
