<div class="text-center">
  <form
    class="form"
    [formGroup]="form"
    (keydown.enter)="$event.preventDefault()"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Ph基準値(以上)</mat-label>
      <input
        matInput
        id="minPh"
        name="minPh"
        formControlName="minPh"
        placeholder="6"
        required
      />

      <mat-error *ngIf="minPh?.errors?.['required']"> 必須です </mat-error>
      <mat-error *ngIf="minPh?.errors?.['min'] || minPh?.errors?.['max']">
        1-14です
      </mat-error>
      <mat-error
        *ngIf="minPh?.value && maxPh?.value && maxPh?.value! < minPh?.value!"
      >
        範囲が不正です
      </mat-error>
    </mat-form-field>
    以上
    <mat-form-field>
      <mat-label>Ph基準値(未満)</mat-label>

      <input
        matInput
        id="maxPh"
        name="maxPh"
        formControlName="maxPh"
        placeholder="7"
        required
      />

      <mat-error
        *ngIf="minPh?.errors?.['required'] || maxPh?.errors?.['required']"
      >
        必須です
      </mat-error>

      <mat-error *ngIf="maxPh?.errors?.['min'] || maxPh?.errors?.['max']">
        1-14です
      </mat-error>
    </mat-form-field>
    未満
  </form>
</div>

<div class="mt-2 text-center">
  <button
    mat-raised-button
    color="primary"
    [disabled]="form.invalid"
    (click)="onSubmit()"
  >
    保存
  </button>
</div>
