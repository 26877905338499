<div *ngIf="isWbgtSensor">
  <mat-card class="site-detail-data-card">
    <mat-card class="site-detail-data-card-title">
      {{ wbgtData ? wbgtData.positionName : "名無し" }}</mat-card
    >
    <mat-card
      class="site-detail-data-card-status {{
        isWinter ? 'status-temperature-signal-' : 'status-signal-'
      }}{{
        wbgtData
          ? isWinter
            ? wbgtData.temperatureStatusNo
            : wbgtData.statusNo
          : 99
      }}"
      >{{
        wbgtData
          ? isWinter
            ? wbgtData.temperatureStatus
            : wbgtData.status
          : "停止"
      }}
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">更新日時:</span>
      <span class="site-detail-data-card-item-date">
        <span class="site-detail-data-card-item-date-inner">
          {{ wbgtData ? wbgtData.date : "-" }}
        </span>
      </span>
    </mat-card>
    <mat-card class="site-detail-data-card-item" *ngIf="!isWinter">
      <span class="site-detail-data-card-item-title">WBGT:</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(156, 39, 176, 1)"
        >
          {{ wbgtData ? (wbgtData.wbgt | number : ".1") : "-" }}
          ℃
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">温度:</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(255, 152, 0, 1)"
        >
          {{ wbgtData ? (wbgtData.temperature | number : ".1") : "-" }}
          ℃
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">湿度:</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(3, 169, 244, 1)"
        >
          {{ wbgtData ? (wbgtData.humidity | number : ".1") : "-" }}
          ％
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">電池残量:</span>
      <div class="site-detail-data-card-item-value">
        <span
          *ngIf="wbgtData?.maker === 'fujikura'"
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(0, 0, 0, 1)"
        >
          {{
            wbgtData
              ? wbgtData.battery
                ? (wbgtData.battery | number : ".1")
                : "-"
              : "-"
          }}
          ％
        </span>
        <span
          *ngIf="wbgtData?.maker === 'tsuruga'"
          class="site-detail-data-card-item-battery-inner"
          style="color: rgba(0, 0, 0, 1)"
          >{{ wbgtData ? wbgtData.batteryComment : "-" }}</span
        >
      </div>
    </mat-card>
  </mat-card>
</div>

<div *ngIf="isWindSensor">
  <mat-card class="site-detail-data-card">
    <mat-card class="site-detail-data-card-title">
      {{ windData ? windData.positionName : "----" }}
    </mat-card>
    <mat-card
      class="site-detail-data-card-status status-wind-signal-{{
        windData ? windData.statusNo : 99
      }}"
    >
      {{ windData ? windData.status : "停止" }}
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">更新日時:</span>
      <div class="site-detail-data-card-item-date">
        <span class="site-detail-data-card-item-date-inner">
          {{ windData?.date }}
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">風向:</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-wind-inner"
          style="color: rgba(156, 39, 176, 1)"
        >
          {{ windData?.directionName }}
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">風速:</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-wind-inner"
          style="color: rgba(255, 152, 0, 1)"
        >
          {{ windData?.speed | number : ".1" }} m/s
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title"></span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(3, 169, 244, 1)"
        >
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title"></span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(3, 169, 244, 1)"
        >
        </span>
      </div>
    </mat-card>
  </mat-card>
</div>

<div *ngIf="isNoiseSensor">
  <mat-card class="site-detail-data-card">
    <mat-card class="site-detail-data-card-title">
      {{ noiseData ? noiseData.positionName : "----" }}
    </mat-card>
    <mat-card
      class="site-detail-data-card-status status-signal-{{
        noiseData ? noiseData.statusNo : 99
      }}"
    >
      {{ noiseData ? noiseData.status : "停止" }}
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">更新日時:</span>
      <div class="site-detail-data-card-item-date">
        <span class="site-detail-data-card-item-date-inner">
          {{ noiseData?.date !== undefined ? noiseData?.date : "----" }}
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">騒音(Max):</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(156, 39, 176, 1)"
        >
          {{
            noiseData?.noiseMax !== undefined
              ? (noiseData?.noiseMax | number)
              : "----"
          }}
          dB
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">騒音(L5):</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(255, 152, 0, 1)"
        >
          {{
            noiseData?.noiseL5 !== undefined
              ? (noiseData?.noiseL5 | number)
              : "----"
          }}
          dB
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">振動(Max):</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(3, 169, 244, 1)"
        >
          {{
            noiseData?.vibrationMax !== undefined
              ? (noiseData?.vibrationMax | number)
              : "----"
          }}
          dB
        </span>
      </div>
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">振動(L10):</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(3, 169, 244, 1)"
        >
          {{
            noiseData?.vibrationL10 !== undefined
              ? (noiseData?.vibrationL10 | number)
              : "----"
          }}
          dB
        </span>
      </div>
    </mat-card>
  </mat-card>
</div>

<div *ngIf="isPhSensor">
  <mat-card class="site-detail-data-card">
    <mat-card class="site-detail-data-card-title">
      {{ phData ? phData.positionName : "----" }}
    </mat-card>
    <mat-card
      class="site-detail-data-card-status status-signal-{{
        phData ? phData.statusNo : 99
      }}"
    >
      {{ phData ? phData.status : "停止" }}
    </mat-card>
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">更新日時:</span>
      <div class="site-detail-data-card-item-date">
        <span class="site-detail-data-card-item-date-inner">
          {{ phData?.date !== undefined ? phData?.date : "----" }}
        </span>
      </div>
    </mat-card>

    <!--
    <mat-card class="site-detail-data-card-item">
      <span class="site-detail-data-card-item-title">pH:</span>
      <div class="site-detail-data-card-item-value">
        <span
          class="site-detail-data-card-item-value-inner"
          style="color: rgba(156, 39, 176, 1)"
        >
          {{ phData?.ph !== undefined ? (phData?.ph | number) : "----" }}
        </span>
      </div>
    </mat-card>
    -->

    <mat-card class="site-detail-data-card-item">
      <div class="ph-sensor-img">
        <img
          src="assets/images/ph/ph_base_v3.png"
          alt=""
          class="ph-sensor-base-img"
        />
        <img
          *ngIf="phData?.ph"
          src="assets/images/ph/ph_arrow_v3.png"
          alt=""
          class="ph-sensor-needle-img"
          [style.transform]="'rotate(' + needleAngle + 'deg)'"
        />
      </div>
    </mat-card>
  </mat-card>
</div>
