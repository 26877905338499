import { LatestSiteData } from 'src/app/shared/interfaces/latest-site-data';
import { Site } from 'src/app/shared/interfaces/site';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SiteDetailDrawingCompassViewerService } from './site-detail-drawing-compass-viewer.service';

@Component({
  selector: 'app-site-detail-drawing-compass-viewer',
  templateUrl: './site-detail-drawing-compass-viewer.component.html',
  styleUrls: ['./site-detail-drawing-compass-viewer.component.scss'],
})
export class SiteDetailDrawingCompassViewerComponent implements OnChanges {
  @Input() site: Site = {} as Site;
  @Input() svgHeight: number = 400;

  // コンパス文字盤角度
  boardAngle = 0;
  // コンパス針角度
  needleAngle = 0;
  // 風速センサー設置場所
  positionName = '';
  // 風速
  speed: number | undefined = undefined;
  // ラベル文字色
  fgColor = 'white';
  // ラベル背景色
  bgColor = 'black';

  constructor(private viewerService: SiteDetailDrawingCompassViewerService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // 現場のコンパス設定が完了していれば、現場のコンパス角度を設定する
    if (this.site.compassAngle !== undefined) {
      this.boardAngle = this.site.compassAngle;
      this.needleAngle = this.site.compassAngle;
    }

    // 現場センサー最新値取得
    const latest: LatestSiteData | null =
      await this.viewerService.getLatestSiteDataFromSiteId(this.site.id);
    // 取得できたら、コンパス針角度を設定する
    if (
      latest !== null &&
      latest.sensorData !== undefined &&
      latest.sensorData.wind !== undefined
    ) {
      const direction = latest.sensorData.wind[0].direction;
      const speed = latest.sensorData.wind[0].speed;
      const date = latest.sensorData.wind[0].date;
      this.positionName = latest.sensorData.wind[0].positionName;

      // 風向
      if (direction !== undefined) {
        // (360 / 16) * (direction + 1) + 180???
        this.needleAngle = (360 / 16) * (direction + 1) + this.boardAngle + 180;
      }

      // 風速センサー停止ステータスで一旦背景色を設定する
      const statusStopped = UtilFunctions.getWindStatusStopped();
      this.bgColor = statusStopped.backgroundColor;

      // 風速と日付に応じて背景色を設定する
      if (speed !== undefined) {
        this.speed = speed;

        if (date !== undefined) {
          const status = UtilFunctions.getWindStatusWithDate(speed, date);

          if (status !== null) {
            this.bgColor = status?.backgroundColor;
            if (status?.foregroundColor !== undefined) {
              this.fgColor = status?.foregroundColor;
            }
          }
        }
      }
    }
  }
}
