import { Subject, Subscription } from 'rxjs';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { Site } from 'src/app/shared/interfaces/site';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SiteDrawingService } from './site-drawing.service';

@Component({
  selector: 'app-site-drawing',
  templateUrl: './site-drawing.component.html',
  styleUrls: ['./site-drawing.component.scss'],
})
export class SiteDrawingComponent implements OnInit, OnDestroy {
  siteId: string = '';
  site: Site | null = null;
  subscriptions: Subscription = new Subscription();

  // 基準値設定完了メッセージ（3秒で消す）
  phRangeSavedMessage: string = '';
  noiseStandardSavedMessage: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private siteDrawingService: SiteDrawingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      this.route.paramMap.subscribe(async (params) => {
        const siteId = params.get('id');
        if (siteId !== null) {
          this.site = await this.siteDrawingService.getSiteWithOrganization(
            siteId
          );
          if (this.site === null) {
            this.onDrawingCanceled();
          }
        } else {
          console.log('siteId is null');
          this.onDrawingCanceled();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onDrawingCanceled() {
    console.log('onDrawingCanceled');
    this.router.navigateByUrl(UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG);
  }

  onDrawingSaved() {
    console.log('onDrawingSaved');
    this.router.navigateByUrl(UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG);
  }

  onPhRangeSaved() {
    this.phRangeSavedMessage = '保存しました';
    setTimeout(() => {
      this.phRangeSavedMessage = '';
    }, 3000);
  }

  onNoiseStandardSaved() {
    this.noiseStandardSavedMessage = '保存しました';
    setTimeout(() => {
      this.noiseStandardSavedMessage = '';
    }, 3000);
  }
}
