import { vi } from 'date-fns/locale';
import {
  collection,
  deleteField,
  doc,
  DocumentData,
  DocumentReference,
  endAt,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
  updateDoc,
  where,
} from 'firebase/firestore';
import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { collectionData, docData, Firestore } from '@angular/fire/firestore';

import { Sensor } from '../interfaces/sensor';
import { SensorHistory } from '../interfaces/sensor-history';
import { Site } from '../interfaces/site';
import { UtilFunctions } from '../utils/util-functions';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class SensorService {
  private accountService = inject(AccountService);

  constructor(private firestore: Firestore) {}

  async getAll(): Promise<Sensor[]> {
    const snapshot = await getDocs(collection(this.firestore, 'sensors'));
    let sensors: Sensor[] = [];

    if (snapshot.empty) return sensors;

    for (let doc of snapshot.docs) {
      const _data = doc.data();
      if (!('deletedAt' in _data)) {
        const _sensor: Sensor = this.fromDocumentData(_data);
        sensors.push(_sensor);
      }
    }

    return sensors;
  }

  async getAllWithOrganization(): Promise<Sensor[]> {
    const organizationId = this.accountService.getOrganizationId();
    const snapshot = await getDocs(collection(this.firestore, 'sensors'));
    let sensors: Sensor[] = [];

    if (snapshot.empty) return sensors;

    for (let doc of snapshot.docs) {
      const _data = doc.data();
      if (!('organizationId' in _data)) {
        continue;
      }
      if (_data['organizationId'] !== organizationId) {
        continue;
      }

      if (!('deletedAt' in _data)) {
        const _sensor: Sensor = this.fromDocumentData(_data);
        sensors.push(_sensor);
      }
    }

    return sensors;
  }

  async getAllBySiteIdWithOrganization(siteId?: string): Promise<Sensor[]> {
    if (!siteId) return this.getAllWithOrganization();

    const organizationId = this.accountService.getOrganizationId();
    const q = query(
      collection(this.firestore, 'sensors'),
      where('siteId', '==', siteId)
    );
    const snapshot = await getDocs(q);
    let sensors: Sensor[] = [];

    if (snapshot.empty) return sensors;

    for (let doc of snapshot.docs) {
      const _data = doc.data();
      if (!('organizationId' in _data)) {
        continue;
      }
      if (_data['organizationId'] !== organizationId) {
        continue;
      }

      if (!('deletedAt' in _data)) {
        const _sensor: Sensor = this.fromDocumentData(_data);
        sensors.push(_sensor);
      }
    }

    return sensors;
  }

  async getFromReference(ref: DocumentReference) {
    const snapshot = await getDoc(ref);
    const data = snapshot.data();

    if (data === undefined) return undefined;

    const sensor: Sensor = this.fromDocumentData(data);
    return sensor;
  }

  fromDocumentData(data: DocumentData): Sensor {
    const sensor: Sensor = { id: data['id'] };
    if ('siteId' in data) sensor.siteId = data['siteId'];
    if ('maker' in data) sensor.maker = data['maker'];
    if ('position' in data) sensor.position = data['position'];
    if ('positionName' in data) sensor.positionName = data['positionName'];
    if ('name' in data) sensor.name = data['name'];

    // センサーの最新値を取得
    if ('data' in data) sensor.data = data['data'];

    // 騒音振動センサーの基準値
    if ('standardNoise' in data) sensor.standardNoise = data['standardNoise'];
    if ('standardVibration' in data)
      sensor.standardVibration = data['standardVibration'];

    // Phセンサーの基準値範囲
    if ('minPh' in data) sensor.minPh = data['minPh'];
    if ('maxPh' in data) sensor.maxPh = data['maxPh'];

    if ('categoryId' in data) sensor.categoryId = data['categoryId'];
    if ('categoryName' in data) sensor.categoryName = data['categoryName'];
    if ('deletedAt' in data) sensor.deletedAt = data['deletedAt'];
    return sensor;
  }

  async getAllFromCategoryId(categoryId: string): Promise<Sensor[]> {
    const organizationId = this.accountService.getOrganizationId();

    const q = query(
      collection(this.firestore, 'sensors'),
      where('categoryId', '==', categoryId)
    );
    const snapshot = await getDocs(q);
    let sensors: Sensor[] = [];

    if (snapshot.empty) return sensors;

    for (let doc of snapshot.docs) {
      const _data = doc.data();

      if (!('organizationId' in _data)) {
        continue;
      }
      if (_data['organizationId'] !== organizationId) {
        continue;
      }
      if ('deletedAt' in _data) {
        continue;
      }

      const _sensor: Sensor = this.fromDocumentData(_data);
      sensors.push(_sensor);
    }

    return sensors;
  }

  async getFromSiteIdAndCategoryId(
    siteId: string,
    categoryId: string
  ): Promise<Sensor[]> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('siteId', '==', siteId),
      where('categoryId', '==', categoryId)
    );

    const snapshot = await getDocs(q);
    let sensors: Sensor[] = [];

    if (snapshot.empty) return sensors;

    for (let doc of snapshot.docs) {
      if (!('deletedAt' in doc.data())) {
        const _data = doc.data();
        const _sensor: Sensor = this.fromDocumentData(_data);

        sensors.push(_sensor);
      }
    }

    return sensors;
  }

  // 未設定センサー
  async getAllNoConfig(): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('categoryName', '!=', 'WIND')
    );
    const snapshot = await getDocs(q);
    let sensors: Sensor[] = [];

    for (let doc of snapshot.docs) {
      const _data = doc.data();
      if (
        !('deletedAt' in _data) &&
        !('siteId' in _data) &&
        !('positionName' in _data)
      ) {
        sensors.push(this.fromDocumentData(_data));
      }
    }

    return sensors;
  }

  async getAllNoConfigWithOrganization(
    organizationId: string
  ): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('organizationId', '==', organizationId),
      where('categoryName', '==', 'WBGT')
    );
    const snapshot = await getDocs(q);
    let sensors: Sensor[] = [];

    for (let doc of snapshot.docs) {
      const _data = doc.data();
      if (
        !('deletedAt' in _data) &&
        !('siteId' in _data) &&
        !('positionName' in _data)
      ) {
        sensors.push(this.fromDocumentData(_data));
      }
    }

    return sensors;
  }

  // 何も設定されてないセンサーと、設定中現場所属センサー
  async getAllWithSiteConfig(siteId: string): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('categoryName', '!=', 'WIND')
    );
    const snapshot = await getDocs(q);
    let sensors: Sensor[] = [];

    for (let doc of snapshot.docs) {
      const _data = doc.data();
      if (
        (!('deletedAt' in _data) &&
          !('siteId' in _data) &&
          !('positionName' in _data)) ||
        _data['siteId'] === siteId
      ) {
        sensors.push(this.fromDocumentData(_data));
      }
    }

    return sensors;
  }

  // 全風速センサー
  async getAllWind(): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('categoryName', '==', 'WIND')
    );
    const snapshot = await getDocs(q);

    const sensors: Sensor[] = [];

    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (!('deletedAt' in _data)) {
          const _sensor: Sensor = this.fromDocumentData(_data);
          sensors.push(_sensor);
        }
      }
    }
    return sensors;
  }

  async getAllWindWithOrganization(
    organizationId: string
  ): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('organizationId', '==', organizationId),
      where('categoryName', '==', 'WIND')
    );
    const snapshot = await getDocs(q);

    const sensors: Sensor[] = [];

    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (!('deletedAt' in _data)) {
          const _sensor: Sensor = this.fromDocumentData(_data);
          sensors.push(_sensor);
        }
      }
    }
    return sensors;
  }

  // 設定中現場の風速センサーと未所属な風速センサー
  async getAllWindWithSiteConfig(siteId: string): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('categoryName', '==', 'WIND')
    );
    const snapshot = await getDocs(q);

    const sensors: Sensor[] = [];

    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (
          (!('deletedAt' in _data) &&
            !('siteId' in _data) &&
            !('positionName' in _data)) ||
          _data['siteId'] === siteId
        ) {
          const _sensor: Sensor = this.fromDocumentData(_data);
          sensors.push(_sensor);
        }
      }
    }
    return sensors;
  }

  // 未所属な風速センサー
  async getAllWindNoConfig(): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('categoryName', '==', 'WIND')
    );
    const snapshot = await getDocs(q);

    const sensors: Sensor[] = [];

    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (
          !('deletedAt' in _data) &&
          !('siteId' in _data) &&
          !('positionName' in _data)
        ) {
          sensors.push(this.fromDocumentData(_data));
        }
      }
    }
    return sensors;
  }

  async getAllWindNoConfigWithOrganization(
    organizationId: string
  ): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('organizationId', '==', organizationId),
      where('categoryName', '==', 'WIND')
    );
    const snapshot = await getDocs(q);

    const sensors: Sensor[] = [];

    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (
          !('deletedAt' in _data) &&
          !('siteId' in _data) &&
          !('positionName' in _data)
        ) {
          sensors.push(this.fromDocumentData(_data));
        }
      }
    }
    return sensors;
  }

  async getAllNoiseNoConfigWithOrganization(
    organizationId: string
  ): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('organizationId', '==', organizationId),
      where('categoryName', '==', 'NOISE')
    );
    const snapshot = await getDocs(q);

    const sensors: Sensor[] = [];

    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (
          !('deletedAt' in _data) &&
          !('siteId' in _data) &&
          !('positionName' in _data)
        ) {
          sensors.push(this.fromDocumentData(_data));
        }
      }
    }
    return sensors;
  }

  async getAllPhNoConfigWithOrganization(
    organizationId: string
  ): Promise<Sensor[] | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('organizationId', '==', organizationId),
      where('categoryName', '==', 'PH')
    );
    const snapshot = await getDocs(q);

    const sensors: Sensor[] = [];

    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (
          !('deletedAt' in _data) &&
          !('siteId' in _data) &&
          !('positionName' in _data)
        ) {
          sensors.push(this.fromDocumentData(_data));
        }
      }
    }
    return sensors;
  }

  fetch(id: string): Observable<Sensor> {
    return docData(doc(this.firestore, 'sensors', id)).pipe(
      map((data) => this.fromDocumentData(data))
    );
  }

  async get(id: string): Promise<Sensor | null> {
    if (id === undefined) {
      return null;
    }
    let sensor: Sensor | null = null;

    const snapshot = await getDoc(doc(this.firestore, 'sensors', id));
    if (snapshot.exists()) {
      const _data = snapshot.data();
      sensor = this.fromDocumentData(_data);
    }

    return sensor;
  }

  async update(id: string, data: any) {
    try {
      await updateDoc(doc(this.firestore, 'sensors', id), data);
    } catch (error) {}
  }

  fetchFromSiteId(siteId: string): Observable<Sensor[]> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('siteId', '==', siteId)
    );
    return collectionData(q).pipe(
      map((sensorData) => {
        const sensors: Sensor[] = [];
        sensorData.map((data) => {
          if (!data.hasOwnProperty('deletedAt')) {
            sensors.push(this.fromDocumentData(data));
          }
        });
        return sensors;
      })
    );
  }

  async getFromSiteId(siteId: string): Promise<Sensor[]> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('siteId', '==', siteId)
    );
    const snapshot = await getDocs(q);
    const sensors: Sensor[] = [];

    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();
        if (!_data.hasOwnProperty('deletedAt')) {
          let sensor: Sensor = this.fromDocumentData(_data);

          sensors.push(sensor);
        }
      }
    }
    return sensors;
  }

  async getFromSiteIds(siteIds: string[]): Promise<Sensor[]> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('siteId', 'in', siteIds)
    );
    const snapshot = await getDocs(q);

    const sensors: Sensor[] = [];

    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (!_data.hasOwnProperty('deletedAt')) {
          let sensor: Sensor = this.fromDocumentData(_data);
          sensors.push(sensor);
        }
      }
    }
    return sensors;
  }

  async getTopFloorFromSiteId(siteId: string): Promise<Sensor | null> {
    return await this.getFromSiteIdAndPosition(siteId, 'top_floor');
  }

  async getBoardFromSiteId(siteId: string): Promise<Sensor | null> {
    return await this.getFromSiteIdAndPosition(siteId, 'board');
  }

  async getWindFromSiteId(siteId: string): Promise<Sensor | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('siteId', '==', siteId),
      where('categoryName', '==', 'WIND')
    );
    const snapshot = await getDocs(q);

    let sensor: Sensor | null = null;
    if (snapshot.docs.length > 0) {
      sensor = this.fromDocumentData(snapshot.docs[0].data());
    }
    return sensor;
  }

  async getFromSiteIdAndPosition(
    siteId: string,
    positionName: string
  ): Promise<Sensor | null> {
    const q = query(
      collection(this.firestore, 'sensors'),
      where('siteId', '==', siteId),
      where('positionName', '==', positionName)
    );
    const snapshot = await getDocs(q);

    let sensor: Sensor | null = null;
    if (snapshot.docs.length) {
      sensor = this.fromDocumentData(snapshot.docs[0].data());
    }
    return sensor;
  }

  async deleteSite(siteId: string) {
    const sensors = await this.getFromSiteId(siteId);
    if (sensors.length > 0) {
      for (let _sensor of sensors) {
        await updateDoc(doc(this.firestore, 'sensors', _sensor!.id), {
          position: deleteField(),
          siteId: deleteField(),
        });
      }
    }
  }

  async deleteSiteFromPosition(siteId: string, position: string) {
    const sensors = await this.getFromSiteId(siteId);
    if (sensors.length > 0) {
      for (let _sensor of sensors) {
        if (_sensor?.position === position) {
          await updateDoc(doc(this.firestore, 'sensors', _sensor?.id), {
            position: deleteField(),
            siteId: deleteField(),
          });
        }
      }
    }
  }

  async deleteSiteFromSensor(sensor: Sensor) {
    await updateDoc(doc(this.firestore, 'sensors', sensor.id), {
      position: deleteField(),
      positionName: deleteField(),
      siteId: deleteField(),
      standardNoise: deleteField(),
      standardVibration: deleteField(),
      minPh: deleteField(),
      maxPh: deleteField(),
    });
  }

  async getHistory(id: string) {
    let history: SensorHistory[] = [];
    const thisMonth = UtilFunctions.thisMonthStart();

    const q = query(
      collection(this.firestore, 'sensors', id, 'history'),
      orderBy('date', 'asc'),
      startAt(thisMonth)
    );
    const snapshot = await getDocs(q);

    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();
        const _history: SensorHistory = {
          id: id,
          date: _data['date'],
        };
        if ('wbgt' in _data) {
          _history.wbgt = _data['wbgt'];
          _history.temperature = _data['temperature'];
          _history.humidity = _data['humidity'];
        }
        if ('windSpeed' in _data) _history.windSpeed = _data['windSpeed'];
        if ('windDirection' in _data)
          _history.windDirection = _data['windDirection'];
        if ('windPosition' in _data)
          _history.windPosition = _data['windPosition'];

        if ('noiseL5' in _data) {
          _history.noiseL5 = _data['noiseL5'];
          _history.noiseMax = _data['noiseMax'];
          _history.vibrationL10 = _data['noiseL10'];
          _history.vibrationL10 = _data['noiseMax'];
        }

        if ('ph' in _data) {
          _history.ph = Math.floor(_data['ph'] * 100) / 100;
        }

        history.push(_history);
      }
    }

    return history;
  }

  // async getHistoryFromSiteId(condition: {
  //   site: Site;
  //   start?: Date;
  //   end?: Date;
  // }) {
  //   let history: SensorHistory[] = [];
  //   const thisMonth = UtilFunctions.thisMonthStart();

  //   let q = query(
  //     collection(this.firestore, 'sites', condition.site.id, 'sensor_history'),
  //     orderBy('date', 'asc'),
  //     startAt(thisMonth)
  //   );

  //   if (condition.start && condition.end) {
  //     q = query(
  //       collection(
  //         this.firestore,
  //         'sites',
  //         condition.site.id,
  //         'sensor_history'
  //       ),
  //       orderBy('date', 'asc'),
  //       startAt(UtilFunctions.formatDate(condition.start)),
  //       endAt(UtilFunctions.formatDate(condition.end))
  //     );
  //   } else if (condition.start) {
  //     q = query(
  //       collection(
  //         this.firestore,
  //         'sites',
  //         condition.site.id,
  //         'sensor_history'
  //       ),
  //       orderBy('date', 'asc'),
  //       startAt(UtilFunctions.formatDate(condition.start))
  //     );
  //   }
  //   const snapshot = await getDocs(q);

  //   if (snapshot.docs.length) {
  //     for (let doc of snapshot.docs) {
  //       const _data = doc.data();
  //       const _history: SensorHistory = {
  //         id: _data['devEUI'],
  //         date: _data['date'],
  //         siteId: condition.site.id,
  //         siteName: condition.site.name,
  //         siteManagementDepartmentName: condition.site.managementDepartmentName,
  //       };
  //       if ('wbgt' in _data) {
  //         _history.wbgt = _data['wbgt'];
  //         _history.temperature = _data['temperature'];
  //         _history.humidity = _data['humidity'];
  //       }
  //       if ('windSpeed' in _data) _history.windSpeed = _data['windSpeed'];
  //       if ('windDirection' in _data)
  //         _history.windDirection = _data['windDirection'];
  //       if ('windPosition' in _data)
  //         _history.windPosition = _data['windPosition'];
  //       history.push(_history);
  //     }
  //   }

  //   return history;
  // }

  // async getHistoryFromSiteIds(condition: {
  //   sites: Site[];
  //   start?: Date;
  //   end?: Date;
  // }) {
  //   const _historyList: SensorHistory[] = [];
  //   for (let site of condition.sites) {
  //     const _history: SensorHistory[] = await this.getHistoryFromSiteId({
  //       site: site,
  //       start: condition.start,
  //       end: condition.end,
  //     });

  //     _historyList.push(..._history);
  //   }

  //   return _historyList;
  // }

  async getHistoryFromDateRange(condition: {
    id?: string;
    start: Date;
    end?: Date;
  }) {
    let history: SensorHistory[] = [];

    let collectionRef = collection(this.firestore, 'mqtt_history');

    if (condition.id) {
      collectionRef = collection(
        this.firestore,
        'sensors',
        condition.id,
        'history'
      );
    }

    let q = query(
      collectionRef,
      orderBy('date', 'asc'),
      startAt(UtilFunctions.formatDate(condition.start))
    );

    if (condition.end) {
      q = query(
        collectionRef,
        orderBy('date', 'asc'),
        startAt(UtilFunctions.formatDate(condition.start)),
        endAt(UtilFunctions.formatDate(condition.end))
      );
    }
    const snapshot = await getDocs(q);

    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();
        const _history: SensorHistory = {
          id: _data['devEUI'],
          date: _data['date'],
        };

        if ('wbgt' in _data) {
          _history.wbgt = _data['wbgt'];
          _history.temperature = _data['temperature'];
          _history.humidity = _data['humidity'];
        }
        if ('windSpeed' in _data) {
          _history.windSpeed = _data['windSpeed'];
        }
        if ('windDirection' in _data) {
          _history.windDirection = _data['windDirection'];
        }

        if ('noiseL5' in _data) {
          _history.noiseL5 = _data['noiseL5'];
          _history.noiseMax = _data['noiseMax'];
          _history.vibrationL10 = _data['vibrationL10'];
          _history.vibrationMax = _data['vibrationMax'];
        }

        if ('ph' in _data) {
          _history.ph = Math.floor(_data['ph'] * 100) / 100;
        }

        history.push(_history);
      }
    }

    return history;
  }

  async getLatestHistory(id: string): Promise<SensorHistory | null> {
    const q = query(
      collection(this.firestore, 'sensors', id, 'history'),
      orderBy('date', 'desc'),
      limit(1)
    );
    const snapshot = await getDocs(q);

    if (snapshot.docs.length) {
      const _data = snapshot.docs[0].data();
      const _history: SensorHistory = {
        id: id,
        date: _data['date'],
      };
      // WBGTと温度
      if ('wbgt' in _data) {
        _history.wbgt = _data['wbgt'];
        _history.temperature = _data['temperature'];
        _history.humidity = _data['humidity'];
      }
      // 風速と風向
      if ('windSpeed' in _data) _history.windSpeed = _data['windSpeed'];
      if ('windDirection' in _data)
        _history.windDirection = _data['windDirection'];
      // 騒音振動
      if ('noiseL5' in _data) _history.noiseL5 = _data['noiseL5'];
      if ('vibrationL10' in _data)
        _history.vibrationL10 = _data['vibrationL10'];
      // Ph
      if ('ph' in _data) _history.ph = Math.floor(_data['ph'] * 100) / 100;

      return _history;
    } else {
      return null;
    }
  }

  async getLatestWindHistory(id: string): Promise<SensorHistory | null> {
    const q = query(
      collection(this.firestore, 'sensors', id, 'history'),
      orderBy('date', 'desc'),
      limit(5)
    );
    const snapshot = await getDocs(q);

    const _history: SensorHistory = { id: id, date: '' };

    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();
        if ('windSpeed' in _data) {
          _history.date = _data['date'];
          _history.windSpeed = _data['windSpeed'];
        }
        if ('windDirection' in _data) {
          _history.windDirection = _data['windDirection'];
        }
        if (
          _history.windSpeed !== undefined &&
          _history.windDirection !== undefined
        ) {
          break;
        }
      }
      return _history;
    } else {
      return null;
    }
  }

  createFromDocumentData(data: DocumentData): SensorHistory {
    const _history: SensorHistory = {
      id: data['devEUI'],
      date: data['date'],
    };

    if ('wbgt' in data) {
      _history.temperature = data['temperature'];
      _history.humidity = data['humidity'];
      if (data['humidity'] === 0) _history.humidity = 100;
      _history.wbgt = data['wbgt'];
    }
    if ('windSpeed' in data) {
      _history.windSpeed = data['windSpeed'];
    }
    if ('windDirection' in data) {
      _history.windDirection = data['windDirection'];
    }
    if ('isWindSpeed' in data) {
      _history.isWindSpeed = data['isWindSpeed'];
    }

    // 振動騒音センサー
    if ('noiseMax' in data) _history.noiseMax = data['noiseMax'];
    if ('noiseL5' in data) _history.noiseL5 = data['noiseL5'];
    if ('vibrationMax' in data) _history.vibrationMax = data['vibrationMax'];
    if ('vibrationL10' in data) _history.vibrationL10 = data['vibrationL10'];

    // Phセンサー
    if ('ph' in data) _history.ph = Math.floor(data['ph'] * 100) / 100;

    return _history;
  }

  // 日中ありったけ
  async getHistoryDaytime(id: string): Promise<SensorHistory[] | null> {
    let history: SensorHistory[] = [];

    const sensor = await this.get(id);

    const q = query(
      collection(this.firestore, 'sensors', id, 'history'),
      orderBy('date', 'desc'),
      where('date', '>=', UtilFunctions.startDateTime())
    );
    const snapshot = await getDocs(q);

    const endDateTime = UtilFunctions.endDateTime();

    let date: string = '';
    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (_data['date'] < endDateTime) {
          const _history: SensorHistory = this.createFromDocumentData(_data);

          if (sensor?.categoryName !== 'WIND') {
            history.push(_history);
          } else if (
            sensor?.categoryName === 'WIND' &&
            _data['isWindSpeed'] === true
          ) {
            if (date === '') {
              history.push(_history);
              date = _history.date;
            }
          }

          // WINDセンサーは30秒に1つなので10分に1つ、得られるデータは逆順なので10分前で設定する
          if (
            sensor?.categoryName === 'WIND' &&
            date !== '' &&
            _history.date <= UtilFunctions.deltaMinutes(-10, date)
          ) {
            date = '';
          }
        }
      }
    }

    return history.reverse();
  }

  // 24時間ありったけ
  async getHistory24Hours(id: string): Promise<SensorHistory[] | null> {
    let history: SensorHistory[] = [];

    const sensor = await this.get(id);

    const q = query(
      collection(this.firestore, 'sensors', id, 'history'),
      orderBy('date', 'desc'),
      where('date', '>=', UtilFunctions.deltaDays(-1))
    );
    const snapshot = await getDocs(q);

    let count = 0;
    let date = '';
    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        if (count === 0) {
          const _history: SensorHistory = this.createFromDocumentData(_data);

          if (sensor?.categoryName !== 'WIND') {
            history.push(_history);
            count++;
          } else {
            if (_data['isWindSpeed'] === true) {
              if (date === '') {
                history.push(_history);
                date = _history.date;
              }
              count++;
            }
          }
        } else {
          count++;

          // WBGTセンサーは1時間に6
          if (sensor?.categoryName !== 'WIND' && count === 5) {
            count = 0;
          }
          // WINDセンサーは3分に2つで60分で40
          if (
            sensor?.categoryName === 'WIND' &&
            date !== '' &&
            _data['date'] <= UtilFunctions.deltaHours(-1, date)
          ) {
            date = '';
            count = 0;
          }
        }
      }
    }

    return history.reverse();
  }

  // 1週間ありったけ
  async getHistory7Days(id: string): Promise<SensorHistory[] | null> {
    let history: SensorHistory[] = [];

    const sensor = await this.get(id);

    const q = query(
      collection(this.firestore, 'sensors', id, 'history'),
      orderBy('date', 'desc'),
      where('date', '>=', UtilFunctions.deltaDays(-7))
    );
    const snapshot = await getDocs(q);

    let count = 0;
    let date = '';

    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        const _history: SensorHistory = this.createFromDocumentData(_data);
        if (count === 0) {
          if (sensor?.categoryName !== 'WIND') {
            history.push(_history);
            count++;
          } else {
            if (_data['isWindSpeed'] === true) {
              if (date === '') {
                history.push(_history);
                date = _history.date;
              }
              count++;
            }
          }
        } else {
          count++;

          if (sensor?.categoryName !== 'WIND') {
            history.push(_history);
          }
          if (
            sensor?.categoryName === 'WIND' &&
            date !== '' &&
            _data['date'] <= UtilFunctions.deltaHours(-12, date)
          ) {
            date = '';
            count = 0;
          }
        }
      }
    }

    return history.reverse();
  }

  // 1ヶ月ありったけから24時間に1つにする
  async getHistory1Months(id: string): Promise<SensorHistory[] | null> {
    let history: SensorHistory[] = [];

    const sensor = await this.get(id);

    const q = query(
      collection(this.firestore, 'sensors', id, 'history'),
      orderBy('date', 'desc'),
      where('date', '>=', UtilFunctions.deltaMonths(-1))
    );
    const snapshot = await getDocs(q);

    let count = 0;
    let date = '';
    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();

        const _history: SensorHistory = this.createFromDocumentData(_data);
        if (count === 0) {
          if (sensor?.categoryName !== 'WIND') {
            history.push(_history);
            count++;
          } else {
            if (_data['isWindSpeed'] === true) {
              if (date === '') {
                history.push(_history);
                date = _history.date;
              }
              count++;
            }
          }
        } else {
          count++;

          // WBGTセンサーは1時間に6回なので24時間で0-143
          if (sensor?.categoryName !== 'WIND') {
            history.push(_history);
          }
          // WINDセンサーは3分に2つで60分で40個、24時間で0-959
          if (
            sensor?.categoryName === 'WIND' &&
            date !== '' &&
            _data['date'] <= UtilFunctions.deltaDays(-1, date)
          ) {
            date = '';
            count = 0;
          }
        }
      }
    }

    return history.reverse();
  }

  getDocumentReference(id: string) {
    return doc(this.firestore, `sensors/${id}`);
  }

  getDocumentPath(id: string) {
    return doc(this.firestore, `sensors/${id}`);
  }

  // pHセンサー限定基準値範囲設定
  updatePhRange(id: string, min: number, max: number) {
    return updateDoc(doc(this.firestore, 'sensors', id), {
      minPh: min,
      maxPh: max,
    });
  }

  // 騒音・振動センサー限定基準値範囲設定
  updateNoiseStandard(id: string, noise: number, vibration: number) {
    return updateDoc(doc(this.firestore, 'sensors', id), {
      standardNoise: noise,
      standardVibration: vibration,
    });
  }
}
