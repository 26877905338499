import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth';
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { collectionData, Firestore } from '@angular/fire/firestore';

import { Account } from '../classes/account';
import { AppConst } from '../constants/app-const';
import { Group } from '../interfaces/group';
import { SignInRequest } from '../interfaces/sign-in-request';
import { SignUpRequest } from '../interfaces/sign-up-request';
import { GroupService } from './group.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  account: Account | null = null;
  group: Group | null = null;
  user$ = user(this.auth);

  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private groupService: GroupService
  ) {}

  getUser(): Account | null {
    return SessionStorageService.getItem(
      AppConst.SESSION_KEY_USER,
      new Account()
    );
  }

  getGroup(): Group | null {
    return SessionStorageService.getItem(AppConst.SESSION_KEY_GROUP, null);
  }

  getOrganizationId(): string {
    const account: Account | null = this.getUser();
    if (account === null) {
      return AppConst.DEFAULT_ORGANIZATION_ID;
    }
    return account.organizationId;
  }

  isWestUser(): boolean {
    // getOrganizationId()の値で判定する
    return this.getOrganizationId() === AppConst.DEFAULT_ORGANIZATION_ID;
  }

  setUser(user: Account): void {
    SessionStorageService.setItem(AppConst.SESSION_KEY_USER, user);
  }

  async signIn(signInRequest: SignInRequest): Promise<void> {
    let authUser = '';

    if (signInRequest.user.indexOf('@') < 0) {
      authUser = `${signInRequest.user}@${AppConst.USER_AUTH_DOMAIN}`;
    } else {
      authUser = signInRequest.user;
    }

    const userCredentials = await signInWithEmailAndPassword(
      this.auth,
      authUser,
      signInRequest.password
    );

    if (userCredentials !== null) {
      const account = await this.getAccount();

      if (account === null) {
        throw 'No such user';
      }
      SessionStorageService.setItem(AppConst.SESSION_KEY_USER, account);
      this.account = account;
      const group = await this.groupService.get(account!.groupId);
      SessionStorageService.setItem(AppConst.SESSION_KEY_GROUP, group);
      this.group = group;
    }
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
    this.account = null;
    this.group = null;
    SessionStorageService.removeItem(AppConst.SESSION_KEY_USER);
    SessionStorageService.removeItem(AppConst.SESSION_KEY_GROUP);
  }

  async getAccount(): Promise<Account | null> {
    if (this.auth.currentUser && this.auth.currentUser.uid) {
      const snapshot = await getDoc(
        doc(this.firestore, 'accounts', this.auth.currentUser.uid)
      );
      if (!snapshot.exists()) {
        return null;
      }
      const data = snapshot.data();
      const account = new Account({
        id: data['id'],
        organizationId: data['organizationId'],
        user: data['user'],
        groupId: data['groupId'],
        groupName: data['groupName'],
      });

      if ('defaultSite' in data) {
        account.defaultSite = data['defaultSite'];
      }

      return account;
    } else {
      return null;
    }
  }

  getAccountsStream(): Observable<Account[]> {
    const q = query(collection(this.firestore, 'accounts'), orderBy('user'));
    return collectionData(q).pipe(
      map((docDataList: DocumentData[]) => {
        return docDataList.map((data: DocumentData) => {
          return new Account({
            id: data['id'],
            organizationId: data['organizationId'],
            user: data['user'],
            groupId: data['groupId'],
            groupName: data['groupName'],
          });
        });
      })
    );
  }

  async signUp(request: SignUpRequest): Promise<boolean> {
    let authUser = '';

    if (request.user.indexOf('@') < 0) {
      authUser = `${request.user}@${AppConst.USER_AUTH_DOMAIN}`;
    } else {
      authUser = request.user;
    }
    try {
      const credential: UserCredential = await createUserWithEmailAndPassword(
        this.auth,
        authUser,
        request.password
      );

      if (credential.user !== null) {
        await setDoc(doc(this.firestore, 'accounts', credential.user.uid), {
          id: credential.user.uid,
          organizationId: request.organizationId,
          user: request.user,
          groupId: request.groupId,
          groupName: request.groupName,
          groupRef: request.groupRef,
        });
      }
    } catch (error) {
      return false;
    }
    return true;
  }

  groupRank() {
    if (this.getGroup() === null) {
      return 0;
    }
    return this.getGroup()!.rank;
  }

  isOuterGroup() {
    const group = this.getGroup();
    // グループ取れなければ外部とする
    if (group === null) {
      return true;
    }
    // 一般ランク未満で、モニターランクではない場合も外部とする
    if (
      group.rank < AppConst.GROUP_RANK_GENERAL &&
      group.rank !== AppConst.GROUP_RANK_MONITOR
    ) {
      return true;
    }

    return false;
  }

  isGeneralGroup() {
    const group = this.getGroup();
    // グループ取れなければ一般とする
    if (group === null) {
      return true;
    }
    // 部門管理者ランク未満で、モニターランクではない場合も一般とする
    if (
      group.rank < AppConst.GROUP_RANK_CRITERIA &&
      group.rank !== AppConst.GROUP_RANK_MONITOR
    ) {
      return true;
    }

    return false;
  }

  isDepAdminGroup() {
    if (this.getGroup() === null) {
      return false;
    }
    if (this.getGroup()!.rank >= AppConst.GROUP_RANK_CRITERIA) return true;
    return false;
  }

  isSysAdminGroup() {
    if (this.getGroup() === null) {
      return false;
    }
    if (this.getGroup()!.rank >= AppConst.GROUP_RANK_SYSADMIN) return true;
    return false;
  }

  isMonitorGroup() {
    const group = this.getGroup();
    if (group === null) {
      return false;
    }
    if (group.rank === AppConst.GROUP_RANK_MONITOR) return true;
    return false;
  }
}
