<div class="sidenav" *ngIf="!accountService.isOuterGroup()">
  <img src="assets/images/hlssicon.png" alt="HLSS" class="sidenav-menu-icon" />

  <mat-nav-list>
    <mat-list-item
      routerLink="site-list"
      *ngIf="accountService.isDepAdminGroup()"
    >
      <mat-icon class="list-item-icon">construction</mat-icon>
      現場一覧
    </mat-list-item>
    <mat-list-item
      routerLink="site-map"
      *ngIf="accountService.isDepAdminGroup()"
    >
      <mat-icon class="list-item-icon">place</mat-icon>
      現場地図表示
    </mat-list-item>
    <mat-list-item
      *ngIf="
        (accountService.isOuterGroup() || accountService.isGeneralGroup()) &&
        isWinter
      "
      (click)="onWnClick()"
    >
      <mat-icon class="list-item-icon">
        <img src="/assets/images/weather/wnicon.png" />
      </mat-icon>
      気象情報
    </mat-list-item>

    <mat-list-item
      routerLink="site-list"
      *ngIf="accountService.isMonitorGroup()"
    >
      <mat-icon class="list-item-icon">construction</mat-icon>
      現場一覧
    </mat-list-item>
    <mat-list-item
      routerLink="site-map"
      *ngIf="accountService.isMonitorGroup()"
    >
      <mat-icon class="list-item-icon">place</mat-icon>
      現場地図表示
    </mat-list-item>

    <mat-accordion *ngIf="accountService.isSysAdminGroup()">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>管理メニュー</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list-item
          routerLink="site-config"
          *ngIf="accountService.isSysAdminGroup()"
        >
          <mat-icon class="list-item-icon">settings</mat-icon>
          現場設定
        </mat-list-item>
        <mat-list-item
          routerLink="extract-data"
          *ngIf="accountService.isSysAdminGroup()"
        >
          <mat-icon class="list-item-icon">search</mat-icon>
          データ抽出
        </mat-list-item>
        <mat-list-item
          routerLink="download-access-log"
          *ngIf="accountService.isSysAdminGroup()"
        >
          <mat-icon class="list-item-icon">list</mat-icon>
          アクセスログ
        </mat-list-item>
      </mat-expansion-panel>
    </mat-accordion>

    <ng-container *ngIf="accountService.isGeneralGroup()">
      <mat-list-item (click)="goGeneralSiteDetail()">
        <mat-icon class="list-item-icon">construction</mat-icon>
        現場詳細
      </mat-list-item>
      <mat-accordion *ngIf="accountService.isGeneralGroup()">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>管理メニュー</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-list-item routerLink="site-config">
            <mat-icon class="list-item-icon">settings</mat-icon>現場設定
          </mat-list-item>
          <mat-list-item routerLink="extract-data">
            <mat-icon class="list-item-icon">search</mat-icon>
            データ抽出
          </mat-list-item>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </mat-nav-list>
</div>
