<section>
  <div class="qr-code-dialog-container">
    <h1 mat-dialog-title>現場QRコード</h1>
    <div mat-dialog-content class="qr-code-dialog-qr-container">
      <div class="qr-code-dialog-qr-canvas">
        <qrcode
          [qrdata]="data.siteUrl"
          [width]="256"
          [errorCorrectionLevel]="'M'"
        >
        </qrcode>
      </div>
      <div class="qr-code-dialog-qr-copy">
        <mat-icon
          matSuffix
          [cdkCopyToClipboard]="data.siteUrl"
          (click)="copyToClipboard(); tooltipQr.show()"
        >
          content_copy
        </mat-icon>
        <span
          #tooltipQr="matTooltip"
          matTooltip="コピーされました"
          matTooltipPosition="right"
        >
        </span>
      </div>
      <div class="qr-code-dialog-qr-download">
        <button mat-raised-button color="primary" (click)="downloadImage()">
          ダウンロード
        </button>
      </div>
    </div>
    <div mat-dialog-content class="qr-code-dialog-site-url-container">
      <mat-label>現場URL（自動生成）</mat-label>
      <input
        matInput
        disabled
        value="{{ data.siteUrl }}"
        class="qr-code-dialog-site-url"
      />
      <mat-icon
        matSuffix
        [cdkCopyToClipboard]="data.siteUrl"
        (click)="tooltip.show()"
      >
        content_copy
      </mat-icon>
      <span
        #tooltip="matTooltip"
        matTooltip="コピーされました"
        matTooltipPosition="right"
      >
      </span>
    </div>
    <div mat-dialog-actions class="qr-code-dialog-actions">
      <button mat-stroked-button mat-dialog-close>閉じる</button>
    </div>
  </div>
</section>
