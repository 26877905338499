import {
  LatestSensorData,
  LatestSiteData,
} from 'src/app/shared/interfaces/latest-site-data';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import { SiteDetailSensorsService } from './site-detail-sensors.service';

@Component({
  selector: 'app-site-detail-sensors',
  templateUrl: './site-detail-sensors.component.html',
  styleUrls: ['./site-detail-sensors.component.scss'],
})
export class SiteDetailSensorsComponent implements OnChanges, OnDestroy {
  constructor(private siteDetailSensorsService: SiteDetailSensorsService) {}

  @Input() latestSiteData: LatestSiteData | null = null;

  sensorData: LatestSensorData | undefined = undefined;

  isSummer: boolean = false;
  isWinter: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.setSensorData();
  }

  setSensorData(): void {
    this.isSummer = this.siteDetailSensorsService.isSummer();
    this.isWinter = this.siteDetailSensorsService.isWinter();

    if (this.latestSiteData?.sensorData !== undefined) {
      this.sensorData = this.latestSiteData?.sensorData;

      // pHデータ捏造
      const phData = this.sensorData.ph;
      if (phData !== undefined) {
        if (phData.id == 'ph00000000000001') {
          phData.date = UtilFunctions.now();
          const ph = Math.floor(Math.random() * 15) + 1;
          if (ph > 11) {
            phData.ph = 11;
          } else {
            phData.ph = ph;
          }
          this.sensorData.ph = phData;
        }
      }
    }
  }

  ngOnDestroy(): void {}
}
