<p *ngIf="isSysAdminGroup" class="page-title">
  データ抽出　月次データダウンロードは<a routerLink="/download-data">こちら</a>
</p>
<mat-card class="extract-data-card">
  <mat-card-header>
    <mat-card-title class="extract-data-title">抽出条件</mat-card-title>
  </mat-card-header>
  <div>
    <form
      class="extract-data-form"
      [formGroup]="extractForm"
      (keydown.enter)="$event.preventDefault()"
      autocomplete="off"
    >
      <div>
        <mat-form-field>
          <mat-label>現場名</mat-label>
          <input
            matInput
            id="siteName"
            name="siteName"
            formControlName="siteName"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>センサー指定</mat-label>
          <mat-select
            id="sensorId"
            name="sensorId"
            formControlName="sensorId"
            (selectionChange)="onSelectionChange($event)"
          >
            <mat-option [value]="0" *ngIf="sensors | async"> 全て </mat-option>
            <mat-option
              *ngFor="let sensor of sensors | async"
              [value]="sensor?.id"
            >
              {{ sensor?.id }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>センサー種別</mat-label>
          <mat-select
            id="sensorCategoryId"
            name="sensorCategoryId"
            formControlName="sensorCategoryId"
            (selectionChange)="onSelectionChange($event)"
          >
            <mat-option [value]="0"> 全て </mat-option>
            <mat-option
              *ngFor="let category of sensorCategories | async"
              [value]="category.id"
            >
              {{ category.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>開始日</mat-label>
          <input
            matInput
            formControlName="startDate"
            [matDatepicker]="startPicker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="extract-data-form-input-time">
          <input matInput formControlName="startHour" />
        </mat-form-field>
        ：
        <mat-form-field class="extract-data-form-input-time">
          <input matInput formControlName="startMinutes" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>終了日</mat-label>
          <input
            matInput
            formControlName="endDate"
            [matDatepicker]="endPicker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="extract-data-form-input-time">
          <input matInput formControlName="endHour" />
        </mat-form-field>
        ：
        <mat-form-field class="extract-data-form-input-time">
          <input matInput formControlName="endMinutes" />
        </mat-form-field>
      </div>
      <!-- loginFormのinvalidプロパティに各項目のバリデーションの総合結果が自動的に入ります -->
      <!-- タグ内でrequiredを設定することで、ふたつの項目のうちどちらかが空白ならボタンを押せない仕組みにしています  -->
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="extractForm.invalid"
        (click)="onClickExtract()"
      >
        抽出
      </button>
    </form>
  </div>
</mat-card>
