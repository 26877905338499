import { setHours, setMinutes, setSeconds } from 'date-fns';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { ExtractDataRequest } from 'src/app/shared/interfaces/extract-data-request';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { SensorHistory } from 'src/app/shared/interfaces/sensor-history';
import { SensorHistoryCsv } from 'src/app/shared/interfaces/sensor-history-csv';
import { Site } from 'src/app/shared/interfaces/site';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SensorCategoryService } from 'src/app/shared/services/sensor-category.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { WindSensorSpeed } from 'src/app/shared/classes/wind-sensor-spees.class';
import { AccountService } from 'src/app/shared/services/account.service';
import { Account } from 'src/app/shared/classes/account';
import { Router } from '@angular/router';

@Component({
  selector: 'app-extract-data-page',
  templateUrl: './extract-data-page.component.html',
  styleUrls: ['./extract-data-page.component.scss'],
})
export class ExtractDataPageComponent implements OnInit {
  sites = this.siteService.getAllWithOrganization();
  sensors!: Promise<Sensor[]>;
  sensorCategories = this.sensorCategoryService.getAll();
  blnLoading: boolean = false;
  strNextPage: string = '';

  extractDataRequest: ExtractDataRequest = {};

  dateControl = new UntypedFormControl(['']);
  minDate = new Date();
  maxDate = new Date();

  // 風速センサーの風速値を保持する
  windSensorSpeed = new WindSensorSpeed();

  // 一般と管理者で出力を変えたいのです
  isSysAdminGroup = this.accountService.isSysAdminGroup();
  isGeneralGroup = this.accountService.isGeneralGroup();
  account: Account | null = null;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private loadingService: LoadingService,
    private siteService: SiteService,
    private sensorService: SensorService,
    private sensorCategoryService: SensorCategoryService,
    private logService: AccessLogService
  ) {}

  extractForm = this.formBuilder.group({
    siteName: new FormControl({ value: '', disabled: this.isGeneralGroup }),
    sensorId: [''],
    sensorCategoryId: [''],
    startDate: [''],
    startHour: [''],
    startMinutes: [''],
    startSeconds: ['0'],
    endDate: [''],
    endHour: [''],
    endMinutes: [''],
    endSeconds: ['59'],
  });

  async ngOnInit() {
    await this.logService.addLog(UrlConst.SLASH + UrlConst.PATH_EXTRACT_DATA);
    const startDate = UtilFunctions.thisMonthStartDate();
    const endDate = UtilFunctions.thisMonthEndDate();
    this.extractForm.get('startDate')?.setValue(startDate);
    this.extractForm.get('startHour')?.setValue(startDate.getHours());
    this.extractForm.get('startMinutes')?.setValue(startDate.getMinutes());

    this.extractForm.get('endDate')?.setValue(endDate);
    this.extractForm.get('endHour')?.setValue(endDate.getHours());
    this.extractForm.get('endMinutes')?.setValue(endDate.getMinutes());

    // 一般ユーザアクセス時は、ダウンロードできるデータは限定される
    if (this.isGeneralGroup) {
      this.account = await this.accountService.getAccount();
      // アカウントがない場合は、入り直し
      if (this.account === null) {
        this.router.navigate(['/login']);
        return;
      }

      const site = await this.siteService.get(this.account.user);
      // 現場が見つからない場合も入り直し
      if (site === null) {
        this.router.navigate(['/login']);
        return;
      }
      this.sensors = this.sensorService.getAllBySiteIdWithOrganization(site.id);
      this.extractForm.get('siteName')?.setValue(site.name);
    } else {
      this.sensors = this.sensorService.getAllWithOrganization();
    }
  }

  //
  // 抽出方法
  // センサーが指定されていたら、他があってもなくてもそのまま抽出
  // 現場名指定のみの場合
  //   指定現場名を含む現場を検索
  //   現場に紐づくセンサーを検索後、センサー指定と同じ要領で抽出
  // センサー種別のみ指定の場合
  //   対象センサーを検索後、センサー指定と同じ要領で抽出
  // 現場指定とセンサー種別が指定されている場合
  //   全現場から指定文字列を含む現場を検索
  //   絞り込んだ現場からセンサー種別にマッチするセンサーを検索
  //   センサー指定と同じ要領で抽出
  //
  async extractData(): Promise<SensorHistoryCsv[] | null> {
    // 最後にCSVにするためのオブジェクト配列
    let history: SensorHistoryCsv[] | null = [];
    // this.extractForm.get('sensorCategoryId')?.value をstring型にする
    const sensorCategoryId: string = this.extractForm
      .get('sensorCategoryId')
      ?.value.toString();

    if (this.extractForm.get('sensorId')?.value) {
      console.log('sensorId: ', this.extractForm.get('sensorId')?.value);
      // センサーID指定
      // 指定センサーIDによるデータ抽出
      history = await this._getHistoryFromSensorId(
        this.extractForm.value.sensorId
      );
    } else {
      console.log('no sensor');
      // センサーID指定なし
      if (
        sensorCategoryId !== '' &&
        sensorCategoryId !== '0' &&
        this.extractForm.get('siteName')?.value === ''
      ) {
        console.log('no site');
        // センサー種別のみ指定
        // センサー種別によるデータ抽出
        const _sensors = await this._getSensorFromCategoryId(sensorCategoryId);

        // センサーIDによるデータ抽出
        for (let _sensor of _sensors) {
          const _history = await this._getHistoryFromSensor(_sensor);
          if (_history !== null) {
            history.push(..._history);
          }
        }
      } else if (
        this.extractForm.get('siteName')?.value !== '' &&
        (sensorCategoryId === '' || sensorCategoryId === '0')
      ) {
        console.log('no category');
        // 現場名のみ指定
        // 現場名を含む現場一覧を作成する
        const _sites = await this._getSiteFromSiteName(
          this.extractForm.get('siteName')?.value
        );
        // 現場に紐づくセンサー一覧を作成する
        const _sensors = await this._getSensorFromSite(_sites);
        // センサーIDによるデータ抽出
        for (let _sensor of _sensors) {
          const _history = await this._getHistoryFromSensor(_sensor);
          if (_history !== null) {
            history = history.concat(_history);
          }
        }
      } else if (
        sensorCategoryId !== '' &&
        sensorCategoryId !== '0' &&
        this.extractForm.get('siteName')?.value !== ''
      ) {
        console.log(
          'site and category: ',
          this.extractForm.get('siteName')?.value,
          sensorCategoryId
        );

        // 現場名、センサー種別が指定されている
        // 現場名を含む現場一覧を作成する
        const _sites = await this._getSiteFromSiteName(
          this.extractForm.get('siteName')?.value
        );

        // 現場に紐づくセンサーのうち、センサー種別にマッチするセンサー一覧を作成する
        const _sensors = await this._getSensorFromSiteAndCategoryId(
          _sites,
          sensorCategoryId
        );
        // センサーIDによるデータ抽出
        for (let _sensor of _sensors) {
          const _history = await this._getHistoryFromSensor(_sensor);

          if (_history !== null) {
            history = history.concat(_history);
          }
        }
      } else {
        console.log('no condition');
        // 条件指定なし（日時のみ）
        // 全センサー一覧
        const _sensors = await this._getSensors();

        // センサーIDによるデータ抽出
        for (let _sensor of _sensors) {
          const _history = await this._getHistoryFromSensor(_sensor);

          if (_history !== null) {
            history = history.concat(_history);
          }
        }
      }
    }

    return history;
  }

  async onClickExtract() {
    this.loadingService.startLoading();
    const history: SensorHistoryCsv[] | null = await this.extractData();

    // // 指定がない場合はundefined
    // let siteName = this.extractForm.get('siteName')?.value;

    // // 最後にCSVにするためのオブジェクト配列
    // let history: SensorHistoryCsv[] | null = [];

    // if (this.extractForm.get('sensorId')?.value) {
    //   // センサーIDが指定されたら現場IDの指定なんて関係ない
    //   history = await this._getHistoryFromSensorId(
    //     this.extractForm.value.sensorId
    //   );
    // } else if (this.extractForm.value.sensorId == '0') {
    //   // 全センサーが対象の場合も現場指定は関係なくなる
    //   const sensors = await this.sensorService.getAll();
    //   sensors?.map(async (sensor) => {
    //     if (sensor) {
    //       const _history = await this._getHistoryFromSensorId(sensor.id);
    //       if (history !== null && _history !== null) {
    //         history = history.concat(_history);
    //       }
    //     }
    //   });
    // } else if (siteName !== '') {
    //   // 現場が指定された
    //   const _history = await this._getHistoryFromSiteName(siteName);
    //   if (_history !== null) {
    //     history = _history;
    //   }
    // } else {
    //   // 何も指定がない場合は指定期間に基づいて出力
    //   const dateRange = this._dateRange();

    //   const historyFromDate = await this.sensorService.getHistoryFromDateRange({
    //     start: dateRange.start,
    //     end: dateRange.end,
    //   });

    //   let sources: SensorHistoryCsv[] = [];
    //   let siteCache: { id: string; site: Site }[] = [];
    //   let sensorCache: { id: string; sensor: Sensor }[] = [];

    //   for (let i = 0; i < historyFromDate.length; i++) {
    //     const h = historyFromDate[i];
    //     let sensor: Sensor | null = null;
    //     const sensorCacheFilter = sensorCache.filter((s) => {
    //       return s.id === h.id;
    //     });
    //     if (sensorCacheFilter[0]) {
    //       sensor = sensorCacheFilter[0]!.sensor;
    //     } else {
    //       sensor = await this.sensorService.get(h.id);
    //       sensorCache.push({ id: h.id, sensor: sensor! });
    //     }

    //     let site: Site | null = null;
    //     if (sensor?.siteId) {
    //       const siteCacheFilter = siteCache.filter((s) => {
    //         return s.id === sensor?.siteId;
    //       });
    //       if (siteCacheFilter[0]) {
    //         site = siteCacheFilter[0]!.site;
    //       } else {
    //         site = await this.siteService.get(sensor.siteId);
    //         siteCache.push({ id: sensor.siteId, site: site! });
    //       }
    //     }

    //     const status = UtilFunctions.getStatus(h.wbgt!);
    //     let source: SensorHistoryCsv = {
    //       date: h.date,
    //       siteId: site ? site.id : '-',
    //       siteName: site ? site.name : '-',
    //       managementDepartmentName: site ? site.managementDepartmentName : '-',
    //       position: '',
    //       siteStatus: status ? status.label : '-',
    //       wbgt: h.wbgt!,
    //       temperature: h.temperature!,
    //       humidity: h.humidity!,
    //     };

    //     if (sensor?.position === 'top_floor') source.position = '躯体最上階';
    //     if (sensor?.position === 'board') source.position = '朝礼看板';

    //     sources.push(source);
    //   }

    //   history = sources;
    // }

    if (history !== null) {
      UtilFunctions.downloadSensorHistoryCsv(history.sort());
    }
    this.loadingService.stopLoading();
  }

  onSelectionChange(event: MatSelectChange) {
    if (event.source.id === 'sensorCategoryId') {
      this.extractDataRequest.sensorCategoryId = event.value.toString();
    }
    if (event.source.id === 'sensorId') {
      this.extractDataRequest.sensorId = event.value;
    }
  }

  async _getHistoryFromSensorId(
    sensorId: string
  ): Promise<SensorHistoryCsv[] | null> {
    const sensor = await this.sensorService.get(sensorId);

    if (sensor === null) return null;

    let site: Site | null = null;

    // 現場にセンサーが紐づいている場合はセンサーの現場IDを信用する
    if (sensor.siteId) {
      site = await this.siteService.get(sensor.siteId);
    }

    let sensorHistory: SensorHistory[] = [];

    // 期間指定
    const dateRange = this._dateRange();
    sensorHistory = await this.sensorService.getHistoryFromDateRange({
      id: sensorId,
      start: dateRange.start,
      end: dateRange.end,
    });

    const history: SensorHistoryCsv[] = [];

    for (let _history of sensorHistory) {
      const _h: SensorHistoryCsv = {
        date: _history.date,
        siteId: site !== null ? site.id : '-',
        siteName: site !== null ? site.name : '-',
        managementDepartmentName:
          site !== null ? site.managementDepartmentName : '-',
        positionName: '',
        siteStatus: '-',
      };

      if (_history.windSpeed !== undefined) {
        const _status = UtilFunctions.getWindStatus(_history.windSpeed);
        _h.windSpeed = _history.windSpeed;
        _h.siteStatus = _status?.label;
        _h.positionName = sensor.positionName;
        // 風速センサーの風速値の保持と平均値の計算
        this.windSensorSpeed.add(_history.windSpeed);
        _h.windSpeedAverage = this.windSensorSpeed.average();

        history.push(_h);
      } else if (_history.windDirection !== undefined) {
        _h.windDirectionName = UtilFunctions.getWindDirectionName(
          _history.windDirection
        );
        _h.positionName = sensor.positionName;
        history.push(_h);
      } else if (_history.wbgt !== undefined) {
        const _status = UtilFunctions.getStatus(_history.wbgt);
        _h.wbgt = _history.wbgt;
        _h.temperature = _history.temperature!;
        _h.humidity = _history.humidity!;
        _h.siteStatus = _status?.label;

        if (sensor?.positionName !== undefined) {
          _h.positionName = sensor.positionName;
        } else {
          _h.positionName = '----';
        }

        history.push(_h);
      } else if (_history.noiseL5 !== undefined) {
        _h.noiseL5 = _history.noiseL5;
        _h.noiseMax = _history.noiseMax;
        _h.vibrationL10 = _history.vibrationL10;
        _h.vibrationMax = _history.vibrationMax;
        history.push(_h);
      } else if (_history.ph !== undefined) {
        _h.ph = _history.ph;
        history.push(_h);
      }
    }

    return history;
  }

  async _getHistoryFromSensor(
    sensor: Sensor
  ): Promise<SensorHistoryCsv[] | null> {
    let site: Site | null = null;

    // 現場にセンサーが紐づいている場合はセンサーの現場IDを信用する
    if (sensor.siteId) {
      site = await this.siteService.get(sensor.siteId);
    }

    let sensorHistory: SensorHistory[] = [];

    // 期間指定
    const dateRange = this._dateRange();
    sensorHistory = await this.sensorService.getHistoryFromDateRange({
      id: sensor.id,
      start: dateRange.start,
      end: dateRange.end,
    });

    const history: SensorHistoryCsv[] = [];

    for (let _history of sensorHistory) {
      const _h: SensorHistoryCsv = {
        date: _history.date,
        siteId: site !== null ? site.id : '-',
        siteName: site !== null ? site.name : '-',
        managementDepartmentName:
          site !== null ? site.managementDepartmentName : '-',
        positionName: '',
        siteStatus: '-',
      };

      if (_history.windSpeed !== undefined) {
        const _status = UtilFunctions.getWindStatus(_history.windSpeed);
        _h.windSpeed = _history.windSpeed;
        _h.siteStatus = _status?.label;
        _h.positionName = sensor.positionName;
        // 風速センサーの風速値の保持と平均値の計算
        this.windSensorSpeed.add(_history.windSpeed);
        _h.windSpeedAverage = this.windSensorSpeed.average();

        // console.log('windSpeed positionName: ', sensor.positionName);
        history.push(_h);
      } else if (_history.windDirection !== undefined) {
        _h.windDirectionName = UtilFunctions.getWindDirectionName(
          _history.windDirection
        );
        _h.positionName = sensor.positionName;
        // console.log('windDirection positionName: ', sensor.positionName);
        history.push(_h);
      } else if (_history.wbgt !== undefined) {
        const _status = UtilFunctions.getStatus(_history.wbgt);
        _h.wbgt = _history.wbgt;
        _h.temperature = _history.temperature!;
        _h.humidity = _history.humidity!;
        _h.siteStatus = _status?.label;

        if (sensor?.positionName !== undefined) {
          _h.positionName = sensor.positionName;
        } else {
          _h.positionName = '----';
        }
        // console.log(
        //   'wbgt positionName: ',
        //   sensor.positionName,
        //   _h.positionName
        // );

        history.push(_h);
      } else if (_history.noiseL5 !== undefined) {
        _h.noiseL5 = _history.noiseL5;
        _h.noiseMax = _history.noiseMax;
        _h.vibrationL10 = _history.vibrationL10;
        _h.vibrationMax = _history.vibrationMax;
        history.push(_h);
      } else if (_history.ph !== undefined) {
        _h.ph = _history.ph;
        history.push(_h);
      }
    }

    return history;
  }

  async _getHistoryFromSiteName(siteName: string) {
    const sites = await this.siteService.getFromContainingName(siteName);
    let sensors: (Sensor | undefined)[] | null = null;

    if (this.extractForm.get('sensorId')?.value) {
      const sensor = await this.sensorService.get(
        this.extractForm.get('sensorId')?.value
      );
      if (sensor) {
        sensors = [sensor];
      }
    } else {
      sensors = await this.sensorService.getFromSiteIds(
        sites.map((site) => {
          return site.id;
        })
      );
    }

    const dateRange = this._dateRange();

    if (sensors && sensors.length > 0) {
      // 現場に紐づくセンサーがある

      // devEUIをキーとした設置場所ハッシュ作成
      // const positionMap: { [index: string]: string | undefined } = {};
      // sensors.forEach((sensor) => {
      //   if (sensor !== undefined) {
      //     positionMap[sensor.id] = sensor.positionName;
      //   }
      // });

      const sensorHistory: SensorHistory[] = [];
      for (let sensor of sensors) {
        if (sensor === undefined) {
          continue;
        }
        const history = await this.sensorService.getHistoryFromDateRange({
          id: sensor.id,
          start: dateRange.start,
          end: dateRange.end,
        });

        sensorHistory.push(...history);
      }

      const history = sensorHistory.map((h) => {
        const status = UtilFunctions.getStatus(h.wbgt!);
        const _h = {
          date: h.date,
          siteId: h.siteId ? h.siteId : '-',
          siteName: h.siteName ? h.siteName : '-',
          managementDepartmentName: h.siteManagementDepartmentName
            ? h.siteManagementDepartmentName
            : '-',
          positionName: '',
          siteStatus: status ? status.label : '-',
          wbgt: h.wbgt!,
          temperature: h.temperature!,
          humidity: h.humidity!,
        };

        //////////////////////////////////////////////////////////////////////////
        // 新方式になったので不要
        // if (positionMap[h.id]) {
        //   if (positionMap[h.id] === 'top_floor') _h.positionName = '躯体最上階';
        //   if (positionMap[h.id] === 'board') _h.positionName = '朝礼看板';
        // }

        return _h;
      });

      return history;
    } else {
      return null;
    }
  }

  // 現場名を含む現場一覧作成
  async _getSiteFromSiteName(siteName: string): Promise<Site[]> {
    return await this.siteService.getFromContainingName(siteName);
  }

  // 現場から紐づくセンサー一覧作成
  async _getSensorFromSite(site: Site | Site[]): Promise<Sensor[]> {
    let sensors: Sensor[] = [];

    if (Array.isArray(site)) {
      for (let _site of site) {
        const _sensors = await this.sensorService.getFromSiteId(_site.id);
        if (_sensors.length > 0) {
          sensors = sensors.concat(_sensors);
        }
      }
    } else {
      sensors = await this.sensorService.getFromSiteId(site.id);
    }
    return sensors;
  }

  // 現場とセンサー種別からセンサー一覧作成
  async _getSensorFromSiteAndCategoryId(
    site: Site | Site[],
    categoryId: string
  ): Promise<Sensor[]> {
    let sensors: Sensor[] = [];
    if (Array.isArray(site)) {
      for (let _site of site) {
        const _sensors = await this.sensorService.getFromSiteIdAndCategoryId(
          _site.id,
          categoryId
        );
        if (_sensors.length > 0) sensors = sensors.concat(_sensors);
      }
    } else {
      sensors = await this.sensorService.getFromSiteIdAndCategoryId(
        site.id,
        categoryId
      );
    }

    return sensors;
  }

  // センサー種別からセンサー一覧作成
  async _getSensorFromCategoryId(categoryId: string): Promise<Sensor[]> {
    return await this.sensorService.getAllFromCategoryId(categoryId);
  }

  // 全センサー一覧作成
  async _getSensors(): Promise<Sensor[]> {
    return await this.sensorService.getAllWithOrganization();
  }

  _dateRange() {
    let startDate: Date = UtilFunctions.thisMonthStartDate();
    if (this.extractForm.get('startDate')?.value) {
      startDate = this.extractForm.get('startDate')?.value;
    }
    startDate = setHours(startDate, 0);
    startDate = setMinutes(startDate, 0);
    startDate = setSeconds(startDate, 0);

    if (this.extractForm.get('startHour')?.value) {
      startDate = setHours(startDate, this.extractForm.get('startHour')?.value);
    }
    if (this.extractForm.get('startMinutes')?.value) {
      startDate = setMinutes(
        startDate,
        this.extractForm.get('startMinutes')?.value
      );
    }

    let endDate: Date = UtilFunctions.thisMonthEndDate();
    if (this.extractForm.get('endDate')?.value) {
      endDate = this.extractForm.get('endDate')?.value;
    }
    endDate = setHours(endDate, 0);
    endDate = setMinutes(endDate, 0);
    endDate = setSeconds(endDate, 0);

    if (this.extractForm.get('endHour')?.value) {
      endDate = setHours(endDate, this.extractForm.get('endHour')?.value);
    }
    if (this.extractForm.get('endMinutes')?.value) {
      endDate = setMinutes(endDate, this.extractForm.get('endMinutes')?.value);
    }

    return { start: startDate, end: endDate };
  }
}
