import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AppConst } from '../shared/constants/app-const';
import { UrlConst } from '../shared/constants/url-const';
import { AccountService } from '../shared/services/account.service';

@Injectable({
  providedIn: 'root',
})
export class DepAdminGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.accountService.getUser();

    if (user === null) {
      return this.cantActivate();
    }

    // 部門管理者ランク未満でモニターランクではない場合は、アクセス不可
    if (
      this.accountService.groupRank() < AppConst.GROUP_RANK_CRITERIA &&
      this.accountService.groupRank() !== AppConst.GROUP_RANK_MONITOR
    ) {
      return this.cantActivateCauseGroupRank();
    }
    return true;
  }

  private cantActivate(): boolean {
    this.router.navigate([UrlConst.PATH_SIGN_IN]);
    return false;
  }

  private cantActivateCauseGroupRank(): boolean {
    this.router.navigate([UrlConst.PATH_SIGN_IN]);
    return false;
  }
}
