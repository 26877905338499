import { ChartConfiguration, ChartType } from 'chart.js';
import * as pluginAnnotation from 'chartjs-plugin-annotation';
import { BaseChartDirective } from 'ng2-charts';

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-line-charts',
  templateUrl: './line-charts.component.html',
  styleUrls: ['./line-charts.component.scss'],
})
export class LineChartsComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() period: string = '';
  @Input() sensorId: string = '';
  @Input() dataList: number[] = [];
  @Input() dataLabel: string[] = [];
  @Input() boarderColor: string = 'rgba(148,159,177,1)';
  @Input() backgroundColor: string = 'rgba(148,159,177,0.2)';

  lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-0': {
        position: 'left',
      },
    },

    plugins: {
      legend: { display: true },
      annotation: {
        annotations: [
          {
            type: 'line',
            scaleID: 'y-axis-0',
            value: 16,
            borderColor: 'orange',
            borderWidth: 2,
            label: {
              position: 'center',
              enabled: true,
              color: 'orange',
              content: 'LineAnno',
              font: {
                weight: 'bold',
              },
            },
          },
        ],
      },
    },
  };

  lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor() {}

  lineChartData: ChartConfiguration['data'] | null = null;

  ngOnInit(): void {
    this.lineChartData = {
      datasets: [
        {
          data: this.dataList,
          label: this.label,
          backgroundColor: this.backgroundColor,
          borderColor: this.boarderColor,
          pointRadius: 1,
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointBorderWidth: 1,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
        },
      ],
      labels: this.dataLabel,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }
}
