import { map, tap } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConst } from '../constants/app-const';

export interface WeatherNewsApiResponse {
  requestId: string;
  wxdata: WeatherNewsApiResponseWxData[];
}

export interface WeatherNewsApiResponseWxData {
  lat: number;
  lon: number;
  srf: WeatherNewsApiResponseSrf[];
  mrf: WeatherNewsApiResponseMrf[];
}

export interface WeatherNewsApiResponseSrf {
  arpress: number;
  date: string;
  prec: number;
  rhum: number;
  temp: number;
  wnddir: number;
  wndspd: number;
  wx: number;
}

export interface WeatherNewsApiResponseMrf {
  date: string;
  maxtemp: number;
  mintemp: number;
  pop: number;
  wx: number;
}

@Injectable({
  providedIn: 'root',
})
export class WeatherNewsService {
  constructor(private http: HttpClient) {}

  fetchByCoordinate(lat: number, lon: number) {
    const url = AppConst.WEATHER_NEWS_API_URL + '?lat=' + lat + '&lon=' + lon;
    const headers = new HttpHeaders({
      'X-API-Key': AppConst.WEATHER_NEWS_API_KEY,
    });

    return this.http.get<WeatherNewsApiResponse>(url, { headers: headers });
  }

  fetchSrfByCoordinate(lat: number, lon: number) {
    return this.fetchByCoordinate(lat, lon).pipe(
      map((result) => result.wxdata[0].srf)
    );
  }
}
