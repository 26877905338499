import { getBlob, ref } from 'firebase/storage';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { AccessLogService } from 'src/app/shared/services/access-log.service';

import { Component, OnInit } from '@angular/core';
import { Storage } from '@angular/fire/storage';

@Component({
  selector: 'app-download-access-log',
  templateUrl: './download-access-log.component.html',
  styleUrls: ['./download-access-log.component.scss'],
})
export class DownloadAccessLogComponent implements OnInit {
  items: {
    name: string;
    year: string;
    month: string;
    day: string;
    path: string;
  }[] = [];

  monthlyItems: {
    name: string;
    year: string;
    month: string;
    path: string;
  }[] = [];

  constructor(
    private accessLogService: AccessLogService,
    private logService: AccessLogService,
    private storage: Storage
  ) {}

  async ngOnInit() {
    await this.logService.addLog(
      UrlConst.SLASH + UrlConst.PATH_DOWNLOAD_ACCESS_LOG
    );
    const logList = await this.accessLogService.getLogList();

    logList.forEach((log) => {
      const element: string[] = log.id.split('-');
      if (element[2]) {
        this.items.push({
          name: log.id,
          year: element[0],
          month: element[1],
          day: element[2],
          path: log.path,
        });
      }
    });

    const monthlyLogList = await this.accessLogService.getMonthlyLogList();

    monthlyLogList.forEach((log) => {
      const element: string[] = log.id.split('-');
      this.monthlyItems.push({
        name: log.id,
        year: element[0],
        month: element[1],
        path: log.path,
      });
    });
  }

  async onClickDownload(index: number) {
    try {
      const storageRef = ref(this.storage, this.items[index].path);
      const blob = await getBlob(storageRef);
      const reader = new FileReader();
      reader.readAsText(blob);
      reader.onload = () => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const newYMD = this.items[index].name.replace(/-/g, '');
        link.href = url;
        link.download = newYMD + '-access.csv';
        link.click();
      };
      reader.onerror = () => {
        console.log('FileReader.readAsText() ERROR: ', reader.error);
      };
    } catch (error) {
      console.error(error);
    }
  }

  async onClickMonthlyDownload(index: number) {
    try {
      const storageRef = ref(this.storage, this.monthlyItems[index].path);
      const blob = await getBlob(storageRef);
      const reader = new FileReader();
      reader.readAsText(blob);
      reader.onload = () => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const newYM = this.monthlyItems[index].name.replace(/-/g, '');
        link.href = url;
        link.download = 'access-monthly-' + newYM + '.csv';
        link.click();
      };
      reader.onerror = () => {
        console.log('FileReader.readAsText() ERROR: ', reader.error);
      };
    } catch (error) {
      console.error(error);
    }
  }
}
