import { collection, getDocs } from 'firebase/firestore';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { SensorCategory } from '../interfaces/sensor-category';

@Injectable({
  providedIn: 'root',
})
export class SensorCategoryService {
  constructor(private firestore: Firestore) {}

  async getAll(): Promise<SensorCategory[]> {
    const snapshot = await getDocs(
      collection(this.firestore, 'sensor_category')
    );

    const sensorCategory: SensorCategory[] = [];

    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();
        sensorCategory.push({
          id: _data['id'],
          name: _data['name'],
        });
      }
    }

    return sensorCategory;
  }
}
