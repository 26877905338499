import { UrlConst } from 'src/app/shared/constants/url-const';
import { Site } from 'src/app/shared/interfaces/site';
import { AccountService } from 'src/app/shared/services/account.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-site-config-alert-page',
  templateUrl: './site-config-alert-page.component.html',
  styleUrls: ['./site-config-alert-page.component.scss'],
})
export class SiteConfigAlertPageComponent implements OnInit {
  siteId: string | null = null;
  site: Site | null = null;
  errors: string[] = [];

  form = this.formBuilder.group({
    emails: this.formBuilder.array([]),
  });

  // パトライト設置
  installPatlite = false;
  installPatliteMessage = ''

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private loadingService: LoadingService,
    private siteService: SiteService,
    private accountService: AccountService
  ) {}

  get emails(): UntypedFormArray {
    return this.form.get('emails') as UntypedFormArray;
  }

  async ngOnInit(): Promise<void> {
    if (
      !(
        this.accountService.isSysAdminGroup() ||
        this.accountService.isGeneralGroup()
      )
    ) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
      return;
    }

    this.siteId = this.route.snapshot.paramMap.get('id');

    if (this.accountService.isGeneralGroup()) {
      const _account = this.accountService.getUser();
      if (_account === null) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      }

      // 一般ユーザでもdefaultSite属性が設定されていれば、それに従う
      if (_account.defaultSite && this.siteId !== _account.defaultSite) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      } else if (!_account.defaultSite && this.siteId !== _account.user) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      }
    }

    if (this.siteId === null) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      return;
    }

    this.site = await this.siteService.getWithOrganization(
      this.siteId,
      this.accountService.getOrganizationId()
    );

    if (this.site === null) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      return;
    }

    this.site.emails?.forEach((email) => {
      this.emails.push(
        this.formBuilder.group({ email: [email, Validators.email] })
      );
    });

    // パトライト設置
    this.installPatlite = this.site.installPatlite ?? false;
  }

  async onClick() {
    const emails: string[] = [];
    this.form.get('emails')?.value.forEach((v: any) => {
      if (v.email) {
        emails.push(v.email);
      }
    });

    if (emails.length > 0) {
      const setEmails = [...new Set(emails.sort())];

      try {
        this.loadingService.startLoading();
        await this.siteService.updateEmails(this.site!, setEmails);
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingService.stopLoading();
      }
    } else {
      try {
        this.loadingService.startLoading();
        await this.siteService.deleteEmails(this.site!);
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingService.stopLoading();
      }
    }
  }

  onClickAdd() {
    this.emails.push(this.formBuilder.group({ email: ['', Validators.email] }));
  }

  onClickRemove(i: number) {
    this.emails.removeAt(i);
  }

  onCancel(): void {
    this.site = null;
    this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
  }

  // パトライト設置情報リアルタイム更新
  async onPatliteSave() {
    this.installPatlite = !this.installPatlite

    try {
      await this.siteService.updateInstallPatlite(this.site!, this.installPatlite)
      this.installPatliteMessage = '保存しました'
      setTimeout(() => {
        this.installPatliteMessage = ''
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  }
}
