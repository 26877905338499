<section *ngIf="site && latestSiteData" class="site-detail-section">
  <ng-container *ngIf="isOuter && isOffHours">
    <div class="site-detail-off-hours">
      <div>ただいまの時間は利用できません</div>
      <div>利用時間は5:00〜19:00です</div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isOuter || !isOffHours">
    <div class="site-detail-sitename-area">
      <mat-card class="site-detail-sitename-card">
        <div>
          <span class="site-detail-sitename-title">現場名:</span>
          <span class="site-detail-sitename-body"> {{ site!.name }} </span>
          <span *ngIf="!isOuter && !isGeneral" class="ml-8">
            <button
              *ngIf="isWnPeriod"
              mat-raised-button
              (click)="onWnClick()"
              class="text-2xl font-bold"
            >
              <mat-icon>
                <img src="/assets/images/weather/wnicon.png" />
              </mat-icon>
              気象情報
            </button>
          </span>
          <span class="ml-8" (click)="onUsageGuideClick()">
            <button mat-raised-button class="text-2xl font-bold">凡例</button>
          </span>

          <!-- 騒音・騒音センサー -->
          <span class="ml-8 text-2xl" *ngIf="isExistsNoiseSensor">
            騒音基準値: {{ standardNoise }} dB　振動基準値:
            {{ standardVibration }}dB
          </span>

          <!-- pHセンサー -->
          <span class="ml-8 text-2xl" *ngIf="isExistsPhSensor">
            pH基準値: {{ minPh }} 以上　{{ maxPh }} 未満
          </span>
        </div>
      </mat-card>
    </div>

    <!-- センサーアラートエリア -->
    <div class="w-full mb-8">
      <app-site-detail-alert
        [site]="site"
        [latestSiteData]="latestSiteData"
      ></app-site-detail-alert>
    </div>

    <div class="mb-8">
      <app-site-detail-drawing [site]="site"> </app-site-detail-drawing>
    </div>

    <div class="mb-8">
      <app-site-detail-sensors [latestSiteData]="latestSiteData">
      </app-site-detail-sensors>
    </div>

    <div class="mb-8 w-full" *ngIf="!isOuter">
      <app-site-detail-charts [latestSiteData]="latestSiteData">
      </app-site-detail-charts>
    </div>
  </ng-container>
</section>
