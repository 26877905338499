<div class="dialog-container">
  <div class="dialog-content">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <p class="dialog-message">{{ data.message }}</p>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button
      mat-flat-button
      id="yesNoDialog_button_no"
      class="flat-button"
      color="primary"
      mat-dialog-close
    >
      {{ data.captionNo }}
    </button>
    <button
      mat-flat-button
      id="yesNoDialog_button_yes"
      class="flat-button"
      color="primary"
      [mat-dialog-close]="true"
    >
      {{ data.captionYes }}
    </button>
  </div>
</div>
