import {
    DrawingSensor
} from 'src/app/pages/site-config/components/drawing-editor/drawing-sensor.interface';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { Site, SiteDrawingSensorPosition } from 'src/app/shared/interfaces/site';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteDetailDrawingViewerService {
  constructor(
    private siteService: SiteService,
    private sensorService: SensorService
  ) {}

  async getDrawingSensorsFromSite(site: Site) {
    // 図面未設定
    if (site.drawing === undefined) {
      return this.setDefaultDrawingSensors(site);
    }

    // センサー位置未設定
    if (site.drawing.positions === undefined) {
      return this.setDefaultDrawingSensors(site);
    }

    // デフォルトセンサー位置設定
    const drawingSensors: DrawingSensor[] = await this.setDefaultDrawingSensors(site);

    const positions = site.drawing.positions;
    for (let position of positions) {
      const sensor = await this.sensorService.get(position.sensorId);

      if (sensor === null) {
        continue;
      }


      // positionNameがあれば採用、なければpositionから推測。それでもなければ'----'を採用。
      const positionName = this.decidePositionName(
        sensor.positionName,
        sensor.position
        );

      const index = drawingSensors.findIndex(s => s.id === sensor.id);

      if (index === -1) {
        continue;
      }

      const drawingSensor = drawingSensors[index];
      drawingSensor.positionName = positionName;

      if (drawingSensor.drawing !== undefined) {
        drawingSensor.drawing.x = position.x;
        drawingSensor.drawing.y = position.y;
      } else {
        drawingSensor.drawing = {
          x: position.x,
          y: position.y,
          width: 80,
          height: 80,
          fgColor: 'white',
          bgColor: 'red',
        }
      }

      // 停止中ステータス
      const statusStopped = UtilFunctions.getStatusStopped();

      // センサーの最新データがあれば値取得とステータス取得
      // なければ停止中ステータスを設定
      if (sensor.data !== undefined) {
        drawingSensor.wbgt = sensor.data.wbgt;
        drawingSensor.temperature = sensor.data.temperature;
        drawingSensor.humidity = sensor.data.humidity;

        // 夏期限定WBGT
        if (UtilFunctions.isSummer()) {
          // WBGT値があればステータス取得、なければ停止中ステータスを設定
          if (drawingSensor.wbgt !== undefined) {
            const status = UtilFunctions.getStatusWithDate(
              sensor.data.wbgt,
              sensor.data.date
            );

            // ステータスが取得できなければ停止中ステータスを設定
            if (status !== null && status.backgroundColor !== undefined) {
              drawingSensor.drawing!.bgColor = status.backgroundColor;
              drawingSensor.drawing!.fgColor = status.foregroundColor!;
            } else {
              drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
            }
          } else {
            drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
          }
        } else {
          // 温度値があればステータス取得、なければ停止中ステータスを設定
          if (drawingSensor.temperature !== undefined) {
            const status = UtilFunctions.getTemperatureStatusWithDate(
              sensor.data.temperature,
              sensor.data.date
            );

            // ステータスが取得できなければ停止中ステータスを設定
            if (status !== null && status.backgroundColor !== undefined) {
              drawingSensor.drawing!.bgColor = status.backgroundColor;
              drawingSensor.drawing!.fgColor = status.foregroundColor!;
            } else {
              drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
            }
          } else {
            drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
          }
        }
      } else {
        // センサーの最新値を取得できなければ停止中ステータスを設定
        drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
      }

      // drawingSensors.push(drawingSensor);
      drawingSensors[index] = drawingSensor;
    }

    return drawingSensors;
  }

  async setDefaultDrawingSensors(site: Site) {
    let sensors: Sensor[] = await this.sensorService.getFromSiteId(site.id);
    sensors = sensors.filter((sensor) => sensor.categoryName === 'WBGT');

    const drawingSensors: DrawingSensor[] = [];
    for (let i = 0; i < sensors.length; i++) {
      const sensor = sensors[i];
      if (sensor.categoryName !== 'WBGT') {
        continue;
      }

      const positionName = this.decidePositionName(
        sensor.positionName,
        sensor.position
      );
      const drawingSensor: DrawingSensor = {
        id: sensor.id,
        positionName: positionName,
        drawing: {
          x: 10,
          y: 10 + i * 80,
          width: 80,
          height: 80,
          fgColor: 'white',
          bgColor: 'red',
        },
      };

      // 停止中ステータス
      const statusStopped = UtilFunctions.getStatusStopped();

      // センサーの最新データがあれば値取得とステータス取得
      // なければ停止中ステータスを設定
      if (sensor.data !== undefined) {
        drawingSensor.wbgt = sensor.data.wbgt;
        drawingSensor.temperature = sensor.data.temperature;
        drawingSensor.humidity = sensor.data.humidity;

        // 夏期限定WBGT
        if (UtilFunctions.isSummer()) {
          // WBGT値があればステータス取得、なければ停止中ステータスを設定
          if (drawingSensor.wbgt !== undefined) {
            const status = UtilFunctions.getStatusWithDate(
              sensor.data.wbgt,
              sensor.data.date
            );

            // ステータスが取得できなければ停止中ステータスを設定
            if (status !== null && status.backgroundColor !== undefined) {
              drawingSensor.drawing!.bgColor = status.backgroundColor;
              drawingSensor.drawing!.fgColor = status.foregroundColor!;
            } else {
              drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
            }
          } else {
            drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
          }
        } else {
          // 温度値があればステータス取得、なければ停止中ステータスを設定
          if (drawingSensor.temperature !== undefined) {
            const status = UtilFunctions.getTemperatureStatusWithDate(
              sensor.data.temperature,
              sensor.data.date
            );

            // ステータスが取得できなければ停止中ステータスを設定
            if (status !== null && status.backgroundColor !== undefined) {
              drawingSensor.drawing!.bgColor = status.backgroundColor;
              drawingSensor.drawing!.fgColor = status.foregroundColor!;
            } else {
              drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
            }
          } else {
            drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
          }
        }
      } else {
        // センサーの最新値を取得できなければ停止中ステータスを設定
        drawingSensor.drawing!.bgColor = statusStopped.backgroundColor;
      }

      drawingSensors.push(drawingSensor);
    }

    return drawingSensors;
  }

  // positionNameの決定
  // 1. sensor.positionNameがあればそれを採用
  // 2. sensor.positionがあればそれから推測
  decidePositionName(
    positionName: string | undefined,
    position: string | undefined
  ) {
    if (positionName !== undefined) {
      return positionName;
    }

    if (position === undefined) {
      return '----';
    }

    if (position === 'top_floor') {
      return '躯体最上階';
    }

    if (position === 'board') {
      return '朝礼看板';
    }

    return '----';
  }

  // 図面画像をCloud Storageにアップロード
  uploadDrawingImage(siteId: string, file: File) {
    return this.siteService.uploadDrawing(siteId, file);
  }

  // センサー位置情報をCloud Firestoreにアップロード
  updateDrawingPositions(siteId: string, drawingSensors: DrawingSensor[]) {
    const positions: SiteDrawingSensorPosition[] = drawingSensors.map(
      (drawingSensor) => {
        return {
          sensorId: drawingSensor.id,
          x: drawingSensor.drawing!.x,
          y: drawingSensor.drawing!.y,
        };
      }
    );

    return this.siteService.updateDrawingPositions(siteId, positions);
  }
}
