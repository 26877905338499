<!-- <div class="site-detail-charts-button-area"> -->
<div class="w-full">
  <div class="w-full flex justify-end gap-4">
    <button
      mat-raised-button
      color="primary"
      class="site-detail-charts-button"
      (click)="onClickDaytimeCharts()"
      [disabled]="daytimeButtonDisabled"
    >
      日中
    </button>
    <button
      mat-raised-button
      color="primary"
      class="site-detail-charts-button"
      (click)="onClick1DayCharts()"
      [disabled]="dayButtonDisabled"
    >
      24時間
    </button>
    <button
      mat-raised-button
      color="primary"
      class="site-detail-charts-button"
      (click)="onClick1WeekCharts()"
      [disabled]="weekButtonDisabled"
    >
      1週間
    </button>
    <button
      mat-raised-button
      color="primary"
      class="site-detail-charts-button"
      (click)="onClick1MonthCharts()"
      [disabled]="monthButtonDisabled"
    >
      1ヶ月
    </button>
  </div>
</div>

<div class="w-full mt-8">
  <div class="w-full flex gap-4">
    <div class="w-full" *ngFor="let latestData of sensorData.wbgt">
      <mat-card *ngIf="!isWinter">
        <mat-card-header>
          <mat-card-title>WBGT（℃）</mat-card-title>
        </mat-card-header>
        <app-wbgt-line-chart
          [data]="latestData"
          [chartRange]="chartRange"
        ></app-wbgt-line-chart>
      </mat-card>
      <mat-card class="mt-4">
        <mat-card-header>
          <mat-card-title>温度（℃）</mat-card-title>
        </mat-card-header>
        <app-temperature-line-chart
          [data]="latestData"
          [chartRange]="chartRange"
        ></app-temperature-line-chart>
      </mat-card>
      <mat-card class="mt-4">
        <mat-card-header>
          <mat-card-title>湿度（%）</mat-card-title>
        </mat-card-header>
        <app-humidity-line-chart
          [data]="latestData"
          [chartRange]="chartRange"
        ></app-humidity-line-chart>
      </mat-card>
    </div>
    <div  class="w-full" *ngFor="let data of sensorData.wind">
      <mat-card>
        <mat-card-header>
          <mat-card-title>風速（m/s）</mat-card-title>
        </mat-card-header>
        <app-wind-line-chart
          [data]="data"
          [chartRange]="chartRange"
        ></app-wind-line-chart>
      </mat-card>
    </div>
    <div  class="w-full" *ngIf="sensorData.noise">
      <mat-card>
        <mat-card-header>
          <mat-card-title>騒音（dB）</mat-card-title>
        </mat-card-header>
        <app-noise-line-chart
          [data]="sensorData.noise"
          [chartRange]="chartRange"
        ></app-noise-line-chart>
      </mat-card>
      <mat-card class="mt-4">
        <mat-card-header>
          <mat-card-title>振動（dB）</mat-card-title>
        </mat-card-header>
        <app-vibration-line-chart
          [data]="sensorData.noise"
          [chartRange]="chartRange"
        ></app-vibration-line-chart>
      </mat-card>
    </div>
    <div  class="w-full" *ngIf="sensorData.ph">
      <mat-card>
        <mat-card-header>
          <mat-card-title>pH</mat-card-title>
        </mat-card-header>
        <app-ph-line-chart
          [data]="sensorData.ph"
          [chartRange]="chartRange"
        ></app-ph-line-chart>
      </mat-card>
    </div>
  </div>
</div>

<!--
<div>
  <div class="mt-4 flex gap-4">
    <div  class="w-full" *ngFor="let latestData of sensorData.wbgt">
      <mat-card>
        <mat-card-header>
          <mat-card-title>湿度（%）</mat-card-title>
        </mat-card-header>
        <app-humidity-line-chart
          [data]="latestData"
          [chartRange]="chartRange"
        ></app-humidity-line-chart>
      </mat-card>
    </div>
  </div>
</div>
-->
<!--
<div>
  <div class="mt-8 flex gap-4">
    <div
      *ngFor="let latestData of sensorData.wbgt"
      class="site-detail-charts-container"
    >
      <app-site-detail-chart
        [wbgtData]="latestData"
        [chartRange]="chartRange"
        class="grow"
      >
      </app-site-detail-chart>
    </div>
    <div
      *ngFor="let latestData of sensorData.wind"
      class="site-detail-charts-container"
    >
      <app-site-detail-chart [windData]="latestData" [chartRange]="chartRange">
      </app-site-detail-chart>
    </div>
  </div>
</div>
-->
