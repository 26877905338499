import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SiteCreateComponent } from './site-create/site-create.component';
import { SiteDrawingComponent } from './site-drawing/site-drawing.component';
import { SiteListComponent } from './site-list/site-list.component';
import { SiteSensorComponent } from './site-sensor/site-sensor.component';
import { SiteUpdateComponent } from './site-update/site-update.component';

const routes: Routes = [
  {
    path: 'site-create',
    component: SiteCreateComponent,
  },
  {
    path: 'site-update/:id',
    component: SiteUpdateComponent,
  },
  {
    path: 'site-sensor/:id',
    component: SiteSensorComponent,
  },
  {
    path: 'site-drawing/:id',
    component: SiteDrawingComponent,
  },
  {
    path: '',
    component: SiteListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SiteConfigRoutingModule {}
