import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { Account } from '../../classes/account';
import { AppConst } from '../../constants/app-const';
import { UrlConst } from '../../constants/url-const';
import { YesNoDialogData } from '../../interfaces/yes-no-dialog-data';
import { AccountService } from '../../services/account.service';
import { LoadingService } from '../../services/loading.service';
import { SiteService } from '../../services/site.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() sidenav: MatSidenav | null = null;

  account: Account | null = new Account();
  searchName: string = '';
  isOuter: boolean = false;
  isGeneral: boolean = false;

  headerService = inject(HeaderService);

  closeNav$ = this.headerService.closeNav$;

  constructor(
    private matDialog: MatDialog,
    private accountService: AccountService,
    private router: Router,
    private loading: LoadingService,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.closeNav$.subscribe((close) => {
      if (close) {
        this.sidenav?.close();
      } else {
        this.sidenav?.open();
      }
    });

    // this.sidenav?.open();

    this.account = this.accountService.getUser();
    this.isOuter = this.accountService.isOuterGroup();
    this.isGeneral = this.accountService.isGeneralGroup();
  }

  /**
   * サインアウトボタン
   */
  clickSignOut() {
    this.sidenav?.close();

    const dialogData: YesNoDialogData = {
      title: AppConst.YESNO_DIALOG_SIGNOUT_TITLE,
      message: AppConst.YESNO_DIALOG_SIGNOUT_MESSAGE,
      captionNo: AppConst.YESNO_DIALOG_CAPTION_NO,
      captionYes: AppConst.YESNO_DIALOG_CAPTION_YES,
    };

    const dialogRef = this.matDialog.open(YesNoDialogComponent, {
      height: AppConst.YES_NO_DIALOG_HEIGHT,
      width: AppConst.YES_NO_DIALOG_WIDTH,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.signOut();
      } else {
        this.sidenav?.open();
      }
    });
  }

  private async signOut() {
    this.loading.startLoading();
    await this.accountService.signOut();
    this.loading.stopLoading();
    this.router.navigate([UrlConst.PATH_SIGN_IN]);
  }

  clickSearch() {
    this.siteService.onNotifySharedDataChanged(this.searchName);
    if (this.router.url !== UrlConst.SLASH + UrlConst.PATH_SITE_LIST) {
      this.siteService.searchName = this.searchName;
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_LIST]);
    }
  }
}
