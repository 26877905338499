import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConst } from 'src/app/shared/constants/app-const';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { Site } from 'src/app/shared/interfaces/site';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

@Component({
  selector: 'app-digital-signage',
  templateUrl: './digital-signage.component.html',
  styleUrls: ['./digital-signage.component.scss']
})
export class DigitalSignageComponent implements OnInit, OnDestroy{
  private route = inject(ActivatedRoute);
  private logService = inject(AccessLogService);
  private siteService = inject(SiteService);
  private headerService = inject(HeaderService);

  site: Site | null = null
  uuid = '';
  isOffHours = false;

  reloadTimer?: NodeJS.Timeout;

  date = UtilFunctions.now();
  noiseL5 = 0;
  vibrationL10 = 0;

  async ngOnInit(): Promise<void> {
    this.isOffHours = UtilFunctions.isOffHours();

    if (this.route.snapshot.paramMap.get('uuid') !== null) {
      this.headerService.closeNav$.next(true);

      // サイトUUIDを取得
      this.uuid = this.route.snapshot.paramMap.get('uuid')!;

            // アクセスログを追加
      await this.logService.addLog(
        UrlConst.SLASH +
          UrlConst.PATH_DIGITAL_SIGNAGE +
          UrlConst.SLASH +
          this.uuid
      );
    }

    await this.reloadData();

    // RELOAD_INTERVAL毎のページデータリロード
    new Promise(async () => {
      this.reloadTimer = setInterval(async () => {
        await this.reloadData();
      }, AppConst.RELOAD_INTERVAL);
    });

  }

  ngOnDestroy(): void {
    if (this.reloadTimer !== undefined) clearInterval(this.reloadTimer);
    this.headerService.closeNav$.next(false);
  }


  // デジタルサイネージ定期更新
  async reloadData() {
    if (this.uuid !== '') {
      this.site = await this.siteService.getFromUuid(this.uuid);
    }

    if (this.site === null) {
      return;
    }

    const latestSiteData = await this.siteService.getLatestSiteData(
      this.site.id
    );

    if (latestSiteData === null) {
      return;
    }

    if (latestSiteData.sensorData === undefined) {
      return;
    }

    if (latestSiteData.sensorData.noise === undefined) {
      return;
    }

    this.date = latestSiteData.sensorData.noise.date !== undefined ? latestSiteData.sensorData.noise.date : UtilFunctions.now();
    this.noiseL5 = latestSiteData.sensorData.noise.noiseL5 !== undefined ? latestSiteData.sensorData.noise.noiseL5 : 0;
    this.vibrationL10 = latestSiteData.sensorData.noise.vibrationL10 !== undefined ? latestSiteData.sensorData.noise.vibrationL10 : 0;
  }
}
