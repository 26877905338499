import { Sensor } from 'src/app/shared/interfaces/sensor';
import {
  Site,
  SiteDrawingSensorPosition,
} from 'src/app/shared/interfaces/site';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Injectable } from '@angular/core';

import { DrawingSensor } from './drawing-sensor.interface';

@Injectable({
  providedIn: 'root',
})
export class DrawingEditorService {
  constructor(
    private siteService: SiteService,
    private sensorService: SensorService
  ) {}

  async getDrawingSensorsFromSite(site: Site) {
    // 図面未設定
    if (site.drawing === undefined) {
      return this.setDefaultDrawingSensors(site);
    }

    // センサー位置未設定
    if (site.drawing.positions === undefined) {
      return this.setDefaultDrawingSensors(site);
    }

    // 設定されているセンサーの数だけDrawingSensorを作成
    const drawingSensors: DrawingSensor[] = await this.setDefaultDrawingSensors(site);


    const positions = site.drawing.positions;
    for (let position of positions) {
      const sensor = await this.sensorService.get(position.sensorId);

      if (sensor === null) {
        continue;
      }

      // positionNameがあれば採用、なければpositionから推測。それでもなければ'----'を採用。
      const positionName = this.decidePositionName(
        sensor.positionName,
        sensor.position
      );

      // デフォルトセンサーの位置情報を上書き
      const index = drawingSensors.findIndex(s => s.id === sensor.id);
      if (index !== -1) {
        const drawingSensor: DrawingSensor = drawingSensors[index];
        drawingSensor.positionName = positionName

        if (drawingSensor.drawing === undefined) {
          drawingSensor.drawing = {
            x: position.x,
            y: position.y,
            width: 80,
            height: 80,
            fgColor: 'white',
            bgColor: 'red',
          }
        } else {
          drawingSensor.drawing.x = position.x
          drawingSensor.drawing.y = position.y
        }

        drawingSensors[index] = drawingSensor
      }
    }

    return drawingSensors;
  }

  async setDefaultDrawingSensors(site: Site) {
    let sensors: Sensor[] = await this.sensorService.getFromSiteId(site.id);
    sensors = sensors.filter((sensor) => sensor.categoryName === 'WBGT');

    const drawingSensors: DrawingSensor[] = [];
    for (let i = 0; i < sensors.length; i++) {
      const sensor = sensors[i];
      if (sensor.categoryName !== 'WBGT') {
        continue;
      }

      const positionName = this.decidePositionName(
        sensor.positionName,
        sensor.position
      );
      const drawingSensor: DrawingSensor = {
        id: sensor.id,
        positionName: positionName,
        drawing: {
          x: 10,
          y: 10 + i * 80,
          width: 80,
          height: 80,
          fgColor: 'white',
          bgColor: 'red',
        },
      };
      drawingSensors.push(drawingSensor);
    }

    return drawingSensors;
  }

  // positionNameの決定
  // 1. sensor.positionNameがあればそれを採用
  // 2. sensor.positionがあればそれから推測
  decidePositionName(
    positionName: string | undefined,
    position: string | undefined
  ) {
    if (positionName !== undefined) {
      return positionName;
    }

    if (position === undefined) {
      return '----';
    }

    if (position === 'top_floor') {
      return '躯体最上階';
    }

    if (position === 'board') {
      return '朝礼看板';
    }

    return '----';
  }

  // 図面画像をCloud Storageにアップロード
  uploadDrawingImage(siteId: string, file: File) {
    return this.siteService.uploadDrawing(siteId, file);
  }

  // センサー位置情報をCloud Firestoreにアップロード
  updateDrawingPositions(siteId: string, drawingSensors: DrawingSensor[]) {
    const positions: SiteDrawingSensorPosition[] = drawingSensors.map(
      (drawingSensor) => {
        return {
          sensorId: drawingSensor.id,
          x: drawingSensor.drawing!.x,
          y: drawingSensor.drawing!.y,
        };
      }
    );

    return this.siteService.updateDrawingPositions(siteId, positions);
  }

  // 風速センサー用コンパスの角度をアップデート
  updateCompassAngle(site: Site, compassAngle: number) {
    return this.siteService.setCompassAngle(site, compassAngle);
  }

  // 現場に風速センサー設置があるかどうか
  hasWindSensor(site: Site) {
    if (site.sensorRef === undefined) {
      return false;
    }
    if (site.sensorRef?.wind === undefined) {
      return false;
    }
    return site.sensorRef.wind.length > 0;
  }
}
