import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { AutoSignInGuard } from './guards/auto-sign-in.guard';
import { DepAdminGuard } from './guards/dep-admin.guard';
import { RankGuard } from './guards/rank.guard';
import { SysAdminGuard } from './guards/sys-admin.guard';
import { AccountListPageComponent } from './pages/components/account-list-page/account-list-page.component';
import { DownloadAccessLogComponent } from './pages/components/download-access-log/download-access-log.component';
import { DownloadDataComponent } from './pages/components/download-data/download-data.component';
import { ExtractDataPageComponent } from './pages/components/extract-data-page/extract-data-page.component';
import { HomePageComponent } from './pages/components/home-page/home-page.component';
import { SignInPageComponent } from './pages/components/sign-in-page/sign-in-page.component';
import { SignUpPageComponent } from './pages/components/sign-up-page/sign-up-page.component';
import { SiteConfigAlertPageComponent } from './pages/components/site-config-alert-page/site-config-alert-page.component';
import { SiteConfigCreatePageComponent } from './pages/components/site-config-create-page/site-config-create-page.component';
import { SiteConfigDrawingPageComponent } from './pages/components/site-config-drawing-page/site-config-drawing-page.component';
import { SiteConfigPageComponent } from './pages/components/site-config-page/site-config-page.component';
import { SiteConfigQrPageComponent } from './pages/components/site-config-qr-page/site-config-qr-page.component';
import { SiteConfigSensorPageComponent } from './pages/components/site-config-sensor-page/site-config-sensor-page.component';
import { SiteDetailPageComponent } from './pages/components/site-detail-page/site-detail-page.component';
import { SiteListPageComponent } from './pages/components/site-list-page/site-list-page.component';
import { SiteMapPageComponent } from './pages/components/site-map-page/site-map-page.component';
import { UrlConst } from './shared/constants/url-const';
import { DigitalSignageComponent } from './pages/digital-signage/digital-signage.component';
import { DownloadPdfComponent } from './pages/components/download-pdf/download-pdf.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  { path: UrlConst.PATH_SIGN_IN, component: SignInPageComponent },
  {
    path: UrlConst.PATH_SIGN_UP,
    component: SignUpPageComponent,
    canActivate: [AuthGuard, RankGuard],
  },
  {
    path: UrlConst.PATH_ACCOUNT_LIST,
    component: AccountListPageComponent,
    canActivate: [AuthGuard, RankGuard],
  },
  {
    path: UrlConst.PATH_SITE_LIST,
    component: SiteListPageComponent,
    canActivate: [AuthGuard, DepAdminGuard],
  },
  {
    path: UrlConst.PATH_SITE_DETAIL,
    component: SiteDetailPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_SITE_DETAIL + '/:id',
    component: SiteDetailPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_SITE_DETAIL_FROM_UUID + '/:uuid',
    component: SiteDetailPageComponent,
    canActivate: [AutoSignInGuard],
  },
  {
    path: UrlConst.PATH_SITE_MAP,
    component: SiteMapPageComponent,
    canActivate: [AuthGuard, DepAdminGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG,
    loadChildren: () =>
      import('./pages/site-config/site-config.module').then(
        (m) => m.SiteConfigModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG_CREATE,
    component: SiteConfigCreatePageComponent,
    canActivate: [AuthGuard, SysAdminGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG_CREATE + '/:id',
    component: SiteConfigCreatePageComponent,
    canActivate: [AuthGuard, SysAdminGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG_SENSOR,
    component: SiteConfigSensorPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG_SENSOR + '/:id',
    component: SiteConfigSensorPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG_DRAWING,
    component: SiteConfigDrawingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG_DRAWING + '/:id',
    component: SiteConfigDrawingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG_ALERT,
    component: SiteConfigAlertPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_SITE_CONFIG_ALERT + '/:id',
    component: SiteConfigAlertPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_EXTRACT_DATA,
    component: ExtractDataPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_DOWNLOAD_DATA,
    component: DownloadDataComponent,
    canActivate: [AuthGuard, SysAdminGuard],
  },
  {
    path: UrlConst.PATH_DOWNLOAD_ACCESS_LOG,
    component: DownloadAccessLogComponent,
    canActivate: [AuthGuard, SysAdminGuard],
  },
  {
    path: UrlConst.PATH_DOWNLOAD_PDF + '/:filename',
    component: DownloadPdfComponent,
    canActivate: [AuthGuard],
  },
  {
    path: UrlConst.PATH_DIGITAL_SIGNAGE + '/:uuid',
    component: DigitalSignageComponent,
    canActivate: [AutoSignInGuard],
  },
  { path: '**', redirectTo: UrlConst.SLASH },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
