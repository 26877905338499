import {
  LatestSiteData,
  NoiseLatestSiteData,
  PhLatestSiteData,
  WbgtLatestSiteData,
  WindLatestSiteData,
} from 'src/app/shared/interfaces/latest-site-data';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';

import { SiteDetailAlertService } from './site-detail-alert.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { Site } from 'src/app/shared/interfaces/site';

@Component({
  selector: 'app-site-detail-alert',
  templateUrl: './site-detail-alert.component.html',
  styleUrls: ['./site-detail-alert.component.scss'],
})
export class SiteDetailAlertComponent implements OnChanges {
  @Input() site: Site | null = null;
  @Input() latestSiteData: LatestSiteData | null = null;

  private accountService = inject(AccountService);

  // 夏期(5〜9月)かどうか
  isSummer = UtilFunctions.isSummer();
  // 冬期(12〜2月)かどうか
  isWinter = this.accountService.isWestUser()
    ? UtilFunctions.isWinter()
    : false;

  // WBGT | Temperatureアラート日時
  wbgtAlertDate: string | undefined = undefined;
  // WBGT 厳重警戒アラート日時
  wbgtHighAlertDate: string | undefined = undefined;
  // Temperatureアラート日時
  todayTemperatureAlertDate: string | undefined = undefined;
  // Temperatureアラート日時
  todayTemperatureAlertValue: number | undefined = undefined;
  // Temperatureアラート日時
  tomorrowTemperatureAlertDate: string | undefined = undefined;
  // WINDアラートデータ
  windAlertData: WindLatestSiteData | null = null;
  // NOISEアラートデータ
  noiseAlertData: NoiseLatestSiteData | null = null;
  // PHアラートデータ
  phAlertData: PhLatestSiteData | null = null;

  constructor(private siteDetailAlertService: SiteDetailAlertService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // センサーデータがない場合は何もしない
    if (this.latestSiteData?.sensorData === undefined) {
      return;
    }

    // WINDセンサーデータ取得
    const windSensorData: WindLatestSiteData[] | undefined =
      this.latestSiteData.sensorData.wind;

    // WBGTアラート日付取得
    this.wbgtAlertDate = this.latestSiteData.firstAlertDate;
    // WBGT厳重警戒アラート日付取得
    this.wbgtHighAlertDate = this.latestSiteData.firstHighAlertDate;

    // 危険 > 厳重警戒という優先順位
    if (!!this.wbgtAlertDate) {
      this.wbgtHighAlertDate = undefined;
    } else if (!!this.wbgtHighAlertDate) {
      this.wbgtAlertDate = undefined;
    }

    if (
      this.isWinter &&
      this.latestSiteData.todayTemperatureAlertDate !== undefined &&
      UtilFunctions.isValidDate(this.latestSiteData.todayTemperatureAlertDate)
    ) {
      // Temperatureアラート日付取得
      this.todayTemperatureAlertDate =
        this.latestSiteData.todayTemperatureAlertDate;
      this.todayTemperatureAlertValue =
        this.latestSiteData.todayTemperatureAlertValue;
    }
    if (
      this.isWinter &&
      this.latestSiteData.tomorrowTemperatureAlertDate !== undefined &&
      UtilFunctions.isValidDate(
        this.latestSiteData.tomorrowTemperatureAlertDate
      )
    ) {
      this.tomorrowTemperatureAlertDate =
        this.latestSiteData.tomorrowTemperatureAlertDate;
    }
    // WINDアラートデータ取得
    this.windAlertData = this.siteDetailAlertService.getWindAlertData(
      this.latestSiteData
    );

    // NOISEアラートデータ取得
    this.noiseAlertData = this.siteDetailAlertService.getNoiseAlertData(
      this.latestSiteData
    );

    // PHアラートデータ取得
    this.phAlertData = this.siteDetailAlertService.getPhAlertData(
      this.latestSiteData
    );
  }
}
