import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-drawing-compass-editor',
  templateUrl: './drawing-compass-editor.component.html',
  styleUrls: ['./drawing-compass-editor.component.scss'],
})
export class DrawingCompassEditorComponent implements OnInit {
  @Input() angle: number = 0;
  @Output() compassAngle: EventEmitter<number> = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.get('angle')?.setValue(this.angle);
  }

  form = this.fb.group({
    angle: [0],
  });

  onChangeAngle(event: Event) {
    // 角度がマイナスの場合は360を足して正の値にする
    if (this.form.get('angle')!.value! < 0) {
      this.form.get('angle')?.setValue(360 + this.form.get('angle')?.value!);
    }

    // 角度が360を超えた場合は360を引いて360未満に変換する
    if (this.form.get('angle')!.value! >= 360) {
      this.form.get('angle')?.setValue(this.form.get('angle')?.value! - 360);
    }

    // 親コンポーネントに角度を報告
    this.compassAngle.emit(this.form.get('angle')!.value!);
  }
}
