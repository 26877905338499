import { Component, Inject, OnInit, inject } from '@angular/core';

import { UtilFunctions } from '../../utils/util-functions';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-usage-guide-dialog',
  templateUrl: './usage-guide-dialog.component.html',
  styleUrls: ['./usage-guide-dialog.component.scss'],
})
export class UsageGuideDialogComponent implements OnInit {
  constructor() {}

  private accountService = inject(AccountService);

  isSummer = false;
  isWinter = false;

  ngOnInit(): void {
    this.isSummer = UtilFunctions.isSummer();
    this.isWinter = this.accountService.isWestUser()
      ? UtilFunctions.isWinter()
      : false;
  }
}
