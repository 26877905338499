import { firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { Router } from '@angular/router';

import { AppConst } from '../../constants/app-const';
import { UrlConst } from '../../constants/url-const';
import { SensorStatus } from '../../interfaces/sensor-status';
import { Site } from '../../interfaces/site';
import { GoogleMapApiService } from '../../services/google-map-api.service';
import { SensorService } from '../../services/sensor.service';
import { SiteService } from '../../services/site.service';
import { UtilFunctions } from '../../utils/util-functions';

@Component({
  selector: 'app-wind-map',
  templateUrl: './wind-map.component.html',
  styleUrls: ['./wind-map.component.scss'],
})
export class WindMapComponent implements OnInit {
  apiLoaded: boolean = false;
  mapOptions: google.maps.MapOptions = {
    mapId: AppConst.GOOGLE_MAP_ID,
    zoom: 9,
    center: { lat: 34.91663711768134, lng: 135.5000950822929 },
    disableDefaultUI: false,
  };
  mapStyles = [
    {
      stylers: [
        {
          saturation: -100,
        },
      ],
    },
  ];

  marks: {
    position: google.maps.LatLngLiteral;
    options?: google.maps.MarkerOptions;
    icon?: google.maps.Symbol;
    label?: google.maps.MarkerLabel;
    title?: string;
    siteId?: string;
  }[] = [];

  constructor(
    private http: HttpClient,
    private geocoder: MapGeocoder,
    private siteService: SiteService,
    private sensorService: SensorService,
    private router: Router,
    private mapApiService: GoogleMapApiService
  ) {}

  async ngOnInit(): Promise<void> {
    this.apiLoaded = await firstValueFrom(this.mapApiService.setApiUrl());
    if (this.apiLoaded) {
      this.setMarkers();
    }
  }

  async setMarkers(): Promise<void> {
    const windSensors = await this.sensorService.getAllWind();
    if (windSensors === null) return;

    for (let windSensor of windSensors!) {
      if (windSensor.siteId === undefined) {
        continue;
      }
      const site: Site | null = await this.siteService.get(windSensor.siteId!);
      if (site === null) {
        continue;
      }

      const latestSiteData = await this.siteService.getLatestSiteData(site.id);
      if (latestSiteData?.windSpeed === undefined) {
        continue;
      }

      let status: SensorStatus | null = null;
      status = UtilFunctions.getWindStatus(latestSiteData?.windSpeed);
      if (status === null) {
        status = AppConst.WIND_SENSOR_STATUS[3];
      }

      let url = '';
      if (status!.no) {
        url = `assets/images/wind-${status!.no}/${
          latestSiteData.windDirection
        }.png`;
      } else {
        url = 'assets/images/wind-99/${latestSiteData.windDirection}.png';
      }

      this.marks.push({
        siteId: site.id,
        position: {
          lat: site.geopoint?.latitude!,
          lng: site.geopoint?.longitude!,
        },
        options: {
          icon: {
            url: url,
          },
        } as google.maps.MarkerOptions,
        title: `${site.name}\n${windSensor.position}: ${
          latestSiteData?.windSpeed
            ? latestSiteData?.windSpeed?.toFixed(1)
            : '-'
        } m/s`,
      });
    }
  }

  onClickMarker(siteId: string | undefined) {
    this.router.navigate([
      UrlConst.SLASH + UrlConst.PATH_SITE_DETAIL + UrlConst.SLASH + siteId,
    ]);
  }
}
