<div class="site-config-drawing-compass-container">
  <mat-card class="site-config-drawing-compass-card">
    <div class="site-config-drawing-compass-angle">
      <div class="site-config-drawing-compass-annotation">
        北の向きを調整してください
      </div>
      <div class="site-config-drawing-compass-form">
        <form [formGroup]="form">
          <mat-form-field>
            <input
              matInput
              type="number"
              id="angle"
              name="angle"
              formControlName="angle"
              appearance="fill"
              value="0"
              (change)="onChangeAngle($event)"
            />
          </mat-form-field>
        </form>
      </div>
    </div>
    <div>
      <img
        src="assets/images/compass_board.png"
        [style.transform]="'rotate(' + angle + 'deg)'"
        class="site-config-drawing-compass-board"
      />
    </div>
  </mat-card>
</div>
