<div class="w-full flex gap-2 justify-center">
  <div>
    <div class="w-full p-2 flex gap-2 justify-center">
      <button mat-raised-button color="primary" (click)="onUploadClick()">
        図面画像アップロード
      </button>
    </div>
    <div class="p-4 w-full flex justify-center">
      <svg
        id="svg"
        [attr.width]="svgWidth"
        [attr.height]="svgHeight"
        xmlns="http://www.w3.org/2000/svg"
      >
        <style>
          /* 背景画像を設定する */
          svg {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        </style>
        <g
          *ngFor="let drawingSensor of drawingSensors"
          (mousedown)="onMouseDown($event, drawingSensor)"
          (mousemove)="onMouseMove($event, drawingSensor)"
          (mouseup)="endMouseUp()"
        >
          <rect
            [attr.x]="drawingSensor.drawing!.x"
            [attr.y]="drawingSensor.drawing!.y"
            [attr.width]="drawingSensor.drawing!.width"
            [attr.height]="drawingSensor.drawing!.height"
            [attr.fill]="drawingSensor.drawing!.bgColor"
            [class.hide]="!isImageLoaded"
            [class.show]="isImageLoaded"
          ></rect>
          <text
            [attr.x]="
              drawingSensor.drawing!.x + drawingSensor.drawing!.width / 2
            "
            [attr.y]="
              drawingSensor.drawing!.y + drawingSensor.drawing!.height / 3
            "
            text-anchor="middle"
            dominant-baseline="central"
            [attr.fill]="drawingSensor.drawing!.fgColor"
            [class.hide]="!isImageLoaded"
            [class.show]="isImageLoaded"
          >
            {{ drawingSensor.positionName }}
          </text>
        </g>
      </svg>
    </div>
  </div>

  <ng-container *ngIf="hasWindSensor">
    <div class="basis-1/6">
      <app-drawing-compass-editor
        [angle]="compassAngle"
        (compassAngle)="onCompassAngle($event)"
      ></app-drawing-compass-editor>
    </div>
  </ng-container>
</div>

<div class="w-full">
  <div class="w-full p-2 flex gap-2 justify-center">
    <button mat-raised-button color="accent" (click)="onCancel()">
      キャンセル
    </button>
    <button mat-raised-button color="primary" (click)="onSave()">
      保存して一覧に戻る
    </button>
  </div>
</div>
