<div>
  <mat-card class="status-signal-20">
    <div class="site-detail-alert-container">
      明日の温度予報は　
      <span class="site-detail-alert-status"> 5℃以下 </span>
      です
      <span
        class="ml-32 underline underline-offset-2 text-blue-900 cursor-pointer"
        (click)="onTomorrowWnClick()"
        *ngIf="!isOuter"
      >
        詳細情報
      </span>
    </div>
  </mat-card>
</div>
