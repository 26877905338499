<!-- Sidenav container -->
<mat-sidenav-container>
  <!-- Side navigation -->
  <mat-sidenav
    #sidenav
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <app-sidenav *ngIf="!isSignInPage() && !isDigitalSignagePage()"></app-sidenav>
  </mat-sidenav>

  <!-- Main contents -->
  <mat-sidenav-content>
    <!-- Loading Spinner -->
    <app-loading></app-loading>

    <!-- Wrapper of body -->
    <div class="body-wrapper">
      <!-- Header -->
      <app-header [sidenav]="sidenav" *ngIf="!isSignInPage() && !isDigitalSignagePage()"></app-header>

      <!-- Router -->
      <router-outlet></router-outlet>

      <!-- Footer -->
      <app-footer *ngIf="!isDigitalSignagePage()"></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
