import { Sensor } from 'src/app/shared/interfaces/sensor';
import { SensorService } from 'src/app/shared/services/sensor.service';

import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DocumentData, DocumentReference } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-sensor-ph-range',
  templateUrl: './sensor-ph-range.component.html',
  styleUrls: ['./sensor-ph-range.component.scss'],
})
export class SensorPhRangeComponent implements OnInit {
  @Input() sensorRef: DocumentReference<DocumentData> | undefined;
  @Output() onSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  sensorService = inject(SensorService);
  fb = inject(FormBuilder);

  sensor: Sensor | undefined = undefined;

  form = this.fb.group({
    minPh: ['', [Validators.required, Validators.min(1), Validators.max(14)]],
    maxPh: ['', [Validators.required, Validators.min(1), Validators.max(14)]],
  });
  get minPh() {
    return this.form.get('minPh');
  }
  get maxPh() {
    return this.form.get('maxPh');
  }

  constructor() {}

  async ngOnInit(): Promise<void> {
    if (this.sensorRef !== undefined) {
      this.sensor = await this.sensorService.getFromReference(this.sensorRef);

      if (this.sensor !== undefined) {
        if (this.sensor.minPh !== undefined) {
          this.minPh?.setValue(this.sensor.minPh?.toString());
        }
        if (this.sensor.maxPh !== undefined) {
          this.maxPh?.setValue(this.sensor.maxPh?.toString());
        }
      }
    }
  }

  async onSubmit() {
    try {
      await this.sensorService.updatePhRange(
        this.sensor?.id!,
        Number(this.minPh?.value),
        Number(this.maxPh?.value)
      );
      this.onSaved.emit(true);
    } catch (error) {
      console.error(error);
    }
  }
}
