import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { QrCodeDialogData } from '../../interfaces/qj-code-dialog-data';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss'],
})
export class QrCodeDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QrCodeDialogData,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {}

  copyToClipboard() {
    const parentElement = this.elementRef.nativeElement
      .querySelector('canvas')
      .toDataURL('image/png');

    const blobData = this.convertBase64ToBlob(parentElement);
    const blob = new Blob([blobData], { type: 'image/png' });
    navigator.clipboard.write([
      new ClipboardItem({
        'image/png': blob,
      }),
    ]);
  }

  downloadImage() {
    const parentElement = this.elementRef.nativeElement
      .querySelector('canvas')
      .toDataURL('image/png');

    const blobData = this.convertBase64ToBlob(parentElement);
    const blob = new Blob([blobData], { type: 'image/png' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // name of the file
    link.download = 'qrcode';
    link.click();
  }

  convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(';base64,');
    // hold the content type
    const imageType = parts[0].split(':')[1];
    // decode base64 string
    const decodedData = window.atob(parts[1]);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }
}
