import { AppConst } from 'src/app/shared/constants/app-const';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.scss'],
})
export class SignInPageComponent implements OnInit {
  title = AppConst.SIGN_IN_CARD_TITLE;
  error: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public loadingService: LoadingService,
    private accountService: AccountService,
    private router: Router,
    private logService: AccessLogService
  ) {}

  signInForm = this.formBuilder.group({
    user: ['', Validators.required],
    password: ['', Validators.required],
  });

  get user(): AbstractControl | null {
    return this.signInForm.get('user');
  }
  get password(): AbstractControl | null {
    return this.signInForm.get('password');
  }

  ngOnInit(): void {
    SessionStorageService.removeItem(AppConst.SESSION_KEY_USER);
    SessionStorageService.removeItem(AppConst.SESSION_KEY_GROUP);
  }

  async clickSignInButton() {
    this.loadingService.startLoading();

    try {
      await this.accountService.signIn({
        user: this.user?.value,
        password: this.password?.value,
      });

      this.error = false;
      await this.logService.addLog(UrlConst.SLASH + UrlConst.PATH_SIGN_IN);
      if (this.accountService.isOuterGroup()) {
        const siteId = this.accountService.account!.user.replace('p', '');
        this.router.navigate([
          UrlConst.SLASH + UrlConst.PATH_SITE_DETAIL + UrlConst.SLASH + siteId,
        ]);
      } else if (this.accountService.isGeneralGroup()) {
        // 一般ユーザ
        // 一般ユーザならアカウントIDは現場IDが普通
        // アカウント情報からdefaultSiteが取得できるならそっちの現場に飛ばす
        if (this.accountService.account?.defaultSite) {
          this.router.navigate([
            UrlConst.SLASH +
              UrlConst.PATH_SITE_DETAIL +
              UrlConst.SLASH +
              this.accountService.account!.defaultSite,
          ]);
        } else {
          // それ以外は通常の一般ユーザ
          this.router.navigate([
            UrlConst.SLASH +
              UrlConst.PATH_SITE_DETAIL +
              UrlConst.SLASH +
              this.accountService.account!.user,
          ]);
        }
      } else {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_MAP]);
      }
    } catch (error) {
      console.error(error);
      this.error = true;
    } finally {
      this.loadingService.stopLoading();
    }
  }
}
