<mat-card>
  <mat-card-content>
    <div class="flex gap-4 justify-center">
      <div>
        <app-site-detail-drawing-viewer
          *ngIf="site"
          [site]="site"
        ></app-site-detail-drawing-viewer>
      </div>
      <div class="basis-1/12">
        <app-site-detail-drawing-compass-viewer
          [site]="site"
        ></app-site-detail-drawing-compass-viewer>
      </div>
    </div>
  </mat-card-content>
</mat-card>
