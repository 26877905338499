import {
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { map, Observable, Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  deleteField,
  docData,
  Firestore,
} from '@angular/fire/firestore';

import { AppConst } from '../constants/app-const';
import { LatestBoardData } from '../interfaces/latest-board-data';
import {
  LatestSiteData,
  NoiseLatestSiteData,
  PhLatestSiteData,
  WbgtLatestSiteData,
  WindLatestSiteData,
} from '../interfaces/latest-site-data';
import { LatestTopFloorData } from '../interfaces/latest-top-floor-data';
import { LatestWindData } from '../interfaces/latest-wind-data';
import { Sensor } from '../interfaces/sensor';
import {
  Site,
  SiteDrawingPosition,
  SiteDrawingSensorPosition,
  SiteSensorRef,
} from '../interfaces/site';
import { SiteUpdateRequest } from '../interfaces/site-update-request';
import { UtilFunctions } from '../utils/util-functions';
import { SensorService } from './sensor.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  sites: Site[] = [];
  searchName: string = '';

  // 検索窓変更監視
  sharedDataSource = new Subject<string>();
  searchName$ = this.sharedDataSource.asObservable();

  private accountService = inject(AccountService);

  constructor(
    private firestore: Firestore,
    private sensorService: SensorService
  ) {}

  async create(request: SiteUpdateRequest) {
    await this.createSite(request);
    await this.createLatestSiteData(request);
  }

  async createSite(request: SiteUpdateRequest) {
    await setDoc(doc(this.firestore, 'sites', request.id), request);
  }

  async createLatestSiteData(request: SiteUpdateRequest) {
    const latestSiteData: LatestSiteData = {
      id: request.id,
      organizationId: request.organizationId,
      name: request.name,
      managementDepartmentId: request.managementDepartmentId,
      managementDepartmentName: request.managementDepartmentName,
    };
    await setDoc(
      doc(this.firestore, 'latest_site_data', request.id),
      latestSiteData
    );
  }

  async update(request: SiteUpdateRequest) {
    await this.updateSite(request);
    await this.updateLatestSiteData(request);
  }

  async updateSite(request: SiteUpdateRequest) {
    await updateDoc(doc(this.firestore, 'sites', request.id), { ...request });
  }

  async updateLatestSiteData(request: SiteUpdateRequest) {
    const latestSiteData: LatestSiteData = {
      id: request.id,
      organizationId: request.organizationId,
      name: request.name,
      managementDepartmentId: request.managementDepartmentId,
      managementDepartmentName: request.managementDepartmentName,
    };
    await updateDoc(doc(this.firestore, 'latest_site_data', request.id), {
      ...latestSiteData,
    });
  }

  async updateLatestTopFloorData(id: string, request: LatestTopFloorData) {
    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      ...request,
    });
  }

  async updateLatestBoardData(id: string, request: LatestBoardData) {
    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      ...request,
    });
  }

  async updateLatestWindData(id: string, request: LatestWindData) {
    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      ...request,
    });
  }

  async updateLatestWbgtSensorData(id: string, request: WbgtLatestSiteData) {
    const latest = await this.getLatestSiteData(id);
    if (latest === null) {
      return;
    }

    const data: {
      wbgt?: WbgtLatestSiteData[];
      wind?: WindLatestSiteData[];
      noise?: NoiseLatestSiteData;
      ph?: PhLatestSiteData;
    } = {};
    if (latest.sensorData === undefined) {
      data.wbgt = [request];
    } else {
      if (latest.sensorData.wbgt !== undefined) {
        data.wbgt = latest.sensorData.wbgt;

        const i = data.wbgt.findIndex((wbgt) => wbgt.id === request.id);
        if (i === -1) {
          data.wbgt.push(request);
        } else {
          data.wbgt[i] = request;
        }
      } else {
        data.wbgt = [request];
      }
      if (latest.sensorData.wind !== undefined) {
        data.wind = latest.sensorData.wind;
      }
      if (latest.sensorData.noise !== undefined) {
        data.noise = latest.sensorData.noise;
      }
      if (latest.sensorData.ph !== undefined) {
        data.ph = latest.sensorData.ph;
      }
    }

    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      sensorData: data,
    });
  }

  async updateLatestWindSensorData(id: string, request: WindLatestSiteData) {
    const latest = await this.getLatestSiteData(id);
    if (latest === null) {
      return;
    }

    const data: {
      wbgt?: WbgtLatestSiteData[];
      wind?: WindLatestSiteData[];
      noise?: NoiseLatestSiteData;
      ph?: PhLatestSiteData;
    } = {};
    if (latest.sensorData === undefined) {
      data.wind = [request];
    } else {
      if (latest.sensorData.wind !== undefined) {
        data.wind = latest.sensorData.wind;

        const i = data.wind.findIndex((wind) => wind.id === request.id);
        if (i === -1) {
          data.wind.push(request);
        } else {
          data.wind[i] = request;
        }
      } else {
        data.wind = [request];
      }
      if (latest.sensorData.wbgt !== undefined) {
        data.wbgt = latest.sensorData.wbgt;
      }
      if (latest.sensorData.noise !== undefined) {
        data.noise = latest.sensorData.noise;
      }
      if (latest.sensorData.ph !== undefined) {
        data.ph = latest.sensorData.ph;
      }
    }

    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      sensorData: data,
    });
  }

  async updateLatestNoiseSensorData(id: string, request: NoiseLatestSiteData) {
    const latest = await this.getLatestSiteData(id);
    if (latest === null) {
      return;
    }

    const data: {
      wbgt?: WbgtLatestSiteData[];
      wind?: WindLatestSiteData[];
      noise?: NoiseLatestSiteData;
      ph?: PhLatestSiteData;
    } = {};

    data.noise = request;

    if (latest.sensorData?.noise !== undefined) {
      data.noise = latest.sensorData.noise;
    }
    if (latest.sensorData?.wbgt !== undefined) {
      data.wbgt = latest.sensorData.wbgt;
    }
    if (latest.sensorData?.wind !== undefined) {
      data.wind = latest.sensorData.wind;
    }
    if (latest.sensorData?.ph !== undefined) {
      data.ph = latest.sensorData.ph;
    }

    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      sensorData: data,
    });
  }

  async updateLatestPhSensorData(id: string, request: PhLatestSiteData) {
    const latest = await this.getLatestSiteData(id);
    if (latest === null) {
      return;
    }

    const data: {
      wbgt?: WbgtLatestSiteData[];
      wind?: WindLatestSiteData[];
      noise?: NoiseLatestSiteData;
      ph?: PhLatestSiteData;
    } = {};

    console.log(request);
    data.ph = request;

    if (latest.sensorData?.wbgt !== undefined) {
      data.wbgt = latest.sensorData.wbgt;
    }
    if (latest.sensorData?.wind !== undefined) {
      data.wind = latest.sensorData.wind;
    }
    if (latest.sensorData?.noise !== undefined) {
      data.noise = latest.sensorData.noise;
    }

    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      sensorData: data,
    });
  }

  findAll(): Observable<Site[]> {
    const q = query(collection(this.firestore, 'sites'), orderBy('name'));
    return collectionData(q).pipe(
      map((dataArray) => {
        return dataArray.map((data) => this.doc2object(data));
      })
    );
  }

  findAllWithOrganization(organizationId: string): Observable<Site[]> {
    const q = query(
      collection(this.firestore, 'sites'),
      where('organizationId', '==', organizationId)
    );
    return collectionData(q).pipe(
      map((dataArray) => {
        return dataArray.map((data) => this.doc2object(data));
      })
    );
  }

  async getAll() {
    const colRef = collection(this.firestore, 'sites');
    const q = query(colRef, orderBy('name'));
    const snapshot = await getDocs(q);

    let sites: Site[] = [];
    snapshot.docs.forEach((doc) => {
      sites.push(this.doc2object(doc.data()));
    });
    this.sites = sites;
    return sites;
  }

  async getAllWithOrganization() {
    const organizationId = this.accountService.getOrganizationId();
    const colRef = collection(this.firestore, 'sites');
    const q = query(
      colRef,
      where('organizationId', '==', organizationId),
      orderBy('name')
    );
    const snapshot = await getDocs(q);

    let sites: Site[] = [];
    snapshot.docs.forEach((doc) => {
      sites.push(this.doc2object(doc.data()));
    });
    this.sites = sites;
    return sites;
  }

  async getAllLatestSiteData() {
    const q = query(
      collection(this.firestore, 'latest_site_data'),
      orderBy('managementDepartmentId', 'asc')
    );
    const snapshot = await getDocs(q);

    let latestSiteData: LatestSiteData[] = [];
    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();
        latestSiteData.push(
          await this.createLatestSiteDataFromDocumentData(_data)
        );
      }
    }
    return latestSiteData;
  }

  async getAllLatestSiteDataWithOrganization(organizationId: string) {
    const q = query(
      collection(this.firestore, 'latest_site_data'),
      where('organizationId', '==', organizationId)
    );
    const snapshot = await getDocs(q);

    let latestSiteData: LatestSiteData[] = [];
    if (snapshot.docs.length) {
      for (let doc of snapshot.docs) {
        const _data = doc.data();
        latestSiteData.push(
          await this.createLatestSiteDataFromDocumentData(_data)
        );
      }
    }
    return latestSiteData;
  }

  async getLatestSiteData(id: string): Promise<LatestSiteData | null> {
    const snapshot = await getDoc(doc(this.firestore, 'latest_site_data', id));

    if (snapshot.exists()) {
      const _data = snapshot.data();
      return await this.createLatestSiteDataFromDocumentData(_data);
    } else {
      return null;
    }
  }

  async getLatestSiteDataWithOrganization(
    id: string,
    organizationId: string
  ): Promise<LatestSiteData | null> {
    const snapshot = await getDoc(doc(this.firestore, 'latest_site_data', id));

    if (snapshot.exists()) {
      const _data = snapshot.data();
      if (_data['organizationId'] !== organizationId) {
        return null;
      }
      return await this.createLatestSiteDataFromDocumentData(_data);
    } else {
      return null;
    }
  }

  async createLatestSiteDataFromDocumentData(data: DocumentData) {
    const latestSiteData: LatestSiteData = {
      id: data['id'],
      name: data['name'],
      managementDepartmentId: data['managementDepartmentId'],
      managementDepartmentName: data['managementDepartmentName'],
    };

    // WBGTアラート日時取得（当日のみ有効）
    if (
      'firstAlertDate' in data &&
      data['firstAlertDate'] !== '' &&
      UtilFunctions.isTodayFromString(data['firstAlertDate'])
    ) {
      latestSiteData.firstAlertDate = data['firstAlertDate'];
    }
    if (
      'firstHighAlertDate' in data &&
      data['firstHighAlertDate'] !== '' &&
      UtilFunctions.isTodayFromString(data['firstHighAlertDate'])
    ) {
      latestSiteData.firstHighAlertDate = data['firstHighAlertDate'];
    }

    // 気温アラート日付
    // プッシュ通知CRONのどさくさで設定される
    // 7:30時点で5度以下であればtodayTemperatureAlertDateが設定される
    // todayTemperatureAlertDate にはその時の気温が入っている
    if (
      'todayTemperatureAlertDate' in data &&
      data['todayTemperatureAlertDate'] !== '' &&
      UtilFunctions.isValidDate(data['todayTemperatureAlertDate'])
    ) {
      latestSiteData.todayTemperatureAlertDate =
        data['todayTemperatureAlertDate'];
      latestSiteData.todayTemperatureAlertValue =
        data['todayTemperatureAlertValue'];
    }
    // 気温アラート日付
    // プッシュ通知CRONのどさくさで設定される
    // 11時時点で明日(7時から18時未満)の予想気温が5度以下であればtomorrowTemperatureAlertDateが設定される
    if (
      'tomorrowTemperatureAlertDate' in data &&
      data['tomorrowTemperatureAlertDate'] !== '' &&
      UtilFunctions.isValidDate(data['tomorrowTemperatureAlertDate'])
    ) {
      latestSiteData.tomorrowTemperatureAlertDate =
        data['tomorrowTemperatureAlertDate'];
    }
    // 風速アラート日時取得
    if ('firstWindAlertDate' in data)
      latestSiteData.firstWindAlertDate = data['firstWindAlertDate'];
    if (
      'windAttentionStatusDate' in data &&
      data['windAttentionStatusDate'] !== ''
    ) {
      latestSiteData.windAttentionStatusDate = data['windAttentionStatusDate'];
      const status = AppConst.WIND_SENSOR_STATUS[2];

      latestSiteData.windStatus = status.label;
      latestSiteData.windStatusNo = status.no;
      latestSiteData.siteWindStatus = status.label;
      latestSiteData.siteWindStatusNo = status.no;
    }
    if ('windAlertStatusDate' in data && data['windAlertStatusDate'] !== '') {
      latestSiteData.windAlertStatusDate = data['windAlertStatusDate'];
      const status = AppConst.WIND_SENSOR_STATUS[4];

      latestSiteData.windStatus = status.label;
      latestSiteData.windStatusNo = status.no;
      latestSiteData.siteWindStatus = status.label;
      latestSiteData.siteWindStatusNo = status.no;
    }

    // 騒音アラート日時を取得
    if (
      'noiseAlertDate' in data &&
      UtilFunctions.isValidDate(data['noiseAlertDate'])
    ) {
      latestSiteData.noiseAlertDate = data['noiseAlertDate'];
    }
    // 振動アラート日時を取得
    if (
      'vibrationAlertDate' in data &&
      UtilFunctions.isValidDate(data['vibrationAlertDate'])
    ) {
      latestSiteData.vibrationAlertDate = data['vibrationAlertDate'];
    }

    // pHアラート日時を取得
    if (
      'minPhAlertDate' in data &&
      UtilFunctions.isTodayFromString(data['minPhAlertDate'])
    ) {
      latestSiteData.minPhAlertDate = data['minPhAlertDate'];
    }

    if (
      'maxPhAlertDate' in data &&
      UtilFunctions.isTodayFromString(data['maxPhAlertDate'])
    ) {
      latestSiteData.maxPhAlertDate = data['maxPhAlertDate'];
    }

    ////////////////////////////////////////////////////////////////////////////////
    // 新方式センサーデータ取得
    if ('sensorData' in data) {
      latestSiteData.sensorData = data['sensorData'];

      ////////////////////////////////////////////////////////////////////////////////
      // 新方式WBGTデータ取得
      if (
        latestSiteData.sensorData !== undefined &&
        latestSiteData.sensorData.wbgt !== undefined
      ) {
        // WBGTデータ取得
        const wbgtArray = latestSiteData.sensorData.wbgt;

        // WBGTステータス用
        const siteWbgtStatus: {
          date?: string;
          wbgt?: number;
          statusNo?: string;
          status?: string;
        } = {};

        // 温度ステータス用
        const siteTemperatureStatus: {
          date?: string;
          temperature?: number;
          statusNo?: string;
          status?: string;
        } = {};

        for (let i = 0; i < wbgtArray.length; i++) {
          const element = wbgtArray[i];

          // WBGTステータス取得
          const wbgtStatus = UtilFunctions.getStatusWithDate(
            element.wbgt!,
            element.date!
          );

          element.statusNo = wbgtStatus.no;
          element.status = wbgtStatus.label;
          wbgtArray[i] = element;

          if (i === 0) {
            siteWbgtStatus.statusNo = element.statusNo;
            siteWbgtStatus.status = element.status;

            if (element.statusNo === AppConst.SENSOR_STATUS_STOPPED) {
              siteWbgtStatus.wbgt = undefined;
              siteWbgtStatus.date = undefined;
            } else {
              siteWbgtStatus.wbgt = element.wbgt;
              siteWbgtStatus.date = element.date;
            }
          } else {
            // 2つ目以降で停止状態ではない場合
            if (element.statusNo !== AppConst.SENSOR_STATUS_STOPPED) {
              // 現場としてのステータス入れ替え規則
              // 1回目と2回目で正常にデータがとれている場合は、WBGTの値が大きい方を採用する
              // 1回目が正常で2回目が異常の場合は、1回目の値を採用する
              // 1回目が異常で2回目が正常の場合は、2回目の値を採用する
              // 1回目と2回目が異常の場合は、異常とする
              if (
                siteWbgtStatus.wbgt !== undefined &&
                element.wbgt !== undefined
              ) {
                if (siteWbgtStatus.wbgt < element.wbgt) {
                  siteWbgtStatus.wbgt = element.wbgt;
                  siteWbgtStatus.date = element.date;
                  siteWbgtStatus.statusNo = element.statusNo;
                  siteWbgtStatus.status = element.status;
                }
              } else if (
                siteWbgtStatus.wbgt === undefined &&
                element.wbgt !== undefined
              ) {
                siteWbgtStatus.wbgt = element.wbgt;
                siteWbgtStatus.date = element.date;
                siteWbgtStatus.statusNo = element.statusNo;
                siteWbgtStatus.status = element.status;
              }
            }
          }

          // 温度ステータス
          const temperatureStatus = UtilFunctions.getTemperatureStatusWithDate(
            element.temperature!,
            element.date!
          );

          element.temperatureStatusNo = temperatureStatus.no;
          element.temperatureStatus = temperatureStatus.label;
          wbgtArray[i] = element;

          if (i === 0) {
            siteTemperatureStatus.statusNo = element.temperatureStatusNo;
            siteTemperatureStatus.status = element.temperatureStatus;

            if (
              siteTemperatureStatus.statusNo === AppConst.SENSOR_STATUS_STOPPED
            ) {
              siteTemperatureStatus.temperature = undefined;
              siteTemperatureStatus.date = undefined;
            } else {
              siteTemperatureStatus.temperature = element.temperature;
              siteTemperatureStatus.date = element.date;
            }
          } else {
            if (element.statusNo !== AppConst.SENSOR_STATUS_STOPPED) {
              if (
                siteTemperatureStatus.temperature !== undefined &&
                element.temperature !== undefined
              ) {
                if (element.temperature < siteTemperatureStatus.temperature) {
                  siteTemperatureStatus.temperature = element.temperature;
                  siteTemperatureStatus.date = element.date;
                  siteTemperatureStatus.statusNo = element.temperatureStatusNo;
                  siteTemperatureStatus.status = element.temperatureStatus;
                }
              } else if (
                siteTemperatureStatus.temperature === undefined &&
                element.temperature !== undefined
              ) {
                siteTemperatureStatus.temperature = element.temperature;
                siteTemperatureStatus.date = element.date;
                siteTemperatureStatus.statusNo = element.temperatureStatusNo;
                siteTemperatureStatus.status = element.temperatureStatus;
              }
            }
          }

          // 鶴賀センサーバッテリステータス
          if (
            element.maker !== undefined &&
            element.maker === 'tsuruga' &&
            element.battery !== undefined
          ) {
            if (element.battery >= 9.3) {
              element.batteryComment = '正常';
            } else if (element.battery >= 9.0 && element.battery < 9.3) {
              element.batteryComment = '残量低下';
            } else if (element.battery < 9.0) {
              element.batteryComment = '電池交換';
            }
          }
        }

        // 加工済の最新のWBGTデータをセット
        latestSiteData.sensorData.wbgt = wbgtArray;

        // 最新のWBGT状況をセット
        latestSiteData.siteStatusNo = siteWbgtStatus.statusNo;
        latestSiteData.siteStatus = siteWbgtStatus.status;

        if (siteWbgtStatus.wbgt !== undefined) {
          latestSiteData.siteDate = siteWbgtStatus.date;
          latestSiteData.siteWbgt = siteWbgtStatus.wbgt;
        } else {
          latestSiteData.siteDate = undefined;
          latestSiteData.siteWbgt = undefined;
        }

        latestSiteData.siteTemperatureStatusNo = siteTemperatureStatus.statusNo;
        latestSiteData.siteTemperatureStatus = siteTemperatureStatus.status;

        if (siteTemperatureStatus.temperature !== undefined) {
          latestSiteData.siteTemperatureDate = siteTemperatureStatus.date;
          latestSiteData.siteTemperature = siteTemperatureStatus.temperature;
        } else {
          latestSiteData.siteTemperatureDate = undefined;
          latestSiteData.siteTemperature = undefined;
        }
      }

      /////////////////////////////////////////////////////////////////////////////
      // 新方式風速データ取得（今度は風速）風速センサーは1つ
      if (
        latestSiteData.sensorData !== undefined &&
        latestSiteData.sensorData.wind !== undefined &&
        latestSiteData.sensorData.wind.length > 0
      ) {
        const element = latestSiteData.sensorData.wind[0];

        if (element.direction !== undefined) {
          element.directionName = UtilFunctions.getWindDirectionName(
            element.direction
          );
        }

        const status = UtilFunctions.getWindStatusWithDate(
          element.speed!,
          element.date!
        );

        element.statusNo = status.no;
        element.status = status.label;
        latestSiteData.sensorData.wind[0] = element;

        latestSiteData.siteWindStatusNo = status.no;
        latestSiteData.siteWindStatus = status.label;

        if (status.no === AppConst.SENSOR_STATUS_STOPPED) {
          latestSiteData.siteWindSpeed = undefined;
          latestSiteData.siteWindDirection = element.direction;
          latestSiteData.siteWindDirectionName = undefined;
          latestSiteData.siteWindDate = undefined;
        } else {
          latestSiteData.siteWindSpeed = element.speed;
          latestSiteData.siteWindDirection = element.direction;
          latestSiteData.siteWindDirectionName = element.directionName;
          latestSiteData.siteWindDate = element.date;
        }
      }

      /////////////////////////////////////////////////////////////////////////////
      // 新方式振動騒音データ取得センサーは1つ
      if (
        latestSiteData.sensorData !== undefined &&
        latestSiteData.sensorData.noise !== undefined
      ) {
        const element = latestSiteData.sensorData.noise;

        // 騒音ステータス取得
        if (element.noiseL5 !== undefined) {
          const sensor = await this.sensorService.get(element.id);

          if (sensor !== null) {
            // 騒音振動センサーなら基準値があるはず
            if (sensor.standardNoise !== undefined) {
              const status = UtilFunctions.getNoiseStatusWithDate(
                sensor.standardNoise,
                element.noiseL5!,
                element.date!
              );

              element.statusNo = status.no;
              element.status = status.label;
            } else {
              const status = UtilFunctions.getStatusStopped();

              element.statusNo = status.no;
              element.status = status.label;
            }
          } else {
            const status = UtilFunctions.getStatusStopped();

            element.statusNo = status.no;
            element.status = status.label;
          }
          latestSiteData.sensorData.noise = element;

          latestSiteData.siteNoiseStatusNo = element.statusNo;
          latestSiteData.siteNoiseStatus = element.status;

          if (element.statusNo === AppConst.SENSOR_STATUS_STOPPED) {
            latestSiteData.siteNoiseMax = undefined;
            latestSiteData.siteNoiseL5 = undefined;
            latestSiteData.siteVibrationMax = undefined;
            latestSiteData.siteVibrationL10 = undefined;
            latestSiteData.siteNoiseDate = undefined;
          } else {
            latestSiteData.siteNoiseMax = element.noiseMax;
            latestSiteData.siteNoiseL5 = element.noiseL5;
            latestSiteData.siteVibrationMax = element.vibrationMax;
            latestSiteData.siteVibrationL10 = element.vibrationL10;
            latestSiteData.siteNoiseDate = element.date;
          }
        } else {
          element.statusNo = AppConst.SENSOR_STATUS_STOPPED;
          element.status = AppConst.SENSOR_STATUS_STOPPED_LABEL;
          latestSiteData.sensorData.noise = element;

          latestSiteData.siteNoiseMax = undefined;
          latestSiteData.siteNoiseL5 = undefined;
          latestSiteData.siteVibrationMax = undefined;
          latestSiteData.siteVibrationL10 = undefined;
          latestSiteData.siteNoiseDate = undefined;
          latestSiteData.siteNoiseStatusNo = AppConst.SENSOR_STATUS_STOPPED;
          latestSiteData.siteNoiseStatus = AppConst.SENSOR_STATUS_STOPPED_LABEL;
        }
      } else {
        latestSiteData.siteNoiseStatusNo = AppConst.SENSOR_STATUS_STOPPED;
        latestSiteData.siteNoiseStatus = AppConst.SENSOR_STATUS_STOPPED_LABEL;
      }

      /////////////////////////////////////////////////////////////////////////////
      // 新方式Phデータ取得センサーは1つ
      if (
        latestSiteData.sensorData !== undefined &&
        latestSiteData.sensorData.ph !== undefined
      ) {
        const element = latestSiteData.sensorData.ph;

        // pHを小数第二位で切り捨て
        if (element.ph !== undefined) {
          element.ph = Math.floor(element.ph * 100) / 100;
        }

        // pHステータス取得
        if (element.ph !== undefined) {
          const sensor = await this.sensorService.get(element.id);

          if (sensor !== null) {
            // pHセンサーなら基準値があるはず
            if (sensor.minPh !== undefined && sensor.maxPh !== undefined) {
              const status = UtilFunctions.getPhStatusWithDate(
                sensor.minPh!,
                sensor.maxPh!,
                element.ph!,
                element.date!
              );

              element.statusNo = status.no;
              element.status = status.label;
            } else {
              const status = UtilFunctions.getStatusStopped();

              element.statusNo = status.no;
              element.status = status.label;
            }
          } else {
            const status = UtilFunctions.getStatusStopped();

            element.statusNo = status.no;
            element.status = status.label;
          }
          latestSiteData.sensorData.ph = element;

          latestSiteData.sitePhStatusNo = element.statusNo;
          latestSiteData.sitePhStatus = element.status;

          if (element.statusNo === AppConst.SENSOR_STATUS_STOPPED) {
            latestSiteData.sitePh = undefined;
            latestSiteData.sitePhDate = undefined;
          } else {
            latestSiteData.sitePh = element.ph;
            latestSiteData.sitePhDate = element.date;
          }
        } else {
          element.statusNo = AppConst.SENSOR_STATUS_STOPPED;
          element.status = AppConst.SENSOR_STATUS_STOPPED_LABEL;
          latestSiteData.sensorData.ph = element;

          latestSiteData.sitePh = undefined;
          latestSiteData.sitePhDate = undefined;
          latestSiteData.sitePhStatusNo = AppConst.SENSOR_STATUS_STOPPED;
          latestSiteData.sitePhStatus = AppConst.SENSOR_STATUS_STOPPED_LABEL;
        }
      } else {
        latestSiteData.sitePhStatusNo = AppConst.SENSOR_STATUS_STOPPED;
        latestSiteData.sitePhStatus = AppConst.SENSOR_STATUS_STOPPED_LABEL;
      }
    }

    return latestSiteData;
  }

  findOne(id: string): Observable<Site> {
    return docData(doc(this.firestore, 'sites', id)).pipe(
      map((data) => {
        return this.doc2object(data);
      })
    );
  }

  fetch(id: string): Observable<Site> {
    return docData(doc(this.firestore, 'sites', id)).pipe(
      map((data) => this.doc2object(data))
    );
  }

  fetchWithOrganization(
    id: string,
    organizationId: string
  ): Observable<Site | null> {
    return docData(doc(this.firestore, 'sites', id)).pipe(
      map((data) => {
        if (data['organizationId'] !== organizationId) {
          return null;
        }
        return this.doc2object(data);
      })
    );
  }

  async get(id: string): Promise<Site | null> {
    if (id === undefined) return null;

    const docSnap = await getDoc(doc(this.firestore, 'sites', id));
    if (docSnap.exists()) {
      return this.doc2object(docSnap.data());
    } else {
      return null;
    }
  }

  async getWithOrganization(
    id: string,
    organizationId: string
  ): Promise<Site | null> {
    const docSnap = await getDoc(doc(this.firestore, 'sites', id));
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data['organizationId'] !== organizationId) {
        return null;
      }
      return this.doc2object(data);
    } else {
      return null;
    }
  }

  async getFromUuid(uuid: string): Promise<Site | null> {
    const q = query(
      collection(this.firestore, 'sites'),
      where('uuid', '==', uuid)
    );
    const snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      const data = snapshot.docs[0].data();
      return this.doc2object(data);
    } else {
      return null;
    }
  }

  async getFromContainingName(name: string): Promise<Site[]> {
    const _sites = await this.getAllWithOrganization();
    const _siteList: Site[] = [];
    const escapedName = UtilFunctions.escapeString(name);
    const regexp = new RegExp(escapedName);

    _sites.forEach((_site) => {
      if (regexp.test(_site.name)) {
        _siteList.push(_site);
      }
    });

    return _siteList;
  }

  async delete(id: string) {
    await this.deleteLatestSiteData(id);
    await this.deleteSiteSensorHistory(id);
    await this.deleteSite(id);
  }

  async deleteSite(id: string) {
    const snapshot = await getDoc(doc(this.firestore, 'sites', id));
    if (snapshot.exists()) {
      if ('drawingImageURL' in snapshot.data()) {
        const type = snapshot.data()['drawingImageType'];
        const storage = getStorage();
        try {
          await deleteObject(ref(storage, `drawing/${id}.${type}`));
        } catch (error) {
          console.error(error);
        }
      }

      await deleteDoc(doc(this.firestore, 'sites', id));
    }
  }

  // 内部のドキュメントがなくなればサブコレクションは自動で消える
  async deleteSiteSensorHistory(id: string) {
    const snapshot = await getDocs(
      collection(this.firestore, 'sites', id, 'sensor_history')
    );
    if (snapshot.docs.length > 0) {
      snapshot.docs.forEach(async (d) => {
        await deleteDoc(
          doc(this.firestore, 'sites', id, 'sensor_history', d.id)
        );
      });
    }
  }

  async deleteLatestSiteData(id: string) {
    const snapshot = await getDoc(doc(this.firestore, 'latest_site_data', id));
    if (snapshot.exists()) {
      await deleteDoc(doc(this.firestore, 'latest_site_data', id));
    }
  }

  async deleteLatestTopFloorData(id: string) {
    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      topFloorWbgt: deleteField(),
      topFloorTemperature: deleteField(),
      topFloorHumidity: deleteField(),
      topFloorDate: deleteField(),
    });
  }

  async deleteLatestBoardData(id: string) {
    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      boardWbgt: deleteField(),
      boardTemperature: deleteField(),
      boardHumidity: deleteField(),
      boardDate: deleteField(),
    });
  }

  async deleteLatestWbgtSensorData(id: string, sensor: Sensor) {
    const latest = await this.getLatestSiteData(id);

    if (latest === null) {
      return;
    }
    if (latest.sensorData === undefined) {
      return;
    }

    if (latest.sensorData.wbgt === undefined) {
      return;
    }

    const array = latest.sensorData.wbgt;
    const newArray = array.filter((wbgtSensor) => wbgtSensor.id !== sensor.id);
    if (newArray.length === 0) {
      delete latest.sensorData['wbgt'];
    } else {
      latest.sensorData.wbgt = newArray;
    }

    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      sensorData: latest.sensorData,
    });
  }

  async deleteLatestWindSensorData(id: string, sensor: Sensor) {
    const latest = await this.getLatestSiteData(id);

    if (latest === null) {
      return;
    }
    if (latest.sensorData === undefined) {
      return;
    }

    if (latest.sensorData.wind === undefined) {
      return;
    }

    const array = latest.sensorData.wind;
    const newArray = array.filter((windSensor) => windSensor.id !== sensor.id);
    latest.sensorData.wind = newArray;

    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      sensorData: latest.sensorData,
    });
  }

  async deleteLatestNoiseSensorData(id: string, sensor: Sensor) {
    const latest = await this.getLatestSiteData(id);

    if (latest === null) {
      return;
    }
    if (latest.sensorData === undefined) {
      return;
    }

    if (latest.sensorData.noise === undefined) {
      return;
    }

    delete latest.sensorData.noise;

    console.log(latest.sensorData);
    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      sensorData: latest.sensorData,
    });
  }

  async deleteLatestPhSensorData(id: string, sensor: Sensor) {
    const latest = await this.getLatestSiteData(id);

    if (latest === null) {
      return;
    }
    if (latest.sensorData === undefined) {
      return;
    }

    if (latest.sensorData.ph === undefined) {
      return;
    }

    delete latest.sensorData.ph;

    console.log(latest.sensorData);
    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      sensorData: latest.sensorData,
    });
  }

  async deleteLatestWindData(id: string) {
    await updateDoc(doc(this.firestore, 'latest_site_data', id), {
      windDate: deleteField(),
      windSpeed: deleteField(),
      windDirection: deleteField(),
      windPosition: deleteField(),
    });
  }

  async uploadDrawing(id: string, file: File) {
    const site = await this.get(id);

    const name = file.name;
    // const type = name!.substr(name!.lastIndexOf('.') + 1 - name!.length);
    const metadata = {
      contentType: file.type,
    };

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `drawing/${id}.${file.type}`);
      await uploadBytes(storageRef, file, metadata);
      const url = await getDownloadURL(storageRef);
      await this.setDrawingImageURL(id, url, file.type);
    } catch (error) {
      console.log(error);
    }
  }

  async updateDrawingPositions(
    id: string,
    positions: SiteDrawingSensorPosition[]
  ) {
    console.log('updateDrawingPositions');
    try {
      const site = await this.get(id);
      if (site === null) {
        throw new Error('site is null');
      }

      let drawing = site.drawing;
      if (drawing === undefined) {
        drawing = { positions: positions };
      } else {
        drawing.positions = positions;
      }
      await updateDoc(doc(this.firestore, 'sites', id), {
        drawing: drawing,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async setDrawingPosition(
    siteId: string,
    data: SiteDrawingPosition | undefined
  ) {
    const snapshot = await getDoc(doc(this.firestore, 'sites', siteId));
    if (!snapshot.exists()) {
      return;
    }

    let position: SiteDrawingPosition | undefined = {};
    if ('drawingPosition' in snapshot.data()) {
      position = snapshot.data()['drawingPosition'];
      position = { ...position, ...data };
    } else {
      position = data;
    }
    await updateDoc(doc(this.firestore, 'sites', siteId), {
      drawingPosition: position,
    });
  }

  async setDrawingImageURL(siteId: string, url: string, type: string) {
    try {
      await updateDoc(doc(this.firestore, 'sites', siteId), {
        drawing: {
          imageUrl: url,
          imageType: type,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async setCompassAngle(site: Site, compassAngle: number) {
    try {
      await updateDoc(doc(this.firestore, 'sites', site.id), {
        compassAngle: compassAngle,
      });
    } catch (error) {
      console.log(error);
    }
  }

  doc2object(doc: DocumentData): Site {
    const site: Site = {
      id: doc['id'],
      name: doc['name'],
      address: doc['address'] ? doc['address'] : '',
      address1: doc['address1'] ? doc['address1'] : '',
      address2: doc['address2'] ? doc['address2'] : '',
      uuid: doc['uuid'] ? doc['uuid'] : '',
      geopoint: doc['geopoint'] ? doc['geopoint'] : undefined,
      managementDepartmentId: doc['managementDepartmentId'],
      managementDepartmentName: doc['managementDepartmentName'],
      createdAt: doc['createdAt'],
      updatedAt: doc['updatedAt'],
    };

    if ('emails' in doc) {
      site.emails = doc['emails'];
    }
    if ('drawingPosition' in doc) {
      site.drawingPosition = doc['drawingPosition'];
    }
    if ('drawingImageURL' in doc) {
      site.drawingImageURL = doc['drawingImageURL'];
    }
    if ('deletedAt' in doc) {
      site.deletedAt = doc['deletedAt'];
    }
    if ('sensorRef' in doc) {
      site.sensorRef = doc['sensorRef'];
    }
    if ('compassAngle' in doc) {
      site.compassAngle = doc['compassAngle'];
    }

    // 図面設定情報
    if ('drawing' in doc) {
      site.drawing = doc['drawing'];
    }

    // パトライト設置
    if ('installPatlite' in doc) {
      site.installPatlite = doc['installPatlite'];
    }

    return site;
  }

  async getSensorRef(
    id: string
  ): Promise<{ [index: string]: DocumentReference } | null> {
    let refMap: { [index: string]: DocumentReference } | null = null;

    try {
      const snapshot = await getDoc(doc(this.firestore, 'sites', id));
      if (snapshot.exists()) {
        if ('sensorRef' in snapshot.data()) {
          refMap = snapshot.data()['sensorRef'];
        }
      }
    } catch (error) {
      console.error(error);
    }

    return refMap;
  }

  async updateSensorRef(site: Site, position: string, ref: DocumentReference) {
    const refMap: SiteSensorRef = {};

    if (site.sensorRef) {
      if ('topFloor' in site.sensorRef) {
        refMap['topFloor'] = site.sensorRef['topFloor'];
      }
      if ('board' in site.sensorRef) {
        refMap['board'] = site.sensorRef['board'];
      }
    }

    if (position === 'topFloor') {
      refMap['topFloor'] = ref;
    } else if (position === 'board') {
      refMap['board'] = ref;
    }

    try {
      await updateDoc(doc(this.firestore, 'sites', site.id), {
        sensorRef: refMap,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async updateWbgtSensorRef(site: Site, ref: DocumentReference) {
    let sensorRef: SiteSensorRef = {};

    if (site.sensorRef !== undefined) {
      sensorRef = site.sensorRef;

      if (sensorRef.wbgt !== undefined) {
        if (Array.isArray(sensorRef.wbgt)) {
          const wbgtArray = sensorRef.wbgt.filter(
            (wbgt) => wbgt.path === ref.path
          );
          if (!(wbgtArray.length > 0)) {
            sensorRef.wbgt.push(ref);
          }
        } else {
          sensorRef.wbgt = [ref];
        }
      } else {
        sensorRef.wbgt = [ref];
      }
    } else {
      sensorRef.wbgt = [ref];
    }

    try {
      await updateDoc(doc(this.firestore, 'sites', site.id), {
        sensorRef: sensorRef,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async updateWindSensorRef(site: Site, ref: DocumentReference) {
    let sensorRef: SiteSensorRef = {};

    if (site.sensorRef !== undefined) {
      sensorRef = site.sensorRef;

      if (sensorRef.wind !== undefined) {
        if (Array.isArray(sensorRef.wind)) {
          const windArray = sensorRef.wind.filter(
            (wind) => wind.path === ref.path
          );
          if (!(windArray.length > 0)) {
            sensorRef.wind.push(ref);
          }
        } else {
          sensorRef.wind = [ref];
        }
      } else {
        sensorRef.wind = [ref];
      }
    } else {
      sensorRef.wind = [ref];
    }

    try {
      await updateDoc(doc(this.firestore, 'sites', site.id), {
        sensorRef: sensorRef,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async updateNoiseSensorRef(site: Site, ref: DocumentReference) {
    let sensorRef: SiteSensorRef = {};

    if (site.sensorRef !== undefined) {
      sensorRef = site.sensorRef;

      if (sensorRef.noise !== undefined) {
        if (Array.isArray(sensorRef.noise)) {
          const noiseArray = sensorRef.noise.filter(
            (noise) => noise.path === ref.path
          );
          if (!(noiseArray.length > 0)) {
            sensorRef.noise.push(ref);
          }
        } else {
          sensorRef.noise = [ref];
        }
      } else {
        sensorRef.noise = [ref];
      }
    } else {
      sensorRef.noise = [ref];
    }

    try {
      await updateDoc(doc(this.firestore, 'sites', site.id), {
        sensorRef: sensorRef,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async updatePhSensorRef(site: Site, ref: DocumentReference) {
    let sensorRef: SiteSensorRef = {};

    if (site.sensorRef !== undefined) {
      sensorRef = site.sensorRef;

      if (sensorRef.ph !== undefined) {
        if (Array.isArray(sensorRef.ph)) {
          const noiseArray = sensorRef.ph.filter((ph) => ph.path === ref.path);
          if (!(noiseArray.length > 0)) {
            sensorRef.ph.push(ref);
          }
        } else {
          sensorRef.ph = [ref];
        }
      } else {
        sensorRef.ph = [ref];
      }
    } else {
      sensorRef.ph = [ref];
    }

    try {
      await updateDoc(doc(this.firestore, 'sites', site.id), {
        sensorRef: sensorRef,
      });
    } catch (error) {
      console.error(error);
    }
  }

  // パトライト設置フィールドのアップデート
  async updateInstallPatlite(site: Site, installPatlite: boolean) {
    await updateDoc(doc(this.firestore, 'sites', site.id), {
      installPatlite: installPatlite,
    });
  }

  async deleteSensorRef(site: Site, position: string) {
    const refMap = site.sensorRef;

    if (refMap !== undefined && position in refMap) {
      if (position === 'topFloor') {
        delete refMap['topFloor'];
      }
      if (position === 'board') {
        delete refMap['board'];
      }
    }

    try {
      const docRef = doc(this.firestore, 'sites', site.id);

      // ポジションキーを削除してもなおキーが残っている場合と
      // 完全に無くなった場合
      if (refMap !== undefined && Object.keys(refMap).length === 0) {
        await updateDoc(docRef, {
          sensorRef: deleteField(),
        });
      } else if (refMap !== undefined && Object.keys(refMap).length > 0) {
        await updateDoc(docRef, {
          sensorRef: refMap,
        });
      }
    } catch (error) {
      console.error('SiteService.deleteSensorRef()');
      console.error(error);
    }
  }

  async deleteWbgtSensorRef(site: Site, sensor: Sensor) {
    const sensorRef = site.sensorRef;

    if (sensorRef === undefined) {
      return;
    }

    if (sensorRef.wbgt === undefined) {
      return;
    }

    const newArray = sensorRef.wbgt.filter((ref) => ref.id !== sensor.id);
    if (newArray.length === 0) {
      delete sensorRef['wbgt'];
    } else {
      sensorRef.wbgt = newArray;
    }

    await updateDoc(doc(this.firestore, 'sites', site.id), {
      sensorRef: sensorRef,
    });
  }

  async deleteWindSensorRef(site: Site, sensor: Sensor) {
    const sensorRef = site.sensorRef;

    if (sensorRef === undefined) {
      return;
    }

    if (sensorRef.wind === undefined) {
      return;
    }

    const newArray = sensorRef.wind.filter((ref) => ref.id !== sensor.id);
    if (newArray.length === 0) {
      delete sensorRef['wind'];
    } else {
      sensorRef.wind = newArray;
    }

    await updateDoc(doc(this.firestore, 'sites', site.id), {
      sensorRef: sensorRef,
    });
  }

  async deleteNoiseSensorRef(site: Site, sensor: Sensor) {
    const sensorRef = site.sensorRef;

    if (sensorRef === undefined) {
      return;
    }

    if (sensorRef.noise === undefined) {
      return;
    }

    const newArray = sensorRef.noise.filter((ref) => ref.id !== sensor.id);
    if (newArray.length === 0) {
      delete sensorRef['noise'];
    } else {
      sensorRef.noise = newArray;
    }

    await updateDoc(doc(this.firestore, 'sites', site.id), {
      sensorRef: sensorRef,
    });
  }

  async deletePhSensorRef(site: Site, sensor: Sensor) {
    const sensorRef = site.sensorRef;

    if (sensorRef === undefined) {
      return;
    }

    if (sensorRef.ph === undefined) {
      return;
    }

    const newArray = sensorRef.ph.filter((ref) => ref.id !== sensor.id);
    if (newArray.length === 0) {
      delete sensorRef['ph'];
    } else {
      sensorRef.noise = newArray;
    }

    await updateDoc(doc(this.firestore, 'sites', site.id), {
      sensorRef: sensorRef,
    });
  }

  async updateEmails(site: Site, emails: string[]) {
    await updateDoc(doc(this.firestore, 'sites', site.id), {
      emails: emails,
      updatedAt: UtilFunctions.now(),
    });
  }

  async deleteEmails(site: Site) {
    await updateDoc(doc(this.firestore, 'sites', site.id), {
      emails: deleteField(),
      updatedAt: UtilFunctions.now(),
    });
  }

  onNotifySharedDataChanged(updated: string) {
    this.sharedDataSource.next(updated);
  }
}
