import { format } from 'date-fns';
import {
    addDoc, collection, getDocs, getFirestore, limit, orderBy, query, where
} from 'firebase/firestore';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { Account } from '../classes/account';
import { AppConst } from '../constants/app-const';
import { UtilFunctions } from '../utils/util-functions';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AccessLogService {
  constructor(private firestore: Firestore) {}

  async addLog(message: string) {
    const account = SessionStorageService.getItem(
      AppConst.SESSION_KEY_USER,
      new Account()
    );
    if (account) {
      const logData = {
        date: UtilFunctions.now(),
        user: account.user,
        message: message,
      };

      await addDoc(collection(this.firestore, 'access_logs'), logData);
      await addDoc(
        collection(this.firestore, 'accounts', account.id, 'access_logs'),
        logData
      );
    }
  }

  async getLogFromDate(date: Date) {
    const logList: { date: string; user: string; message: string }[] = [];

    try {
      const q = query(
        collection(this.firestore, 'access_logs'),
        where('date', '>=', format(date, 'yyyy-MM-dd 00:00:00')),
        where('date', '<=', format(date, 'yyyy-MM-dd 23:59:59')),
        orderBy('date', 'asc')
      );

      const snapshot = await getDocs(q);
      if (snapshot.docs.length > 0) {
        for (let doc of snapshot.docs) {
          const data = doc.data();
          logList.push({
            date: data['date'],
            user: data['user'],
            message: data['message'],
          });
        }
      }
    } catch (error) {
      console.log(error);
    }

    return logList;
  }

  async getLogList() {
    const logList: { id: string; path: string }[] = [];

    const q = query(
      collection(this.firestore, 'access_log_csv'),
      orderBy('id', 'desc'),
      limit(7)
    );
    const snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        logList.push({
          id: doc.data()['id'],
          path: doc.data()['path'],
        });
      }
    }

    return logList;
  }

  async getMonthlyLogList() {
    const logList: { id: string; path: string }[] = [];

    const q = query(
      collection(this.firestore, 'access_log_csv'),
      where('category', '==', 'monthly'),
      orderBy('id', 'desc'),
      limit(12)
    );
    const snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        logList.push({
          id: doc.data()['id'],
          path: doc.data()['path'],
        });
      }
    }

    return logList;
  }
}
