import { user } from 'rxfire/auth';

export class Account {
  id: string = '';
  user: string = '';
  organizationId: string = '';
  groupId: string = '';
  groupName: string = '';
  defaultSite: string = '';

  constructor(data?: {
    id: string;
    user: string;
    organizationId?: string;
    groupId?: string;
    groupName?: string;
    defaultSite?: string;
  }) {
    if (data) {
      this.id = data.id;
      this.user = data.user;
      if (data.organizationId) {
        this.organizationId = data.organizationId;
      }
      if (data.groupId) {
        this.groupId = data.groupId;
      }
      if (data.groupName) {
        this.groupName = data.groupName;
      }
      if (data.defaultSite) {
        this.defaultSite = data.defaultSite;
      }
    }
  }
}
