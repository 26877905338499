<div class="p-4">
  <div *ngIf="isWinter">
    <div class="font-bold text-4xl">温度凡例</div>
    <div class="pl-8 pt-4 pb-4 text-2xl w-full">
      <div class="border-1 border-black border-solid">
        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-10">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">要注意</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            -5℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-9">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">要注意</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            -5℃以上 0℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-8">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">要注意</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            0℃以上 5℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-7">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">ー</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            5℃以上 10℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-6">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">ー</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            5℃以上 10℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-5">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">ー</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            5℃以上 10℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-4">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">ー</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            5℃以上 10℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-3">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">ー</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            5℃以上 10℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-2">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">ー</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            5℃以上 10℃以下
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-1">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">ー</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            35℃以上
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-temperature-signal-99">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">停止</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            信号なし又はセンサー未設定
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isWinter">
    <div class="font-bold text-4xl">WBGT凡例</div>
    <div class="pl-8 pt-4 pb-4 text-2xl w-full">
      <div class="border-1 border-black border-solid">
        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-signal-20">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">危険</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            33℃以上
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-signal-11">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">
            厳重警戒
          </div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            30℃から33℃
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-signal-10">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">警戒</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            28℃から30℃
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-signal-3">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">注意</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            26℃から28℃
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-signal-2">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">
            ほぼ安全
          </div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            25℃から26℃
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-signal-1">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">
            ほぼ安全
          </div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            25℃未満
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-signal-99">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">停止</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            信号なし又はセンサー未設定
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4">
    <div class="font-bold text-4xl">風速凡例</div>
    <div class="pl-8 pt-4 pb-4 text-2xl w-full">
      <div class="border-1 border-black border-solid">
        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-wind-signal-20">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">警報</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            10m以上
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-wind-signal-10">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">注意</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            8m以上10m未満
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-wind-signal-3">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">注意</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            5m以上8m未満
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-wind-signal-2">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">
            ほぼ安全
          </div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            2m以上5m未満
          </div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-wind-signal-1">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">
            ほぼ安全
          </div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">2m未満</div>
        </div>

        <div class="w-full flex">
          <div class="p-2 border-1 border-black border-solid">
            <span class="global-status-wind-signal-99">・</span>
          </div>
          <div class="p-2 w-1/6 border-1 border-black border-solid">停止</div>
          <div class="p-2 w-1/2 border-1 border-black border-solid">
            信号なし
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
