import { Observable, of, Subscription } from 'rxjs';
import { TodayWnDialogComponent } from 'src/app/shared/components/today-wn-dialog/today-wn-dialog.component';
import {
  WeatherNewsApiResponseSrf,
  WeatherNewsService,
} from 'src/app/shared/services/weather-news.service';

import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { SiteDetailAlertService } from '../site-detail-alert/site-detail-alert.service';
import { Site } from 'src/app/shared/interfaces/site';
import { LatestSiteData } from 'src/app/shared/interfaces/latest-site-data';

@Component({
  selector: 'app-site-detail-alert-temperature',
  templateUrl: './site-detail-alert-temperature.component.html',
  styleUrls: ['./site-detail-alert-temperature.component.scss'],
})
export class SiteDetailAlertTemperatureComponent implements OnChanges {
  @Input() site: Site | null = null;
  @Input() latestSiteData: LatestSiteData | null = null;
  @Input() temperature: number | undefined = undefined;

  private weatherNewsService = inject(WeatherNewsService);

  subscriptions = new Subscription();
  weatherNewsSrf$: Observable<WeatherNewsApiResponseSrf[]> = of([]);

  isOuter = false; // 外部のユーザかどうか

  constructor(
    private dialog: MatDialog,
    private siteDetailAlertService: SiteDetailAlertService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isOuter = this.siteDetailAlertService.isOuterUser();

    if (this.site !== null) {
      const geo = this.site.geopoint;
      if (geo !== undefined) {
        this.weatherNewsSrf$ = this.weatherNewsService.fetchSrfByCoordinate(
          geo.latitude,
          geo.longitude
        );
      }
    }
  }

  onTodayWnClick() {
    this.subscriptions.add(
      this.weatherNewsSrf$.subscribe((srf) => {
        this.dialog.open(TodayWnDialogComponent, {
          data: { temperature: this.temperature, srf: srf },
          width: '1200px',
        });
      })
    );
  }
}
