<ng-container *ngIf="isWbgtChart">
  <div class="site-detail-charts-container">
    <mat-card class="site-detail-charts-card" *ngIf="!isWinter">
      <app-line-charts
        class="site-detail-charts-card-item"
        *ngIf="wbgtDataList.length > 0"
        label="WBGT（℃）"
        [dataList]="wbgtDataList"
        [dataLabel]="wbgtDataLabel"
        boarderColor="rgba(156, 39, 176, 1)"
        backgroundColor="rgba(156, 39, 176, 0.2)"
      ></app-line-charts>
    </mat-card>
    <mat-card class="site-detail-charts-card">
      <app-line-charts
        class="site-detail-charts-card-item"
        *ngIf="temperatureDataList.length > 0"
        label="温度（℃）"
        [dataList]="temperatureDataList"
        [dataLabel]="wbgtDataLabel"
        boarderColor="rgba(255,152,0,1)"
        backgroundColor="rgba(255,152,0,0.2)"
      ></app-line-charts>
    </mat-card>
    <mat-card class="site-detail-charts-card">
      <app-line-charts
        class="site-detail-charts-card-item"
        *ngIf="humidityDataList.length > 0"
        [dataList]="humidityDataList"
        [dataLabel]="wbgtDataLabel"
        boarderColor="rgba(3,169,244,1)"
        backgroundColor="rgba(3,169,244,0.2)"
        label="湿度（％）"
      ></app-line-charts>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="isWindChart">
  <div class="site-detail-charts-container">
    <mat-card class="site-detail-charts-card">
      <app-line-charts
        class="site-detail-charts-card-item"
        *ngIf="windSpeedDataList.length > 0"
        [dataList]="windSpeedDataList"
        [dataLabel]="windDataLabel"
        boarderColor="rgba(156, 39, 176, 1)"
        backgroundColor="rgba(156, 39, 176, 0.2)"
        label="風速（m/s）"
      ></app-line-charts>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="isNoiseChart">
  <div class="site-detail-charts-container">
    <mat-card class="site-detail-charts-card">
      <app-line-charts
        class="site-detail-charts-card-item"
        *ngIf="noiseDataList.length > 0"
        [dataList]="noiseDataList"
        [dataLabel]="noiseDataLabel"
        boarderColor="rgba(156, 39, 176, 1)"
        backgroundColor="rgba(156, 39, 176, 0.2)"
        label="騒音（dB）"
      ></app-line-charts>
    </mat-card>
  </div>
</ng-container>
