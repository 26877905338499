import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { AppConst } from '../../constants/app-const';
import { WeatherNewsApiResponseSrf } from '../../services/weather-news.service';
import { UtilFunctions } from '../../utils/util-functions';

export interface DialogData {
  srf: WeatherNewsApiResponseSrf[];
}

export interface DialogSrf {
  month: number;
  date: number;
  hour: number;
  wx: number;
  wxIconUrl: string;
  temp: number;
}

@Component({
  selector: 'app-wn-dialog',
  templateUrl: './wn-dialog.component.html',
  styleUrls: ['./wn-dialog.component.scss'],
})
export class WnDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  todaySrf: DialogSrf[] = [];
  tomorrowSrf: DialogSrf[] = [];

  ngOnInit(): void {
    for (let s of this.data.srf) {
      const srf: DialogSrf = {
        month: UtilFunctions.getMonthFromString(s.date),
        date: UtilFunctions.getDateFromString(s.date),
        hour: UtilFunctions.getHourFromString(s.date),
        wx: s.wx,
        wxIconUrl: this.getWxIconUrl(s.wx),
        temp: s.temp,
      };

      if (UtilFunctions.wnIsTodayFromString(s.date)) {
        this.todaySrf.push(srf);
      }
      if (UtilFunctions.wnIsTomorrowFromString(s.date)) {
        this.tomorrowSrf.push(srf);
      }
    }
  }

  getWxIconUrl(wx: number) {
    return AppConst.WEATHER_NEWS_API_ICON_URL + wx + '.png';
  }
}
