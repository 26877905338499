import { UrlConst } from 'src/app/shared/constants/url-const';
import { AccessLogService } from 'src/app/shared/services/access-log.service';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  constructor(private logService: AccessLogService) {}

  async ngOnInit() {
    await this.logService.addLog(UrlConst.SLASH);
  }
}
