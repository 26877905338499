import { UrlConst } from 'src/app/shared/constants/url-const';
import { WbgtLatestSiteData } from 'src/app/shared/interfaces/latest-site-data';
import { Site } from 'src/app/shared/interfaces/site';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SensorWbgtFormService {
  constructor(
    private accessLogService: AccessLogService,
    private accountService: AccountService,
    private siteService: SiteService,
    private sensorService: SensorService
  ) {}

  addLog() {
    return this.accessLogService.addLog(
      UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG +
        UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG_SENSOR
    );
  }

  isValidUser() {
    return (
      this.accountService.isSysAdminGroup() ||
      this.accountService.isGeneralGroup()
    );
  }

  isGeneralGroup() {
    return this.accountService.isGeneralGroup();
  }

  getUser() {
    return this.accountService.getUser();
  }

  getSite(id: string) {
    return this.siteService.get(id);
  }

  getSiteWithOrganization(id: string) {
    return this.siteService.getWithOrganization(
      id,
      this.accountService.getOrganizationId()
    );
  }

  getSensor(id: string) {
    return this.sensorService.get(id);
  }

  getNoConfigSensors() {
    return this.sensorService.getAllNoConfig();
  }

  getNoConfigSensorsWithOrganization() {
    return this.sensorService.getAllNoConfigWithOrganization(
      this.accountService.getOrganizationId()
    );
  }

  //
  // setSensorToSite
  //
  // 現場へのセンサー設定
  // 1. 該当センサーデータに対して現場IDと設置場所を設定する
  // 2. 現場に対してセンサーのリファレンスを追加する
  // 3. 最新データに対してセンサー情報を設定する
  //
  async setSensorToSite(site: Site, sensorId: string, positionName: string) {
    // センサー情報更新
    await this.sensorService.update(sensorId, {
      siteId: site.id,
      positionName: positionName,
    });

    const sensor = await this.sensorService.get(sensorId);

    // 現場情報更新
    await this.siteService.updateWbgtSensorRef(
      site,
      this.sensorService.getDocumentReference(sensorId)
    );

    // 最新データ更新
    const latestHistory = await this.sensorService.getLatestHistory(sensorId);
    if (latestHistory !== null) {
      const data: WbgtLatestSiteData = {
        id: sensorId,
        positionName: positionName,
        date: latestHistory.date,
        temperature: latestHistory.temperature,
        humidity: latestHistory.humidity,
        wbgt: latestHistory.wbgt,
      };
      if (sensor !== null && sensor.maker !== undefined) {
        data.maker = sensor.maker;
      }

      await this.siteService.updateLatestWbgtSensorData(site.id, data);
    } else {
      const data: WbgtLatestSiteData = {
        id: sensorId,
        positionName: positionName,
      };
      if (sensor !== null && sensor.maker !== undefined) {
        data.maker = sensor.maker;
      }

      await this.siteService.updateLatestWbgtSensorData(site.id, data);
    }
  }
}
