import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { getBlob, ref } from 'firebase/storage';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class SensorLogService {
  constructor(private firestore: Firestore, private storage: Storage) {}

  async getLogList() {
    const logList: { id: string; path: string }[] = [];

    const q = query(
      collection(this.firestore, 'monthly_sensor_csv'),
      orderBy('id', 'desc')
    );
    const snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      for (let doc of snapshot.docs) {
        logList.push({
          id: doc.data()['id'],
          path: doc.data()['path'],
        });
      }
    }

    return logList;
  }

  async getBlob(path: string) {
    const storageRef = ref(this.storage, path);
    return await getBlob(storageRef);
  }
}
