import {
    LatestSiteData, NoiseLatestSiteData, PhLatestSiteData, WbgtLatestSiteData, WindLatestSiteData
} from 'src/app/shared/interfaces/latest-site-data';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { AccountService } from 'src/app/shared/services/account.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteDetailAlertService {
  constructor(
    private accountService: AccountService,
    private sensorService: SensorService
  ) {}

  // WBGTアラートデータ取得
  // センサーデータがない場合はnullを返す
  // センサーデータが1つの場合はそのデータを返す
  // センサーデータが複数の場合は、最も古いデータを返す
  // センサーデータがあるが、WBGTアラートデータがない場合はnullを返す
  // 夏期の場合はWBGTで日中最初のアラート時間がセンサーデータにセットされている(MQTT)
  // 夏期以外の場合は気温で日中最初のアラート時間がセンサーデータにセットされている(MQTT)
  async getWbgtAlertData(
    wbgtSensorData: WbgtLatestSiteData[] | undefined
  ): Promise<WbgtLatestSiteData | null> {
    // センサーデータがない場合は何もしない
    if (wbgtSensorData === undefined) {
      return null;
    }

    const alertData: WbgtLatestSiteData[] = []; // アラートデータ

    // センサーデータ取得しつつ、WBGTアラートデータ取得
    for (let sensorData of wbgtSensorData) {
      // WBGTアラートデータ取得
      const sensor: Sensor | null = await this.sensorService.get(sensorData.id);
      if (sensor === null) {
        continue;
      }
      if (sensor.data === undefined) {
        continue;
      }
      if (sensor.data.firstAlertDate === undefined) {
        continue;
      }
      alertData.push({
        id: sensor.id,
        positionName: sensor.positionName ?? '',
        date: sensor.data.firstAlertDate,
      });
    }

    if (alertData.length === 0) {
      return null;
    }
    if (alertData.length === 1) {
      return alertData[0];
    }
    if (alertData.length > 1) {
      // アラートデータが複数ある場合は、最も古いデータを返す
      const newData = alertData.sort((a, b) => (a.date! < b.date! ? -1 : 1));
      return newData[0];
    }

    return null;
  }

  // WINDアラートデータ取得
  // センサーデータがない場合はnullを返す
  // センサーデータがあるが、WBGTアラートデータがない場合はnullを返す
  getWindAlertData(latestSiteData: LatestSiteData): WindLatestSiteData | null {
    // センサーデータがない場合は何もしない
    if (latestSiteData.sensorData === undefined) {
      return null;
    }
    if (latestSiteData.sensorData.wind === undefined) {
      return null;
    }

    if (latestSiteData.firstWindAlertDate === undefined) {
      return null;
    }
    if (UtilFunctions.isValidDate(latestSiteData.firstWindAlertDate)) {
      return {
        id: latestSiteData.sensorData.wind[0].id ?? '',
        positionName: latestSiteData.sensorData.wind[0].positionName ?? '',
        date: latestSiteData.firstWindAlertDate,
      };
    }

    return null;
  }

  // NOISEアラートデータ取得
  // センサーデータがない場合はnullを返す
  // センサーデータがあるが、アラートデータがない場合はnullを返す
  getNoiseAlertData(
    latestSiteData: LatestSiteData
  ): NoiseLatestSiteData | null {
    // センサーデータがない場合は何もしない
    if (latestSiteData.sensorData === undefined) {
      return null;
    }
    if (latestSiteData.sensorData.noise === undefined) {
      return null;
    }

    const noise = latestSiteData.sensorData.noise;

    if (
      latestSiteData.noiseAlertDate === undefined &&
      latestSiteData.vibrationAlertDate === undefined
    ) {
      return null;
    }

    const noiseLatestSiteData: NoiseLatestSiteData = {
      id: noise.id ?? '',
      positionName: noise.positionName ?? '',
    };

    if (
      latestSiteData.noiseAlertDate !== undefined &&
      UtilFunctions.isValidDate(latestSiteData.noiseAlertDate)
    ) {
      noiseLatestSiteData.noiseAlertDate = latestSiteData.noiseAlertDate;
    }
    if (
      latestSiteData.vibrationAlertDate !== undefined &&
      UtilFunctions.isValidDate(latestSiteData.vibrationAlertDate)
    ) {
      noiseLatestSiteData.vibrationAlertDate =
        latestSiteData.vibrationAlertDate;
    }

    if (
      noiseLatestSiteData.noiseAlertDate !== undefined ||
      noiseLatestSiteData.vibrationAlertDate !== undefined
    ) {
      return noiseLatestSiteData;
    }

    return null;
  }

  // PHアラートデータ取得
  // センサーデータがない場合はnullを返す
  // センサーデータがあるが、アラートデータがない場合はnullを返す
  getPhAlertData(latestSiteData: LatestSiteData): PhLatestSiteData | null {
    // センサーデータがない場合は何もしない
    if (latestSiteData.sensorData === undefined) {
      return null;
    }
    if (latestSiteData.sensorData.ph === undefined) {
      return null;
    }

    const ph = latestSiteData.sensorData.ph;

    if (
      latestSiteData.minPhAlertDate === undefined &&
      latestSiteData.maxPhAlertDate === undefined
    ) {
      return null;
    }

    const phLatestSiteData: PhLatestSiteData = {
      id: ph.id ?? '',
      positionName: ph.positionName ?? '',
    };

    if (
      latestSiteData.minPhAlertDate !== undefined &&
      UtilFunctions.isValidDate(latestSiteData.minPhAlertDate)
    ) {
      phLatestSiteData.minPhAlertDate = latestSiteData.minPhAlertDate;
    }
    if (
      latestSiteData.maxPhAlertDate !== undefined &&
      UtilFunctions.isValidDate(latestSiteData.maxPhAlertDate)
    ) {
      phLatestSiteData.maxPhAlertDate = latestSiteData.maxPhAlertDate;
    }

    if (
      phLatestSiteData.minPhAlertDate !== undefined ||
      phLatestSiteData.maxPhAlertDate !== undefined
    ) {
      return phLatestSiteData;
    }

    return null;
  }

  // 外部のユーザかどうか
  isOuterUser(): boolean {
    return this.accountService.isOuterGroup();
  }
}
