<ng-container *ngIf="!isWinter && !!wbgtHighAlertDate">
  <app-site-detail-high-alert-wbgt
    [date]="wbgtHighAlertDate"
  ></app-site-detail-high-alert-wbgt>
</ng-container>

<ng-container *ngIf="!isWinter && !!wbgtAlertDate">
  <app-site-detail-alert-wbgt
    [date]="wbgtAlertDate"
  ></app-site-detail-alert-wbgt>
</ng-container>

<ng-container *ngIf="isWinter && todayTemperatureAlertValue">
  <app-site-detail-alert-temperature
    [site]="site"
    [latestSiteData]="latestSiteData"
    [temperature]="todayTemperatureAlertValue"
  ></app-site-detail-alert-temperature>
</ng-container>

<ng-container *ngIf="isWinter && tomorrowTemperatureAlertDate">
  <app-site-detail-alert-temperature-tomorrow
    [site]="site"
    [latestSiteData]="latestSiteData"
    [date]="tomorrowTemperatureAlertDate"
  ></app-site-detail-alert-temperature-tomorrow>
</ng-container>

<ng-container *ngIf="windActualAttentionData">
  <app-site-detail-alert-wind
    [date]="windActualAttentionData.date!"
  ></app-site-detail-alert-wind>
</ng-container>

<ng-container *ngIf="windAlertData">
  <app-site-detail-alert-wind
    [date]="windAlertData.date!"
  ></app-site-detail-alert-wind>
</ng-container>

<ng-container *ngIf="noiseAlertData">
  <app-site-detail-alert-noise
    [data]="noiseAlertData"
  ></app-site-detail-alert-noise>
</ng-container>

<ng-container *ngIf="phAlertData">
  <app-site-detail-alert-ph [data]="phAlertData"></app-site-detail-alert-ph>
</ng-container>
