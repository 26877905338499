import { MaterialModule } from 'src/app/material/material.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SiteFormComponent } from './components/site-form/site-form.component';
import { SiteConfigRoutingModule } from './site-config-routing.module';
import { SiteCreateComponent } from './site-create/site-create.component';
import { SiteListComponent } from './site-list/site-list.component';
import { SiteUpdateComponent } from './site-update/site-update.component';
import { SiteSensorComponent } from './site-sensor/site-sensor.component';
import { SensorWbgtFormComponent } from './components/sensor-wbgt-form/sensor-wbgt-form.component';
import { SensorWindFormComponent } from './components/sensor-wind-form/sensor-wind-form.component';
import { SensorListTableComponent } from './components/sensor-list-table/sensor-list-table.component';
import { SiteDrawingComponent } from './site-drawing/site-drawing.component';
import { DrawingEditorComponent } from './components/drawing-editor/drawing-editor.component';
import { DrawingCompassEditorComponent } from './components/drawing-compass-editor/drawing-compass-editor.component';
import { SensorNoiseFormComponent } from './components/sensor-noise-form/sensor-noise-form.component';
import { SensorPhFormComponent } from './components/sensor-ph-form/sensor-ph-form.component';
import { SensorPhRangeComponent } from './components/sensor-ph-range/sensor-ph-range.component';
import { SensorNoiseStandardComponent } from './components/sensor-noise-standard/sensor-noise-standard.component';

@NgModule({
  declarations: [SiteListComponent, SiteCreateComponent, SiteFormComponent, SiteUpdateComponent, SiteSensorComponent, SensorWbgtFormComponent, SensorWindFormComponent, SensorListTableComponent, SiteDrawingComponent, DrawingEditorComponent, DrawingCompassEditorComponent, SensorNoiseFormComponent, SensorPhFormComponent, SensorPhRangeComponent, SensorNoiseStandardComponent],
  imports: [
    CommonModule,
    SiteConfigRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
})
export class SiteConfigModule {}
