import { AccountService } from 'src/app/shared/services/account.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteSensorService {
  constructor(
    private accountService: AccountService,
    private siteService: SiteService,
    private sensorService: SensorService
  ) {}

  getSiteWithOrganization(id: string) {
    return this.siteService.getWithOrganization(
      id,
      this.accountService.getOrganizationId()
    );
  }

  fetchSite(id: string) {
    return this.siteService.fetch(id);
  }

  fetchSiteWithOrganization(id: string) {
    return this.siteService.fetchWithOrganization(
      id,
      this.accountService.getOrganizationId()
    );
  }

  fetchSensor(id: string) {
    return this.sensorService.fetch(id);
  }
}
