import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SiteDetailAlertService } from '../site-detail-alert/site-detail-alert.service';

@Component({
  selector: 'app-site-detail-actual-attention-wind',
  templateUrl: './site-detail-actual-attention-wind.component.html',
  styleUrls: ['./site-detail-actual-attention-wind.component.scss'],
})
export class SiteDetailActualAttentionWindComponent implements OnChanges {
  @Input() date: string = '';

  isOuter = false; // 外部のユーザかどうか

  constructor(private siteDetailAlertService: SiteDetailAlertService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isOuter = this.siteDetailAlertService.isOuterUser();
  }
}
