<ng-container *ngIf="dataSource.length > 0; else noData">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td
        class="cursor-pointer"
        mat-cell
        *matCellDef="let element"
        (click)="onSelect(element.id)"
      >
        {{ element.id }}
      </td>
    </ng-container>

    <!-- CategoryName Column -->
    <ng-container matColumnDef="categoryName">
      <th mat-header-cell *matHeaderCellDef>種別</th>
      <td mat-cell *matCellDef="let element">{{ element.categoryName }}</td>
    </ng-container>

    <!-- PositionName Column -->
    <ng-container matColumnDef="positionName">
      <th mat-header-cell *matHeaderCellDef>設置場所</th>
      <td mat-cell *matCellDef="let element">{{ element.positionName }}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        class="cursor-pointer"
        mat-cell
        *matCellDef="let element"
        align="right"
      >
        <a (click)="onDelete(element.id)"><mat-icon>delete</mat-icon></a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="hover:bg-gray-100"
    ></tr>
  </table>
</ng-container>
<ng-template #noData> 未設定</ng-template>
