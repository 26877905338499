import { Component, OnInit, inject } from '@angular/core';
import { Storage, getBlob, getBytes, ref } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { AccessLogService } from 'src/app/shared/services/access-log.service';

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.scss'],
})
export class DownloadPdfComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private logService = inject(AccessLogService);
  private storage = inject(Storage);

  // ファイル名
  filename = '';

  async ngOnInit(): Promise<void> {
    if (this.route.snapshot.paramMap.get('filename') !== null) {
      // URIで指定されたfilenameを取得
      this.filename = this.route.snapshot.paramMap.get('filename')!;

      // アクセスログを追加
      await this.logService.addLog(
        UrlConst.SLASH +
          UrlConst.PATH_DOWNLOAD_PDF +
          UrlConst.SLASH +
          this.filename
      );

      // Cloud Storageの /download/pdf/filenameを表示する
      // このとき、ブラウザはPDFファイルをダウンロードする
      await this.downloadPdf();
    }
  }

  async downloadPdf() {
    try {
      const storageRef = ref(
        this.storage,
        'download/' + this.filename + '.pdf'
      );
      const blob = await getBlob(storageRef);
      console.log(blob);

      const reader = new FileReader();
      reader.readAsText(blob);
      reader.onload = () => {
        const url = window.URL.createObjectURL(blob);
        const container = document.getElementById('container')!;
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.width = '100%';
        iframe.height = '100%';
        container.appendChild(iframe);
      };
      reader.onerror = () => {
        console.log('FileReader.readAsText() ERROR: ', reader.error);
      };
    } catch (error) {
      console.error(error);
    }
  }
}
