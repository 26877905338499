import { UrlConst } from 'src/app/shared/constants/url-const';
import { LatestNoiseSensorData } from 'src/app/shared/interfaces/latest-noise-sensor-data';
import { Site } from 'src/app/shared/interfaces/site';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SensorNoiseFormService {
  constructor(
    private accessLogService: AccessLogService,
    private accountService: AccountService,
    private siteService: SiteService,
    private sensorService: SensorService
  ) {}

  addLog() {
    return this.accessLogService.addLog(
      UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG +
        UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG_SENSOR
    );
  }

  isValidUser() {
    return (
      this.accountService.isSysAdminGroup() ||
      this.accountService.isGeneralGroup()
    );
  }

  isGeneralGroup() {
    return this.accountService.isGeneralGroup();
  }

  getUser() {
    return this.accountService.getUser();
  }

  getSite(id: string) {
    return this.siteService.get(id);
  }

  getSiteWithOrganization(id: string) {
    return this.siteService.getWithOrganization(
      id,
      this.accountService.getOrganizationId()
    );
  }

  getSensor(id: string) {
    return this.sensorService.get(id);
  }

  getNoConfigSensors() {
    return this.sensorService.getAllWindNoConfig();
  }

  getNoConfigSensorsWithOrganization() {
    return this.sensorService.getAllNoiseNoConfigWithOrganization(
      this.accountService.getOrganizationId()
    );
  }

  //
  // setSensorToSite
  //
  // 現場へのセンサー設定
  // 1. 該当センサーデータに対して現場IDと設置場所を設定する
  // 2. 現場に対してセンサーのリファレンスを追加する
  // 3. 最新データに対してセンサー情報を設定する
  //
  async setSensorToSite(
    site: Site,
    sensorId: string,
    positionName: string,
    standardNoise: number,
    standardVibration: number
  ) {
    // センサー情報更新
    await this.sensorService.update(sensorId, {
      siteId: site.id,
      positionName: positionName,
      standardNoise: standardNoise,
      standardVibration: standardVibration,
    });

    // 現場情報更新
    await this.siteService.updateNoiseSensorRef(
      site,
      this.sensorService.getDocumentReference(sensorId)
    );

    // 最新データ更新
    const latestHistory = await this.sensorService.getLatestHistory(sensorId);
    if (latestHistory !== null) {
      const request: LatestNoiseSensorData = {
        id: sensorId,
        positionName: positionName,
      };

      request.noiseMax = latestHistory.noiseMax;
      request.noiseL5 = latestHistory.noiseL5;
      request.vibrationMax = latestHistory.vibrationMax;
      request.vibrationL10 = latestHistory.vibrationL10;

      await this.siteService.updateLatestNoiseSensorData(site.id, request);
    } else {
      await this.siteService.updateLatestNoiseSensorData(site.id, {
        id: sensorId,
        positionName: positionName,
      });
    }
  }
}
