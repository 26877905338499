import { map, Observable, shareReplay, tap } from 'rxjs';

import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AppConst } from './shared/constants/app-const';
import { UrlConst } from './shared/constants/url-const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = AppConst.APP_TITLE;
  isHandset$: Observable<boolean> = this.breakpointObserver
    // .observe(Breakpoints.Handset)
    .observe('(max-width: 960px) and (orientation: portrait)')
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}

  /**
   * URIからページ判定
   */
  public isSignInPage(): boolean {
    // サインインページ
    if (UrlConst.SLASH + UrlConst.PATH_SIGN_IN === this.router.url) {
      return true;
    }
    return false;
  }

  /**
   * デジタルサイネージページ判定
   */
  public isDigitalSignagePage(): boolean {
    const pattern = new RegExp('^\\/digital\\-signage\\/.+');
    return pattern.test(this.router.url)
  }
}
