<section>
  <div *ngIf="apiLoaded">
    <google-map
      class="google-map-container"
      width="97vw"
      height="70vh"
      [options]="mapOptions"
    >
      <map-marker
        *ngFor="let mark of marks"
        [position]="mark.position"
        [options]="mark.options!"
        [title]="mark.title!"
        [clickable]="true"
        (mapClick)="onClickMarker(mark.siteId)"
      ></map-marker>
    </google-map>
  </div>
</section>
