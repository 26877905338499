import { Subscription } from 'rxjs';
import { Account } from 'src/app/shared/classes/account';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-list-page',
  templateUrl: './account-list-page.component.html',
  styleUrls: ['./account-list-page.component.scss'],
})
export class AccountListPageComponent implements OnInit, OnDestroy {
  accounts$ = this.accountService.getAccountsStream();
  accounts: Account[] = [];
  // accounts: Account[] = [];
  accountsSubscription?: Subscription;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private logService: AccessLogService
  ) {}

  ngOnInit(): void {
    this.accountsSubscription = this.accounts$.subscribe(
      (accounts) => (this.accounts = accounts)
    );
  }

  ngOnDestroy(): void {
    this.accountsSubscription?.unsubscribe();
  }
}
