<section>
  <p class="page-title">アクセスログダウンロード</p>
  <mat-card class="download-access-log-card">
    <mat-card-content>
      <mat-list role="list">
        <mat-list-item
          role="listitem"
          *ngFor="let item of items; let i = index"
        >
          <span class="download-access-log-date">
            {{ item.year }}年{{ item.month }}月{{ item.day }}日
          </span>
          <mat-icon
            class="download-access-log-icon"
            (click)="onClickDownload(i)"
          >
            download
          </mat-icon>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>
  <p class="page-title">月別アクセスログダウンロード</p>
  <mat-card class="download-access-log-card">
    <mat-card-content>
      <mat-list role="list">
        <mat-list-item
          role="listitem"
          *ngFor="let item of monthlyItems; let i = index"
        >
          <span class="download-access-log-date">
            {{ item.year }}年{{ item.month }}月
          </span>
          <mat-icon
            class="download-access-log-icon"
            (click)="onClickMonthlyDownload(i)"
          >
            download
          </mat-icon>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>
</section>
