<mat-card class="status-signal-20">
  <div class="site-detail-alert-container">
    <span class="site-detail-alert-time">{{ date }}</span>
    に風速ステータスが
    <span class="site-detail-alert-status">警報 </span>
    になりました。
  </div>
  <div class="mt-8 text-center" *ngIf="!isOuter">
    <div>
      <a
        class="underline-offset-2 underline text-blue-900"
        href="/assets/pdf/wind.pdf"
        target="_blank"
      >
        ◆安全資料のリンク
      </a>
    </div>
  </div>
</mat-card>
