import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ChartsModule } from '../charts/charts.module';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { AccountListPageComponent } from './components/account-list-page/account-list-page.component';
import { DownloadAccessLogComponent } from './components/download-access-log/download-access-log.component';
import { DownloadDataComponent } from './components/download-data/download-data.component';
import { ExtractDataPageComponent } from './components/extract-data-page/extract-data-page.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { SignInPageComponent } from './components/sign-in-page/sign-in-page.component';
import { SignUpPageComponent } from './components/sign-up-page/sign-up-page.component';
import { SiteConfigAlertPageComponent } from './components/site-config-alert-page/site-config-alert-page.component';
import { SiteConfigCreatePageComponent } from './components/site-config-create-page/site-config-create-page.component';
import { SiteConfigDrawingCompassComponent } from './components/site-config-drawing-compass/site-config-drawing-compass.component';
import { SiteConfigDrawingPageComponent } from './components/site-config-drawing-page/site-config-drawing-page.component';
import { SiteConfigPageComponent } from './components/site-config-page/site-config-page.component';
import { SiteConfigQrPageComponent } from './components/site-config-qr-page/site-config-qr-page.component';
import { SiteConfigSensorPageComponent } from './components/site-config-sensor-page/site-config-sensor-page.component';
import { SiteDetailChartComponent } from './components/site-detail-page/components/site-detail-chart/site-detail-chart.component';
import { SiteDetailChartsComponent } from './components/site-detail-page/components/site-detail-charts/site-detail-charts.component';
import { SiteDetailSensorComponent } from './components/site-detail-page/components/site-detail-sensor/site-detail-sensor.component';
import { SiteDetailSensorsComponent } from './components/site-detail-page/components/site-detail-sensors/site-detail-sensors.component';
import { SiteDetailPageComponent } from './components/site-detail-page/site-detail-page.component';
import { SiteListPageComponent } from './components/site-list-page/site-list-page.component';
import { SiteMapPageComponent } from './components/site-map-page/site-map-page.component';
import { SiteDetailDrawingComponent } from './components/site-detail-page/components/site-detail-drawing/site-detail-drawing.component';
import { SiteDetailDrawingViewerComponent } from './components/site-detail-page/components/site-detail-drawing-viewer/site-detail-drawing-viewer.component';
import { SiteDetailDrawingCompassViewerComponent } from './components/site-detail-page/components/site-detail-drawing-compass-viewer/site-detail-drawing-compass-viewer.component';
import { SiteDetailAlertComponent } from './components/site-detail-page/components/site-detail-alert/site-detail-alert.component';
import { SiteDetailAlertWbgtComponent } from './components/site-detail-page/components/site-detail-alert-wbgt/site-detail-alert-wbgt.component';
import { SiteDetailAlertWindComponent } from './components/site-detail-page/components/site-detail-alert-wind/site-detail-alert-wind.component';
import { SiteDetailAlertTemperatureComponent } from './components/site-detail-page/components/site-detail-alert-temperature/site-detail-alert-temperature.component';
import { SiteDetailAlertNoiseComponent } from './components/site-detail-page/components/site-detail-alert-noise/site-detail-alert-noise.component';
import { SiteDetailAlertPhComponent } from './components/site-detail-page/components/site-detail-alert-ph/site-detail-alert-ph.component';
import { DigitalSignageComponent } from './digital-signage/digital-signage.component';
import { SiteDetailAlertTemperatureTomorrowComponent } from './components/site-detail-page/components/site-detail-alert-temperature-tomorrow/site-detail-alert-temperature-tomorrow.component';
import { DownloadPdfComponent } from './components/download-pdf/download-pdf.component';
import { SiteDetailHighAlertWbgtComponent } from './components/site-detail-page/components/site-detail-high-alert-wbgt/site-detail-high-alert-wbgt.component';

@NgModule({
  declarations: [
    SignInPageComponent,
    HomePageComponent,
    SiteListPageComponent,
    DownloadAccessLogComponent,
    DownloadDataComponent,
    ExtractDataPageComponent,
    SiteMapPageComponent,
    SiteConfigPageComponent,
    SiteConfigCreatePageComponent,
    SiteConfigSensorPageComponent,
    SiteConfigDrawingPageComponent,
    SiteDetailPageComponent,
    SignUpPageComponent,
    SiteConfigDrawingCompassComponent,
    AccountListPageComponent,
    SiteConfigAlertPageComponent,
    SiteConfigQrPageComponent,
    SiteDetailSensorsComponent,
    SiteDetailSensorComponent,
    SiteDetailChartsComponent,
    SiteDetailChartComponent,
    SiteDetailDrawingComponent,
    SiteDetailDrawingViewerComponent,
    SiteDetailDrawingCompassViewerComponent,
    SiteDetailAlertComponent,
    SiteDetailAlertWbgtComponent,
    SiteDetailAlertWindComponent,
    SiteDetailAlertTemperatureComponent,
    SiteDetailAlertNoiseComponent,
    SiteDetailAlertPhComponent,
    DigitalSignageComponent,
    SiteDetailAlertTemperatureTomorrowComponent,
    DownloadPdfComponent,
    SiteDetailHighAlertWbgtComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    ChartsModule,
  ],
})
export class PagesModule {}
