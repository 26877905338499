<p class="page-title">現場情報登録 / 変更</p>
<mat-card class="config-site-sensor-card">
  <mat-card-header>
    <mat-card-title class="config-site-sensor-title">
      {{ site?.name }}　センサー登録
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form
      class="form"
      [formGroup]="form"
      (keydown.enter)="$event.preventDefault()"
      autocomplete="off"
    >
      <mat-form-field>
        <mat-label>躯体最上階WBGTセンサーID</mat-label>
        <mat-select formControlName="topFloorSensor">
          <mat-option [value]="0"> 未登録 </mat-option>
          <mat-option *ngFor="let s of topFloorSensors" [value]="s.id">
            {{ s.id }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>朝礼看板WBGTセンサーID</mat-label>
        <mat-select formControlName="boardSensor">
          <mat-option [value]="0"> 未登録 </mat-option>
          <mat-option *ngFor="let s of boardSensors" [value]="s.id">
            {{ s.id }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="windEnable">
        <mat-form-field>
          <mat-label>風速センサー名(自由入力)</mat-label>
          <input
            matInput
            id="windSensorName"
            name="windSensorName"
            formControlName="windSensorName"
            placeholder="A棟"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>風速センサーID</mat-label>
          <mat-select formControlName="windSensor">
            <mat-option [value]="0"> 未設定 </mat-option>
            <mat-option *ngFor="let s of windSensors" [value]="s.id">
              {{ s.id }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <!-- loginFormのinvalidプロパティに各項目のバリデーションの総合結果が自動的に入ります -->
      <!-- タグ内でrequiredを設定することで、ふたつの項目のうちどちらかが空白ならボタンを押せない仕組みにしています  -->
      <button
        *ngIf="!updateMode"
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="form.invalid"
        (click)="create()"
      >
        登録して図面設定画面に進む
      </button>
      <button
        *ngIf="updateMode"
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="form.invalid"
        (click)="update()"
      >
        更新する
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="cancel()"
      >
        キャンセル
      </button>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <mat-error *ngIf="0 < errors.length">
      <ul>
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </mat-error>
  </mat-card-footer>
</mat-card>
