import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-site-update',
  templateUrl: './site-update.component.html',
  styleUrls: ['./site-update.component.scss'],
})
export class SiteUpdateComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  id: string | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => (this.id = params.get('id')));
  }
}
