import { AppConst } from 'src/app/shared/constants/app-const';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { Site } from 'src/app/shared/interfaces/site';
import { LoadingService } from 'src/app/shared/services/loading.service';

import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SensorListTableService } from '../sensor-list-table/sensor-list-table.service';
import { SensorNoiseFormService } from './sensor-noise-form.service';

@Component({
  selector: 'app-sensor-noise-form',
  templateUrl: './sensor-noise-form.component.html',
  styleUrls: ['./sensor-noise-form.component.scss'],
})
export class SensorNoiseFormComponent implements OnInit {
  private formBuilder = inject(UntypedFormBuilder);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private loadingService = inject(LoadingService);
  private sensorNoiseFormService = inject(SensorNoiseFormService);
  private sensorListTableService = inject(SensorListTableService);

  constructor() {}

  @Input() sensor: Sensor | null = null;
  @Output() registered = new EventEmitter<boolean>();
  @Output() canceled = new EventEmitter<boolean>();

  errors: string[] = [];
  siteId: string | null = null;
  site: Site | null = null;
  sensors: Sensor[] | null = null;

  isUpdate: boolean = false;

  standardNoise = AppConst.DEFAULT_NOISE;
  standardVibration = AppConst.DEFAULT_VIBRATION;

  form = this.formBuilder.group({
    sensorId: ['', [Validators.required]],
    positionName: ['', [Validators.required]],
  });
  get sensorId() {
    return this.form.get('sensorId');
  }
  get positionName() {
    return this.form.get('positionName');
  }

  async ngOnInit() {
    this.sensorNoiseFormService.addLog();

    this.route.paramMap.subscribe((params) => (this.siteId = params.get('id')));

    // 管理者か一般ユーザのみアクセス可能
    if (!this.sensorNoiseFormService.isValidUser()) {
      console.log('user is not sysadmin or general');
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
      return;
    }

    // 一般ユーザの場合は、自分のサイトのみアクセス可能
    if (this.sensorNoiseFormService.isGeneralGroup()) {
      console.log('user is general');
      const account = this.sensorNoiseFormService.getUser();
      if (account === null) {
        console.log('account is null');
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      }
      if (this.siteId !== account.user) {
        console.log('siteId != user');
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      }
    }

    // サイトIDが指定されていない場合は、サイト一覧に戻る
    if (this.siteId === null) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      return;
    }

    // サイト情報取得
    this.site = await this.sensorNoiseFormService.getSiteWithOrganization(
      this.siteId
    );

    // サイトが取得できなかった場合は、サイト一覧に戻る
    if (this.site === null) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      return;
    }

    // フォームのプルダウンリスト作成（未使用センサー）
    const sensors: Sensor[] | null =
      await this.sensorNoiseFormService.getNoConfigSensorsWithOrganization();

    this.sensors = sensors;

    // センサーが指定されていたら更新モード
    if (
      this.sensor !== null &&
      this.sensor.id !== undefined &&
      this.sensors !== null
    ) {
      this.isUpdate = true;

      // 未使用センサーに今のセンサーを追加する
      const usedSensor = await this.sensorNoiseFormService.getSensor(
        this.sensor.id
      );
      this.sensors.unshift(usedSensor!);

      this.form.setValue({
        sensorId: this.sensor.id,
        positionName: this.sensor.positionName ? this.sensor.positionName : '',
      });
      this.standardNoise = this.sensor.standardNoise
        ? this.sensor.standardNoise
        : AppConst.DEFAULT_NOISE;
      this.standardVibration = this.sensor.standardVibration
        ? this.sensor.standardVibration
        : AppConst.DEFAULT_VIBRATION;
    } else {
      this.isUpdate = false;
      // デフォルト値を設定
      this.form.setValue({
        standardNoise: AppConst.DEFAULT_NOISE.toString(),
        standardVibration: AppConst.DEFAULT_VIBRATION.toString(),
      });
    }
  }

  async onSubmit() {
    try {
      this.loadingService.startLoading();

      // 更新でIDが変わっている場合は一旦古いセンサーを削除する
      if (this.isUpdate) {
        if (this.sensor === null) {
          this.registered.emit(false);
          return;
        }

        // センサー変更の時は一旦今のセンサー情報を削除して、後に控える登録処理を実行する
        if (this.sensorId?.value !== this.sensor.id) {
          try {
            await this.sensorListTableService.deleteSensor(
              this.site!,
              this.sensor!
            );
          } catch (error) {
            console.log('SiteConfigPageComponent.deleteConfig(): ', error);
            return;
          }
        }
      }

      // 設置場所の文言だけの変更
      await this.sensorNoiseFormService.setSensorToSite(
        this.site!,
        this.sensorId?.value,
        this.positionName?.value,
        this.standardNoise,
        this.standardVibration
      );

      this.registered.emit(true);
      if (this.isUpdate === true) {
        this.isUpdate = false;
        this.sensor = null;
      }
      this.form.reset();
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.stopLoading();
    }
  }

  cancel() {
    this.isUpdate = false;
    this.sensor = null;

    this.canceled.emit(true);
  }
}
