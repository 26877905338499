import { Site } from 'src/app/shared/interfaces/site';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DrawingEditorService } from './drawing-editor.service';
import { DrawingSensor } from './drawing-sensor.interface';

@Component({
  selector: 'app-drawing-editor',
  templateUrl: './drawing-editor.component.html',
  styleUrls: ['./drawing-editor.component.scss'],
})
export class DrawingEditorComponent implements OnInit {
  // 19 : 6 = svgWidth : svgHeight
  // svgWidth = svgHeight * 19 / 6
  @Input() site: Site = {} as Site;
  @Input() svgWidth: number = (400 * 19) / 6;
  @Input() svgHeight: number = 400;
  @Input() imgUrl: string = '';
  @Output() drawingCanceled = new EventEmitter<boolean>();
  @Output() drawingSaved = new EventEmitter<boolean>();

  // 画像が選択されていない状態の背景色
  svgBgColor = 'lightgray';
  // 画像が選択されているかどうか
  isImageLoaded = false;

  // 現場に風速センサーがあるかどうか
  hasWindSensor = false;

  title = 'ng-svg';

  // ファイルアップロード用HTMLエレメント
  input = document.createElement('input');

  // アップロードされた画像ファイル
  imageFile: File | null = null;

  isDragging = false;
  dragStartX = 0;
  dragStartY = 0;

  drawingSensors: DrawingSensor[] = [];

  // 風速センサーコンパスの北の向き
  compassAngle: number = 0;

  constructor(private drawingEditorService: DrawingEditorService) {}

  async ngOnInit(): Promise<void> {
    if (this.site === null) {
      this.onCancel();
      return;
    }

    if (
      this.site.drawing !== undefined &&
      this.site.drawing.imageUrl !== undefined
    ) {
      this.setImageBackground(this.site.drawing.imageUrl);
    } else {
      this.setDefaultBackground();
    }

    this.drawingSensors =
      await this.drawingEditorService.getDrawingSensorsFromSite(this.site);

    this.setupInputElement();

    this.hasWindSensor = this.drawingEditorService.hasWindSensor(this.site);
    if (this.hasWindSensor) {
      if (this.site.compassAngle !== undefined) {
        this.compassAngle = this.site.compassAngle;
      }
    }
  }

  // マウスをクリックしたときの処理
  onMouseDown(event: MouseEvent, sensor: DrawingSensor) {
    this.isDragging = true;
    if (sensor.drawing === undefined) {
      return;
    }
    this.dragStartX = event.clientX - sensor.drawing.x;
    this.dragStartY = event.clientY - sensor.drawing.y;
  }

  // クリックした状態でマウスを動かしたときの処理
  onMouseMove(event: MouseEvent, sensor: DrawingSensor) {
    if (!this.isDragging) return;

    if (sensor.drawing === undefined) {
      return;
    }

    const dx: number = event.clientX - this.dragStartX;
    const dy: number = event.clientY - this.dragStartY;

    if (dx < 0) {
      sensor.drawing.x = 0;
    } else if (dx + sensor.drawing.width > this.svgWidth) {
      sensor.drawing.x = this.svgWidth - sensor.drawing.width;
    } else {
      sensor.drawing.x = dx;
    }

    if (dy < 0) {
      sensor.drawing.y = 0;
    } else if (dy + sensor.drawing.height > this.svgHeight) {
      sensor.drawing.y = this.svgHeight - sensor.drawing.height;
    } else {
      sensor.drawing.y = dy;
    }
  }

  // マウスを離したときの処理
  endMouseUp() {
    this.isDragging = false;
  }

  // 画像アップローダー
  onUploadClick() {
    this.input.click();
  }

  // 画像設定をやめて一覧に戻る
  onCancel() {
    this.setDefaultBackground();
    this.drawingCanceled.emit(true);
  }

  // 画像を設定する
  async onSave() {
    // 画像が新規もしくは変更されている場合は画像をCloud Storageにアップロードする
    if (this.imageFile !== null) {
      await this.drawingEditorService.uploadDrawingImage(
        this.site.id,
        this.imageFile
      );
    }

    // センサーの位置情報を保存する
    if (this.drawingSensors.length > 0) {
      await this.drawingEditorService.updateDrawingPositions(
        this.site.id,
        this.drawingSensors
      );
    }

    if (this.hasWindSensor) {
      await this.drawingEditorService.updateCompassAngle(
        this.site,
        this.compassAngle
      );
    }
    // 画像設定を完了して一覧に戻る
    this.drawingSaved.emit(true);
  }

  setupInputElement() {
    this.input.type = 'file';
    this.input.accept = 'image/*';
    this.input.onchange = (event) => {
      if (event.target === null) {
        return;
      }

      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files === null) {
        return;
      }

      const file = files[0];
      this.imageFile = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const svg = document.getElementById('svg');
          if (svg === null) {
            return;
          }

          // 画像のサイズを取得
          const imgWidth = img.width;
          const imgHeight = img.height;

          // 画像の縦横比を計算
          const aspectRatio = imgWidth / imgHeight;

          // 画像の縦横比を維持したまま、SVGの横のサイズを設定
          // 縦は固定
          this.svgWidth = this.svgHeight * aspectRatio;
          svg.style.backgroundImage = `url(${reader.result})`;
          this.isImageLoaded = true;
        };
      };
    };
  }

  setImageBackground(imageUrl: string) {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const svg = document.getElementById('svg') as HTMLElement;

      // 画像のサイズを取得
      const imgWidth = img.width;
      const imgHeight = img.height;

      // 画像の縦横比を計算
      const aspectRatio = imgWidth / imgHeight;

      // 画像の縦横比を維持したまま、SVGの横のサイズを設定
      // 縦は固定
      this.svgWidth = this.svgHeight * aspectRatio;
      svg.style.backgroundImage = `url(${imageUrl})`;
      this.isImageLoaded = true;
    };
  }

  // SVGの背景をデフォルト設定
  setDefaultBackground() {
    // SVG背景色を設定する
    const svg = document.getElementById('svg') as HTMLElement;
    svg.style.backgroundColor = this.svgBgColor;
    svg.style.backgroundImage = 'none';
    // svgWidthを初期化
    this.svgWidth = (this.svgHeight * 19) / 6;
    // this.inputによる画像のロードをキャンセルする
    this.input.value = '';

    this.isImageLoaded = false;
  }

  // 風速センサー向けコンパスの北の向きを取得
  onCompassAngle(angle: number) {
    this.compassAngle = angle;
  }
}
