import { Site } from 'src/app/shared/interfaces/site';
import { SensorService } from 'src/app/shared/services/sensor.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteConfigDrawingPageService {
  constructor(private sensorService: SensorService) {}

  getSensorsFromSite(site: Site) {
    return this.sensorService.getFromSiteId(site.id);
  }
}
