import { Observable, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Account } from '../../classes/account';
import { UrlConst } from '../../constants/url-const';
import { Site } from '../../interfaces/site';
import { AccountService } from '../../services/account.service';
import { SiteService } from '../../services/site.service';
import {
  WeatherNewsApiResponseSrf,
  WeatherNewsService,
} from '../../services/weather-news.service';
import { UtilFunctions } from '../../utils/util-functions';
import { QrCodeDialogComponent } from '../qr-code-dialog/qr-code-dialog.component';
import { WnDialogComponent } from '../wn-dialog/wn-dialog.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  account: Account | null = null;
  site: Site | null = null;

  subscriptions = new Subscription();

  weatherNewsSrf$: Observable<WeatherNewsApiResponseSrf[]> = of([]);

  isSummer = UtilFunctions.isSummer();
  isWinter = this.accountService.isWestUser()
    ? UtilFunctions.isWinter()
    : false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public accountService: AccountService,
    private siteService: SiteService,
    public dialog: MatDialog,
    private weatherNewsService: WeatherNewsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.account = this.accountService.getUser();
    this.site = await this.siteService.get(this.account?.user!);

    if (this.site !== null) {
      const geo = this.site.geopoint;
      if (geo !== undefined) {
        this.weatherNewsSrf$ = this.weatherNewsService.fetchSrfByCoordinate(
          geo.latitude,
          geo.longitude
        );
      }
    }
  }

  openQRCodeDialog() {
    if (this.site === null) {
      return;
    }
    this.dialog.open(QrCodeDialogComponent, {
      data: {
        siteId: this.site?.id!,
        siteUrl:
          'https://' +
          environment.fqdn +
          UrlConst.SLASH +
          'q/' +
          this.site.uuid,
      },
    });
  }

  goGeneralSiteDetail() {
    const account = this.accountService.getUser();
    if (account === null) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
      return;
    }

    if (account.defaultSite) {
      this.router.navigate([
        UrlConst.SLASH + UrlConst.PATH_SITE_DETAIL,
        account.defaultSite,
      ]);
    } else {
      this.router.navigate([
        UrlConst.SLASH + UrlConst.PATH_SITE_DETAIL,
        account.user,
      ]);
    }
  }

  async onWnClick() {
    const url = this.router.url;
    const reg = new RegExp('^/site-detail/(.*)$');
    const result = url.match(reg);

    if (result === null) return;

    this.site = await this.siteService.get(result[1]);

    if (this.site !== null) {
      const geo = this.site.geopoint;
      if (geo !== undefined) {
        this.weatherNewsSrf$ = this.weatherNewsService.fetchSrfByCoordinate(
          geo.latitude,
          geo.longitude
        );
      }
    }

    this.subscriptions.add(
      this.weatherNewsSrf$.subscribe((srf) => {
        this.dialog.open(WnDialogComponent, {
          data: { srf: srf },
          width: '1200px',
        });
      })
    );
  }
}
