import { Sensor } from 'src/app/shared/interfaces/sensor';
import { Site } from 'src/app/shared/interfaces/site';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SensorListTableService {
  constructor(
    private siteService: SiteService,
    private sensorService: SensorService
  ) {}

  fetchSensorsFromSiteId(site: Site) {
    return this.sensorService.fetchFromSiteId(site.id);
  }

  getSensorsFromSiteId(site: Site) {
    return this.sensorService.getFromSiteId(site.id);
  }

  //
  // deleteSensor
  //
  // 登録の逆
  // 最新データ(latest_site_data)からセンサー情報削除更新
  // 現場データ(sites)からセンサー情報削除更新
  // センサーデータ(sensors)から現場情報削除更新
  //
  async deleteSensor(site: Site, sensor: Sensor) {
    if (sensor.categoryName === 'WBGT') {
      await this.siteService.deleteLatestWbgtSensorData(site.id, sensor);
      await this.siteService.deleteWbgtSensorRef(site, sensor);
      await this.sensorService.deleteSiteFromSensor(sensor);
    }

    if (sensor.categoryName === 'WIND') {
      await this.siteService.deleteLatestWindSensorData(site.id, sensor);
      await this.siteService.deleteWindSensorRef(site, sensor);
      await this.sensorService.deleteSiteFromSensor(sensor);
    }

    if (sensor.categoryName === 'NOISE') {
      await this.siteService.deleteLatestNoiseSensorData(site.id, sensor);
      await this.siteService.deleteNoiseSensorRef(site, sensor);
      await this.sensorService.deleteSiteFromSensor(sensor);
    }

    if (sensor.categoryName === 'PH') {
      await this.siteService.deleteLatestPhSensorData(site.id, sensor);
      await this.siteService.deletePhSensorRef(site, sensor);
      await this.sensorService.deleteSiteFromSensor(sensor);
    }
  }
}
