<div class="p-4 pt-8">
  <div>
    <span class="text-4xl font-bold text-red-500"
      >タイル工事等の作業計画の検討をしてください。
    </span>
  </div>
  <div class="mt-8">
    <span class="text-4xl font-bold">◆明日以降の予報</span>
  </div>
  <div class="my-8 flex shrink-0">
    <div *ngFor="let srf of tomorrowSrf" class="shrink-0">
      <div>
        <div class="border-b-slate-300 border-2 text-center">
          <span class="text-4xl">{{ srf.hour }}時</span>
        </div>
        <div class="border-b-slate-300 border-2">
          <img src="{{ srf.wxIconUrl }}" class="h-20" />
        </div>
        <div class="border-b-slate-300 border-2 text-center">
          <span class="text-4xl text-red-500 font-bold" *ngIf="srf.temp <= 5">
            {{ srf.temp }}℃
          </span>
          <span class="text-4xl" *ngIf="srf.temp > 5">{{ srf.temp }}℃</span>
        </div>
      </div>
    </div>
  </div>
  <div class="my-16">
    <span class="text-4xl font-bold">◆技術資料のリンク</span>
    <div class="mt-4 ml-4">
      <a
        class="underline-offset-2 underline text-4xl text-blue-500"
        href="/assets/pdf/temperature.pdf"
        target="_blank"
      >
        寒冷期における施工上の注意事項
      </a>
    </div>
  </div>
</div>
