import { catchError, firstValueFrom, map, Observable, of, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';

import { AppConst } from '../constants/app-const';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapApiService {
  constructor(private http: HttpClient, private geocoder: MapGeocoder) {}

  setApiUrl(): Observable<boolean> {
    return this.http
      .jsonp(
        AppConst.GOOGLE_MAP_API_KEY_URL + AppConst.GOOGLE_MAP_API_KEY,
        'callback'
      )
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => of(false))
      );
  }

  async getGeometry(address: string) {
    const geoMap: { latitude: number; longitude: number } = {
      latitude: 0,
      longitude: 0,
    };

    const result = await firstValueFrom(
      this.geocoder.geocode({ address: address } as google.maps.GeocoderRequest)
    );

    geoMap.latitude = result.results[0].geometry.location.lat();
    geoMap.longitude = result.results[0].geometry.location.lng();

    return geoMap;
  }
}
