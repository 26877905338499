import { AppConst } from 'src/app/shared/constants/app-const';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { Group } from 'src/app/shared/interfaces/group';
import { SignUpRequest } from 'src/app/shared/interfaces/sign-up-request';
import { Site } from 'src/app/shared/interfaces/site';
import { SiteManagementDepartment } from 'src/app/shared/interfaces/site-management-department';
import { SiteUpdateRequest } from 'src/app/shared/interfaces/site-update-request';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';
import { environment } from 'src/environments/environment';
import { v4 as uuidV4 } from 'uuid';

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SiteFormService } from './site-form.service';

@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss'],
})
export class SiteFormComponent implements OnInit {
  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private loadingService: LoadingService,
    public siteFormService: SiteFormService
  ) {}

  @Input() id: string | null = '';
  site: Site | null = null;
  departments: SiteManagementDepartment[] = [];
  departments$ = this.siteFormService.getAllDepartment();
  errors: string[] = [];

  managementDepartmentId: string = '';
  uuid: string = uuidV4();
  siteUrl: string = '';
  address1: string = '';
  address2: string = '';

  form = this.formBuilder.group({
    id: [
      '',
      [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(9),
        Validators.maxLength(10),
      ],
    ],
    name: ['', Validators.required],
    address1: ['', Validators.required],
    address2: [''],
    departmentId: ['', Validators.required],
    emails: this.formBuilder.array([]),
  });

  async ngOnInit(): Promise<void> {
    if (!this.siteFormService.apiLoaded) {
      try {
        await this.siteFormService.loadApi();
      } catch (error) {
        console.log('ngOnInit', error);
      }
    }

    // this.departments = await this.siteManagementDepartmentService.getAll();
    this.departments = await this.departments$;
    this.siteUrl =
      'https://' + environment.fqdn + UrlConst.SLASH + 'q/' + this.uuid;

    if (this.id) {
      this.site = await this.siteFormService.getSiteWithOrganization(this.id);

      if (this.site === null) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
        return;
      }

      this.form.get('id')?.setValue(this.site.id);
      this.form.get('name')?.setValue(this.site.name);
      if (this.site.address1) {
        this.form.get('address1')?.setValue(this.site.address1);
      } else {
        this.form.get('address1')?.setValue(this.site.address);
      }

      if (this.site.address2) {
        this.form.get('address2')?.setValue(this.site.address2);
      }

      this.managementDepartmentId = this.site.managementDepartmentId;
      this.form.get('departmentId')?.setValue(this.site.managementDepartmentId);

      if (this.site.uuid) {
        this.siteUrl =
          'https://' +
          environment.fqdn +
          UrlConst.SLASH +
          'q/' +
          this.site.uuid;
      }
    }
  }

  async onClick(next: boolean) {
    const siteManagementDepartment: SiteManagementDepartment | undefined =
      this.departments.find((d) => {
        return d.id === this.form.get('departmentId')?.value;
      });

    if (siteManagementDepartment === undefined) {
      return;
    }

    const request: SiteUpdateRequest = {
      id: this.form.get('id')?.value,
      organizationId: this.siteFormService.getOrganizationId(),
      name: this.form.get('name')?.value,
      address1: this.form.get('address1')?.value,
      address2: this.form.get('address2')?.value,
      uuid: this.uuid,
      managementDepartmentId: this.form.get('departmentId')?.value,
      managementDepartmentName: siteManagementDepartment.name,
      updatedAt: UtilFunctions.now(),
    };

    request.address2 = this.form.get('address2')?.value;
    request.address = request.address1 + this.form.get('address2')?.value;

    // 新規もしくは住所が変わった場合、もしくはgeopointがFirestoreに保存されてない時
    if (
      this.site === null ||
      this.site.geopoint === undefined ||
      (this.site && this.site.address !== request.address)
    ) {
      const geo = await this.siteFormService.getGeometry(request.address);
      request.geopoint = this.siteFormService.getGeoPoint(geo);
    }

    if (this.site === null) {
      this.create(request, next);
    } else {
      this.update(request);
    }
  }

  onCancel(): void {
    this.site = null;
    this.departments = [];
    this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
  }

  async create(request: SiteUpdateRequest, next: boolean) {
    try {
      this.loadingService.startLoading();
      await this.siteFormService.createSite(request);

      // 作成する現場用の一般ユーザと外部ユーザを作成する
      const generalGroup: Group = await this.siteFormService.getGeneralGroup();

      const generalRequest: SignUpRequest = {
        organizationId: this.siteFormService.getOrganizationId(),
        user: request.id,
        password: AppConst.SITE_PASSWORD,
        groupId: generalGroup.id,
        groupName: generalGroup.name,
        groupRef: this.siteFormService.getGroupReference(generalGroup.id),
      };
      await this.siteFormService.signUp(generalRequest);

      const outerGroup: Group = await this.siteFormService.getOuterGroup();

      const outerRequest: SignUpRequest = {
        organizationId: this.siteFormService.getOrganizationId(),
        user: request.id + 'p',
        password: AppConst.OUTER_PASSWORD,
        groupId: outerGroup.id,
        groupName: outerGroup.name,
        groupRef: this.siteFormService.getGroupReference(outerGroup.id),
      };
      await this.siteFormService.signUp(outerRequest);

      if (next) {
        this.router.navigate([
          UrlConst.SLASH +
            UrlConst.PATH_SITE_CONFIG +
            UrlConst.SLASH +
            UrlConst.PATH_SITE_CONFIG_SENSOR +
            UrlConst.SLASH,
          request.id,
        ]);
      } else {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.stopLoading();
    }
  }

  async update(request: SiteUpdateRequest): Promise<void> {
    try {
      this.loadingService.startLoading();
      await this.siteFormService.updateSite(request);
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.stopLoading();
    }
  }
}
