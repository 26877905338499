<div class="w-full p-8 h-screen border border-red-500 flex justify-center items-center align-middle">
  <div class="w-full text-8xl">
    <div class="w-full border border-black">
      <div class="p-4 font-bold text-center border border-black bg-gray-300">騒音・振動計</div>
      <div class="p-4 text-right text-6xl border border-black bg-gray-300">更新日時: {{ date }}</div>
      <div class="w-full flex">
        <div class="p-4 flex-1 font-bold text-center border border-black">騒音</div>
        <div class="p-4 flex-1 font-bold text-center border border-black">振動</div>
      </div>
      <div class="w-full flex">
        <div class="p-4 flex-1 text-right border border-black">{{ noiseL5 | number: ".1" }} db　</div>
        <div class="p-4 flex-1 text-right border border-black">{{ vibrationL10 | number: ".1" }} db　</div>
      </div>
    </div>
  </div>
</div>
