import { UrlConst } from 'src/app/shared/constants/url-const';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { Site } from 'src/app/shared/interfaces/site';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-site-config-sensor-page',
  templateUrl: './site-config-sensor-page.component.html',
  styleUrls: ['./site-config-sensor-page.component.scss'],
})
export class SiteConfigSensorPageComponent implements OnInit {
  windEnable = true;
  strNextPage: string = '';
  errors: string[] = [];
  siteId: string | null = null;
  site: Site | null = null;
  topFloorSensors: Sensor[] | null = null;
  boardSensors: Sensor[] | null = null;
  windSensors: Sensor[] | null = null;
  topFloorSensor: Sensor | null = null;
  boardSensor: Sensor | null = null;
  windSensor: Sensor | null = null;

  updateMode: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private siteService: SiteService,
    private sensorService: SensorService,
    private accountService: AccountService,
    private logService: AccessLogService
  ) {}

  form = this.formBuilder.group({
    topFloorSensor: [''],
    boardSensor: [''],
    windSensorName: [''],
    windSensor: [''],
    validator: windSensorValidator,
  });

  async ngOnInit() {
    await this.logService.addLog(
      UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_SENSOR
    );

    if (
      !(
        this.accountService.isSysAdminGroup() ||
        this.accountService.isGeneralGroup()
      )
    ) {
      console.log('user is not sysadmin or general');
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
      return;
    }

    this.siteId = this.route.snapshot.paramMap.get('id');
    if (this.accountService.isGeneralGroup()) {
      console.log('user is general');
      const _account = this.accountService.getUser();
      if (_account === null) {
        console.log('account is null');
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      }
      if (this.siteId !== _account?.user) {
        console.log('siteId != user');
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SIGN_IN]);
        return;
      }
    }

    if (this.siteId === null) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
    } else {
      this.site = await this.siteService.get(this.siteId);

      if (this.site === null) {
        this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      }

      const topFloorSensor = await this.sensorService.getTopFloorFromSiteId(
        this.siteId
      );
      this.topFloorSensor = topFloorSensor;
      if (topFloorSensor !== null) {
        this.form.get('topFloorSensor')?.setValue(topFloorSensor.id);
      }
      const boardSensor = await this.sensorService.getBoardFromSiteId(
        this.siteId
      );
      this.boardSensor = boardSensor;
      if (boardSensor !== null) {
        this.form.get('boardSensor')?.setValue(boardSensor.id);
      }
      const windSensor = await this.sensorService.getWindFromSiteId(
        this.siteId
      );
      this.windSensor = windSensor;
      if (windSensor !== null) {
        this.form.get('windSensorName')?.setValue(windSensor.position);
        this.form.get('windSensor')?.setValue(windSensor.id);
      }

      // フォームのプルダウンリスト作成
      let sensors: Sensor[] | null = null;
      let windSensors: Sensor[] | null = null;
      if (topFloorSensor || boardSensor || windSensor) {
        this.updateMode = true;
        sensors = await this.sensorService.getAllWithSiteConfig(this.siteId);
        windSensors = await this.sensorService.getAllWindWithSiteConfig(
          this.siteId
        );
      } else {
        sensors = await this.sensorService.getAllNoConfig();
        windSensors = await this.sensorService.getAllWindNoConfig();
      }

      this.windSensors = windSensors;
      this.topFloorSensors = sensors;
      this.boardSensors = sensors;
    }
  }

  async createTopFloor() {
    // 躯体最上階
    await this.sensorService.update(this.form.get('topFloorSensor')?.value, {
      siteId: this.site!.id,
      position: 'top_floor',
    });

    const latestTopFloorHistory = await this.sensorService.getLatestHistory(
      this.form.get('topFloorSensor')?.value
    );
    if (latestTopFloorHistory !== null) {
      await this.siteService.updateLatestTopFloorData(this.site!.id, {
        topFloorDate: latestTopFloorHistory?.date,
        topFloorTemperature: latestTopFloorHistory?.temperature,
        topFloorHumidity: latestTopFloorHistory?.humidity,
        topFloorWbgt: latestTopFloorHistory?.wbgt,
      });
    }

    await this.siteService.updateSensorRef(
      this.site!,
      'topFloor',
      this.sensorService.getDocumentPath(this.form.get('topFloorSensor')?.value)
    );
  }

  async createBoard() {
    await this.sensorService.update(this.form.get('boardSensor')?.value, {
      siteId: this.site!.id,
      position: 'board',
    });
    const latestBoardHistory = await this.sensorService.getLatestHistory(
      this.form.get('boardSensor')?.value
    );
    if (latestBoardHistory !== null) {
      await this.siteService.updateLatestBoardData(this.site!.id, {
        boardDate: latestBoardHistory.date,
        boardTemperature: latestBoardHistory.temperature,
        boardHumidity: latestBoardHistory.humidity,
        boardWbgt: latestBoardHistory.wbgt,
      });
    }

    await this.siteService.updateSensorRef(
      this.site!,
      'board',
      this.sensorService.getDocumentReference(
        this.form.get('boardSensor')?.value
      )
    );
  }

  async create() {
    try {
      this.loadingService.startLoading();
      // センサーに現場と配置の情報を追加する
      // 最新現場データをセンサーの最新データで更新する

      // 躯体最上階
      if (this.form.get('topFloorSensor')?.value) {
        await this.createTopFloor();
      }

      // 朝礼看板前
      if (this.form.get('boardSensor')?.value) {
        await this.createBoard();
      }

      // 風速センサー
      if (this.form.get('windSensor')?.value) {
        await this._newWindSensor();
      }

      this.router.navigate([
        UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_DRAWING,
        this.siteId,
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.stopLoading();
    }
  }

  async update() {
    if (!this.updateMode) {
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
      return;
    }

    try {
      this.loadingService.startLoading();
      // 躯体最上階センサーの指定が変わった
      if (
        this.topFloorSensor?.id !== undefined &&
        this.topFloorSensor?.id !== this.form.get('topFloorSensor')?.value
      ) {
        // センサーから現場情報を削除
        await this.sensorService.deleteSiteFromPosition(
          this.siteId!,
          'top_floor'
        );

        // 現場からセンサーリファレンスを削除
        await this.siteService.deleteSensorRef(this.site!, 'topFloor');

        // 未登録現場からセンサーを外す行為なのか、センサーを変更する行為なのか
        // 0: センサー外し
        // 0以外: センサー変更
        if (this.form.get('topFloorSensor')?.value === 0) {
          await this.siteService.deleteLatestTopFloorData(this.siteId!);
        } else {
          // センサーに現場情報と設置場所を設定
          await this.sensorService.update(
            this.form.get('topFloorSensor')?.value,
            {
              siteId: this.site!.id,
              position: 'top_floor',
            }
          );

          // センサーの最新データをlatest_update_dataに設定する
          const latestTopFloorHistory =
            await this.sensorService.getLatestHistory(
              this.form.get('topFloorSensor')?.value
            );
          if (latestTopFloorHistory !== null) {
            await this.siteService.updateLatestTopFloorData(this.site!.id, {
              topFloorDate: latestTopFloorHistory.date,
              topFloorTemperature: latestTopFloorHistory.temperature,
              topFloorHumidity: latestTopFloorHistory.humidity,
              topFloorWbgt: latestTopFloorHistory.wbgt,
            });
          }

          await this.siteService.updateSensorRef(
            this.site!,
            'topFloor',
            this.sensorService.getDocumentReference(
              this.form.get('topFloorSensor')?.value
            )
          );
        }
      } else if (
        this.topFloorSensor?.id === undefined &&
        this.form.get('topFloorSensor')?.value
      ) {
        await this.createTopFloor();
      }

      // 朝礼看板のセンサーが変わった
      if (
        this.boardSensor?.id !== undefined &&
        this.boardSensor?.id !== this.form.get('boardSensor')?.value
      ) {
        // センサーから現場情報を削除
        await this.sensorService.deleteSiteFromPosition(this.siteId!, 'board');

        // 現場からセンサーリファレンスを削除
        await this.siteService.deleteSensorRef(this.site!, 'board');

        // 未登録現場からセンサーを外す行為なのか、センサーを変更する好意なのか
        // 0: センサー外し
        // 0以外: センサー変更
        if (this.form.get('boardSensor')?.value === 0) {
          await this.siteService.deleteLatestBoardData(this.siteId!);
        } else {
          await this.sensorService.update(this.form.get('boardSensor')?.value, {
            siteId: this.site!.id,
            position: 'board',
          });

          const latestBoardHistory = await this.sensorService.getLatestHistory(
            this.form.get('boardSensor')?.value
          );

          if (latestBoardHistory !== null) {
            await this.siteService.updateLatestBoardData(this.site!.id, {
              boardDate: latestBoardHistory?.date,
              boardTemperature: latestBoardHistory?.temperature,
              boardHumidity: latestBoardHistory?.humidity,
              boardWbgt: latestBoardHistory?.wbgt,
            });
          }

          await this.siteService.updateSensorRef(
            this.site!,
            'board',
            this.sensorService.getDocumentReference(
              this.form.get('boardSensor')?.value
            )
          );
        }
      } else if (
        this.boardSensor?.id === undefined &&
        this.form.get('boardSensor')?.value
      ) {
        await this.createBoard();
      }

      // 風速センサー
      await this._changeWindSensor();

      // 更新の場合は、現場設定画面に戻す
      this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.stopLoading();
    }
  }

  cancel() {
    this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG]);
  }

  async _newWindSensor(): Promise<void> {
    try {
      await this.sensorService.update(this.form.get('windSensor')?.value, {
        siteId: this.site!.id,
        position: this.form.get('windSensorName')?.value,
      });

      const latestWindHistory = await this.sensorService.getLatestWindHistory(
        this.form.get('windSensor')?.value
      );
      if (latestWindHistory !== null) {
        await this.siteService.updateLatestWindData(this.site!.id, {
          windDate: latestWindHistory.date,
          windSpeed: latestWindHistory.windSpeed,
          windDirection: latestWindHistory.windDirection,
        });
      }

      await this.siteService.updateSensorRef(
        this.site!,
        'wind',
        this.sensorService.getDocumentReference(
          this.form.get('windSensor')?.value
        )
      );
    } catch (error) {
      console.error(error);
    }
  }

  async _changeWindSensor(): Promise<void> {
    // 元々設定されていた？ - (No) -> (新規扱い) センサーへの現場IDと設置場所追加と、latest_site_dataの変更
    // - (Yes) -> 変わったのは設置場所？センサー？
    // 設置場所の時: センサーの設置場所変更
    // センサーの時:
    //   元のセンサーから現場IDと設置場所を削除
    //   latest_site_dataの情報更新
    //   新しいセンサーに対しては新規扱い
    if (this.windSensor === null) {
      // 新規扱い
      try {
        await this._newWindSensor();
      } catch (error) {
        console.error(error);
      }
    } else if (this.windSensor.id !== this.form.get('windSensor')?.value) {
      // センサー変更あり
      const _sensorId: string = this.form.get('windSensor')?.value;

      // センサーを外した
      await this.siteService.deleteLatestWindData(this.siteId!);
      await this.sensorService.deleteSiteFromSensor(this.windSensor);
      await this.siteService.deleteSensorRef(this.site!, 'wind');
      if (this.form.get('windSensor')?.value !== 0) {
        // センサー変更
        // センサーを外しているので、新たに新しいセンサーで設定
        await this._newWindSensor();
      }
    } else if (
      this.windSensor.position !== this.form.get('windSensorName')?.value
    ) {
      // 設置場所変更
      await this.sensorService.update(this.form.get('windSensor')?.value, {
        siteId: this.site!.id,
        position: this.form.get('windSensorName')?.value,
      });
    }
  }
}

function windSensorValidator(form: AbstractControl) {
  const _name = form.get('windSensorName')?.value;
  const _id = form.get('windSensor')?.value;

  // 設置場所とセンサー両方ある
  if (_name !== undefined && _name !== '' && _id !== undefined && _id !== 0) {
    return null;
  }
  // 設置場所とセンサー両方ない
  if (
    (_name === undefined || _name === '') &&
    (_id === undefined || _id === 0)
  ) {
    form.get('windSensorName')!.setErrors(null);
    form.get('windSensor')!.setErrors(null);
    return null;
  }
  // 設置場所のみ指定
  if (_name !== undefined && _name !== '') {
    return form.get('windSensor')!.setErrors({ invalid: true });
  }
  // センサーのみ指定
  if (_id !== undefined && _id !== 0) {
    return form.get('windSensorName')!.setErrors({ invalid: true });
  }
}
