<div class="text-center">
  <form
    class="form"
    [formGroup]="form"
    (keydown.enter)="$event.preventDefault()"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>騒音基準値(dB数値入力)</mat-label>
      <input
        matInput
        id="standardNoise"
        name="standardNoise"
        formControlName="standardNoise"
        placeholder="80"
        required
      />
      <mat-error *ngIf="standardNoise?.errors?.['required']"
        >必須です</mat-error
      >
      <mat-error
        *ngIf="standardNoise?.errors?.['min'] || standardNoise?.errors?.['max']"
        >0-100です</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>振動基準値(dB数値入力)</mat-label>
      <input
        matInput
        id="standardVibration"
        name="standardVibration"
        formControlName="standardVibration"
        placeholder="80"
        required
      />
      <mat-error *ngIf="standardVibration?.errors?.['required']"
        >必須です</mat-error
      >
      <mat-error
        *ngIf="standardVibration?.errors?.['min'] || standardVibration?.errors?.['max']"
        >0-100です</mat-error
      >
    </mat-form-field>
  </form>
</div>

<div class="mt-2 text-center">
  <button
    mat-raised-button
    color="primary"
    [disabled]="form.invalid"
    (click)="onSubmit()"
  >
    保存
  </button>
</div>
