import { SiteService } from 'src/app/shared/services/site.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteDetailDrawingCompassViewerService {
  constructor(private siteService: SiteService) {}

  async getLatestSiteDataFromSiteId(siteId: string) {
    return await this.siteService.getLatestSiteData(siteId);
  }
}
