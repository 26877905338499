import {
  LatestSensorData,
  LatestSiteData,
} from 'src/app/shared/interfaces/latest-site-data';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';

import { SiteDetailChartsService } from './site-detail-charts.service';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-site-detail-charts',
  templateUrl: './site-detail-charts.component.html',
  styleUrls: ['./site-detail-charts.component.scss'],
})
export class SiteDetailChartsComponent implements OnInit, OnDestroy {
  private accountService = inject(AccountService);

  constructor(private siteDetailChartsService: SiteDetailChartsService) {}

  @Input() latestSiteData: LatestSiteData | null = null;
  sensorData: LatestSensorData = {};

  chartRange: string = '';

  isSummer = false;
  isWinter = false;
  daytimeButtonDisabled = false;
  dayButtonDisabled = false;
  weekButtonDisabled = false;
  monthButtonDisabled = false;

  ngOnInit(): void {
    this.isSummer = UtilFunctions.isSummer();
    this.isWinter = this.accountService.isWestUser()
      ? UtilFunctions.isWinter()
      : false;

    // 設置センサー数取得
    if (this.latestSiteData !== null) {
      this.siteDetailChartsService.getNumberOfSensors(this.latestSiteData);
    }

    if (
      this.latestSiteData !== null &&
      this.latestSiteData.sensorData !== undefined
    ) {
      this.sensorData = this.latestSiteData.sensorData;
    }

    this.onClickDaytimeCharts();
  }
  ngOnDestroy(): void {}

  disableAllChartsButton() {
    this.daytimeButtonDisabled = false;
    this.dayButtonDisabled = false;
    this.weekButtonDisabled = false;
    this.monthButtonDisabled = false;
  }

  onClickDaytimeCharts() {
    this.disableAllChartsButton();
    this.daytimeButtonDisabled = true;
    this.chartRange = 'daytime';
  }

  onClick1DayCharts() {
    this.disableAllChartsButton();
    this.dayButtonDisabled = true;
    this.chartRange = 'day';
  }

  onClick1WeekCharts() {
    this.disableAllChartsButton();
    this.weekButtonDisabled = true;
    this.chartRange = 'week';
  }

  onClick1MonthCharts() {
    this.disableAllChartsButton();
    this.monthButtonDisabled = true;
    this.chartRange = 'month';
  }
}
