import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { AppConst } from '../constants/app-const';
import { Group } from '../interfaces/group';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private firestore: Firestore) {}

  async getAll(): Promise<Group[]> {
    const colRef = collection(this.firestore, 'groups');
    const q = query(colRef, orderBy('rank', 'desc'));
    const snapshot = await getDocs(q);

    let groupList: Group[] = [];

    for (let doc of snapshot.docs) {
      const data = doc.data();

      if (data['rank'] < AppConst.GROUP_RANK_ADMINISTRATOR) {
        groupList.push({
          id: data['id'],
          name: data['name'],
          rank: data['rank'],
        });
      }
    }

    return groupList;
  }

  async get(id: string): Promise<Group> {
    const snapshot = await getDoc(doc(this.firestore, 'groups', id));

    let group: Group = { id: '', name: '', rank: 0 };

    if (snapshot.exists()) {
      const data = snapshot.data();
      group.id = data['id'];
      group.name = data['name'];
      group.rank = data['rank'];
    }

    return group;
  }

  getGroupReference(id: string) {
    return doc(this.firestore, 'groups', id);
  }

  async isGeneral(id: string) {
    const group = await this.get(id);
    if (group.rank < AppConst.GROUP_RANK_CRITERIA) return true;
    return false;
  }
}
