<div>
  <div class="relative flex justify-center">
    <div>
      <img
        src="assets/images/compass_board.png"
        [style.transform]="'rotate(' + boardAngle + 'deg)'"
      />
      <img
        src="assets/images/compass_needle.png"
        [style.transform]="'rotate(' + needleAngle + 'deg)'"
        class="absolute top-0 left-0"
      />
    </div>
  </div>
  <div
    class="p-4 w-full"
    [style.background-color]="bgColor"
    [style.color]="fgColor"
  >
    <div class="text-center">
      {{ positionName }}
    </div>
    <div class="text-center">
      {{ speed ? (speed | number : ".1") : "----" }} m/s
    </div>
  </div>
</div>
