import { DrawingSensor } from 'src/app/pages/site-config/components/drawing-editor/drawing-sensor.interface';
import { Site } from 'src/app/shared/interfaces/site';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';

import { SiteDetailDrawingViewerService } from './site-detail-drawing-viewer.service';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-site-detail-drawing-viewer',
  templateUrl: './site-detail-drawing-viewer.component.html',
  styleUrls: ['./site-detail-drawing-viewer.component.scss'],
})
export class SiteDetailDrawingViewerComponent implements OnChanges {
  @Input() site: Site = {} as Site;
  // 19 : 6 = svgWidth : svgHeight
  // svgWidth = svgHeight * 19 / 6
  @Input() svgWidth: number = 0;
  @Input() svgHeight: number = 400;
  @Input() imgUrl: string = '';

  // 夏期限定
  isSummer = false;
  // 冬期限定
  isWinter = false;

  // 画像が選択されていない状態の背景色
  svgBgColor = 'lightgray';
  // 画像が選択されているかどうか
  isImageLoaded = false;

  drawingSensors: DrawingSensor[] = [];

  private accountService = inject(AccountService);

  constructor(private drawingViewerService: SiteDetailDrawingViewerService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.site === null) {
      this.setDefaultBackground();
      return;
    }

    // 夏期限定
    this.isSummer = UtilFunctions.isSummer();
    // 冬期限定
    this.isWinter = this.accountService.isWestUser()
      ? UtilFunctions.isWinter()
      : false;

    if (
      this.site.drawing !== undefined &&
      this.site.drawing.imageUrl !== undefined
    ) {
      this.setImageBackground(this.site.drawing.imageUrl);
    } else {
      this.setDefaultBackground();
    }

    this.drawingSensors =
      await this.drawingViewerService.getDrawingSensorsFromSite(this.site);
  }

  setImageBackground(imageUrl: string) {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const svg = document.getElementById('svg') as HTMLElement;

      // 画像のサイズを取得
      const imgWidth = img.width;
      const imgHeight = img.height;

      // 画像の縦横比を計算
      const aspectRatio = imgWidth / imgHeight;

      // 画像の縦横比を維持したまま、SVGの横のサイズを設定
      // 縦は固定
      this.svgWidth = this.svgHeight * aspectRatio;
      svg.style.backgroundImage = `url(${imageUrl})`;
      this.isImageLoaded = true;
    };
  }

  // SVGの背景をデフォルト設定
  setDefaultBackground() {
    // SVG背景色を設定する
    const svg = document.getElementById('svg') as HTMLElement;
    svg.style.backgroundColor = this.svgBgColor;
    svg.style.backgroundImage = 'none';
    // svgWidthを初期化
    this.svgWidth = (this.svgHeight * 19) / 6;

    this.isImageLoaded = false;
  }
}
