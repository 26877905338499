import { LatestSiteData } from 'src/app/shared/interfaces/latest-site-data';
import { SensorHistory } from 'src/app/shared/interfaces/sensor-history';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { Injectable, inject } from '@angular/core';
import { AccountService } from 'src/app/shared/services/account.service';

@Injectable({
  providedIn: 'root',
})
export class SiteDetailChartsService {
  numberOfSensors = 1;

  private accountService = inject(AccountService);

  constructor(private sensorService: SensorService) {}

  isSummer() {
    return UtilFunctions.isSummer();
  }

  isWinter() {
    return this.accountService.isWestUser() ? UtilFunctions.isWinter() : false;
  }

  // 現場に設定されているセンサー数を取得する
  getNumberOfSensors(latestData: LatestSiteData) {
    this.numberOfSensors = 0;
    const sensorData = latestData.sensorData;
    if (sensorData === undefined) {
      this.numberOfSensors = 2;
    } else {
      if (sensorData.wbgt !== undefined)
        this.numberOfSensors += sensorData.wbgt.length;
      if (sensorData.wind !== undefined)
        this.numberOfSensors += sensorData.wind.length;
      if (sensorData.noise !== undefined) this.numberOfSensors += 1;
      if (sensorData.ph !== undefined) this.numberOfSensors += 1;
    }

    return this.numberOfSensors;
  }

  getDaytimeSensorHistory(sensorId: string) {
    return this.sensorService.getHistoryDaytime(sensorId);
  }

  getDaySensorHistory(sensorId: string) {
    return this.sensorService.getHistory24Hours(sensorId);
  }

  getWeekSensorHistory(sensorId: string) {
    return this.sensorService.getHistory7Days(sensorId);
  }

  getMonthSensorHistory(sensorId: string) {
    return this.sensorService.getHistory1Months(sensorId);
  }

  createWbgtChartDataSets(histories: SensorHistory[]) {
    if (histories === null) return;

    const dataLabel: string[] = [];
    const wbgtDataList = [];
    const temperatureDataList = [];
    const humidityDataList = [];

    for (let history of histories) {
      dataLabel.push(UtilFunctions.formatDateForCharts(history.date, 'HH:mm'));
      wbgtDataList.push(history.wbgt!);
      temperatureDataList.push(history.temperature!);
      humidityDataList.push(history.humidity!);
    }

    if (wbgtDataList.length === 0) {
      wbgtDataList.push(0);
      temperatureDataList.push(0);
      humidityDataList.push(0);
    }

    return {
      label: dataLabel,
      wbgt: wbgtDataList,
      temperature: temperatureDataList,
      humidity: humidityDataList,
    };
  }

  createWindChartDataSets(histories: SensorHistory[]) {
    if (histories === null) return;

    const dataLabel: string[] = [];
    const speedDataList = [];

    for (let history of histories) {
      dataLabel.push(UtilFunctions.formatDateForCharts(history.date, 'HH:mm'));
      speedDataList.push(history.windSpeed!);
    }

    if (speedDataList.length === 0) {
      speedDataList.push(0);
    }

    return {
      label: dataLabel,
      speed: speedDataList,
    };
  }

  createNoiseChartDataSets(histories: SensorHistory[]) {
    if (histories === null) return;

    const dataLabel: string[] = [];
    const noiseDataList = [];

    for (let history of histories) {
      dataLabel.push(UtilFunctions.formatDateForCharts(history.date, 'HH:mm'));
      noiseDataList.push(history.noiseMax!);
    }

    if (noiseDataList.length === 0) {
      noiseDataList.push(0);
    }

    return {
      label: dataLabel,
      noise: noiseDataList,
    };
  }
}
