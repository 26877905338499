<ngx-charts-area-chart
  *ngIf="results.length > 0"
  (window:resize)="onResize($event)"
  [view]="view"
  [scheme]="colorScheme"
  [customColors]="customColors"
  [results]="results"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendPosition]="legendPosition"
  [legendTitle]="legendTitle"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [timeline]="timeline"
  [gradient]="gradient"
  [yScaleMin]="yScaleMin"
  [yScaleMax]="yScaleMax"
  (select)="onSelect($event)"
  #chart
>
  <ng-template #tooltipTemplate let-model="model">
    <xhtml:div class="area-tooltip-container">
      <xhtml:div class="tooltip-item">
        <xhtml:span
          class="tooltip-item-color"
          [style.background-color]="lineColor"
        ></xhtml:span>
        騒音
        <span class="tooltip-label">{{ model.name }}</span>
        <span class="tooltip-val">{{ model.value.toLocaleString() }}dB</span>
      </xhtml:div>
    </xhtml:div>
  </ng-template>
  <ng-template #seriesTooltipTemplate let-model="model">
    <xhtml:div class="area-tooltip-container">
      <xhtml:div class="tooltip-item">
        <xhtml:span
          class="tooltip-item-color"
          [style.background-color]="lineColor"
        ></xhtml:span>
        騒音
        <span class="tooltip-val">{{ model[0].value.toLocaleString() }}dB</span>
      </xhtml:div>
    </xhtml:div>
  </ng-template>
</ngx-charts-area-chart>
