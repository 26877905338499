import { Site } from 'src/app/shared/interfaces/site';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-site-detail-drawing',
  templateUrl: './site-detail-drawing.component.html',
  styleUrls: ['./site-detail-drawing.component.scss'],
})
export class SiteDetailDrawingComponent {
  @Input() site: Site = {} as Site;
}
