import { PhLatestSiteData } from 'src/app/shared/interfaces/latest-site-data';

import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { SiteDetailAlertService } from '../site-detail-alert/site-detail-alert.service';

@Component({
  selector: 'app-site-detail-alert-ph',
  templateUrl: './site-detail-alert-ph.component.html',
  styleUrls: ['./site-detail-alert-ph.component.scss'],
})
export class SiteDetailAlertPhComponent implements OnChanges {
  @Input() data: PhLatestSiteData | null = null;

  isOuter = false; // 外部のユーザかどうか

  siteDetailAlertService = inject(SiteDetailAlertService);

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isOuter = this.siteDetailAlertService.isOuterUser();
  }
}
