<section>
  <p class="page-title">通知設定</p>

  <div class="alert-patlite-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>パトライト設置</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="flex justify-between items-center">
          <div>
            <mat-checkbox [checked]="installPatlite" (change)="onPatliteSave()">設置</mat-checkbox>
          </div>
          <div *ngIf="installPatliteMessage" class="text-red-500">{{ installPatliteMessage }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>


  <mat-card class="config-site-create-card">
    <mat-card-header>
      <mat-card-title>メールアドレス設定</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div>
        <form class="config-site-create-form" [formGroup]="form" (keydown.enter)="$event.preventDefault()" autocomplete="off">
          <div formArrayName="emails">
            <div *ngFor="let email of emails.controls; let i = index">
              <div [formGroupName]="i">
                <mat-form-field>
                  <mat-label>メールアドレス</mat-label>
                  <input matInput formControlName="email" />
                </mat-form-field>
                <mat-icon (click)="onClickRemove(i)">delete</mat-icon>
              </div>
            </div>
            <button mat-button color="primary" (click)="onClickAdd()">
              メールアドレス追加
            </button>
          </div>

          <button type="button" mat-raised-button color="primary" [disabled]="form.invalid" (click)="onClick()">
            登録
          </button>
          <button type="button" mat-raised-button color="primary" (click)="onCancel()">
            キャンセル
          </button>
        </form>
      </div>
    </mat-card-content>

    <mat-card-footer>
      <mat-error *ngIf="0 < errors.length">
        <ul>
          <li *ngFor="let error of errors">{{ error }}</li>
        </ul>
      </mat-error>
    </mat-card-footer>
  </mat-card>
</section>
