import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';

import { UrlConst } from '../shared/constants/url-const';
import { AccountService } from '../shared/services/account.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.accountService.getUser();

    if (user === null) {
      return this.cantActivate();
    }

    return true;
  }

  private cantActivate(): boolean {
    this.router.navigate([UrlConst.PATH_SIGN_IN]);
    return false;
  }
}
