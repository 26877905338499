// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBjumyWI2s_0ZdN9_sYzjrJMido-w7B4Q4',
    authDomain: 'haseko-iot-app-test.firebaseapp.com',
    projectId: 'haseko-iot-app-test',
    storageBucket: 'haseko-iot-app-test.appspot.com',
    messagingSenderId: '267794531481',
    appId: '1:267794531481:web:75a2920e0297a1c56a6408',
    measurementId: 'G-WLC9T342D1',
  },
  fqdn: 'test.hlss.jp',
  groupIdGeneral: 'gppTHfbfkcElnFF25c8J',
  groupIdOuter: 'BhKTnegKFaqbyjvqqfEz',
  mapReloadIntervalMin: 1,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
