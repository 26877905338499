<section class="sign-in-page-wrapper">
  <div class="sign-in-page">
    <div class="sign-in-title-wrapper">
      <p>ＨＬＳＳ</p>
    </div>
    <app-error-messaging></app-error-messaging>
    <div class="sign-in-criteria-wrapper">
      <form [formGroup]="signInForm">
        <mat-error class="validation-error" *ngIf="error">
          ユーザ名かパスワードに誤りがあります
        </mat-error>

        <mat-form-field class="form-field">
          <input
            id="signin-user-account"
            matInput
            type="text"
            formControlName="user"
            placeholder="ユーザ名"
            required
          />
          <mat-error
            class="validation-error"
            *ngIf="user?.invalid && (user?.dirty || user?.touched)"
          >
            必須項目です
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field">
          <input
            id="signin-user-password"
            matInput
            type="password"
            formControlName="password"
            placeholder="パスワード"
            required
          />
          <mat-error
            class="validation-error"
            *ngIf="password?.invalid && (password?.dirty || password?.touched)"
          >
            必須項目です
          </mat-error>
        </mat-form-field>
      </form>
      <button
        mat-flat-button
        color="primary"
        id="sign-in-button"
        class="flat-button"
        type="button"
        [disabled]="!signInForm.valid"
        (click)="clickSignInButton()"
      >
        サインイン
      </button>
    </div>
  </div>
</section>
