import { AccountService } from 'src/app/shared/services/account.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteDrawingService {
  constructor(
    private accountService: AccountService,
    private siteService: SiteService
  ) {}

  getSite(siteId: string) {
    return this.siteService.get(siteId);
  }

  getSiteWithOrganization(siteId: string) {
    return this.siteService.getWithOrganization(
      siteId,
      this.accountService.getOrganizationId()
    );
  }
}
