<div class="w-full">
  <mat-card class="w-full status-signal-20">
    <div class="w-full site-detail-alert-container">
      現在の温度が　
      <span class="site-detail-alert-status"> 5℃以下 </span>
      です
      <span
        class="ml-32 underline underline-offset-2 text-blue-900 cursor-pointer"
        (click)="onTodayWnClick()"
        *ngIf="!isOuter"
      >
        詳細情報
      </span>
    </div>
  </mat-card>
</div>
