<p class="page-title">現場情報登録 / 変更</p>
<mat-card class="config-site-sensor-card">
  <mat-card-header>
    <mat-card-title class="config-site-sensor-title">
      {{ site?.name }}　Phセンサー{{ isUpdate ? "変更" : "登録" }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form
      class="form"
      [formGroup]="form"
      (keydown.enter)="$event.preventDefault()"
      autocomplete="off"
    >
      <mat-form-field>
        <mat-label>センサーID</mat-label>
        <mat-select formControlName="sensorId" required>
          <mat-option [value]="undefined"> 未登録 </mat-option>
          <mat-option *ngFor="let sensor of sensors" [value]="sensor.id">
            {{ sensor.id }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>設置場所(自由入力)</mat-label>
        <input
          matInput
          id="positionName"
          name="positionName"
          formControlName="positionName"
          placeholder="朝礼看板"
          required
        />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions align="end">
    <div>
      <button mat-raised-button color="accent" (click)="cancel()">
        キャンセル
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="form.invalid"
        (click)="onSubmit()"
      >
        登録
      </button>
    </div>
  </mat-card-actions>

  <mat-card-footer>
    <mat-error *ngIf="0 < errors.length">
      <ul>
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </mat-error>
  </mat-card-footer>
</mat-card>
