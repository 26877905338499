import { interval, map, Observable, of, shareReplay, Subscription } from 'rxjs';
import { TodayWnDialogComponent } from 'src/app/shared/components/today-wn-dialog/today-wn-dialog.component';
import { TomorrowWnDialogComponent } from 'src/app/shared/components/tomorrow-wn-dialog/tomorrow-wn-dialog.component';
import { UsageGuideDialogComponent } from 'src/app/shared/components/usage-guide-dialog/usage-guide-dialog.component';
import { WnDialogComponent } from 'src/app/shared/components/wn-dialog/wn-dialog.component';
import { AppConst } from 'src/app/shared/constants/app-const';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { LatestSiteData } from 'src/app/shared/interfaces/latest-site-data';
import { Sensor } from 'src/app/shared/interfaces/sensor';
import { Site, SiteDrawingPosition } from 'src/app/shared/interfaces/site';
import { SiteList } from 'src/app/shared/interfaces/site-list';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';
import {
  WeatherNewsApiResponseSrf,
  WeatherNewsService,
} from 'src/app/shared/services/weather-news.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-site-detail-page',
  templateUrl: './site-detail-page.component.html',
  styleUrls: ['./site-detail-page.component.scss'],
})
export class SiteDetailPageComponent implements OnInit, OnDestroy {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  subscriptions = new Subscription();

  windEnable = true;
  isOuter = false;
  isGeneral = false;
  isOffHours = false;
  isSummer = UtilFunctions.isSummer();
  isWnPeriod = this.accountService.isWestUser()
    ? UtilFunctions.isWnPeriod()
    : false;

  siteId: string = '';
  uuid: string = '';
  site: Site | null = null;
  siteList: SiteList | null = null;
  latestSiteData: LatestSiteData | null = null;
  boxPosition: SiteDrawingPosition = {};

  topFloorBoxBgColor: string = '';
  boardBoxBgColor: string = '';
  windBoxBgColor: string = '';

  windSensor: Sensor | null = null;

  wbgtSensors: Sensor[] = [];

  boardAngle: number | undefined = undefined;
  needleAngle: number | undefined = undefined;

  weatherNewsSrf$: Observable<WeatherNewsApiResponseSrf[]> = of([]);

  // 凡例表示用
  isExistsNoiseSensor = false;
  isExistsPhSensor = false;
  standardNoise = 0;
  standardVibration = 0;
  minPh = 0;
  maxPh = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
    private siteService: SiteService,
    private sensorService: SensorService,
    private logService: AccessLogService,
    private weatherNewsService: WeatherNewsService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.isOffHours = UtilFunctions.isOffHours();
    this.isOuter = this.accountService.isOuterGroup();
    this.isGeneral = this.accountService.isGeneralGroup();

    if (this.route.snapshot.paramMap.get('id') !== null) {
      this.siteId = this.route.snapshot.paramMap.get('id')!;

      await this.logService.addLog(
        UrlConst.SLASH +
          UrlConst.PATH_SITE_DETAIL +
          UrlConst.SLASH +
          this.siteId
      );
    }
    if (this.route.snapshot.paramMap.get('uuid') !== null) {
      this.uuid = this.route.snapshot.paramMap.get('uuid')!;
      await this.logService.addLog(
        UrlConst.SLASH +
          UrlConst.PATH_SITE_DETAIL_FROM_UUID +
          UrlConst.SLASH +
          this.uuid
      );
      this.isOuter = this.accountService.isOuterGroup();
      this.isGeneral = this.accountService.isGeneralGroup();
    }

    await this.reloadData();

    // RELOAD_INTERVAL毎のページデータリロード
    interval(AppConst.RELOAD_INTERVAL).subscribe(async () => {
      console.log('interval');
      await this.reloadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async reloadData() {
    if (this.siteId !== '') {
      this.site = await this.siteService.getWithOrganization(
        this.siteId,
        this.accountService.getOrganizationId() ??
          AppConst.DEFAULT_ORGANIZATION_ID
      );
      if (this.site === null) {
        this.router.navigate([UrlConst.PATH_SITE_LIST]);
        return;
      }
    }
    if (this.uuid !== '') {
      this.site = await this.siteService.getFromUuid(this.uuid);
    }

    if (this.site !== null) {
      const geo = this.site.geopoint;
      if (geo !== undefined) {
        this.weatherNewsSrf$ = this.weatherNewsService.fetchSrfByCoordinate(
          geo.latitude,
          geo.longitude
        );
      }
    }
    this.siteId = this.site!.id;
    this.boardAngle = this.site!.compassAngle;

    const latestSiteData = await this.siteService.getLatestSiteData(
      this.siteId
    );

    this.latestSiteData = this.checkStatus(latestSiteData);

    // 騒音・振動センサーの有無を確認
    this.checkNoiseSensor();
    // phセンサーの有無を確認
    this.checkPhSensor();

    this.windSensor = await this.sensorService.getWindFromSiteId(this.siteId);

    if (this.latestSiteData?.windDirection) {
      this.needleAngle =
        (this.latestSiteData.windDirection + 1) * 22.5 + this.boardAngle! + 180;
      // 0     1  2
      // 22.5 *2 *3
    }

    if (this.latestSiteData!.siteWindStatusNo) {
      this.windBoxBgColor = AppConst.WIND_SENSOR_STATUS.filter(
        (s) => s.no === this.latestSiteData!.siteWindStatusNo
      )[0].backgroundColor;
    }
  }

  checkStatus(data: LatestSiteData | null) {
    if (data === null) return null;

    const sensorData = data.sensorData;
    if (sensorData !== undefined && sensorData.wbgt !== undefined) {
      for (let i = 0; i < sensorData.wbgt.length; i++) {
        const d = sensorData.wbgt[i];

        if (d.date !== undefined) {
          if (!UtilFunctions.isValidDate(d.date)) {
            d.statusNo = '99';
            d.status = '停止';
          }
        } else {
          d.statusNo = '99';
          d.status = '停止';
        }

        sensorData.wbgt[i] = d;
      }
    }

    return data;
  }

  onTodayWnClick() {
    this.subscriptions.add(
      this.weatherNewsSrf$.subscribe((srf) => {
        this.dialog.open(TodayWnDialogComponent, {
          data: { srf: srf },
          width: '1200px',
        });
      })
    );
  }

  onTomorrowWnClick() {
    this.subscriptions.add(
      this.weatherNewsSrf$.subscribe((srf) => {
        this.dialog.open(TomorrowWnDialogComponent, {
          data: { srf: srf },
          width: '1200px',
        });
      })
    );
  }

  async onWnClick() {
    const url = this.router.url;
    const reg = new RegExp('^/site-detail/(.*)$');
    const result = url.match(reg);

    if (result === null) return;

    this.site = await this.siteService.get(result[1]);

    if (this.site !== null) {
      const geo = this.site.geopoint;
      if (geo !== undefined) {
        this.weatherNewsSrf$ = this.weatherNewsService.fetchSrfByCoordinate(
          geo.latitude,
          geo.longitude
        );
      }
    }

    this.subscriptions.add(
      this.weatherNewsSrf$.subscribe((srf) => {
        this.dialog.open(WnDialogComponent, {
          data: { srf: srf },
          width: '1200px',
        });
      })
    );
  }

  onUsageGuideClick() {
    this.dialog.open(UsageGuideDialogComponent, {
      width: '600px',
    });
  }

  // 騒音・振動センサーの有無を確認する
  async checkNoiseSensor() {
    const sensorData = this.latestSiteData?.sensorData;
    if (sensorData === undefined) {
      this.isExistsNoiseSensor = false;
      return;
    } else {
      if (sensorData.noise !== undefined) {
        const sensor = await this.sensorService.get(sensorData.noise.id);
        if (sensor !== null) {
          this.isExistsNoiseSensor = true;
          this.standardNoise = sensor.standardNoise ? sensor.standardNoise : 0;
          this.standardVibration = sensor.standardVibration
            ? sensor.standardVibration
            : 0;
          return;
        } else {
          this.isExistsNoiseSensor = false;
          return;
        }
      } else {
        this.isExistsNoiseSensor = false;
      }
    }
  }

  // pHセンサーの有無を確認する
  async checkPhSensor() {
    const sensorData = this.latestSiteData?.sensorData;
    if (sensorData === undefined) {
      this.isExistsPhSensor = false;
      return;
    } else {
      if (sensorData.ph !== undefined) {
        const sensor = await this.sensorService.get(sensorData.ph.id);
        if (sensor !== null) {
          this.isExistsPhSensor = true;
          this.minPh = sensor.minPh ? sensor.minPh : 0;
          this.maxPh = sensor.maxPh ? sensor.maxPh : 0;
          return;
        } else {
          this.isExistsPhSensor = false;
          return;
        }
      } else {
        this.isExistsPhSensor = false;
      }
    }
  }
}
