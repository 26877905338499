<p class="">現場情報登録 / 変更</p>
<mat-card>
  <mat-card-header>
    <mat-card-title>現場図面設定</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-drawing-editor
      *ngIf="site"
      [site]="site"
      (drawingCanceled)="onDrawingCanceled()"
      (drawingSaved)="onDrawingSaved()"
    ></app-drawing-editor>
  </mat-card-content>
</mat-card>

<div class="mt-4 flex gap-1">
  <div class="w-1/5" *ngIf="site?.sensorRef?.noise?.length">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          騒音・振動基準値設定
          <span class="text-xs text-red-400">
            {{ noiseStandardSavedMessage }}
          </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-sensor-noise-standard
          [sensorRef]="(site!.sensorRef?.noise)![0]"
          (onSaved)="onNoiseStandardSaved()"
        ></app-sensor-noise-standard>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="w-1/5" *ngIf="site?.sensorRef?.ph?.length">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          pH基準値設定
          <span class="text-xs text-red-400">
            {{ phRangeSavedMessage }}
          </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-sensor-ph-range
          [sensorRef]="(site!.sensorRef?.ph)![0]"
          (onSaved)="onPhRangeSaved()"
        ></app-sensor-ph-range>
      </mat-card-content>
    </mat-card>
  </div>
</div>
