import { Subscription } from 'rxjs';
import { AppConst } from 'src/app/shared/constants/app-const';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { LatestSiteData } from 'src/app/shared/interfaces/latest-site-data';
import { SiteListFilterRequest } from 'src/app/shared/interfaces/site-list-filter-request';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { SiteManagementDepartmentService } from 'src/app/shared/services/site-management-department.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-list-page',
  templateUrl: './site-list-page.component.html',
  styleUrls: ['./site-list-page.component.scss'],
})
export class SiteListPageComponent implements OnInit, OnDestroy, AfterViewInit {
  windEnable = true;
  displayedColumns: string[] = [
    'name',
    'managementDepartmentName',
    'siteStatus',
    'siteWbgt',
    'windStatus',
    'windSpeed',
    'windDirectionName',
  ];
  dataSource = new MatTableDataSource<LatestSiteData>();

  @ViewChild(MatSort) sort: MatSort = new MatSort();

  isSummer: boolean = false;
  isWinter: boolean = false;

  statusList = AppConst.SENSOR_STATUS;
  temperatureStatusList = AppConst.TEMPERATURE_SENSOR_STATUS;
  windStatusList = AppConst.WIND_SENSOR_STATUS;
  managementDepartmentList =
    this.managementDepartmentService.getAllWithOrganization();

  subscription: Subscription = new Subscription();

  name = '';
  managementDepartmentId = '';
  managementDepartmentName = '';
  siteStatusNo = '';
  siteStatus = '';
  siteWbgt = '';
  siteTemperatureStatusNo = '';
  siteTemperatureStatus = '';
  siteTemperature = '';
  windStatusNo = '';
  windSpeed = '';
  windDirectionName = '';

  latestSiteData: LatestSiteData[] = [];
  gTimer: any = null;

  filterRequest: SiteListFilterRequest = {};

  reloadTimer: NodeJS.Timeout | undefined = undefined;

  // 現場クリックしても詳細に遷移しないようにする
  clickable = !this.accountService.isMonitorGroup();

  constructor(
    private router: Router,
    private accountService: AccountService,
    private siteService: SiteService,
    private managementDepartmentService: SiteManagementDepartmentService,
    private logService: AccessLogService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.logService.addLog(UrlConst.SLASH + UrlConst.PATH_SITE_LIST);
    await this.reloadLatestSiteData();

    this.isSummer = UtilFunctions.isSummer();
    this.isWinter = this.accountService.isWestUser()
      ? UtilFunctions.isWinter()
      : false;

    // RELOAD_INTERVAL毎のページデータリロード
    new Promise(async () => {
      this.reloadTimer = setInterval(async () => {
        await this.reloadLatestSiteData();
      }, AppConst.RELOAD_INTERVAL);
    });
  }

  async reloadLatestSiteData(): Promise<void> {
    const latest = await this.siteService.getAllLatestSiteDataWithOrganization(
      this.accountService.getOrganizationId() ??
        AppConst.DEFAULT_ORGANIZATION_ID
    );

    this.latestSiteData = latest;
    this.dataSource = new MatTableDataSource(latest);

    this.siteService.searchName$.subscribe((updated: string) => {
      this.name = updated;
      this.applyFilter();
    });
    if (this.siteService.searchName) {
      this.name = this.siteService.searchName;
      this.siteService.searchName = '';
      this.applyFilter();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
    }, 1 * 1000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.reloadTimer != undefined) clearInterval(this.reloadTimer);
  }

  applyFilter(event?: Event | MatSelectChange) {
    if (this.gTimer) {
      clearTimeout(this.gTimer);
    }
    this.gTimer = setTimeout(() => {
      this.filterRequest.name = this.name.trim();
      this.filterRequest.managementDepartmentId = this.managementDepartmentId;

      if (this.isSummer) {
        this.filterRequest.siteStatusNo = this.siteStatusNo;
        this.filterRequest.siteWbgt = this.siteWbgt;
      } else {
        this.filterRequest.siteTemperatureStatusNo =
          this.siteTemperatureStatusNo;
        this.filterRequest.siteTemperature = this.siteTemperature;
      }
      this.filterRequest.windStatusNo = this.windStatusNo;
      this.filterRequest.windSpeed = this.windSpeed;
      this.filterRequest.windDirectionName = this.windDirectionName;

      this.dataSource = new MatTableDataSource(
        this.filterSite(this.filterRequest)
      );

      this.dataSource.sort = this.sort;
    }, AppConst.SITE_LIST_PAGE_FILTER_TIMEOUT);
  }

  filterSite(filter: SiteListFilterRequest): LatestSiteData[] {
    let filterResponse: LatestSiteData[] = this.latestSiteData;

    if (filter.siteStatusNo) {
      filterResponse = filterResponse.filter((r) => {
        return r.siteStatusNo === filter.siteStatusNo;
      });
    }
    if (filter.siteWbgt) {
      filterResponse = filterResponse.filter((r) => {
        return Number(r.siteWbgt) >= Number(filter.siteWbgt);
      });
    }
    if (filter.siteTemperatureStatusNo) {
      filterResponse = filterResponse.filter((r) => {
        return r.siteTemperatureStatusNo === filter.siteTemperatureStatusNo;
      });
    }
    if (filter.siteTemperature) {
      filterResponse = filterResponse.filter((r) => {
        return Number(r.siteTemperature) <= Number(filter.siteTemperature);
      });
    }
    if (filter.windStatusNo) {
      filterResponse = filterResponse.filter((r) => {
        return r.siteWindStatusNo === filter.windStatusNo;
      });
    }
    if (filter.windSpeed) {
      filterResponse = filterResponse.filter((r) => {
        return Number(r.siteWindSpeed) >= Number(filter.windSpeed);
      });
    }
    if (filter.windDirectionName) {
      const regexp = new RegExp(filter.windDirectionName);
      filterResponse = filterResponse.filter((r) => {
        return r.siteWindDirectionName?.match(regexp);
      });
    }
    if (filter.name) {
      const regexp = new RegExp(filter.name);
      filterResponse = filterResponse.filter((r) => {
        return r.name.match(regexp);
      });
    }
    if (filter.managementDepartmentId) {
      filterResponse = filterResponse.filter((r) => {
        return r.managementDepartmentId === filter.managementDepartmentId;
      });
    }
    return filterResponse;
  }

  toDetail(id: string) {
    if (this.clickable === false) return;

    this.router.navigate([UrlConst.SLASH + UrlConst.PATH_SITE_DETAIL, id]);
  }
}
