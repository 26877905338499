import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { HumidityLineChartComponent } from './components/humidity-line-chart/humidity-line-chart.component';
import { NoiseLineChartComponent } from './components/noise-line-chart/noise-line-chart.component';
import { PhLineChartComponent } from './components/ph-line-chart/ph-line-chart.component';
import { TemperatureLineChartComponent } from './components/temperature-line-chart/temperature-line-chart.component';
import { VibrationLineChartComponent } from './components/vibration-line-chart/vibration-line-chart.component';
import { WbgtLineChartComponent } from './components/wbgt-line-chart/wbgt-line-chart.component';
import { WindLineChartComponent } from './components/wind-line-chart/wind-line-chart.component';

@NgModule({
  declarations: [
    WbgtLineChartComponent,
    WindLineChartComponent,
    TemperatureLineChartComponent,
    HumidityLineChartComponent,
    NoiseLineChartComponent,
    VibrationLineChartComponent,
    PhLineChartComponent,
  ],
  imports: [CommonModule, NgxChartsModule],
  exports: [
    WbgtLineChartComponent,
    WindLineChartComponent,
    TemperatureLineChartComponent,
    HumidityLineChartComponent,
    NoiseLineChartComponent,
    VibrationLineChartComponent,
    PhLineChartComponent,
  ],
})
export class ChartsModule {}
