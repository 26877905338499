import { firstValueFrom } from 'rxjs';
import { AppConst } from 'src/app/shared/constants/app-const';
import { SignUpRequest } from 'src/app/shared/interfaces/sign-up-request';
import { SiteUpdateRequest } from 'src/app/shared/interfaces/site-update-request';
import { AccountService } from 'src/app/shared/services/account.service';
import { GoogleMapApiService } from 'src/app/shared/services/google-map-api.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { SiteManagementDepartmentService } from 'src/app/shared/services/site-management-department.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { UtilFunctions } from 'src/app/shared/utils/util-functions';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { GeoPoint } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class SiteFormService {
  constructor(
    private siteService: SiteService,
    private accountService: AccountService,
    private groupService: GroupService,
    private siteManagementDepartmentService: SiteManagementDepartmentService,
    private mapApiService: GoogleMapApiService
  ) {}

  apiLoaded: boolean = false;

  async loadApi() {
    this.apiLoaded = await firstValueFrom(this.mapApiService.setApiUrl());
  }

  getAllDepartment() {
    return this.siteManagementDepartmentService.getAllWithOrganization();
  }

  getSite(id: string) {
    return this.siteService.get(id);
  }

  getSiteWithOrganization(id: string) {
    return this.siteService.getWithOrganization(id, this.getOrganizationId());
  }

  getGeometry(address: string) {
    return this.mapApiService.getGeometry(address);
  }

  getGeoPoint(geo: { latitude: number; longitude: number }) {
    return new GeoPoint(geo.latitude, geo.longitude);
  }

  getGroup(id: string) {
    return this.groupService.get(id);
  }

  getGeneralGroup() {
    return this.getGroup(environment.groupIdGeneral);
  }

  getOuterGroup() {
    return this.getGroup(environment.groupIdOuter);
  }

  getGroupReference(id: string) {
    return this.groupService.getGroupReference(id);
  }

  getOrganizationId() {
    return (
      this.accountService.getOrganizationId() ??
      AppConst.DEFAULT_ORGANIZATION_ID
    );
  }

  signUp(request: SignUpRequest) {
    return this.accountService.signUp(request);
  }

  createSite(request: SiteUpdateRequest) {
    request.createdAt = UtilFunctions.now();

    return this.siteService.create(request);
  }

  updateSite(request: SiteUpdateRequest) {
    return this.siteService.update(request);
  }
}
