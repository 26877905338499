import { Group } from 'src/app/shared/interfaces/group';
import { SignUpRequest } from 'src/app/shared/interfaces/sign-up-request';
import { AccountService } from 'src/app/shared/services/account.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { LoadingService } from 'src/app/shared/services/loading.service';

import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  NgForm,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-sign-up-page',
  templateUrl: './sign-up-page.component.html',
  styleUrls: ['./sign-up-page.component.scss'],
})
export class SignUpPageComponent implements OnInit {
  form = this.formBuilder.group({
    organizationId: [''],
    user: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirm: ['', [Validators.required, Validators.minLength(8)]],
    groupId: ['', Validators.required],
  });

  groups$: Promise<Group[]> = this.groupService.getAll();
  error: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private groupService: GroupService,
    private loadingService: LoadingService,
    private snackBar: MatSnackBar
  ) {}

  get organizationId(): AbstractControl | null {
    return this.form.get('organizationId');
  }
  get user(): AbstractControl | null {
    return this.form.get('user');
  }
  get password(): AbstractControl | null {
    return this.form.get('password');
  }
  get confirm(): AbstractControl | null {
    return this.form.get('confirm');
  }

  ngOnInit(): void {}

  async clickSignUpButton() {
    this.loadingService.startLoading();

    if (this.password?.value !== this.confirm?.value) {
      this.error = true;
      this.loadingService.stopLoading();
      return;
    }

    try {
      const group: Group = await this.groupService.get(
        this.form.get('groupId')?.value
      );
      const request: SignUpRequest = {
        organizationId: this.organizationId?.value,
        user: this.user?.value,
        password: this.password?.value,
        groupId: this.form.get('groupId')?.value,
        groupName: group.name,
        groupRef: this.groupService.getGroupReference(group.id),
      };
      this.error = await this.accountService.signUp(request);
      if (!this.error) {
        this.form.reset();
        this.snackBar.open('ユーザが作成されました', '閉じる', {
          duration: 2.5 * 1000,
        });
      }
    } catch (error) {
      this.error = true;
    } finally {
      this.loadingService.stopLoading();
    }
  }
}
