import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, of } from 'rxjs';
import {
  WeatherNewsApiResponseSrf,
  WeatherNewsService,
} from 'src/app/shared/services/weather-news.service';
import { SiteDetailAlertService } from '../site-detail-alert/site-detail-alert.service';
import { TomorrowWnDialogComponent } from 'src/app/shared/components/tomorrow-wn-dialog/tomorrow-wn-dialog.component';
import { Site } from 'src/app/shared/interfaces/site';
import { LatestSiteData } from 'src/app/shared/interfaces/latest-site-data';

@Component({
  selector: 'app-site-detail-alert-temperature-tomorrow',
  templateUrl: './site-detail-alert-temperature-tomorrow.component.html',
  styleUrls: ['./site-detail-alert-temperature-tomorrow.component.scss'],
})
export class SiteDetailAlertTemperatureTomorrowComponent implements OnChanges {
  @Input() site: Site | null = null;
  @Input() latestSiteData: LatestSiteData | null = null;
  @Input() date: string = '';

  private weatherNewsService = inject(WeatherNewsService);

  subscriptions = new Subscription();
  weatherNewsSrf$: Observable<WeatherNewsApiResponseSrf[]> = of([]);

  temperature: number | undefined = undefined;

  isOuter = false; // 外部のユーザかどうか

  constructor(
    private dialog: MatDialog,
    private siteDetailAlertService: SiteDetailAlertService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isOuter = this.siteDetailAlertService.isOuterUser();

    if (this.site !== null) {
      const geo = this.site.geopoint;
      if (geo !== undefined) {
        this.weatherNewsSrf$ = this.weatherNewsService.fetchSrfByCoordinate(
          geo.latitude,
          geo.longitude
        );
      }
    }

    if (
      this.latestSiteData !== null &&
      this.latestSiteData.sensorData !== undefined &&
      this.latestSiteData.sensorData.wbgt !== undefined
    ) {
      const wbgtSensorData = this.latestSiteData.sensorData.wbgt;
      let temp: number | undefined = undefined;
      for (let data of wbgtSensorData) {
        if (data.temperature !== undefined) {
          temp = data.temperature;
        }
      }
      if (temp !== undefined) {
        this.temperature = temp;
      }
    }
  }

  onTomorrowWnClick() {
    this.subscriptions.add(
      this.weatherNewsSrf$.subscribe((srf) => {
        this.dialog.open(TomorrowWnDialogComponent, {
          data: { srf: srf },
          width: '1200px',
        });
      })
    );
  }
}
