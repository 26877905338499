import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  format,
  isAfter,
  isBefore,
  setDate,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';

import { AppConst } from '../constants/app-const';
import { SensorHistoryCsv } from '../interfaces/sensor-history-csv';
import { SensorStatus } from '../interfaces/sensor-status';

export class UtilFunctions {
  static now() {
    return this.formatDate(new Date());
  }

  // 日付は当日のものか判定
  static isTodayFromString(strDate: string) {
    const now = new Date();
    const date = new Date(Date.parse(strDate));

    if (
      now.getFullYear() === date.getFullYear() &&
      now.getMonth() === date.getMonth() &&
      now.getDate() === date.getDate()
    ) {
      return true;
    }

    return false;
  }

  // 日付が翌日のものか判定
  static isTomorrowFromString(strDate: string) {
    const now = new Date();
    const tomorrow = addDays(now, 1);
    const date = new Date(Date.parse(strDate));

    if (
      tomorrow.getFullYear() === date.getFullYear() &&
      tomorrow.getMonth() === date.getMonth() &&
      tomorrow.getDate() === date.getDate()
    ) {
      return true;
    }

    return false;
  }

  // WeatherNews用
  // 現在の時間から24時間分かどうか
  // 秒で計算
  // 23時間分の秒数(82800000)
  // 1時間分の秒数(3600000)
  static wnIsTodayFromString(strDate: string) {
    const now = new Date();
    const date = new Date(Date.parse(strDate));
    const diff = date.getTime() - now.getTime();

    if (-3600000 <= diff && diff < 82800000) {
      return true;
    }

    return false;
  }

  // WeatherNews用
  // 翌日7時から24時間分表示
  // 秒で計算
  static wnIsTomorrowFromString(strDate: string) {
    const now = new Date();
    const tomorrow = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      7,
      0,
      0
    );
    const date = new Date(Date.parse(strDate));
    const diff = date.getTime() - tomorrow.getTime();

    if (0 <= diff && diff < 86400000) {
      return true;
    }

    return false;
  }

  // 月を取得
  static getMonthFromString(strDate: string) {
    const date = new Date(Date.parse(strDate));
    return date.getMonth() + 1;
  }

  // 日を取得
  static getDateFromString(strDate: string) {
    const date = new Date(Date.parse(strDate));
    return date.getDate();
  }

  // 時を取得
  static getHourFromString(strDate: string) {
    const date = new Date(Date.parse(strDate));
    return date.getHours();
  }

  // 夏期は4月から10月まで
  static isSummer() {
    const now = new Date();

    const nowMonth = now.getMonth() + 1;

    if (nowMonth >= 4 && nowMonth <= 10) {
      return true;
    }

    return false;
  }

  // 冬期は11月から3月末まで
  static isWinter() {
    const now = new Date();

    const nowMonth = now.getMonth() + 1;

    if (nowMonth >= 1 && nowMonth <= 3) {
      return true;
    }
    if (nowMonth >= 11 && nowMonth <= 12) {
      return true;
    }

    return false;
  }

  // WeatherNewsの利用は12月から2月末まで
  static isWnPeriod() {
    // isWinter()と同じ
    return this.isWinter();
  }

  // 本システムの開始日時（6:00）
  static startDateTime() {
    let date = setHours(new Date(), 6);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);

    return this.formatDate(date);
  }

  // 本システムの終了日時（19:00）
  static endDateTime() {
    let date = setHours(new Date(), 19);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);

    return this.formatDate(date);
  }

  // 00:00:00
  static startAt(date: Date) {
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    return this.formatDate(date);
  }

  // 23:59:59
  static endAt(date: Date) {
    date = setHours(date, 23);
    date = setMinutes(date, 59);
    date = setSeconds(date, 59);
    return this.formatDate(date);
  }

  // 指定分後の日時を取得、指定されない時は現在時刻を使用
  static deltaMinutes(delta: number, strDate?: string) {
    let date: Date;
    if (strDate) {
      date = new Date(strDate);
    } else {
      date = new Date();
    }
    return this.formatDate(addMinutes(date, delta));
  }

  // 指定時間後の日時を取得、指定されない時は現在時刻を使用
  static deltaHours(delta: number, strDate?: string) {
    let date: Date;
    if (strDate) {
      date = new Date(strDate);
    } else {
      date = new Date();
    }
    return this.formatDate(addHours(date, delta));
  }

  // 指定日後の日時を取得、指定されない時は現在時刻を使用
  static deltaDays(delta: number, strDate?: string) {
    let date: Date;
    if (strDate) {
      date = new Date(strDate);
    } else {
      date = new Date();
    }
    return this.formatDate(addDays(date, delta));
  }

  // 指定月後の日時を取得、指定されない時は現在時刻を使用
  static deltaMonths(delta: number, strDate?: string) {
    let date: Date;
    if (strDate) {
      date = new Date(strDate);
    } else {
      date = new Date();
    }
    return this.formatDate(addMonths(date, delta));
  }

  // 今月最初の日時をDateで取得
  static thisMonthStartDate() {
    let date = new Date();
    date = setDate(date, 1);
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    return date;
  }

  // 今月最後の日時をDateで取得
  static thisMonthEndDate() {
    let date = this.thisMonthStartDate();
    date = addMonths(date, 1);
    date = addDays(date, -1);
    date = setHours(date, 23);
    date = setMinutes(date, 59);
    date = setSeconds(date, 59);
    return date;
  }

  // 今月最初の日時を文字列で取得
  static thisMonthStart() {
    const date = this.thisMonthStartDate();
    return this.formatDate(date);
  }

  // 今月最後の日時を文字列で取得
  static thisMonthEnd() {
    const date = this.thisMonthEndDate();
    return this.formatDate(date);
  }

  // Dateを文字列に変換
  static formatDate(date: Date) {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  }

  // グラフ描画用月日時を文字列で取得
  static formatDateForCharts(dateStr: string, formatStr: string) {
    if (formatStr === '') {
      formatStr = 'M/d HH';
    }
    return format(new Date(dateStr), formatStr);
  }

  // 指定日付が現在時刻の3時間以内かどうか
  static isValidDate(strDate: string) {
    const date = new Date(strDate);
    const hourAgo = addHours(new Date(), -3);
    if (isAfter(date, hourAgo)) {
      return true;
    } else {
      return false;
    }
  }

  // 指定日付が指定時間内かどうか
  static isValidDateByHours(strDate: string, hours: number) {
    const date = new Date(strDate);
    return date.getHours() === hours;
  }

  // 指定日付が本システム営業時間内かどうか
  static isOffHours() {
    const now = new Date();
    let start = setHours(new Date(), 5);
    start = setMinutes(start, 0);
    start = setSeconds(start, 0);

    let end = setHours(new Date(), 19);
    end = setMinutes(end, 0);
    end = setSeconds(end, 0);

    return isBefore(now, start) || isAfter(now, end);
  }

  // WBGTステータス取得
  static getStatus(wbgt: number): SensorStatus {
    return this.getWbgtStatus(wbgt);
  }

  static getWbgtStatus(wbgt: number): SensorStatus {
    if (wbgt >= 33) {
      return AppConst.SENSOR_STATUS[5];
    }
    if (wbgt >= 30 && wbgt < 33) {
      return AppConst.SENSOR_STATUS[4];
    }
    if (wbgt >= 28 && wbgt < 30) {
      return AppConst.SENSOR_STATUS[3];
    }
    if (wbgt >= 26 && wbgt < 28) {
      return AppConst.SENSOR_STATUS[2];
    }
    if (wbgt >= 25 && wbgt < 26) {
      return AppConst.SENSOR_STATUS[1];
    }

    // wbgt < 25
    // if (wbgt < 25) {
    //   return AppConst.SENSOR_STATUS[0];
    // }
    return AppConst.SENSOR_STATUS[0];
  }

  // 温度ステータス取得
  static getTemperatureStatus(temperature: number): SensorStatus {
    if (temperature <= -5) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[9];
    }
    if (-5 <= temperature && temperature <= 0) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[8];
    }
    if (0 <= temperature && temperature <= 5) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[7];
    }
    if (5 <= temperature && temperature <= 10) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[6];
    }
    if (10 <= temperature && temperature <= 15) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[5];
    }
    if (15 <= temperature && temperature <= 20) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[4];
    }
    if (20 <= temperature && temperature <= 25) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[3];
    }
    if (25 <= temperature && temperature <= 30) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[2];
    }
    if (30 <= temperature && temperature <= 35) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[1];
    }

    // if (35 <= temperature) {
    return AppConst.TEMPERATURE_SENSOR_STATUS[0];
  }

  // 停止ステータス取得
  static getStatusStopped(): SensorStatus {
    return {
      no: AppConst.SENSOR_STATUS_STOPPED,
      label: AppConst.SENSOR_STATUS_STOPPED_LABEL,
      backgroundColor: AppConst.SENSOR_STATUS_STOPPED_BGCOLOR,
      foregroundColor: AppConst.SENSOR_STATUS_STOPPED_COLOR,
    };
  }

  // WBGTステータス取得（指定日付）
  static getStatusWithDate(wbgt: number, date: string): SensorStatus {
    if (!this.isValidDate(date)) {
      return AppConst.SENSOR_STATUS[6];
    }
    return this.getStatus(wbgt);
  }

  // 温度ステータス取得（指定日付）
  static getTemperatureStatusWithDate(
    temperature: number,
    date: string
  ): SensorStatus {
    if (!this.isValidDate(date)) {
      return AppConst.TEMPERATURE_SENSOR_STATUS[10];
    }
    return this.getTemperatureStatus(temperature);
  }

  // 風速停止ステータス取得
  static getWindStatusStopped() {
    return {
      no: AppConst.SENSOR_STATUS_STOPPED,
      label: AppConst.SENSOR_STATUS_STOPPED_LABEL,
      backgroundColor: AppConst.SENSOR_STATUS_STOPPED_BGCOLOR,
      foregroundColor: AppConst.SENSOR_STATUS_STOPPED_COLOR,
    };
  }

  // 風速ステータス取得
  static getWindStatus(speed: number): SensorStatus {
    if (10 <= speed) {
      return AppConst.WIND_SENSOR_STATUS[4];
    }
    if (8 <= speed && speed < 10) {
      return AppConst.WIND_SENSOR_STATUS[3];
    }
    if (5 <= speed && speed < 8) {
      return AppConst.WIND_SENSOR_STATUS[2];
    }
    if (2 <= speed && speed < 5) {
      return AppConst.WIND_SENSOR_STATUS[1];
    }

    // 風速 2 m/s 未満
    // if (speed < 2) {
    //   return AppConst.WIND_SENSOR_STATUS[0];
    // }
    return AppConst.WIND_SENSOR_STATUS[0];
  }

  // 風速ステータス取得（指定日付）
  static getWindStatusWithDate(speed: number, date: string): SensorStatus {
    if (!this.isValidDate(date)) {
      return AppConst.WIND_SENSOR_STATUS[5];
    }
    return this.getWindStatus(speed);
  }

  // 騒音ステータス取得（指定日付）
  static getNoiseStatusWithDate(
    std: number,
    noise: number,
    date: string
  ): SensorStatus {
    if (!this.isValidDate(date)) {
      return AppConst.NOISE_SENSOR_STATUS[2];
    }

    if (noise < std) {
      return AppConst.NOISE_SENSOR_STATUS[0];
    } else {
      return AppConst.NOISE_SENSOR_STATUS[1];
    }
  }

  // Phステータス取得（指定日付）
  static getPhStatusWithDate(
    min: number,
    max: number,
    ph: number,
    date: string
  ): SensorStatus {
    if (!this.isValidDate(date)) {
      return AppConst.PH_SENSOR_STATUS[3];
    }

    if (min <= ph && ph < max) {
      return AppConst.PH_SENSOR_STATUS[0];
    } else if (ph < min) {
      return AppConst.PH_SENSOR_STATUS[1];
    } else if (max <= ph) {
      return AppConst.PH_SENSOR_STATUS[2];
    }

    return AppConst.PH_SENSOR_STATUS[3];
  }

  // 風向き（名前）を取得
  static getWindDirectionName(direction: number): string {
    let name = '';
    switch (direction) {
      case 0:
        name = '北北東';
        break;
      case 1:
        name = '北東';
        break;
      case 2:
        name = '東北東';
        break;
      case 3:
        name = '東';
        break;
      case 4:
        name = '東南東';
        break;
      case 5:
        name = '南東';
        break;
      case 6:
        name = '南南東';
        break;
      case 7:
        name = '南';
        break;
      case 8:
        name = '南南西';
        break;
      case 9:
        name = '南西';
        break;
      case 10:
        name = '西南西';
        break;
      case 11:
        name = '西';
        break;
      case 12:
        name = '西北西';
        break;
      case 13:
        name = '北西';
        break;
      case 14:
        name = '北北西';
        break;
      case 15:
        name = '北';
        break;
      default:
        name = '北';
        break;
    }

    return name;
  }

  // CSVダウンロード
  static downloadCsv(filename: string, data: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], { type: 'text/csv' });

    //BlobからオブジェクトURLを作成する
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    //ダウンロード用にリンクを作成する
    const download = document.createElement('a');
    //リンク先に上記で生成したURLを指定する
    download.href = url;
    //download属性にファイル名を指定する
    download.download = filename;
    //作成したリンクをクリックしてダウンロードを実行する
    download.click();
    //createObjectURLで作成したオブジェクトURLを開放する
    (window.URL || window.webkitURL).revokeObjectURL(url);
  }

  // センサーログCSVダウンロード
  static downloadSensorHistoryCsv(history: SensorHistoryCsv[]) {
    const filename = 'haseko-' + format(new Date(), 'yyyyMMddHHmmss') + '.csv';

    const csvHeader = [
      '日時',
      '現場ID',
      '現場名',
      '管理部署名',
      '設置場所',
      'ステータス',
      'WBGT',
      '温度',
      '湿度',
      '風速',
      '風速平均',
      '風向',
      '騒音(L5)',
      '騒音(Max)',
      '振動(L10)',
      '振動(Max)',
      'pH',
    ].join(',');

    const dataArray: string[] = [];
    for (let h of history) {
      if (h.windSpeed !== undefined) {
        dataArray.push(
          [
            h.date,
            h.siteId,
            h.siteName,
            h.managementDepartmentName,
            h.positionName,
            h.siteStatus,
            '-',
            '-',
            '-',
            h.windSpeed ? h.windSpeed : 0,
            h.windSpeedAverage ? h.windSpeedAverage : 0,
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
          ].join(',')
        );
      } else if (h.windDirectionName !== undefined) {
        dataArray.push(
          [
            h.date,
            h.siteId,
            h.siteName,
            h.managementDepartmentName,
            h.positionName,
            h.siteStatus,
            '-',
            '-',
            '-',
            '-',
            '-',
            h.windDirectionName,
            '-',
            '-',
            '-',
            '-',
            '-',
          ].join(',')
        );
      } else if (h.noiseL5 !== undefined) {
        dataArray.push(
          [
            h.date,
            h.siteId,
            h.siteName,
            h.managementDepartmentName,
            h.positionName,
            h.siteStatus,
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            h.noiseL5,
            h.noiseMax,
            h.vibrationL10,
            h.vibrationMax,
            '-',
          ].join(',')
        );
      } else if (h.ph !== undefined) {
        dataArray.push(
          [
            h.date,
            h.siteId,
            h.siteName,
            h.managementDepartmentName,
            h.positionName,
            h.siteStatus,
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            h.ph,
          ].join(',')
        );
      } else {
        dataArray.push(
          [
            h.date,
            h.siteId,
            h.siteName,
            h.managementDepartmentName,
            h.positionName,
            h.siteStatus,
            h.wbgt,
            h.temperature,
            h.humidity,
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
          ].join(',')
        );
      }
    }

    dataArray.unshift(csvHeader);

    const data = dataArray.join('\r\n');

    UtilFunctions.downloadCsv(filename, data);
  }

  static escapeString(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
