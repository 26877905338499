import { firstValueFrom } from 'rxjs';

import {
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

import { AppConst } from '../../constants/app-const';
import { UrlConst } from '../../constants/url-const';
import { Sensor } from '../../interfaces/sensor';
import { Site } from '../../interfaces/site';
import { AccountService } from '../../services/account.service';
import { GoogleMapApiService } from '../../services/google-map-api.service';
import { SensorService } from '../../services/sensor.service';
import { SiteService } from '../../services/site.service';
import { MapService } from './map.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isWbgtMarker = false;
  @Input() isTemperatureMarker = false;
  @Input() isWindMarker = false;
  @Input() clickable = true;

  mapService = inject(MapService);

  reloadTimer: NodeJS.Timeout | undefined = undefined;

  apiLoaded: boolean = false;
  mapOptions: google.maps.MapOptions = {
    mapId: AppConst.GOOGLE_MAP_ID,
    zoom: 9,
    center: this.mapService.getDefaultCenter(),
    disableDefaultUI: false,
  };
  mapStyles = [
    {
      stylers: [
        {
          saturation: -100,
        },
      ],
    },
  ];

  wbgtMarks: {
    position: google.maps.LatLngLiteral;
    options?: google.maps.MarkerOptions;
    icon?: google.maps.Symbol;
    label?: google.maps.MarkerLabel;
    title?: string;
    siteId?: string;
    zIndex?: number;
    visible?: boolean;
  }[] = [];

  temperatureMarks: {
    position: google.maps.LatLngLiteral;
    options?: google.maps.MarkerOptions;
    icon?: google.maps.Symbol;
    label?: google.maps.MarkerLabel;
    title?: string;
    siteId?: string;
    zIndex?: number;
    visible?: boolean;
  }[] = [];

  windMarks: {
    position: google.maps.LatLngLiteral;
    options?: google.maps.MarkerOptions;
    icon?: google.maps.Symbol;
    label?: google.maps.MarkerLabel;
    title?: string;
    siteId?: string;
    visible?: boolean;
  }[] = [];

  constructor(
    private accountService: AccountService,
    private siteService: SiteService,
    private sensorService: SensorService,
    private router: Router,
    private mapApiService: GoogleMapApiService
  ) {}

  async ngOnInit(): Promise<void> {
    this.apiLoaded = await firstValueFrom(this.mapApiService.setApiUrl());
    if (this.apiLoaded) {
      await this.setAll();

      // RELOAD_INTERVAL毎のページデータリロード
      new Promise(async () => {
        this.reloadTimer = setInterval(async () => {
          await this.setAll();
          this.toggleMarkers();
        }, AppConst.RELOAD_INTERVAL);
      });
    }
  }

  async setAll(): Promise<void> {
    await this.setWbgtMarkers();
    await this.setTemperatureMarkers();
    await this.setWindMarkers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.toggleMarkers();
  }

  ngOnDestroy(): void {
    if (this.reloadTimer != undefined) clearTimeout(this.reloadTimer);
  }

  getOrganizationId() {
    return (
      this.accountService.getOrganizationId() ??
      AppConst.DEFAULT_ORGANIZATION_ID
    );
  }

  async setWbgtMarkers() {
    // 毎回空にする
    this.wbgtMarks.length = 0;

    const sites = await this.siteService.getAllWithOrganization();

    sites.forEach(async (site: Site) => {
      const latestSiteData = await this.siteService.getLatestSiteData(site.id);

      let url = '';
      let zIndex = 0;
      if (latestSiteData?.siteStatusNo !== undefined) {
        url = `assets/images/wbgt-s/mappin-${latestSiteData?.siteStatusNo}.png`;
        if (latestSiteData.siteStatusNo === '99') {
          zIndex = 0;
        } else {
          zIndex = Number(latestSiteData?.siteStatusNo);
        }
      } else {
        url = 'assets/images/wbgt-s/mappin-99.png';
        zIndex = 0;
      }

      let title = '';
      if (latestSiteData?.sensorData === undefined) {
        title = '----';
      }
      if (latestSiteData?.sensorData?.wbgt === undefined) {
        title = '----';
      }
      if (latestSiteData?.sensorData?.wbgt !== undefined) {
        const wbgtData = latestSiteData?.sensorData?.wbgt;
        const titleArray = [];
        for (let i = 0; i < wbgtData.length; i++) {
          titleArray.push(
            `${wbgtData[i].positionName}: ${
              wbgtData[i].wbgt ? wbgtData[0].wbgt?.toFixed(1) : '-'
            } ℃`
          );
        }
        title = site.name + '\n' + titleArray.join('\n');
      }

      this.wbgtMarks.push({
        siteId: site.id,
        position: {
          lat: site.geopoint?.latitude!,
          lng: site.geopoint?.longitude!,
        },
        options: {
          icon: {
            url: url,
          },
          zIndex: zIndex,
          cursor: this.clickable ? 'pointer' : 'grab',
        } as google.maps.MarkerOptions,
        title: title,
        visible: this.isWbgtMarker,
      });
    });
  }

  async setTemperatureMarkers() {
    // 毎回空にする
    this.temperatureMarks.length = 0;

    const sites = await this.siteService.getAllWithOrganization();

    sites.forEach(async (site: Site) => {
      const latestSiteData = await this.siteService.getLatestSiteData(site.id);

      let url = '';
      let zIndex = 0;
      if (latestSiteData?.siteTemperatureStatusNo !== undefined) {
        url = `assets/images/wbgt-w/mappin-${latestSiteData?.siteTemperatureStatusNo}.png`;
        if (latestSiteData.siteTemperatureStatusNo === '99') {
          zIndex = 0;
        } else {
          zIndex = Number(latestSiteData?.siteTemperatureStatusNo);
        }
      } else {
        url = 'assets/images/wbgt-w/mappin-99.png';
        zIndex = 0;
      }

      this.temperatureMarks.push({
        siteId: site.id,
        position: {
          lat: site.geopoint?.latitude!,
          lng: site.geopoint?.longitude!,
        },
        options: {
          icon: {
            url: url,
          },
          zIndex: zIndex,
          cursor: this.clickable ? 'pointer' : 'grab',
        } as google.maps.MarkerOptions,
        title: `${site.name}\n${
          latestSiteData?.siteTemperature
            ? latestSiteData?.siteTemperature?.toFixed(1)
            : '-'
        } ℃`,
        visible: this.isTemperatureMarker,
      });
    });
  }

  async setWindMarkers(): Promise<void> {
    // 毎回空にする
    this.windMarks.length = 0;

    const windSensors = await this.sensorService.getAllWindWithOrganization(
      this.getOrganizationId()
    );

    if (windSensors === null) return;

    windSensors.forEach(async (windSensor: Sensor) => {
      if (windSensor.siteId === undefined) {
        return;
      }
      const site: Site | null = await this.siteService.get(windSensor.siteId!);
      if (site === null) {
        return;
      }

      const latestSiteData = await this.siteService.getLatestSiteData(site.id);
      if (latestSiteData === null) {
        return;
      }

      let url = '';
      let zIndex = 0;
      if (
        latestSiteData.siteWindStatusNo !== undefined &&
        latestSiteData.siteWindStatusNo !== '99'
      ) {
        url = `assets/images/wind-${latestSiteData.siteWindStatusNo}/${latestSiteData.siteWindDirection}.png`;
        zIndex = Number(latestSiteData.siteWindStatusNo);
      } else {
        if (latestSiteData.siteWindDirection === undefined) {
          url = `assets/images/wind-99/0.png`;
        } else {
          url = `assets/images/wind-99/${latestSiteData.siteWindDirection}.png`;
        }
        zIndex = 0;
      }

      this.windMarks.push({
        siteId: site.id,
        position: {
          lat: site.geopoint?.latitude!,
          lng: site.geopoint?.longitude!,
        },
        options: {
          icon: {
            url: url,
          },
          zIndex: zIndex,
          cursor: this.clickable ? 'pointer' : 'grab',
        } as google.maps.MarkerOptions,
        title: `${site.name}\n${windSensor.position}: ${
          latestSiteData?.siteWindSpeed
            ? latestSiteData?.siteWindSpeed?.toFixed(1)
            : '-'
        } m/s`,
        visible: this.isWindMarker,
      });
    });
  }

  toggleMarkers() {
    for (let m of this.wbgtMarks) {
      m.visible = this.isWbgtMarker;
    }
    for (let m of this.temperatureMarks) {
      m.visible = this.isTemperatureMarker;
    }
    for (let m of this.windMarks) {
      m.visible = this.isWindMarker;
    }
  }

  onClickMarker(siteId: string | undefined) {
    if (this.clickable === false) return;

    this.router.navigate([
      UrlConst.SLASH + UrlConst.PATH_SITE_DETAIL + UrlConst.SLASH + siteId,
    ]);
  }
}
