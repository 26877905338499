import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-site-config-drawing-compass',
  templateUrl: './site-config-drawing-compass.component.html',
  styleUrls: ['./site-config-drawing-compass.component.scss'],
})
export class SiteConfigDrawingCompassComponent implements OnInit {
  @Input() angle: number = 0;
  @Output() compassAngle: EventEmitter<number> = new EventEmitter();

  // angle: number = 0;

  constructor(private formBuilder: UntypedFormBuilder) {}

  form = this.formBuilder.group({
    angle: ['0'],
  });

  ngOnInit(): void {
    this.form.get('angle')?.setValue(this.angle);
  }

  onChangeAngle(event: any) {
    if (this.form.get('angle')?.value < 0) {
      this.angle = 360 + this.form.get('angle')?.value;
    } else {
      this.angle = +this.form.get('angle')?.value;
    }
    this.compassAngle.emit(this.angle);
  }
}
