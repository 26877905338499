<p class="page-title">現場情報登録 / 変更</p>
<mat-card class="config-site-drawing-card">
  <mat-card class="config-site-drawing-card-1">
    <mat-card-header>
      <mat-card-title>現場図面設定</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button mat-raised-button color="primary" (click)="toggleBoard()">
        WBGT{{ sensors[1].positionName }}
      </button>
      <button mat-raised-button color="primary" (click)="toggleTopFloor()">
        WBGT{{ sensors[0].positionName }}
      </button>
    </mat-card-content>
  </mat-card>
  <div class="config-site-drawing-card-container">
    <mat-card class="config-site-drawing-card-2">
      <label for="fileupload" class="filelabel">図面アップロード</label>
      <input
        id="fileupload"
        name="fileupload"
        class="fileupload"
        type="file"
        mat-raised-button
        color="primary"
        (change)="onChange($event)"
      />
      <div class="config-site-drawing-2">
        <div class="site-drawing-container">
          <div
            *ngIf="imageSrc && toggleTopFloorBox"
            id="topFloorBox"
            class="top-floor-box"
            cdkDragBoundary=".site-drawing-container"
            [ngStyle]="topFloorBoxStyle"
            cdkDrag
            (cdkDragReleased)="onRelease($event, 'top_floor')"
          >
            {{ sensors[0].positionName }}
          </div>
          <div
            *ngIf="imageSrc && toggleBoardBox"
            id="boardBox"
            class="board-box"
            cdkDragBoundary=".site-drawing-container"
            [ngStyle]="boardBoxStyle"
            cdkDrag
            (cdkDragReleased)="onRelease($event, 'board')"
          >
            {{ sensors[1].positionName }}
          </div>
          <img [src]="imageSrc" class="drawing-image" />
        </div>
      </div>
    </mat-card>
    <ng-container *ngIf="windEnable && isExistsImage && site">
      <div class="site-config-drawing-compass-container">
        <app-site-config-drawing-compass
          [angle]="compassAngle"
          (compassAngle)="onChangeCompassAngle($event)"
        >
        </app-site-config-drawing-compass>
      </div>
    </ng-container>
  </div>
  <mat-card class="config-site-drawing-card-3">
    <div class="config-site-drawing-3">
      <button mat-raised-button color="primary" (click)="onClick()">
        登録して一覧に戻る
      </button>
      <button mat-raised-button color="primary" (click)="clickCancel()">
        キャンセル
      </button>
    </div>
  </mat-card>
</mat-card>
