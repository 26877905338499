import { AppConst } from 'src/app/shared/constants/app-const';
import { AccountService } from 'src/app/shared/services/account.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SiteService } from 'src/app/shared/services/site.service';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteListService {
  constructor(
    private loading: LoadingService,
    private accountService: AccountService,
    private siteService: SiteService,
    private sensorService: SensorService
  ) {}

  isAvailableGroup() {
    return this.accountIsSysAdminGroup() || this.accountIsGeneralGroup();
  }

  accountIsSysAdminGroup() {
    return this.accountService.isSysAdminGroup();
  }

  accountIsGeneralGroup() {
    return this.accountService.isGeneralGroup();
  }

  getOrganizationId() {
    return (
      this.accountService.getOrganizationId() ??
      AppConst.DEFAULT_ORGANIZATION_ID
    );
  }

  getSites() {
    return this.siteService.findAllWithOrganization(this.getOrganizationId());
  }

  getSite(id: string) {
    return this.siteService.findOne(id);
  }

  // 一般ユーザの現場を取得する
  // defaultSiteが属性設定されている時は、そちらの現場を取得する
  // それ以外は通常のユーザID == 現場IDとして現場を抽出する
  getMySite() {
    const account = this.accountService.getUser();
    if (account?.defaultSite) {
      return this.siteService.findOne(account!.defaultSite);
    }

    return this.siteService.findOne(account!.user);
  }

  async deleteConfig(siteId: string) {
    this.loading.startLoading();
    try {
      await this.sensorService.deleteSite(siteId);
      await this.siteService.delete(siteId);
    } catch (error) {
      console.log('SiteListService.deleteConfig(): ', error);
    } finally {
      this.loading.stopLoading();
    }
  }
}
