import { Subscription, take } from 'rxjs';
import { QrCodeDialogComponent } from 'src/app/shared/components/qr-code-dialog/qr-code-dialog.component';
import { YesNoDialogComponent } from 'src/app/shared/components/yes-no-dialog/yes-no-dialog.component';
import { AppConst } from 'src/app/shared/constants/app-const';
import { UrlConst } from 'src/app/shared/constants/url-const';
import { Site } from 'src/app/shared/interfaces/site';
import { YesNoDialogData } from 'src/app/shared/interfaces/yes-no-dialog-data';
import { AccessLogService } from 'src/app/shared/services/access-log.service';
import { environment } from 'src/environments/environment';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { SiteListService } from './site-list.service';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss'],
})
export class SiteListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'address', 'action'];
  dataSource: Site[] = [];
  subscriptions: Subscription = new Subscription();

  isSysAdmin = false;

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private logService: AccessLogService,
    public dialog: MatDialog,
    private siteListService: SiteListService
  ) {}

  ngOnInit() {
    this.logService.addLog(UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG);

    if (!this.siteListService.isAvailableGroup()) {
      this.router.navigateByUrl(UrlConst.SLASH + UrlConst.PATH_SIGN_IN);
      return;
    }

    if (this.siteListService.accountIsSysAdminGroup()) {
      this.isSysAdmin = true;

      this.subscriptions.add(
        this.siteListService
          .getSites()
          .subscribe((sites) => (this.dataSource = sites))
      );
    }
    if (this.siteListService.accountIsGeneralGroup()) {
      this.isSysAdmin = false;

      this.subscriptions.add(
        this.siteListService
          .getMySite()
          .subscribe((site) => (this.dataSource = [site]))
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clickConfigCreator(siteId: string) {
    this.router.navigate([
      UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG +
        UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG_UPDATE,
      siteId,
    ]);
  }

  clickConfigSensor(siteId: string) {
    this.router.navigate([
      UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG +
        UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG_SENSOR,
      siteId,
    ]);
  }

  clickConfigDrawing(siteId: string) {
    this.router.navigate([
      UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG +
        UrlConst.SLASH +
        UrlConst.PATH_SITE_CONFIG_DRAWING,
      siteId,
    ]);
  }

  clickConfigAlert(siteId: string) {
    this.router.navigate([
      UrlConst.SLASH + UrlConst.PATH_SITE_CONFIG_ALERT,
      siteId,
    ]);
  }

  async openQRCodeDialog(siteId: string) {
    let _site: Site | null = null;
    this.siteListService
      .getSite(siteId)
      .pipe(take(1))
      .subscribe((site) => {
        _site = site;

        if (_site === null) {
          return;
        }

        this.dialog.open(QrCodeDialogComponent, {
          data: {
            siteId: _site.id,
            siteUrl:
              'https://' +
              environment.fqdn +
              UrlConst.SLASH +
              'q/' +
              _site.uuid,
          },
        });
      });
  }

  async clickConfigDelete(siteId: string) {
    let _site: Site | null = null;
    this.siteListService
      .getSite(siteId)
      .pipe(take(1))
      .subscribe((site) => {
        _site = site;

        if (_site === null) {
          return;
        }

        const dialogData: YesNoDialogData = {
          title: AppConst.YESNO_DIALOG_DELETE_SITE_TITLE + `　(${_site.name})`,
          message: AppConst.YESNO_DIALOG_DELETE_SITE_MESSAGE,
          captionNo: AppConst.YESNO_DIALOG_CAPTION_NO,
          captionYes: AppConst.YESNO_DIALOG_CAPTION_YES,
        };

        const dialogRef = this.matDialog.open(YesNoDialogComponent, {
          height: AppConst.YES_NO_DIALOG_HEIGHT,
          width: AppConst.YES_NO_DIALOG_WIDTH,
          data: dialogData,
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            try {
              await this.siteListService.deleteConfig(siteId);
            } catch (error) {
              console.log('SiteConfigPageComponent.deleteConfig(): ', error);
            }
          }
        });
      });
  }
}
