import {
  NoiseLatestSiteData,
  WbgtLatestSiteData,
  WindLatestSiteData,
} from 'src/app/shared/interfaces/latest-site-data';
import { SensorHistory } from 'src/app/shared/interfaces/sensor-history';

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SiteDetailChartsService } from '../site-detail-charts/site-detail-charts.service';

@Component({
  selector: 'app-site-detail-chart',
  templateUrl: './site-detail-chart.component.html',
  styleUrls: ['./site-detail-chart.component.scss'],
})
export class SiteDetailChartComponent implements OnChanges {
  constructor(private siteDetailChartsService: SiteDetailChartsService) {}

  @Input() chartRange: string = '';
  @Input() wbgtData: WbgtLatestSiteData | undefined = undefined;
  @Input() windData: WindLatestSiteData | undefined = undefined;
  @Input() noiseData: NoiseLatestSiteData | undefined = undefined;

  isSummer: boolean = false;
  isWinter: boolean = false;
  isWbgtChart: boolean = false;
  isWindChart: boolean = false;
  isNoiseChart: boolean = false;

  sensorId: string = '';

  wbgtDataLabel: string[] = [];
  wbgtDataList: number[] = [];
  temperatureDataList: number[] = [];
  humidityDataList: number[] = [];
  windDataLabel: string[] = [];
  windSpeedDataList: number[] = [];
  noiseDataLabel: string[] = [];
  noiseDataList: number[] = [];

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.isSummer = this.siteDetailChartsService.isSummer();
    this.isWinter = this.siteDetailChartsService.isWinter();

    if (this.wbgtData !== undefined) {
      this.sensorId = this.wbgtData.id;
      this.isWbgtChart = true;
      this.isWindChart = false;
      this.isNoiseChart = false;
    } else if (this.windData !== undefined) {
      this.sensorId = this.windData.id;
      this.isWbgtChart = false;
      this.isWindChart = true;
      this.isNoiseChart = false;
    } else if (this.noiseData !== undefined) {
      this.sensorId = this.noiseData.id;
      this.isWbgtChart = false;
      this.isWindChart = false;
      this.isNoiseChart = true;
    }

    const histories = await this.getHistories();
    this.clearAllData();
    this.createDataSets(histories);
  }

  async getHistories() {
    let histories: SensorHistory[] | null = null;
    if (this.chartRange === '' || this.chartRange === 'daytime') {
      histories = await this.siteDetailChartsService.getDaytimeSensorHistory(
        this.sensorId
      );
    } else if (this.chartRange === 'day') {
      histories = await this.siteDetailChartsService.getDaySensorHistory(
        this.sensorId
      );
    } else if (this.chartRange === 'week') {
      histories = await this.siteDetailChartsService.getWeekSensorHistory(
        this.sensorId
      );
    } else if (this.chartRange === 'month') {
      histories = await this.siteDetailChartsService.getMonthSensorHistory(
        this.sensorId
      );
    }

    return histories;
  }

  createDataSets(histories: SensorHistory[] | null) {
    if (histories === null) return;

    if (this.isWbgtChart) {
      const result =
        this.siteDetailChartsService.createWbgtChartDataSets(histories);
      if (result !== undefined) {
        this.wbgtDataLabel = result.label;
        this.wbgtDataList = result.wbgt;
        this.temperatureDataList = result.temperature;
        this.humidityDataList = result.humidity;
      }
    } else if (this.isWindChart) {
      const result =
        this.siteDetailChartsService.createWindChartDataSets(histories);
      if (result !== undefined) {
        this.windDataLabel = result.label;
        this.windSpeedDataList = result.speed;
      }
    } else if (this.isNoiseChart) {
      console.log(histories);
      const result =
        this.siteDetailChartsService.createNoiseChartDataSets(histories);

      console.log(result);
      if (result !== undefined) {
        this.noiseDataLabel = result.label;
        this.noiseDataList = result.noise;
      }
    }
  }

  clearAllData() {
    this.wbgtDataLabel = [];
    this.wbgtDataList = [];
    this.temperatureDataList = [];
    this.humidityDataList = [];
    this.windDataLabel = [];
    this.windSpeedDataList = [];
    this.noiseDataLabel = [];
    this.noiseDataList = [];
  }
}
