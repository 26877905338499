<mat-card class="status-signal-11">
  <div class="w-full text-center">
    <span class="site-detail-alert-time">{{ date }}</span>
    にWBGTステータスが
    <span class="site-detail-alert-status">厳重警戒</span>
    になりました。
  </div>
  <div class="w-full mt-8 text-center" *ngIf="!isOuter">
    <div>
      <a
        class="underline-offset-2 underline text-blue-900"
        href="/assets/pdf/wbgt.pdf"
        target="_blank"
      >
        ◆安全資料のリンク
      </a>
    </div>
  </div>
</mat-card>
